'use strict';

var safeGet = require('utils/code-checks').safeGet;
var erro = require('utils/erro-ajax');
var api = require('api');

module.exports = {

  view: function (ctrl, args) {
    var documento = safeGet(args, 'prop');
    var nome = args.validaNome;

    return m('div#select-orgao.input-container', {
      class: nome && nome.erro && nome.erro()
    }, m.component(require('componentes/select2'), {
      ajax: {
        url: '/documentos/buscar-por-nome',
        dataType: 'json',
        delay: 250,
        data: function (params) {
          return {
            q: params.term
          };
        },
        processResults: function (data, page) {
          var result = _.map(data, function (o) {
            return {
              id: o.id,
              text: o.nome
            };
          });

          return {
            results: result
          };
        },
        cache: true
      },
      prop: documento,
      bloquear: args.bloquear,
      width: '100%',
      minimumResultsForSearch: 1,
      minimumInputLength: 3,
      initSelection: function (element, callback) {
        api.obterNomeOrgao(documento())
            .then(function (orgaoNome) {
              callback({
                id: documento(),
                text: orgaoNome
              });
            }, erro);
      }
    }));
  }
};
