'use strict';

var permissoes = require('utils/permissoes');

module.exports = {

    view: function (ctrl, args) {
        return m('span.novo',
            m('div.btn-menu', [
                m('a.button#permissoes', {href: '#'}, [
                    m('i.fa.fa-cogs'),
                    m.trust('Administração')
                ]),
                m('ul.menu-dropdown', [
                    permissoes.ehAdmin() ? m('li', m('a',
                        {href: '/editar/administracao/importar-ajuda'}, [
                            m('i.fa.fa-upload'),
                            m.trust('Importação de arquivo de ajuda')
                        ])) : '',
                    permissoes.ehAdmin() ? m('li', m('a',
                        {href: '/editar/administracao/habilitar-selo-digital'}, [
                            m('i.fa.fa-check-square-o'),
                            m.trust('Habilitar/desabilitar selos digitais')
                        ])) : '',

                    permissoes.ehAdmin() ? m('li', m('a',
                        {href: 'editar/administracao/habilitar-brasil-cidadao'}, [
                            m('i.fa.fa-check-square-o'),
                            m.trust('Habilitar/desabilitar Brasil Cidadão')
                        ])): '',

                    permissoes.ehAdmin() ? m('li', m('a',
                        {href: 'editar/administracao/habilitar-chatbot'}, [
                            m('i.fa.fa-check-square-o'),
                            m.trust('Habilitar/desabilitar ChatBot')
                        ])): '',

                    m('li.btn-dropdown', [
                        m('a',
                            {href: '#'}, [
                                m('i.fa.fa-pencil-square-o'),
                                m.trust('Gerenciar unidades de atendimento')
                            ]),
                        m('ul.menu-dropdown', [
                            m('li', m('a',
                                {href: '/editar/administracao/unidade/editor'}, [
                                    m('i.fa.fa-building-o'),
                                    m.trust('Cadastrar nova unidade')
                                ])
                            ),
                            m('li', m('a',
                                {href: '/editar/administracao/unidade/importar'}, [
                                    m('i.fa.fa-upload'),
                                    m.trust('Importar unidades')
                                ])
                            ),
                            m('li', m('a',
                                {href: '/editar/administracao/unidade/lista'}, [
                                    m('i.fa.fa-list'),
                                    m.trust('Listar unidades')
                                ])
                            ),
                            !permissoes.ehEditor() ? m('li', m('a',
                                {href: '/editar/administracao/unidade/atribuir'}, [
                                    m('i.fa.fa-exchange'),
                                    m.trust('Atribuir Serviços')
                                ])
                            ) : ''
                        ])
                    ]),

                    m('li.btn-dropdown', permissoes.ehAdmin() ? [
                        m('a',
                            {href: '#'}, [
                                m('i.fa.fa-pencil-square-o'),
                                m.trust('Gerenciar portais externos')
                            ]),
                        m('ul.menu-dropdown', [
                            m('li', m('a',
                                {href: '/editar/administracao/portais-externos/cadastro'}, [
                                    m('i.fa.fa-plus'),
                                    m.trust('Cadastrar portal externo')
                                ])
                            ),

                            m('li', m('a',
                                {href: '/editar/administracao/portais-externos/lista'}, [
                                    m('i.fa.fa-list'),
                                    m.trust('Listar portais externos')
                                ])
                            )
                        ])
                    ] : ''),


                    m('li.btn-dropdown', [
                        permissoes.ehAdmin() ? m('a',
                            {href: '#'}, [
                                m('i.fa.fa-university'),
                                m.trust('Gerenciar Órgãos')
                            ]) : '',
                        m('ul.menu-dropdown', [
                            m('li', m('a',
                                {href: '/editar/administracao/orgao/integracao'}, [
                                    m('i.fa.fa-puzzle-piece'),
                                    m.trust('Integração SIORG')
                                ])
                            ),
                            m('li', m('a',
                                {href: '/editar/administracao/orgao/lista'}, [
                                    m('i.fa.fa-search'),
                                    m.trust('Pesquisar Órgão')
                                ])
                            ),
                            m('li', m('a',
                                {href: '/editar/administracao/orgao/editor'}, [
                                    m('i.fa.fa-plus'),
                                    m.trust('Criar Órgão')
                                ])
                            )
                        ])
                    ]),
                    m('li.btn-dropdown', [
                        permissoes.ehAdmin() ? m('a',
                            {href: '#'}, [
                                m('i.fa.fa-star'),
                                m.trust('Gerenciar Página de Destaque')
                            ]) : '',
                        m('ul.menu-dropdown', [
                            m('li', m('a',
                                {href: '/editar/administracao/pagina-destaque/servico'}, [
                                    m('i.fa.fa-star'),
                                    m.trust('Gerenciar Serviços de Destaque')
                                ])
                            ),
                            m('li', m('a',
                                {href: '/editar/administracao/pagina-destaque/categoria'}, [
                                    m('i.fa.fa-star'),
                                    m.trust('Gerenciar Categorias de Destaque')
                                ])
                            ),
                        ])
                    ]),
                    m('li', permissoes.ehAdmin() ? m('a',
                        {href: '/editar/administracao/categorias'}, [
                            m('i.fa.fa-folder-open'),
                            m.trust('Gerenciar Categorias')
                        ]) : ''),

                    m('li', permissoes.ehAdmin() ? m('a',
                        {href: '/editar/administracao/menu-rodape'}, [
                            m('i.fa.fa-pencil-square-o'),
                            m.trust('Gerenciar Menu e Rodapé')
                        ]) : ''),

                    m('li', permissoes.ehAdmin() ? m('a',
                        {href: '/editar/administracao/relatorio'}, [
                            m('i.fa.fa-thumbs-up'),
                            m.trust('Gerar Relatório das Avaliações')
                        ]) : ''),
                    m('li', permissoes.ehAdmin() ? m('a',
                        {href: '/editar/administracao/verificar-links'}, [
                            m('i.fa.fa-search'),
                            m.trust('Verificar Links Quebrados')
                        ]) : '')
                ])
            ])
        );
    }
};
