/**
 * @class AtribuirServicoView
 */
var ListaUnidadeController = (function (m, require) {

    return function (args) {
        var CabecalhoModel = require('cabecalho/cabecalho-model');
        var api = require('api');
        var avisos = require('utils/avisos');
        var Validacoes = require('utils/validacoes');

        var self = this;
        this.cabecalho = new CabecalhoModel();
        this.caiuSessao = m.prop(false);

        this.filtro = m.prop('');
        this.orgaos = [];

        this.listarOrgaoSemSiorg = function(){
            api.listarOrgaoSemSiorg().then(function (response) {
                self.orgaos = JSON.parse(response).resposta
            });
        }

        this.excluirOrgao = function (orgao) {
            alertify.labels.cancel = 'Cancelar';
            alertify.labels.ok = 'Remover';
            alertify.confirm('Você tem certeza que deseja remover a unidade: ' + orgao.nomeOrgao + '?', function (result) {
                if (result) {
                    api.removerUnidadeSemSiorg(orgao)
                        .then(this.listarOrgaoSemSiorg);
                }
            }.bind(this));
        };

        this.pesquisar = function (termo) {
            api.buscarOrgaoSemSiorg(termo).then(function (response) {
                self.orgaos = JSON.parse(response).resposta
            });
        };

        this.voltarPagina = function () {
            history.go(-1);
        };

        this.listarOrgaoSemSiorg();

    };
})(m, require);

module.exports = ListaUnidadeController;