/**
 * Created by ygorazevedo on 2/24/17.
 */
'use strict';

var orgaoToJson = function (orgao) {
    return {
        dbId: orgao.dbId(),
        id: orgao.codigoSiorg()
    };
};

var paisToJson = function (pais) {
    return {
        id: pais.id(),
        nome: pais.nome(),
        nomeIngles: pais.nomeIngles()
    };
};

var estadoToJSON = function (estado) {
    return {
        id: estado.id(),
        nome: estado.nome(),
        sigla: estado.sigla(),
        pais: paisToJson(estado.pais())
    };
};

var cidadeToJSON = function (cidade) {
    return {
        id: cidade.id(),
        nome: cidade.nome(),
        codigoIbge: cidade.codigoIbge(),
        estado: estadoToJSON(cidade.estado())
    };
};

var enderecoToJson = function (endereco) {
    if(endereco.pais().id() != 33){
        return {
            logradouro: endereco.logradouro(),
            numero: endereco.numero(),
            complemento: endereco.complemento(),
            paisDTO: paisToJson(endereco.pais()),
            estadoExterior: endereco.estadoExterior(),
            cidadeExterior: endereco.cidadeExterior(),
            bairro: endereco.bairro(),
            cep: endereco.cep()
        };
    } else {
        return {
            logradouro: endereco.logradouro(),
            numero: endereco.numero(),
            complemento: endereco.complemento(),
            paisDTO: paisToJson(endereco.pais()),
            estadoDTO: estadoToJSON(endereco.estado()),
            cidadeDTO: cidadeToJSON(endereco.cidade()),
            bairro: endereco.bairro(),
            cep: endereco.cep()
        };
    }
};

var horarioAtendimentoToJson = function (ha) {
    return {
        id: ha.id(),
        horaInicio: ha.horaInicio(),
        horaFim: ha.horaFim(),
        diaInicio: ha.diaInicio(),
        diaFim: ha.diaFim()
    }
};

var unidadeToJson = function (ua) {
    return {
        id : ua.id(),
        orgao : orgaoToJson(ua.orgao()),
        nome : ua.nome(),
        cnpj : ua.cnpj(),
        email : ua.email(),
        site: ua.site(),
        rip: ua.rip(),
        endereco : enderecoToJson(ua.endereco()),
        telefone : ua.telefone(),
        horarios : ua.horarioDeAtendimento().map(horarioAtendimentoToJson),
        longitude : ua.longitude(),
        latitude : ua.latitude(),
        informacoes : ua.informacoes()
    };
};

module.exports = {
    unidadeToJson: unidadeToJson,
    horarioAtendimentoToJson: horarioAtendimentoToJson,
    cidadeToJSON: cidadeToJSON,
    estadoToJSON: estadoToJSON,
    paisToJson: paisToJson,
    enderecoToJson: enderecoToJson,
    orgaoToJson: orgaoToJson
}