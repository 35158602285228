'use strict';

var safeGet = require('utils/code-checks').safeGet;
var erro = require('utils/erro-ajax');
var api = require('api');

module.exports = {

  view: function (ctrl, args) {
    var estado = safeGet(args, 'prop');
    var estados = args.estados;

    return m('div#select-estado.input-container',
      m.component(require('componentes/select2'), {
      data: estados,
      prop: estado,
      width: '55em',
      minimumResultsForSearch: 1,
      onchange: args.onchange
    }));
  }
};
