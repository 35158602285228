var AtribuirServicoView = (function (m, require) {
    /**
     * @param {AtribuirServicoView} ctrl
     * @param args
     */
    return function (ctrl, args) {

        var tooltips = require('tooltips');

        const UNIDADE_ATRIBUIR_PATH = 'administracao/unidade/atribuir-servico';

        var atribuir = {
            publicar: _.bind(ctrl.publicar, ctrl),
            publicarButton: true,
            publicarButtonDisable: ctrl.isAtribuido,
            descartarButton: false,
            publicarSucessoMsg: 'Unidades publicadas com sucesso!',
            publicarErroMsg: 'Erro ao publicar. Tente novamente em alguns minutos',
            thenn: _.bind(ctrl.thenn, ctrl),
            publicando: ctrl.publicando
        };

        return m('#conteudo',[
            m('span.cabecalho-cor'),
            m('#wrapper', [
                m.component(require('cabecalho/cabecalho'), {
                    metadados: true,
                    atribuir: atribuir,
                    nomeDaPagina: 'Atribuir Serviços',
                    logout: true,
                    caiuSessao: m.prop(false)
                }),
                m('nav#menu-lateral', [
                    m('#secao-despublicar', [
                        m('hr'),
                        m('label', [
                            m('', [
                                'Status: ',
                                m('span.publicado', ctrl.isPublicado() ? 'publicado' : 'despublicado')
                            ])
                        ]),
                        m('button.botao-primario', {
                            onclick: ctrl.despublicar,
                            disabled: !ctrl.isPublicado()
                        }, ctrl.despublicando() ? m('i.fa.fa-spin.fa-spinner') : 'despublicar')
                    ])
                ]),
                m('div#admin', [
                    m('div.scroll', [
                        m('fieldset', [
                            m('div', [
                                m('h3', [
                                    'Órgão responsável',
                                    m.component(tooltips.orgaoResponsavel)
                                ]),
                                m.component(require('pagina/orgao/select-orgao'), {
                                    prop: ctrl.orgao.codigoSiorg,
                                    bloquear: ctrl.bloquearOrgao,
                                    validaNome: ctrl.orgao.codigoSiorg,
                                    onchange: _.bind(ctrl.listarUnidade, ctrl)
                                })
                            ]),
                            m('.campo', [
                                m('h3', 'Serviços / Páginas Temáticas'),
                                m.component(require(UNIDADE_ATRIBUIR_PATH + '/select-servico-pagina-tematica'), {
                                    prop: ctrl.servico,
                                    codSiorg: ctrl.orgao.codigoSiorg,
                                    validaNome: ctrl.orgao.codigoSiorg,
                                    servicosPaginas: ctrl.servicosPaginas,
                                    onchange: _.bind(ctrl.buscarUnidadesAtribuidas, ctrl)
                                })
                            ]),
                            m('.campo', [
                                m('h3', 'Unidades de Atendimento Relacionadas ao Serviço / Página Temática'),
                                m('div.unidade-select', [
                                    m('table#atribuirTable', [
                                        m('tr', [
                                            m('th.text-center.botao-td', 'Todas as Unidades'),
                                            m('td'),
                                            m('th.text-center.botao-td', 'Unidades atribuídas')
                                        ]),
                                        m('tr', [
                                            m('td[rowspan=4].select-td', [
                                                m('select[multiple]#selectParaAtribuir', {
                                                    onchange: ctrl.popularArrayParaAtribuirUnidade,
                                                    disabled: !ctrl.orgao.codigoSiorg() || !ctrl.servico()
                                                },[
                                                    ctrl.todasUnidades.map(function (u) {
                                                        return m('option', {
                                                            value: u.id,
                                                            text: ctrl.stringToEllipses(u.nome, 38),
                                                            title: u.nome
                                                        })
                                                    })
                                                ])
                                            ]),
                                            m('td.text-center.botao-td', [
                                                m('a.botao-atribuir', {
                                                    title: 'Atribuir todas as Unidades',
                                                    onclick: ctrl.atribuirTodasUnidade
                                                }, [
                                                    m('i.fa.fa-angle-double-right')
                                                ]),
                                            ]),
                                            m('td[rowspan=4].select-td', [
                                                m('select[multiple]#selectAtribuida', {
                                                    onchange: ctrl.popularArrayParaDesatribuirUnidade,
                                                    disabled: !ctrl.orgao.codigoSiorg() || !ctrl.servico()
                                                }, [
                                                    ctrl.unidadesAtribuidas.map(function (u) {
                                                        return m('option', {
                                                            value: u.id,
                                                            text: ctrl.stringToEllipses(u.nome, 38),
                                                            title: u.nome
                                                        })
                                                    })
                                                ]),
                                            ])
                                        ]),
                                        m('tr', [
                                            m('td.text-center.botao-td', [
                                                m('a.botao-atribuir', {
                                                    title: 'Atribuir Unidades selecionadas',
                                                    onclick: ctrl.atribuirUnidade
                                                }, [
                                                    m('i.fa.fa-angle-right')
                                                ]),
                                            ])
                                        ]),
                                        m('tr', [
                                            m('td.text-center.botao-td', [
                                                m('a.botao-atribuir', {
                                                    title: 'Desatribuir Unidades selecionadas',
                                                    onclick: ctrl.desatribuirUnidade
                                                }, [
                                                    m('i.fa.fa-angle-left')
                                                ]),
                                            ])
                                        ]),
                                        m('tr', [
                                            m('td.text-center.botao-td', [
                                                m('a.botao-atribuir', {
                                                    title: 'Desatribuir todas as Unidades',
                                                    onclick: ctrl.desatribuirTodasUnidade
                                                }, [
                                                    m('i.fa.fa-angle-double-left')
                                                ]),
                                            ])
                                        ]),
                                    ])
                                ])
                            ]),
                            m('.campo', [
                                m('button.botao-confirmar.button', {
                                    onclick: ctrl.atribuir,
                                    disabled: !ctrl.orgao.codigoSiorg() || ctrl.servico() == -1
                                }, ctrl.atribuindo ? m('i.fa.fa-spin.fa-spinner') : 'Salvar'),
                                m('button.botao-cancelar.button', {
                                    disabled: !(ctrl.servico() > -1),
                                    onclick: ctrl.buscarUnidadesAtribuidas
                                }, 'Cancelar')
                            ])
                        ])
                    ])
                ])
            ])
        ]);

    };
})(m, require);

module.exports = AtribuirServicoView;