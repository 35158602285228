    'use strict';

    var api = require('api');
    var slugify = require('slugify');
    var select = require('componentes/select2');

    var listaTempoPeriodo = [];
    var mapTempoPeriodo;
    var listaTempoMedida;

    var selectTipo = function (prop, tte) {
        var tipo = mapTempoPeriodo()[prop()];
        if(tipo){
            prop(tipo);
        }
        return m.component(require('componentes/select2'), {
            prop: prop,
            tte: tte,
            data: listaTempoPeriodo
        });
    };

    var selectAteEntre = function (prop, tte) {
        return m.component(require('componentes/select2'), {
            prop: prop,
            tte: tte,
            data: listaTempoMedida()
        });
    };


    var controller = function(args) {

        api.get('/lista-formulario/lista-tempo-periodos-slugfy')
            .then(function(lista) {
                mapTempoPeriodo = m.prop(lista);
                api.get('/lista-formulario/lista-tempo-periodos')
                    .then(function(lista) {
                        listaTempoPeriodo = [];
                        return lista.map(function(t) {
                            listaTempoPeriodo.push({ id: t.nomeTempoPeriodo, text: t.nomeTempoPeriodo});
                        });
                    });
            });

        listaTempoMedida = api.get('/lista-formulario/lista-tempo-medidas')
            .then(function(lista) {
                  return lista.map(function(t) {
                    return { id: slugify(t[1]), text: t[1] };
                });
            });

        this.tempoEstimado = args.servico().tempoTotalEstimado;
    };

    var view = function (ctrl, args) {
        var tte = args.servico().tempoTotalEstimado;

        return m('fieldset#tempo-total-estimado', [
            m('h3', [
                'Tempo estimado para realizar esse serviço',
                m.component(require('tooltips').tempoTotalEstimado)
            ]),

            selectAteEntre(tte().tipo, tte()),

            m('span.tipo-ate', {
                style: {
                        display: tte().tipo() === 'ate' || tte().tipo() === 'em-media' ? 'inline' : 'none'
                }
            }, [
                m('.input-container.inline.margin-right', {
                    class: tte().ateMaximo.erro()
                }, [m('input.ate-maximo[type="number"]', {
                    min: 1,
                    value: tte().ateMaximo(),
                    onchange: m.withAttr('value', tte().ateMaximo)
                })]),

                selectTipo(tte().ateTipoMaximo)
            ]),

            m('span.tipo-entre', {
                style: {
                    display: tte().tipo() === 'entre' ? 'inline' : 'none'
                }
            }, [
                m('.input-container.inline', {
                    class: tte().entreMinimo.erro()
                }, [m('input.entre-minimo[type="number"]', {
                    min: 1,
                    value: tte().entreMinimo(),
                    onchange: m.withAttr('value', tte().entreMinimo)
                })]),

                m('span', ' e '),

                m('.input-container.inline.margin-right', {
                    class: tte().entreMaximo.erro()
                }, [m('input.entre-maximo[type="number"]', {
                    value: tte().entreMaximo(),
                    onchange: m.withAttr('value', tte().entreMaximo)
                })]),

                selectTipo(tte().entreTipoMaximo)
            ]),

            m('label.titulo.opcional', 'Comentários sobre exceções ou informações adicionais ao tempo estimado'),

            m.component(require('componentes/editor-markdown'), {
                rows: 3,
                onchange: m.withAttr('value', tte().descricao),
                onblur: m.withAttr('value', tte().descricao),
                value: tte().descricao(),
                erro: tte().descricao.erro()
            })
        ]);
    };

    module.exports = {controller:controller, view:view};
