/**
 * Created by ygorazevedo on 1/23/17.
 */
'use strict';

var UnidadeAtendimento = require('administracao/unidade/modelos').UnidadeAtendimento;
var salvarUnidade = require('administracao/unidade/util/salvar').salvarUnidade;
var Api = require('api');

module.exports = {
    controller: function (args) {
        var self = this;
        this.unidadeAtendimento = null;
        var idUnidade = m.route.param('id');
        this.podeSalvar = true;

        if(idUnidade){
            this.alertMsg = 'Unidade de Atendimento alterada com sucesso.';
            Api.buscarUnidadePorId(idUnidade).then(function (response) {
                response = JSON.parse(response);
                self.unidadeAtendimento = new UnidadeAtendimento(response.resposta);
                var usuarioSiorgOuId = window.loggedUser.siorg;
                if(self.unidadeAtendimento.orgao().codigoSiorg().indexOf(usuarioSiorgOuId) == -1 &&
                    self.unidadeAtendimento.orgao().dbId().toString().indexOf(usuarioSiorgOuId) == -1) {
                    self.podeSalvar = false;
                }
            });
        } else {
            this.unidadeAtendimento = new UnidadeAtendimento();
            this.alertMsg = 'Unidade de Atendimento cadastrada com sucesso.';
        }

        this.salvar = function () {
            return salvarUnidade(this.unidadeAtendimento)
                .then(function (data) {
                    var ua = JSON.parse(data);
                    self.unidadeAtendimento = new UnidadeAtendimento(ua);
                    m.route('/editar/administracao/unidade/lista');
                });
        };

        this.salvandoUnidade = m.prop(false);
    },

    view: function (ctrl) {

        const UNIDADE_CADASTRO_PATH = 'administracao/unidade/cadastro';

        return m('#conteudo', [
            m('span.cabecalho-cor'),
            m('#wrapper', [
                m.component(require('cabecalho/cabecalho'), {
                    metadados: true,
                    nomeDaPagina: ctrl.unidadeAtendimento.nome() || 'Nova Unidade de Atendimento',
                    salvar: _.bind(ctrl.salvar, ctrl),
                    disabled: !ctrl.podeSalvar,
                    salvandoServico: ctrl.salvandoUnidade,
                    caiuSessao: m.prop(false),
                    logout: true,
                    alertMsg: ctrl.alertMsg
                }),
                m('div#admin', [
                    m('div.scroll', [
                        m.component(require(UNIDADE_CADASTRO_PATH + '/dados-basicos'), {
                            orgao: ctrl.unidadeAtendimento.orgao,
                            podeSalvar: ctrl.podeSalvar,
                            nome: ctrl.unidadeAtendimento.nome,
                            cnpj: ctrl.unidadeAtendimento.cnpj,
                            site: ctrl.unidadeAtendimento.site,
                            email: ctrl.unidadeAtendimento.email,
                            rip: ctrl.unidadeAtendimento.rip
                        }),
                        m.component(require(UNIDADE_CADASTRO_PATH + '/endereco'), {
                            endereco: ctrl.unidadeAtendimento.endereco
                        }),
                        m.component(require(UNIDADE_CADASTRO_PATH + '/atendimento'), {
                            telefone: ctrl.unidadeAtendimento.telefone,
                            horarioDeAtendimento: ctrl.unidadeAtendimento.horarioDeAtendimento
                        }),
                        m.component(require(UNIDADE_CADASTRO_PATH + '/informacoes'), {
                            informacoes: ctrl.unidadeAtendimento.informacoes
                        }),
                        m.component(require(UNIDADE_CADASTRO_PATH + '/localizacao'), {
                            latitude: ctrl.unidadeAtendimento.latitude,
                            longitude: ctrl.unidadeAtendimento.longitude
                        })
                    ])
                ])
            ])
        ]);
    }
};
