/**
 * Created by ygorazevedo on 3/17/17.
 */

var safeGet = require('utils/code-checks').safeGet;
var avisos = require('utils/avisos');
var promise = require('utils/promise');
var permissoes = require('utils/permissoes');
var api = require('api');

function botaoQueEspera(flagProp, opts) {
    return m('button#' + opts.id, {
        onclick: opts.onclick,
        disabled: (opts.disabled || flagProp() ? true : false)
    }, flagProp() ? m('i.fa.fa-spin.fa-spinner') : m('i.fa.fa-' + opts.icon));
};

module.exports = {
    controller: function (args) {
        this.publicar = safeGet(args, 'publicar');
        this.descartar = args.descartar;
        this.publicando = args.publicando || m.prop(false);
        this.descartando = m.prop(false);
        this.salvando = args.publicando || m.prop(false);
        this.publicarButton = args.publicarButton;
        this.publicarButtonDisable = args.publicarButtonDisable;
        this.descartarButton = args.descartarButton;
        this.descartarButtonDisable = args.descartarButtonDisable;
        this.publicarSucessoMsg = args.publicarSucessoMsg;
        this.publicarErroMsg = args.publicarErroMsg;
        this.descartarSucessoMsg = args.descartarSucessoMsg;
        this.descartarErroMsg = args.descartarErroMsg;
        this.thenn = args.thenn;
        
        var self = this;

        this.descartarClick = function () {
            this.opera(
                this.descartando,
                this.descartar()
                    .then(
                        avisos.sucessoFn(self.descartarSucessoMsg),
                        avisos.erroFn(self.descartarErroMsg)));
        };

        this.publicarClick = function () {
            this.opera(
                this.publicando,
                this.publicar()
                    .then(
                        self.thenn ? self.thenn : avisos.sucessoFn(self.publicarSucessoMsg),
                        avisos.erroFn(self.publicarErroMsg)));
        };

        this.opera = function (prop, operacao) {
            prop(true);
            this.salvando(true);
            m.redraw();
            promise.onSuccOrError(
                operacao,
                _.bind(function () {
                    self.thenn ? prop(true) : prop(false);
                    self.thenn ? this.salvando(true) : this.salvando(false);
                    m.redraw();
                }, this));
        };
    },

    view: function (ctrl) {
        return m('span#publicar-view', [
            m('span.label-botao', 'Publicar alterações?'),
            m.trust('&nbsp&nbsp'),

            ctrl.descartarButton ? botaoQueEspera(ctrl.descartando, {
                id: 'descartar',
                onclick: _.bind(ctrl.descartarClick, ctrl),
                icon: 'times',
                disabled: ctrl.salvando() || !ctrl.descartarButtonDisable()
            }) : '',

            ctrl.publicarButton ? botaoQueEspera(ctrl.publicando, {
                id: 'publicar',
                onclick: _.bind(ctrl.publicarClick, ctrl),
                icon: 'check',
                disabled: ctrl.salvando() || !ctrl.publicarButtonDisable()
            }) : ''
        ]);
    }
}