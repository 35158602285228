'use strict';

var fazBuscaPor = function(busca, paginas, key) {

    var mapChars = function(str) {
        return str
            .replace(/[¹]/ig,      '1')
            .replace(/[²]/ig,      '2')
            .replace(/[³]/ig,      '3')
            .replace(/[áàâãäª]/ig, 'a')
            .replace(/[éèêẽë]/ig,  'e')
            .replace(/[íìîĩï]/ig,  'i')
            .replace(/[óòôõöº°]/ig,'o')
            .replace(/[úùûũü]/ig,  'u')
            .replace(/[ç]/ig,      'c')
            .replace(/[\W]/ig,     '')
    };

    var mapped   = mapChars(_.trim(busca).toLowerCase());
    if (mapped === "") {
        return paginas;
    }

    var filtered = [];
    for (var i in paginas) {
        var page = paginas[i];
        var lkey = _.get(page, key).toLowerCase()
        var pFound = mapChars(lkey).match(".*?" + mapped + ".*");
        if (pFound != null) {
            filtered.push(page);
        }
    }

    return filtered;
};

module.exports = { fazBuscaPor:fazBuscaPor };