var PublicarCategoriasDestaqueView = (function (m, require) {
    /**
     * @param {AtribuirServicoView} ctrl
     * @param args
     */
    return function (ctrl, args) {

        var tooltips = require('tooltips');

        var atribuir = {
            salvando: ctrl.salvando,
            publicar: _.bind(ctrl.publicar, ctrl),
            publicarButton: true,
            publicarButtonDisable: ctrl.alterado,
            publicarSucessoMsg: 'Categorias em destaque publicadas com sucesso. As informações apareceram no Portal em até 15 minutos!',
            publicarErroMsg: 'Erro ao publicar as Categorias em destaque. Tente novamente em alguns minutos',
            descartar: _.bind(ctrl.despublicar, ctrl),
            descartarButton: true,
            descartarButtonDisable: ctrl.alterado,
            descartarSucessoMsg: 'As alteraçõs das Categorias em destaque foram descartadas.',
            descartarErroMsg: 'Erro ao descartar as alterações das Categorias em destaque. Tente novamente em alguns minutos'
        };

        return m('#conteudo',[
            m('span.cabecalho-cor'),
            m('#wrapper', [
                m.component(require('cabecalho/cabecalho'), {
                    metadados: true,
                    atribuir: atribuir,
                    nomeDaPagina: 'Categorias em Destaque',
                    logout: true,
                    caiuSessao: m.prop(false)
                }),
                m('div#admin', [
                    m('div.scroll', [
                        m('fieldset#destaque', [
                            m('h3', { style: {paddingBottom: '1em'}}, 'Gerenciar Categorias em Destaque'),
                            m('h1', 'Adicione a categoria para ser exibido em destaque no Portal'),
                            m.component(require('administracao/unidade/atribuir-servico/select-servico-pagina-tematica'),
                                {
                                    prop: ctrl.servico,
                                    servicosPaginas: ctrl.categorias,
                                    width: '40em',
                                    component: m('button.botao-primario.fa.fa-plus', {
                                        style: {float: 'right'},
                                        onclick: _.bind(ctrl.adicionarDestaque, ctrl)
                                    })
                                }),
                            m('h1', { style: {paddingTop: '1em'}}, 'Ordene as Categorias que serão exibidas em destaque no Portal'),
                            m('div', [
                                m('table#tableDestaque', [
                                    m('tr.text-center', 'Categorias em Destaque'),
                                    m('td', [
                                        m('select[multiple].right#selectDestaque', {
                                            onchange: _.bind(ctrl.setParaOrdenar, ctrl)
                                        } ,[
                                            ctrl.categoriasDestaque.map(function (s) {
                                                return m('option', {
                                                    value: s.id,
                                                    text: ctrl.stringToEllipses(s.nome, 33),
                                                    title: s.nome
                                                })
                                            })
                                        ]),
                                        m('span.right', {style: {marginRight: '4em'}} ,ctrl.categoriasDestaque.length)
                                    ]),
                                    m('td', [
                                        m('tr', m('button.botao-primario.fa.fa-caret-up', {
                                            onclick: _.bind(ctrl.incrementarPosicaoDestaque, ctrl)
                                        })),
                                        m('tr', m('button.botao-primario.fa.fa-caret-down', {
                                            onclick: _.bind(ctrl.decrementarPosicaoDestaque, ctrl)
                                        }))
                                    ]),
                                    m('tr', m('button.botao-primario.left', {
                                        onclick: _.bind(ctrl.removerEmDestaque, ctrl)
                                    }, 'Remover'))
                                ])
                            ])
                        ])
                    ])
                ])
            ])
        ]);

    };
})(m, require);

module.exports = PublicarCategoriasDestaqueView;