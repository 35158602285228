'use strict';

var selectOrgao = require('pagina/orgao/select-orgao');
var api = require('api');

module.exports = function (m, siorgInicial, element) {
    var campoSiorg = document.getElementById(element);
    if (!campoSiorg) {
        return;
    }

    var siorg = {};
    if(siorgInicial){
        if(siorgInicial.indexOf('http') > -1) {
            var siorgSplited = siorgInicial.split('/');
            var siorgUser = siorgSplited[siorgSplited.length - 1];
            api.buscarIdPorSiorg(siorgUser).then(function(response){
                var resposta = JSON.parse(response).resposta;
                siorg.nome = m.prop(resposta);
            });
        } else {
            siorg.nome = m.prop(siorgInicial);
        }
    } else {
        siorg.nome = m.prop("");
    }


    siorg.view = function () {
        return m('label', [
            m('h3', 'Orgão'),
            m.component(selectOrgao, {
                prop: siorg.nome
            }),
            m('input[type=hidden]#siorg', {
                name: 'siorg',
                value: siorg.nome()
            })
        ]);
    };

    m.mount(campoSiorg, {
        view: siorg.view
    });
};
