'use strict';

var v = require('utils/validacoes');
var modelos = require('servico/modelos');
var permissoes = require('utils/permissoes')

module.exports = {

  controller: function (args) {
    this.adicionar = function (orgaos) {
        var orgao = new modelos.Orgao();
        orgao.adicionado = true;
        orgaos.push(orgao);
    };

    this.remover = function (orgaos, i) {
        orgaos.splice(i, 1);
    };
  },

  view: function (ctrl, args) {
    var orgaos = args.prop;

    if(orgaos.length == 0){
        var orgao = new modelos.Orgao();
        orgaos.push(orgao);
    };

    var opcional = function () {
        return permissoes.ehAdmin() ? '.opcional' : '';
    };

    return m('fieldset#orgaos', [
        m('h3' + opcional(), ['Lista de Órgãos']),
        orgaos.map(function (orgao, index) {
            return m('fieldset#lista-orgaos', [
                m('.lista-orgaos', m.component(require('pagina/orgao/select-orgao'), {
                    prop: orgao.dbId
                })),
                m('button.remove.absolute.remover-orgao-btn', {
                    onclick: ctrl.remover.bind(ctrl, orgaos, index)
                }),
            ]);
        }),
        m('button.adicionar.adicionar-orgao', {
            onclick: ctrl.adicionar.bind(ctrl, orgaos)
        }, [
            m('i.fa.fa-plus'),
            ' Adicionar Órgão '
        ])
    ]);
  }
};
