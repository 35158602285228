'use strict';

var permissoes = require('utils/permissoes');

module.exports = {

    view: function (ctrl, args) {
        return m('#dados-basicos', [
            m.component(require('./nome'), args),
            m.component(require('./descricao'), args),
        ]);
    }
};
