
var ListaPortaisExternosController = (function (m, require) {

    return function (args) {
        var CabecalhoModel = require('cabecalho/cabecalho-model');
        var Api = require('api');
        var avisos = require('utils/avisos');

        var self = this;
        this.estado = m.prop('');
        this.cabecalho = new CabecalhoModel();
        this.caiuSessao = m.prop(false);

        this.filtro = m.prop('');
        this.portaisExternos = [];

        this.estados = [];

        this.pesquisaTimeOut;

        Api.listarEstados().then(function (data) {
            JSON.parse(data).map(function (estado) {
                self.estados.push({
                    id: estado.nome,
                    text: estado.nome + ' - ' + estado.sigla
                });
            });
        });

        this.listarPortaisExternos = function () {
            Api.listarPortaisExternos().then(function (data) {
                self.portaisExternos = JSON.parse(data).resposta;
            });
        };

        this.filtrarPorEstado = function (estado) {
            this.estado = m.prop(estado);
            Api.listarPortaisExternosPorEstado(estado).then(function (data) {
                self.portaisExternos = JSON.parse(data).resposta;
            });
        };

        this.excluirPortalExterno = function (portalExterno) {
            alertify.labels.cancel = 'Cancelar';
            alertify.labels.ok = 'Remover';
            alertify.confirm('Você tem certeza que deseja remover este portal externo?', function (result) {
                if (result) {
                    Api.removerPortalExterno(portalExterno.id)
                        .then( this.estado() != '' ?  this.listarPortaisExternos :  this.filtrarPorEstado(this.estado()) )
                        .then(function () {
                            alertify.success('Portal etxerno excluído com sucesso!', 0);
                        });
                }
            }.bind(this));
        };

        this.listarPortaisExternos();
    };
})(m, require);

module.exports = ListaPortaisExternosController;