var ImportacaoSiorgView = (function (m, require) {
    /**
     * @param {ImportacaoSiorgController} ctrl
     * @param args
     */
    return function (ctrl, args) {
        var tooltips = require('tooltips');
        var Paginacao = require('componentes/paginacao');

        var verificarImportacao = function(configuracaoSiorg){
            var message = "";
            if(configuracaoSiorg.importandoDadosSiorg()){
                message = "Buscando órgãos do SIORG";
            } else if(configuracaoSiorg.atualizandoBaseSiorg()){
                message = "Importando órgãos para a base de dados";
            }

            return m("div", [m('div.meter', [m('span', {"style": {"width":"100%"}})]),
                m('div.msg-importacao', [m('label', message)])
            ]);
        };

        var montarHistoricoOuTabela = function() {
            if(ctrl.data.length > 0){
                return m.component(Paginacao, {
                    data: ctrl.data,
                    rowsperpage: ctrl.rowsperpage,
                    pagerender: tabelaPreview,
                    wrapperclass: 'column'
                })
            } else {
                if(ctrl.historico && ctrl.hasAlteracaoImportacao == 1){
                    return m("div", [
                        m('label', "Última importação realizada em: " + ctrl.historico.dataImportacao()),
                        m('label.bold', "Importação realizada com sucesso!"),
                        m('ul', [
                            m('li.result-importacao', ctrl.historico.quantidadeOrgaosNovos() + " Órgãos Novo(s)"),
                            m('li.result-importacao', ctrl.historico.quantidadeOrgaosDesativados() + " Órgãos Desativado(s)"),
                            m('li.result-importacao', ctrl.historico.quantidadeOrgaosAtualizados() + " Órgãos Atualizado(s)")
                        ])
                    ]);
                } else if(ctrl.hasAlteracaoImportacao == 0){
                    return m("div", [
                        m('label', "Nenhum busca no SIORG foi realizada.")
                    ]);
                } else {
                    return m("div", [
                        m('label', "Não foi encontrado nenhum órgão atualizado no SIORG")
                    ]);
                }
            }
        };

        var tabelaPreview = function (data) {

            if(data.length > 0){
                var date = new Date(data[0].dataCriacao);
                var dataUltimoImport = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear() + ', '
                    + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();

                return m('.ui.sixteen.wide.column#bem-vindo', {
                    style: {
                        'float':'none'
                    }
                }, [
                    m('label.ultima-att', "Última atualização da tabela Órgão com o SIORG ocorrida em: " + dataUltimoImport),
                    m('table.ui.table', [
                        m('thead',
                            m('tr',{
                                    style: {
                                        'background-color':'#F7F7F7'
                                    }
                            },
                                m('th[colspan=2]', "CÓDIGO SIORG"),
                                m('th[colspan=4]', "NOME DO ÓRGÃO"),
                                m('th[colspan=2]', ['SITUAÇÃO', m.component(tooltips.situacaoSiorg)]),
                                m('th.text-center.no-padding-left[colspan=2]', 'MUDANÇA')
                            )
                        ),
                        m('tbody', data.map(function (dadosSiorg) {
                            return m('tr', {
                                key: dadosSiorg.id
                            }, [
                                m('td[colspan=2]', dadosSiorg.codigoSiorg),
                                m('td[colspan=4]', [
                                    m('a', dadosSiorg.nomeAntigo ? dadosSiorg.nomeAntigo : dadosSiorg.nomeOrgao)
                                ]),
                                m('td[colspan=2]', dadosSiorg.statusRegistro),
                                m('td.text-center[colspan=2]', dadosSiorg.nomeAntigo
                                    ? dadosSiorg.nomeOrgao + '/ Versão: ' + dadosSiorg.versao
                                    : dadosSiorg.versao)
                            ]);
                        }))
                    ])
                ]);
            }
        };

        var montarTabelaResultado = function(){
            if(ctrl.configuracaoSiorg.importandoDadosSiorg() || ctrl.configuracaoSiorg.atualizandoBaseSiorg()){
                return verificarImportacao(ctrl.configuracaoSiorg);
            } else {
                return m('div', [
                    m('h1', {class: ctrl.data.length > 0 ? "hide" : "show"}, ["Resultado da busca"]),
                    m('h1', {class: ctrl.data.length == 0 ? "hide" : "show"}, ["Resultado da importação"]),
                    m('button.btns-acao.botao-primario', {
                        onclick: ctrl.importarTodos,
                        disabled: ctrl.atualizacaoAutomatica()
                    },[
                        'BUSCAR ÓRGÃOS DO SIORG'
                    ]),
                    m('button.btns-acao.botao-primario',  {
                        class: ctrl.data.length == 0 ? "hide" : "show",
                        onclick: ctrl.exportarTodos,
                    },[
                        'IMPORTAR ÓRGÃOS PARA BASE DE DADOS'
                    ]),
                    montarHistoricoOuTabela()
                ])
            }
        };

        $('.horario').mask('99:99');
        return m('#conteudo',[
            m('span.cabecalho-cor'),
            m('#wrapper', [
                m.component(require('cabecalho/cabecalho'), {
                    metadados: false,
                    nomeDaPagina: 'Integração SIORG',
                    logout: true
                }),

                m('div.importacao.importar-unidade', [
                    m('h2', ["Integração SIORG"]),
                    m('h3', ["Integração em Lote"]),
                    m('div.fieldset', [
                        m('fieldset#nome', [
                            m('h4', ["Configuração da atualização:"]),
                            m('label.input-container',
                                m('input[type=checkbox]', {
                                    value: "SIM",
                                    checked: ctrl.configuracaoSiorg.atualizacaoAutomatica() == "SIM",
                                    onchange: ctrl.habilitarAtualizacao
                                }),
                                'Habilitar atualização automática'
                            ),

                            m('div.integracao-siorg',
                                m('div.input-container', {
                                        class: ctrl.configuracaoSiorg.dia.erro()
                                    }, 'Atualizar a cada: ',
                                    m('input[type=number].dias',{
                                        value: ctrl.configuracaoSiorg.dia(),
                                        onchange: m.withAttr('value', ctrl.configuracaoSiorg.dia),
                                        disabled: ctrl.configuracaoSiorg.atualizacaoAutomatica() == "NAO"
                                    }),
                                    " dias"
                                ),

                                m('div.input-container[data-target="erro-hora"]', {
                                        class: ctrl.configuracaoSiorg.horario.erro()
                                    } ,'Horário de atualização: ',
                                    m('input[type=text].horario',{
                                        value: ctrl.configuracaoSiorg.horario(),
                                        onchange: m.withAttr('value', ctrl.configuracaoSiorg.horario),
                                        disabled: ctrl.configuracaoSiorg.atualizacaoAutomatica() == "NAO"
                                    })
                                )
                            ),

                            m('div.btns-acao',
                                m('button.botao-primario.cancelar', {
                                    onclick: ctrl.limparForm
                                },[
                                    'Limpar'
                                ]),
                                m('button.botao-primario', {
                                    onclick: ctrl.salvarConfiguracao
                                }, [
                                    m.trust('Atualizar Configuração')
                                ])
                            )
                        ]),
                            montarTabelaResultado()
                        ]),

                    m('h3', {style: {marginTop: '3em'}} , ["Integração Manual"]),
                    m('div.fieldset', {style: {marginBottom: '2em'}} , [
                        m('div.integracao-siorg', [
                            m('div.left', {style: {width: '89%'}} ,'Nº SIORG: ',
                                m('input[type=text].dias', {
                                    value: ctrl.siorgImportacaoManual(),
                                    onchange: m.withAttr('value', ctrl.siorgImportacaoManual),
                                    style: {width: '86%'}
                                })
                            ),
                            m('div.right', [
                                m('button.botao-primario', {
                                    onclick: ctrl.importarManual
                                },[
                                    'Buscar'
                                ])
                            ])
                        ]),
                        m('.ui.sixteen.wide.column#bem-vindo', [
                            typeof ctrl.orgaoImportacaoManual == 'object' ?  m('div', [
                                m('table.ui.table', [
                                    m('thead',
                                        m('tr',{
                                                style: {
                                                    'background-color':'#F7F7F7'
                                                }
                                            },
                                            m('th[colspan=2]', "CÓDIGO SIORG"),
                                            m('th[colspan=4]', "NOME DO ÓRGÃO"),
                                            m('th[colspan=2]', ['SITUAÇÃO', m.component(tooltips.situacaoSiorg)]),
                                            m('th.text-center.no-padding-left[colspan=2]', 'MUDANÇA')
                                        )
                                    ),
                                    m('tbody', [
                                        m('tr', [
                                            m('td[colspan=2]', ctrl.orgaoImportacaoManual.codigoSiorg),
                                            m('td[colspan=4]', [
                                                m('a', ctrl.orgaoImportacaoManual.nomeAntigo ? ctrl.orgaoImportacaoManual.nomeAntigo : ctrl.orgaoImportacaoManual.nomeOrgao)
                                            ]),
                                            m('td[colspan=2]', ctrl.orgaoImportacaoManual.statusRegistro == 'SEM_ALTERACAO' ? 'SEM ALTERAÇÃO' : ctrl.orgaoImportacaoManual.statusRegistro),
                                            m('td.text-center[colspan=2]', ctrl.orgaoImportacaoManual.nomeAntigo
                                                ? ctrl.orgaoImportacaoManual.nomeOrgao + '/ Versão: ' + ctrl.orgaoImportacaoManual.versao
                                                : ctrl.orgaoImportacaoManual.versao)
                                        ])
                                    ])
                                ]),
                                m('div.right', {style: {margin: '2em 0'}} , [
                                    m('button.botao-primario', {
                                        onclick: ctrl.exportarManual,
                                        disabled: ctrl.orgaoImportacaoManual.statusRegistro == 'SEM_ALTERACAO'
                                    },[
                                        'Importar'
                                    ])
                                ]),
                            ]) : '',
                            ctrl.orgaoImportacaoManual == 1 ? m('h1.text-center', {style: {marginTop: '2em'}} , 'Órgão não encontrado!') : ''
                        ])
                    ])
                ])
            ])
        ]);

    };
})(m, require);

module.exports = ImportacaoSiorgView;