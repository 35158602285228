/**
 * Created by ygorazevedo on 4/27/17.
 */
'use strict';

var CabecalhoModel = require('cabecalho/cabecalho-model');
var slugify = require('slugify');
var paginaEmEdicao = require('servico/servico-em-edicao');

module.exports = {
    controller: function (args) {
        this.cabecalho = new CabecalhoModel();
        this.pagina = paginaEmEdicao.recuperar(this.cabecalho);
        this.caiuSessao = m.prop(false);

        this.editar = function () {
            var id = slugify(this.pagina().nome());
            var dbId = this.pagina().dbId();
            m.route('/editar/pagina-tematica/' + id + '/' + dbId);
            return true;
        };
    },

    view: function (ctrl, args) {
        return m('#conteudo', [
            m('span.cabecalho-cor'),
            m('#wrapper', [
                m.component(require('cabecalho/cabecalho'), {
                    metadados: true,
                    nomeDaPagina: ctrl.pagina().nome(),
                    logout: true,
                    editar: _.bind(ctrl.editar, ctrl),
                    cabecalho: ctrl.cabecalho,
                    caiuSessao: ctrl.caiuSessao
                }),
                m('#visualizar', m('#main', m('section#conteudo', [m('.row',
                    m('.aviso-visualizar', 'Esta é uma pré-visualização de como ficará a página quando publicado no Portal de Serviços.'),
                    m('h2', ctrl.pagina().nome())),
                    m('.row', m.component(require('pagina/tematica/visualizar/conteudo'), {
                        pagina: ctrl.pagina
                    })),
                    m('.row', m.component(require('pagina/tematica/visualizar/orgaos'), {
                        pagina: ctrl.pagina
                    }))
                ])))
            ])
        ]);
    }
};
