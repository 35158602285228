/**
 * Created by ygorazevedo on 2/8/17.
 */
'use strict';

var tooltips = require('tooltips');
var permissoes = require('utils/permissoes');
var Api = require('api');

module.exports = {
    controller: function (args) {
        this.nomePortalExterno = args.nomePortalExterno;
        this.nomeEstado = args.nomeEstado;
        this.siglaEstado = args.siglaEstado;
        this.periodicidadeExecucaoRotina = args.periodicidadeExecucaoRotina;
        this.urlApi = args.urlApi;

        var self = this;

        this.testeUrlDisabled = false;
        this.retornoTesteUrl = ""

        this.testarUrlApi = function(){
            self.retornoTesteUrl = "";
            self.testeUrlDisabled = true;
            if(!self.urlApi || self.urlApi().trim() == "" ){
                self.testeUrlDisabled = false;
                alertify.error("Preencha o campo URL da API");
            }else{
                Api.testarApiPortalExterno(self.urlApi()).then(function (data) {
                    self.testeUrlDisabled = false;
                    self.retornoTesteUrl = JSON.stringify(JSON.parse(data).resposta, null, "\t");
                }, function(error){
                    self.testeUrlDisabled = false;
                    alertify.error(error);
                });
            }
        };

        this.exibirResultadoTesteUrl = function(){
            return this.retornoTesteUrl != "";
        }

    },

    view: function (ctrl) {

        return m('fieldset#dados-basicos', [
            m('h3', [
                'Nome do Portal Externo',
                m.component(tooltips.portalExternoNome)
            ]),
            m('.input-container', {
                    class: ctrl.nomePortalExterno.erro(),
                },
                m('input[type=text]', {
                    value: ctrl.nomePortalExterno(),
                    onchange: m.withAttr('value', ctrl.nomePortalExterno)
                })),
            m('br'),
            m('h3', [
                'Nome do Estado',
                m.component(tooltips.portalExternoNomeEstado)
            ]),
            m('.input-container', {
                    class: ctrl.nomeEstado.erro(),
                },
                m('input[type=text]', {
                    value: ctrl.nomeEstado(),
                    onchange: m.withAttr('value', ctrl.nomeEstado)
                })),
            m('h3', [
                'Sigla do Estado',
                m.component(tooltips.portalExternoSiglaEstado)
            ]),

            m('.input-container', {
                    class: ctrl.siglaEstado.erro(),
                },
                m('input[type=text]', {
                    value: ctrl.siglaEstado(),
                    onchange: m.withAttr('value', ctrl.siglaEstado)
                })),
            m('h3', [
                'URL da API',
                m.component(tooltips.portalExternoUrlApi)
            ]),


            m('div.portal-externo-url', {
                style: {
                }
            }, [
                m('.input-container.inline', {
                        class: ctrl.urlApi.erro(),
                    },
                    m('input[type=text]', {
                        value: ctrl.urlApi(),
                        onchange: m.withAttr('value', ctrl.urlApi),
                        style:{
                            width:"80%",
                            float: "left"
                        }
                    })),

                m('a.button.botao-primario.right', {
                    onclick: ctrl.testarUrlApi,
                    disabled: ctrl.testeUrlDisabled
                }, 'Testar URL'),
                m('div', {style:{clear:"both"}},m("textarea",{
                    value: ctrl.retornoTesteUrl,
                    readonly: ctrl.exibirResultadoTesteUrl() ? 'readonly' : '',
                    rows: 20,
                    style:{
                        display: ctrl.exibirResultadoTesteUrl() ? 'block' : 'none'
                    }
                }))
            ]),

            m('h3', [
                'Periodicidade de execução da rotina',
                m.component(tooltips.portalExternoPeriodicidadeExecucaoRotina)
            ]),
            m('.input-container', {
                    class: ctrl.periodicidadeExecucaoRotina.erro(),
                },
            m('input.valor[type=text]', {
                value: ctrl.periodicidadeExecucaoRotina(),
                onchange: m.withAttr('value', ctrl.periodicidadeExecucaoRotina),
            }))
        ]);
    }
};
