'use strict';

module.exports = {

  controller: function (args) {},

  view: function () {
    return m('');
  }
};
