'use strict';

var erro = require('utils/erro-ajax');
var extrairMetadados = require('utils/extrair-metadados');
var atributosCsrf = require('utils/atributos-csrf');
var slugify = require('slugify');
var tratamentoAcessoNegado = require('utils/tratamento-acesso-negado');
var tratamentoConcorrenciaEdicao = require('utils/tratamento-concorrencia-edicao');

function request(opts) {
    return m.request(_.assign({
        deserialize: _.identity
    }, opts))
        .then(null, opts.error ? opts.error : erro);
}

function serializeXml(svc) {
    return new XMLSerializer().serializeToString(svc);
}

function deserializeXml(svc) {
    if (svc === 'acesso_negado') {
        return 'acesso_negado';
    } else if (svc === 'conflito_edicao') {
        return 'conflito_edicao';
    } else {
        return jQuery.parseXML(svc);
    }
}

function tratamentoErros(xhr) {
    var erroPermissao = tratamentoAcessoNegado(xhr);

    if (erroPermissao !== '') {
        return erroPermissao;
    }

    tratamentoConcorrenciaEdicao(xhr);
    return xhr.responseText;

}

function configCsrf(xhr) {
    xhr.setRequestHeader(atributosCsrf.header, atributosCsrf.token);
}

var nonJsonErrors = function (xhr) {
    return xhr.status > 200 ? JSON.stringify(xhr.responseText) : xhr.responseText
};

module.exports = {

    salvar: function (tipo, nome, xml, metadados, dbId, isPublicacao, isSolicitacaoPublicacao) {
        var id = slugify(nome);

        return request({
            method: 'POST',
            url: '/editar/api/pagina/' + tipo + '/' + id + (!dbId ? '' : '/' + dbId + '/' + (isPublicacao ? true : false) + '/' + (isSolicitacaoPublicacao ? true : false)),
            data: xml,
            config: function (xhr) {
                xhr.setRequestHeader('Accepts', 'application/xml');
                xhr.setRequestHeader('Content-Type', 'application/xml');
                xhr.setRequestHeader(atributosCsrf.header, atributosCsrf.token);
            },
            serialize: serializeXml,
            extract: extrairMetadados(metadados),
            deserialize: deserializeXml,
            background: true
        });
    },

    carregar: function (tipo, nome, metadados, dbId) {
        var dbId = !dbId ? '' : '/' + dbId;
        return request({
            method: 'GET',
            url: '/editar/api/pagina/' + tipo + '/' + slugify(nome) + dbId,
            config: function (xhr) {
                xhr.setRequestHeader('Accept', 'application/xml');
            },
            extract: extrairMetadados(metadados),
            deserialize: deserializeXml
        });
    },

    get: function (resource) {
        return request({
            method: 'GET',
            url: '/editar/api/' + resource,
            extract: nonJsonErrors,
            deserialize: jQuery.parseJSON
        });
    },

    buscar: function (resource) {
        return request({
            method: 'GET',
            url: resource,
            extract: nonJsonErrors,
            deserialize: jQuery.parseJSON
        });
    },

    publicar: function (tipo, docId, metadados, dbId) {
        console.log('PUT /editar/api/pagina/' + tipo + '/' + slugify(docId) + '/' + dbId);
        return request({
            method: 'PUT',
            background: true,
            url: '/editar/api/pagina/publicacao/publicar/' + tipo + '/' + slugify(docId) + '/' + dbId,
            extract: extrairMetadados(metadados),
            config: configCsrf
        });
    },

    reprovarPublicacao: function (justificativa) {
        console.log('PUT /editar/api/pagina/servico/reprovarpublicacao');
        return request({
            method: 'PUT',
            background: true,
            url: '/editar/api/pagina/servico/reprovarpublicacao',
            data: justificativa,
            config: configCsrf
        });
    },

    avaliar: function (tipo, id, metadados, dbId) {
        var mId = _.find(metadados);
        id = slugify(id);
        id = (mId == undefined && id) ? id : mId;
        return request({
            method: 'PUT',
            background: true,
            url: '/avaliar/api/pagina/' + tipo + '/' + id + '/' + dbId,
            extract: extrairMetadados(metadados, true),
            config: function (xhr) {
                if (!metadados.editado) {
                    xhr.setRequestHeader('X-Git-Commit-Editado', null);
                    xhr.setRequestHeader('X-Git-Autor-Editado', null);
                    xhr.setRequestHeader('X-Git-Horario-Editado', null);
                }
                configCsrf(xhr);
            }
        });
    },

    descartar: function (tipo, id, metadados, dbId) {
        var dbId = !dbId ? '' : '/' + dbId;
        var url = '/editar/api/pagina/publicacao/descartar' + tipo + '/' + id + dbId;
        var mimeType = 'application/xml';

        return request({
            method: 'POST',
            background: true,
            url: url,
            config: function (xhr) {
                xhr.setRequestHeader('Accept', mimeType);
                configCsrf(xhr);
            },
            extract: extrairMetadados(metadados),
            deserialize: function (str) {
                return new DOMParser().parseFromString(str, 'application/xml');
            }
        });
    },

    despublicar: function (tipo, id, metadados, dbId) {
        var dbId = !dbId ? '' : '/' + dbId;
        id = slugify(id);
        var url = '/editar/api/pagina/publicacao/despublicar/' + tipo + '/' + id + dbId;
        return request({
            method: 'POST',
            background: true,
            url: url,
            extract: extrairMetadados(metadados),
            config: configCsrf
        });
    },

    alterarDisponibilidade: function (tipo, id, metadados, dbId) {
        var url = '/editar/api/pagina/alterar-disponibilidade/' + tipo + '/' +dbId;
        return request({
            method: 'POST',
            background: true,
            url: url,
            extract: extrairMetadados(metadados),
            config: configCsrf
        });
    },


    excluir: function (tipo, id, metadados, dbId) {
        var dbId = !dbId ? '' : '/' + dbId;
        var url = '/editar/api/pagina/' + tipo + '/' + slugify(id) + dbId;

        return request({
            method: 'DELETE',
            url: url,
            extract: tratamentoErros,
            config: configCsrf
        });
    },

    renomear: function (tipo, id, novoNome) {
        //var dbId = !dbId ?'':'/' + dbId;
        return request({
            method: 'PATCH',
            background: true,
            url: '/editar/api/pagina/servico/' + tipo + "/" + id,
            config: configCsrf,
            serialize: _.identity,
            data: novoNome
        });
    },

    importarXml: function (urlParam) {
        return request({
            method: 'GET',
            url: '/editar/api/pagina/importar-xml',
            config: function (xhr) {
                xhr.setRequestHeader('Accept', 'application/xml');
            },
            data: {
                url: urlParam
            }
        }).then(deserializeXml)
            .then(null, function (e) {
                erro('Erro no formato XML. Verifique se o conteúdo do endereço informado é válido: ' + urlParam);
            });
        //retorno com erro não usa xml, por isso não usamos função "deserialize", e fazemos isso aqui
    },

    obterNomeOrgao: function (orgaoId) {
        return request({
            method: 'GET',
            url: '/editar/api/orgao/nome-por-id',
            data: {
                orgaoId: orgaoId
            },
        });
    },

    obterNomeServico: function (servicoId) {
        return request({
            method: 'GET',
            url: '/editar/api/servico/nome',
            data: {
                servicoId: servicoId
            }
        });
    },

    verificacaoExistePaginaOrgao: function (orgaoDbId, dbId) {
        return request({
            method: 'GET',
            url: '/editar/api/orgao/verificar-existe-pagina-orgao',
            data: {
                orgaoDbId: orgaoDbId,
                dbId: dbId
            },
        });
    },

    salvarAjudaHtml: function (file) {
        return m.request({
            method: "POST",
            url: "/editar/administracao/salvar-ajuda-html",
            data: file,
            config: configCsrf,
            serialize: function (data) {
                return data;
            },
            deserialize: function (data) {
                return data;
            }
        });
    },

    buscarAjudaHtml: function () {
        return m.request({
            method: "GET",
            url: "/editar/administracao/buscar-ajuda-html",
            config: configCsrf,
            serialize: function (data) {
                return data;
            },
            deserialize: function (data) {
                return data;
            }
        });
    },

    buscarFlagSeloDigital: function () {
        return m.request({
            method: "GET",
            url: "/editar/administracao/buscar-flag-selo-digital",
            config: configCsrf,
            serialize: function (data) {
                return data;
            },
            deserialize: function (data) {
                return data;
            }
        });
    },

    buscarFlagBrasilCidadao: function () {
        return m.request({
            method: "GET",
            url: "/editar/administracao/buscar-flag-brasil-cidadao",
            config: configCsrf,
            serialize: function (data) {
                return data;
            },
            deserialize: function (data) {
                return data;
            }
        });
    },

    buscarFlagChatBot: function () {
        return m.request({
            method: "GET",
            url: "/editar/administracao/buscar-flag-chatbot",
            config: configCsrf,
            serialize: function (data) {
                return data;
            },
            deserialize: function (data) {
                return data;
            }
        });
    },

    salvarFlagSeloDigital: function (flag) {
        return m.request({
            method: "PUT",
            url: "/editar/administracao/salvar-flag-selo-digital/" + flag,
            config: configCsrf,
            serialize: function (data) {
                return data;
            },
            deserialize: function (data) {
                return data;
            }
        });
    },

    salvarFlagBrasilCidadao: function (flag) {
        return m.request({
            method: "PUT",
            url: "/editar/administracao/salvar-flag-brasil-cidadao/" + flag,
            config: configCsrf,
            serialize: function (data) {
                return data;
            },
            deserialize: function (data) {
                return data;
            }
        });
    },

    salvarFlagChatBot: function (flag) {
        return m.request({
            method: "PUT",
            url: "/editar/administracao/salvar-flag-chatbot/" + flag,
            config: configCsrf,
            serialize: function (data) {
                return data;
            },
            deserialize: function (data) {
                return data;
            }
        });
    },

    buscarSeloDigital: function (dbId) {
        var url = '/editar/api/servico/selodigital/' + dbId;
        return request({
            method: 'GET',
            url: url,
            config: configCsrf
        });
    },
    alterarSeloDigital: function (dbId, porcentagem, porcentagemManual) {
        var url = '/editar/api/servico/selodigital/' + dbId + '/' + porcentagem + '/' + porcentagemManual;
        return request({
            method: 'POST',
            background: true,
            url: url,
            config: configCsrf
        });
    },
    importarUnidades: function (file) {
        return m.request({
            method: "POST",
            url: "/editar/api/unidade-atendimento/importar",
            data: file,
            config: configCsrf,
            serialize: function (data) {
                return data;
            },
            deserialize: function (data) {
                return data;
            }
        });
    },
    salvarImportacao: function (unidades, codSiorg) {
        return m.request({
            method: "POST",
            url: "/editar/api/unidade-atendimento/salvar-importacao/" + codSiorg,
            data: unidades,
            config: configCsrf
        });
    },
    verificarImportacaoUnidadeAtendimento: function () {
        return m.request({
            method: "GET",
            url: "/editar/api/unidade-atendimento/importando",
            config: configCsrf
        });
    },
    salvarUnidade: function (unidade) {
        return request({
            method: "POST",
            url: "/editar/api/unidade-atendimento/salvar",
            data: unidade,
            config: configCsrf
        });
    },
    listarUnidades: function () {
        return m.request({
            method: "GET",
            url: "/editar/api/unidade-atendimento/listar",
            config: configCsrf
        });
    },
    servicosPaginasVinculados: function (ids) {
        return m.request({
            method: "GET",
            url: "/editar/api/unidade-atendimento/servicos-paginas-vinculados",
            data: {
                ids: ids.join(',')
            },
            config: configCsrf
        });
    },
    removerUnidade: function (id) {
        return request({
            method: 'DELETE',
            url: "/editar/api/unidade-atendimento/" + id,
            config: configCsrf
        });
    },
    pesquisarUnidadePorNome: function (termo) {
        return m.request({
            method: "GET",
            url: "/editar/api/unidade-atendimento/pesquisar?q=" + termo,
            config: configCsrf
        });
    },
    buscarPaisPorId: function (idPais) {
        return request({
            method: 'GET',
            url: '/editar/api/unidade-atendimento/paises/nome',
            data: {
                idPais: idPais
            }
        });
    },

    buscarEstadoPorId: function (idEstado) {
        return request({
            method: 'GET',
            url: '/editar/api/unidade-atendimento/estados/nome',
            data: {
                idEstado: idEstado
            },
        });
    },

    listarEstados: function () {
        return request({
            method: 'GET',
            url: '/editar/api/unidade-atendimento/estados/listar',
        });
    },

    buscarCidadePorId: function (idCidade) {
        return request({
            method: 'GET',
            url: '/editar/api/unidade-atendimento/cidades/nome',
            data: {
                idCidade: idCidade
            }
        });
    },

    buscarUnidadePorId: function (id) {
        return request({
            method: 'GET',
            url: '/editar/api/unidade-atendimento/' + id,
            config: configCsrf
        });
    },

    buscarUnidade: function (ua) {
        return request({
            method: 'GET',
            url: '/editar/api/unidade-atendimento/verificarUnico',
            data: {
                nome: ua.nome(),
                orgaoId: ua.orgao().dbId(),
                cnpj: ua.cnpj()
            }
        });
    },

    buscarUnidadePorOrgao: function (orgaoId) {
        return request({
            method: 'GET',
            url: '/editar/api/unidade-atendimento/listar/por-orgao',
            data: {
                orgaoId: orgaoId
            }
        });
    },

    atribuirUnidadesParaServico: function (servicoId, unidades) {
        return request({
            method: 'POST',
            url: '/editar/api/servico/atribuir/' + servicoId,
            data: unidades,
            config: configCsrf
        });
    },

    atribuirUnidadesParaPaginaTematica: function (paginaId, unidades) {
        return request({
            method: 'POST',
            url: '/editar/pagina-tematica/atribuir/' + paginaId,
            data: unidades,
            config: configCsrf
        });
    },

    publicarUnidadesAtribuidasParaServico: function (servicoId) {
        return request({
            method: 'GET',
            url: '/editar/api/servico/publicar/unidades/' + servicoId,
            config: configCsrf
        });
    },

    publicarUnidadesAtribuidasParaPaginaTematica: function (paginaId) {
        return request({
            method: 'GET',
            url: '/editar/pagina-tematica/publicar/unidades/' + paginaId,
            config: configCsrf
        });
    },

    despublicarUnidadesAtribuidasParaServico: function (servicoId) {
        return request({
            method: 'GET',
            url: '/editar/api/servico/despublicar/unidades/' + servicoId,
            config: configCsrf
        });
    },

    despublicarUnidadesAtribuidasParaPaginaTematica: function (paginaId) {
        return request({
            method: 'GET',
            url: '/editar/pagina-tematica/despublicar/unidades/' + paginaId,
            config: configCsrf
        });
    },

    buscarUnidadesAtribuidasPorPaginaTematica: function (paginaId) {
        return request({
            method: 'GET',
            url: '/editar/api/unidade-atendimento/buscarPorPaginaTematica/' + paginaId,
            config: configCsrf
        });
    },

    buscarUnidadesAtribuidasPorServico: function (servicoId) {
        return request({
            method: 'GET',
            url: '/editar/api/unidade-atendimento/buscarPorServico/' + servicoId,
            config: configCsrf
        });
    },

    buscarServicosPaginasPorOrgao: function (orgaoId) {
        return request({
            method: 'GET',
            url: '/editar/api/servico/pagina-tematica',
            data: {
                orgaoId: orgaoId
            },
            config: configCsrf
        });
    },

    buscarServicosPaginasPublicadas: function () {
        return request({
            method: 'GET',
            url: '/editar/api/servico/pagina-tematica/listar',
            config: configCsrf
        });
    },

    salvarOrgaoSemSiorg: function (orgao) {
        return request({
            method: 'POST',
            url: '/editar/api/orgao/salvar',
            data: {
                dbId: orgao.dbId(),
                nomeOrgao: orgao.nome()
            },
            config: configCsrf
        });
    },

    listarOrgaoSemSiorg: function () {
        return request({
            method: 'GET',
            url: '/editar/api/orgao/sem-siorg/listar',
            config: configCsrf
        });
    },

    buscarOrgaoSemSiorg: function (termo) {
        return request({
            method: 'GET',
            url: '/editar/api/orgao/sem-siorg/buscar',
            data: {
                termo: termo
            },
            config: configCsrf
        });
    },

    pesquisarOrgao: function (termo) {
        return request({
            method: 'GET',
            url: '/editar/api/orgao/pesquisar',
            data: {
                q: termo
            },
            config: configCsrf
        });
    },

    buscarUnicoSemSiorg: function (idOrgao) {
        return request({
            method: 'GET',
            url: '/editar/api/orgao/sem-siorg/' + idOrgao,
            config: configCsrf
        });
    },

    removerUnidadeSemSiorg: function (orgao) {
        return m.request({
            method: 'DELETE',
            url: "/editar/api/orgao/" + orgao.id,
            config: configCsrf,
            unwrapSuccess: function(){
                alertify.success(orgao.nomeOrgao + ' excluído(a) com sucesso!', 0);
            },
            unwrapError: function(){
                alertify.error(orgao.nomeOrgao +' está em uso!', 0);
            }
        });
    },

    listarDadosSiorg: function () {
        return request({
            method: 'GET',
            url: '/editar/dadossiorg/listar',
            config: configCsrf
        });
    },

    buscarConfiguracaoSiorg: function () {
        return request({
            method: 'GET',
            url: '/editar/dadossiorg/configuracao',
            config: configCsrf
        });
    },

    importarTodos: function () {
        return request({
            method: 'GET',
            url: '/editar/dadossiorg/importar-todos',
            config: configCsrf
        });
    },

    importarManual: function (siorg) {
        return request({
            method: 'GET',
            url: '/editar/dadossiorg/importar/' + siorg,
            config: configCsrf
        });
    },

    exportarTodos: function () {
        return request({
            method: 'GET',
            url: '/editar/dadossiorg/exportar-todos',
            config: configCsrf
        });
    },

    exportarManual: function (orgaoImportacaoManual) {
        return request({
            method: 'PUT',
            url: '/editar/dadossiorg/exportar-manual',
            data: orgaoImportacaoManual,
            config: configCsrf
        });
    },

    salvarConfiguracaoSiorg: function (config) {
        return request({
            method: 'POST',
            url: '/editar/dadossiorg/configuracao',
            data: config,
            config: configCsrf
        });
    },

    buscarIdPorSiorg: function (siorg) {
        return request({
            method: 'GET',
            url: '/editar/api/orgao/id/' + siorg,
            config: configCsrf
        });
    },

    buscarSiorgPorId: function (id) {
        return request({
            method: 'GET',
            url: '/editar/api/orgao/' + id,
            config: configCsrf
        });
    },

    buscarHistoricoImportacaoSiorg: function (flag) {
        return request({
            method: 'GET',
            url: '/editar/dadossiorg/historico',
            data: {flag: flag},
            config: configCsrf
        });
    },

    buscarServicosEmDestaque: function () {
        return request({
            method: 'GET',
            url: '/editar/api/destaque/servicos',
            config: configCsrf
        });
    },

    publicarServicosEmDestaque: function (servicosDestaque) {
        return request({
            method: 'POST',
            data: servicosDestaque,
            url: '/editar/api/destaque/servicos/publicar',
            config: configCsrf
        });
    },

    buscarCategoriasEmDestaque: function () {
        return request({
            method: 'GET',
            url: '/editar/api/destaque/categorias',
            config: configCsrf
        });
    },

    publicarCategoriasEmDestaque: function (categoriasDestaque) {
        return request({
            method: 'POST',
            data: categoriasDestaque,
            url: '/editar/api/destaque/categorias/publicar',
            config: configCsrf
        });
    },

    listarCategoriasPublicadas: function () {
        return request({
            method: 'GET',
            url: '/editar/api/areas-interesse',
            config: configCsrf
        });
    },

    criarCategoria: function (nome) {
        return request({
            method: 'PUT',
            url: '/editar/api/areas-interesse/' + nome,
            config: configCsrf
        });
    },

    alterarCategoria: function (categoria) {
        return request({
            method: 'POST',
            data: categoria,
            url: '/editar/api/areas-interesse',
            config: configCsrf
        });
    },

    removerCategoria: function (id) {
        return request({
            method: 'DELETE',
            url: '/editar/api/areas-interesse/' + id,
            config: configCsrf
        });
    },

    isPublicadoComAreaInteresse: function (nome) {
        return request({
            method: 'GET',
            url: '/editar/api/servico/publicado/area-interesse',
            data: {
                nome: nome
            },
            config: configCsrf
        });
    },

    buscarFlagTemporaria: function (flagKey) {
        return request({
            method: 'GET',
            url: '/editar/api/buscar-flag-temporaria',
            data: {
                flagKey: flagKey
            },
            config: configCsrf
        });
    },

    verificarLinks: function (orgaoId, notificar) {
        return request({
            method: 'GET',
            url: '/editar/api/verificao-links/verificar/' + orgaoId + '/' + notificar,
            config: configCsrf,
            extract: nonJsonErrors,
            deserialize: jQuery.parseJSON
        });
    },

    buscarFlagSistema: function (flag) {
        return request({
            method: 'GET',
            url: '/editar/api/flag-sistema',
            data: {
                flag: flag
            },
            config: configCsrf,
            extract: nonJsonErrors,
            deserialize: jQuery.parseJSON
        });
    },

    salvarConfigVerificacaoLinks: function (config) {
        return request({
            method: 'PUT',
            url: '/editar/api/verificao-links/configuracao',
            data: config,
            config: configCsrf,
            extract: nonJsonErrors,
            deserialize: jQuery.parseJSON
        });
    },
    salvarPortalExterno: function (portalExterno) {
        return request({
            method: "POST",
            url: "/editar/api/portal-externo/salvar",
            data: portalExterno,
            config: configCsrf,
            error : function (error) {
                throw JSON.parse(error).mensagem;
            }
        });
    },

    listarPortaisExternos: function () {
        return request({
            method: 'GET',
            url: '/editar/api/portal-externo/listar',
            config: configCsrf
        });
    },

    listarPortaisExternosPorEstado: function (estado) {
        return request({
            method: 'GET',
            url: '/editar/api/portal-externo/listar/por-estado?nomeEstado='+estado,
            config: configCsrf
        });
    },

    buscarPortalExternoPorId: function (id) {
        return request({
            method: 'GET',
            url: '/editar/api/portal-externo/'+id,
            config: configCsrf
        });
    },

    removerPortalExterno: function (id) {
        return request({
            method: 'DELETE',
            url: "/editar/api/portal-externo/" + id,
            config: configCsrf
        });
    },

    testarApiPortalExterno: function (urlApi) {
        return request({
            method: "GET",
            url: "/editar/api/portal-externo/testar-url-api?urlApi="+urlApi,
            config: configCsrf,
            error : function (error) {
                throw JSON.parse(error).mensagem;
            }
        });
    },

    buscarMenuCabecalhoRodape: function () {
        return request({
            method: "GET",
            url: '/editar/api/template/menu-cabecalho-rodape',
            config: configCsrf,
        });
    },

    salvarMenuCabecalhoRodape: function (menuRodape) {
        return request({
            method: "POST",
            url: "/editar/api/template/salvar",
            data: menuRodape,
            config: configCsrf,
            extract: nonJsonErrors,
            error : function (error) {
                throw JSON.parse(error).mensagem;
            }
        });
    }

};
