/**
 * Created by ygorazevedo on 4/27/17.
 */
'use strict';

module.exports = {

    controller: function (args) {
        this.pagina = args.pagina;
        this.converter = new window.showdown.Converter();
    },

    view: function (ctrl) {
        if (!ctrl.pagina().contato().trim('')) {
            return m.component(require('servico/visualizar/view-vazia'));
        }

        return m('#pagina-conteudo', [
            m('h3.subtitulo-servico', 'Contato'),
            m('.markdown', m.trust(ctrl.converter.makeHtml(ctrl.pagina().contato())))
        ]);

    }
};
