'use strict';


module.exports = {

    view: function (ctrl, args) {
        var despublicarConfig = args.despublicarConfig;

        var despublicarComponente = despublicarConfig ? m.component(require('componentes/menu/despublicar-button'), despublicarConfig) : '';

        return m('nav#menu-lateral', [
            despublicarComponente,
        ]);
    }
};
