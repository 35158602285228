'use strict';

var Tooltips = require('tooltips');
var CabecalhoModel = require('cabecalho/cabecalho-model');
var EditorBase = require('componentes/editor-base');

var slugify = require('slugify');
var paginaEmEdicao = require('servico/servico-em-edicao');
var carregarPagina = require('xml/carregar').carregarOrgao;
var salvarOrgao = require('xml/salvar').salvarOrgao;
var publicarOrgao = require('xml/publicar').publicarOrgao;
var verificarPublicacao = require('xml/publicar').verificarPublicacao;
var descartarOrgao = require('xml/descartar').descartarOrgao;
var despublicar = require('api').despublicar;
var avaliarServico = require('api').avaliar;
var redirecionarNovaPagina = require('redirecionador');
var permissoes = require('utils/permissoes');
var routeUtils = require('utils/route-utils');
var promiseUtil = require('utils/promise');

var api = require('api');

module.exports = {
  controller: function (args) {
    var self = this;
    this.tipo = m.prop('orgao');

    this.modificado = m.prop(false);
    this.cabecalho = new CabecalhoModel();
    this.pagina = carregarPagina(m.route.param('id'), this.cabecalho, m.route.param('dbId'));
    this.salvandoServico = m.prop(false);
    this.caiuSessao = m.prop(false);
    this.redirect = m.prop(false);
    this.docId = m.route.param('id');
    this.dbId = m.route.param('dbId');
    this.bloquearSelect = false;

      if(!permissoes.ehAdmin()){
          var nomeOrgao = window.loggedUser.siorg_name;
          api.pesquisarOrgao(nomeOrgao).then(function (response) {
              var orgaos = JSON.parse(response);
              self.pagina().orgaoDbId(orgaos[0].id);
              // self.orgao().codigoSiorg(orgaos[0].codigoSiorg);
          });
          self.bloquearSelect = true;
      }

    this._onOp = _.bind(function (pagina) {
      if(!pagina) {
        window.location.reload();
      } else {
        this.pagina(pagina);
        this.modificado(false);
        redirecionarNovaPagina(this.tipo(), pagina.url(), pagina.dbId(), this.redirect);
        this.redirect(false);
        return pagina;
      }
    }, this);

    this.salvar = _.bind(function (isPublicacao, isSolicitacaoPublicacao) {

      return salvarOrgao(this.pagina(), this.docId, this.cabecalho.metadados, this.dbId, isPublicacao, isSolicitacaoPublicacao)
        .then(function (xml) {
          if(isSolicitacaoPublicacao){
                alertify.success('Solicitação de publicação enviada com sucesso!');
          }else if(isPublicacao) {
            alertify.success('Página de Órgão publicado com sucesso! As informações aparecerão no Portal em até 15 minutos.');
          }
          return xml;
        })
        .then(this.pagina)
        .then(this._onOp);
    }, this);

    this.publicar = function (isSolicitacaoPublicacao) {

      return verificarPublicacao(null, 'orgao', this.docId, this.dbId)
          .then(_.bind(function () {

            if(isSolicitacaoPublicacao) {
                this.salvar(false,true);
            }else{
                this.salvar(true);
            }

        }, this), function (reason) {
            alertify.error(reason);
            return false;
          })
        .then(_.bind(function (s) {
          return publicarOrgao(s, this.docId, this.cabecalho.metadados, this.dbId);
        }, this))
        .then(this._onOp);
    };

      this.solicitarPublicacao = function () {
          return this.publicar(true);
      };

    this.avaliar = function() {
      return avaliarServico('orgao', this.docId, this.cabecalho.metadados, this.dbId);
    };

    this.descartar = _.bind(function () {
      this.redirect(true);
      return descartarOrgao(this.pagina(), this.cabecalho.metadados, this.dbId)
        .then(this._onOp);
    }, this);

    this.despublicar = function () {
      return despublicar('orgao', this.docId, this.cabecalho.metadados, this.dbId);
    };

    this.visualizar = function () {
      var id = slugify(this.pagina().url());
      paginaEmEdicao.manter(this.pagina, this.cabecalho.metadados);
      m.route('/editar/visualizar/pagina/orgao/' + id);
      return true;
    }
  },

  view: function (ctrl, args) {
    if (routeUtils.ehNovo() && !permissoes.podeCriarPagina('orgao')) {
      return m.component(require('acesso-negado'));
    }

    $('.editor-markdown textarea').each(function () {
        if(!$(this).hasClass("markItUpEditor")){
            $(this).markItUp(settingsMarkitup);
        }
    });

    if (ctrl.pagina() && !routeUtils.ehNovo() &&
      !permissoes.podeSalvarPagina(
        window.loggedUser.siorg,
        ctrl.pagina().url(),
        ctrl.pagina().orgaoDbId()
      )) {
      return m.component(require('acesso-negado'));
    }

    if (!ctrl.pagina()) {
      return m('');
    }

    var tamanhoConteudo = 10000;
    var tooltips = {
      tipo: Tooltips.tipoPagina,
      nome: Tooltips.escolhaOrgao,
      conteudo: Tooltips.conteudoOrgao,
      contato: Tooltips.contatoOrgao
    };

    var binding = {
      pagina: ctrl.pagina,
      validaNome: ctrl.pagina().nome,
      nome: routeUtils.ehNovo() ? ctrl.pagina().orgaoDbId : ctrl.pagina().nome,
      novo: routeUtils.ehNovo()
    };

    if(!permissoes.ehAdmin() && ctrl.pagina().url() == ''){
      var siorgUser = window.loggedUser.siorg;
      ctrl.pagina().url(siorgUser);
      ctrl.bloquearSelect = true;
    }

    return m.component(EditorBase, {
      conteudoConfig: function (element, isInitialized) {
        if (isInitialized) {
          return;
        }
        jQuery(element).on('change', function () {
          ctrl.modificado(true);
        });
        jQuery(window).bind('beforeunload', function () {
          if (ctrl.modificado()) {
            return 'Suas últimas alterações ainda não foram salvas.';
          }
        });
      },

      cabecalhoConfig: {
        metadados: true,
        logout: true,
        nomeDaPagina: ctrl.pagina().nome() || 'Novo orgão',
        salvar: _.bind(ctrl.salvar, ctrl),
        publicar: _.bind(ctrl.publicar, ctrl),
        descartar: _.bind(ctrl.descartar, ctrl),
        avaliar: _.bind(ctrl.avaliar, ctrl),
        cabecalho: ctrl.cabecalho,
        salvandoServico: ctrl.salvandoServico,
        visualizar: _.bind(ctrl.visualizar, ctrl),
        caiuSessao: ctrl.caiuSessao,
        orgaoId: ctrl.pagina().url()
      },

      menuLateralConfig: {
        despublicarConfig: {
          tipo: 'orgao',
          despublicar: _.bind(ctrl.despublicar, ctrl),
          metadados: ctrl.cabecalho.metadados(),
          salvandoServico: ctrl.salvandoServico,
          caiuSessao: ctrl.caiuSessao,
          orgaoId: ctrl.pagina().url()
        }
      },

      componentes: [
        m.component(require('pagina/componentes/tipo-de-pagina'), {
          tipo: ctrl.tipo,
          tooltipTipo: tooltips.tipo
        }),

        m.component(require('pagina/componentes/nome'), _.assign(binding, {
          titulo: 'Selecione o órgão',
          componente: require('pagina/orgao/select-orgao'),
          bloquear: ctrl.bloquearSelect,
          tooltipNome: tooltips.nome,
        })),

        m.component(require('pagina/componentes/conteudo'), {
          prop: ctrl.pagina().conteudo,
          maximo: tamanhoConteudo,
          tooltipConteudo: tooltips.conteudo
        }),

        m.component(require('pagina/componentes/conteudo'), {
          label: 'Contato do órgão',
          prop: ctrl.pagina().contato,
          maximo: tamanhoConteudo,
          tooltipConteudo: tooltips.contato
        }),
      ]
    });

  }
};
