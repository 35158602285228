'use strict';


var menuRodapeToJson = function (menuRodape) {

    return  [
        {
            id : menuRodape[0].id(),
            template :  menuRodape[0].template()
        },
        {
            id : menuRodape[1].id(),
            template :  menuRodape[1].template()
        }
    ]
};

module.exports = {
    menuRodapeToJson: menuRodapeToJson,
}