'use strict';

var safeGet = require('utils/code-checks').safeGet;
var v = require('utils/validacoes');
var id = require('utils/id');
var api = require('api');

function urlToNome(url, nomeProp) {
  if(nomeProp().indexOf('(') >-1){
    return;
  }
  if (url && url.indexOf("/") > -1) {
    var url = url.split("/");
    var codSiorg = url[url.length - 1];
    api.buscarIdPorSiorg(codSiorg).then(function (response) {
      api.obterNomeOrgao(JSON.parse(response).resposta)
          .then(nomeProp);
    });
  } else if(url) {
    api.obterNomeOrgao(url)
        .then(nomeProp);
  }
}

function createUrl(initial, nomeProp, orgaoDbId) {
  var validaIdJaExistente = _.trim(initial) ? _.noop : v.idUnicoOrgao;
  var urlProp = v.prop(initial, v.obrigatorio, validaIdJaExistente);
  urlToNome(initial || orgaoDbId, nomeProp);
  var url = function () {
    urlToNome(_.head(arguments), nomeProp);
    return urlProp.apply(this, arguments);
  };
  url.erro = urlProp.erro;

  return url;
}

module.exports = function (config) {
  var data = (config || {});
  var tamanho = safeGet(data, 'tamanho');

  this.id = id('orgao');
  this.nome = v.prop(data.nome || '', v.obrigatorio);
  this.url = createUrl(data.url || '', this.nome, data.orgaoDbId);
  this.conteudo = v.prop(data.conteudo || '', v.obrigatorio, v.maximo(tamanho));
  this.contato = v.prop(data.contato || '', v.obrigatorio);
  this.dbId = v.prop(data.dbId || '', v.obrigatorio);
  this.orgaoDbId = v.prop(data.orgaoDbId || '', v.obrigatorio);
};
