'use strict';

window.cabecalhoModel = new (require('cabecalho/cabecalho-model'))();
var slugify = require('slugify');
var idUnico = require('utils/id-unico');
var api = require('api');
var permissoes = require('utils/permissoes');

module.exports = {

  controller: function (args) {
    var self = this;
    this.servico = args.servico;
    this.salvandoServico = args.salvandoServico;
    this.renomeando = m.prop(false);
    this.docId = m.route.param('id');
    this.cabecalho = cabecalhoModel;
    this.isPublicado = args.isPublicado;

    this.validar = function (nome) {
      var idAtual = slugify(this.servico().nome());
      if (!nome) {
        return 'Este campo é obrigatório.';
      }
      if (idAtual !== slugify(nome) && !idUnico(nome)) {
        return 'O nome preenchido já está sendo utilizado em outro serviço.';
      }
    };

    this.renomearServico = function (novoNome) {
      this.renomeando(true);
      this.salvandoServico(true);
      m.redraw();
      var servico = this.servico();
      var dbId = servico.dbId();
      api.renomear('servico', dbId, novoNome).then(function (response) {
        servico.nome(novoNome);
        m.route('/editar/servico/' + slugify(novoNome) + '/' + response);
        alertify.success('Serviço renomeado com sucesso!', 0);
        self.renomeando(false);
        self.salvandoServico(false);
      });
    };
  },

  view: function (ctrl, args) {
    ctrl.servico = args.servico;

    var novo = args.novo;
    var servico = ctrl.servico();

    var componenteNome = novo ? m('div.input-container', {
      class: servico.nome.erro()
    }, m('input[type=text]', {
      onchange: m.withAttr('value', servico.nome),
      value: servico.nome(),
      autofocus: 'autofocus'
    })) : m('div', [
      m('span', servico.nome()),
      permissoes.podeRenomearServico() ? m('button.renomear', {
        disabled: ctrl.renomeando() || ctrl.salvandoServico(),
        style: {
          float: 'right'
        },
        onclick: function () {
          var renomear = function () {
            alertify.labels.cancel = 'Cancelar';
            alertify.labels.ok = 'Renomear';
            alertify.prompt('Novo nome do serviço:',
              function (e, novoNome) {
                if (e && servico.nome() !== novoNome) {
                  var validacao = ctrl.validar(novoNome);
                  if (!validacao) {
                    ctrl.renomearServico(novoNome);
                  } else {
                    alertify.labels.ok = 'Ok';
                    alertify.alert(validacao, function () {
                      renomear();
                    });
                  }

                }
              },
              servico.nome()
            );
          };
          if(ctrl.isPublicado) {
            alertify.confirm('Ao alterar o nome do Serviço todas as alterações salvas serão publicadas. Deseja continuar?', function (result) {
              if(result) {
                renomear();
              }
            });
          } else {
            renomear();
          }
        }
      }, ctrl.renomeando() ? [m('i.fa.fa-spin.fa-spinner'), ' Alterando nome...'] : [m('i.fa.fa-pencil'), ' Alterar nome']) : m('')
    ]);

    return m('fieldset#nome', [
      m('h2', 'dados básicos'),
      m('h3', [
        'Nome do serviço',
        m.component(require('tooltips').nome)
      ]),
      componenteNome
    ]);

  }

};
