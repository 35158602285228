'use strict';

var focus = require('focus');
var Api = require('api');
var avisos = require('utils/avisos');
var modelos = require('super-servico/modelos');

module.exports = {
    controller: function (args) {
        this.servicoPublicado = [];
        var self = this;

        this.servico = args.servico;

        Api.get('servico/publicado/por-orgao/-1').then(function (response) {
            if (response) {
                self.servicoPublicado = response.map(function (s) {
                    return {
                        id: s.id,
                        text: s.nomeServico
                    }
                });
            }
        });

        this.adicionar = function () {
            var servicosRelacionados = this.servico().servicosRelacionados();
            servicosRelacionados.push(new modelos.servicoRelacionado());
            this.servico().servicosRelacionados(servicosRelacionados);
        };

        this.remover = function (i) {
            var servicosRelacionados = this.servico().servicosRelacionados();
            servicosRelacionados.splice(i, 1);
            this.servico().servicosRelacionados(servicosRelacionados);
        };
    },

    view: function (ctrl) {
        var erro = ctrl.servico().servicosRelacionados.erro();
        var erroFieldSet = _.isString(erro) ? erro : '';

        return m('fieldset#servicos-relacionados.relative', [
            m('h3.titulo-servico-relacionado', {
                class: erroFieldSet
            }, [
                'Servicos Relacionados',
                m.component(require('tooltips').servicoRelacionado)
            ]),
            ' ',
            m('h3.tag-servico-relacionado', {
                class: erroFieldSet
            }, [
                'Tag',
                m.component(require('tooltips').tag)
            ]),

            ctrl.servico().servicosRelacionados().map(function (servicos, i) {
                var propId;
                var propTag;
                if (typeof ctrl.servico().servicosRelacionados()[i].id == 'string') {
                    propId = m.prop(ctrl.servico().servicosRelacionados()[i].id);
                } else {
                    propId = ctrl.servico().servicosRelacionados()[i].id;
                }
                if (typeof ctrl.servico().servicosRelacionados()[i].tag == 'string') {
                    propTag = m.prop(ctrl.servico().servicosRelacionados()[i].tag);
                } else {
                    propTag = ctrl.servico().servicosRelacionados()[i].tag;
                }


                return [
                    ctrl.servico().servicosRelacionados().length === 1 ? '' : m('button.remove.absolute', {
                        style: {marginTop: i == 0 ? '5px' : '50px'},
                        onclick: ctrl.remover.bind(ctrl, i)
                    }),

                    m('.input-container.full .servico-relacionado', {style: {marginTop: '1em', clear: 'both'}}, [
                        m.component(require('componentes/select-servico-publicado'), {
                            prop: propId,
                            onchange: _.bind(function (value) {
                                if (_.indexOf(this.servico().servicosRelacionados(), value) != -1) {
                                    avisos.erro('Este serviço já foi selecionado!');
                                    prop('');
                                    return;
                                }
                                this.servico().servicosRelacionados()[i].id = value;
                            }, ctrl)
                        }),
                        m('.input-container.full', {}, [m('input[type=text]', {
                            prop: propTag,
                            value: propTag(),
                            onchange: m.withAttr('value', ctrl.servico().servicosRelacionados()[i].tag)


                        })]),
                    ])
                ]
            }),
            m('button.adicionar.adicionar-servicos-relacionados', {
                style: {marginTop: '3em'},
                onclick: ctrl.adicionar.bind(ctrl)
            }, [
                m('i.fa.fa-plus'),
                ' Adicionar demais serviços relacionados '
            ])
        ]);
    }
};
