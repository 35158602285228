'use strict';

module.exports = function (str) {
  return _.deburr(str)
    .trim()
    .toLowerCase()
    .replace(/[\s\\\/\.-\W]+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
};
