'use strict';

window.cabecalhoModel = new (require('cabecalho/cabecalho-model'))();
var slugify = require('slugify');
var promise = require('utils/promise');
var salvarSuperServico = require('xml/salvar').salvarSuperServico;
var publicarServico = require('xml/publicar').publicarSuperServico;
var validarParaPublicar = require('xml/publicar').validar;
var descartarServico = require('xml/descartar').descartarSuperServico;
var despublicarServico = require('api').despublicar;
var servicoEmEdicao = require('super-servico/servico-em-edicao');
var redirecionarNovoServico = require('redirecionador');
var routeUtils = require('utils/route-utils');
var permissoes = require('utils/permissoes');
var modificado = m.prop(false);

module.exports = {
  controller: function () {
    var self = this;
    this.podeSalvar = true;

    this.verificaPodeSalvar = function (servico) {
        var usuarioSiorgOuId = window.loggedUser.siorg;
        if(!routeUtils.ehNovo() && !permissoes.ehAdmin() && servico.orgao().nome().indexOf(usuarioSiorgOuId) == -1 &&
          servico.orgao().dbId().indexOf(usuarioSiorgOuId) == -1) {
            this.podeSalvar = false;
        }
    };

    this.cabecalho =  cabecalhoModel;
    this.caiuSessao = m.prop(false);
    this.servico = servicoEmEdicao.recuperar(this.cabecalho, _.bind(this.verificaPodeSalvar, this));

    this.salvandoServico = m.prop(false);
    this.redirect = m.prop(false);
    this.docId = m.route.param('id');
    this.dbId = m.route.param('dbId');
    this.ajudaTutorial = true;

    this._servicoSalvo = _.bind(function (servico) {
      this.servico(servico);
      this.cabecalho.limparErro();
      modificado(false);
      redirecionarNovoServico('super-servico', servico.nome(), servico.dbId(), this.redirect);
      this.redirect(false);
      return servico;
    }, this);

    this.salvar = function (isPublicacao) {

      return salvarSuperServico(this.servico(), this.servico().nome(), this.cabecalho.metadados, this.dbId, isPublicacao)
        .then(function (xml) {

          if(isPublicacao) {
            alertify.success('Super Serviço publicado com sucesso! As informações aparecerão no Portal em até 15 minutos.');
          }

          return xml;
        })
        .then(this._servicoSalvo);
    };

    this.publicar = function () {

      return promise.resolved(this.servico())
        .then(validarParaPublicar)
        .then(_.bind(function (s) {
                return self.salvar(true);
        }, this))
        .then(_.bind(function (s) {

        }, this));
    };

    this.descartar = _.bind(function () {
      this.redirect(true);
      return descartarServico(this.servico(), this.cabecalho.metadados, this.dbId)
        .then(this._servicoSalvo);
    }, this);

    this.despublicar = function () {
      return despublicarServico('super-servico', this.servico().nome(), this.cabecalho.metadados, this.dbId);
    };

    this.visualizar = function () {
      var id = slugify(this.servico().nome());
      servicoEmEdicao.manter(this.servico, this.cabecalho.metadados);
      m.route('/editar/visualizar/super-servico/' + id);
      return true;
    };


  },

  view: function (ctrl) {

    if (routeUtils.ehNovo() && !permissoes.podeCriarPagina('super-servico')) {
      return m.component(require('acesso-negado'));
    }

    var isPublicado = false;
    if(ctrl.cabecalho.metadados.publicado) {
      isPublicado = ctrl.cabecalho.metadados.publicado.horario != null;
    }

    var binding = {
      servico: ctrl.servico,
      novo: routeUtils.ehNovo(),
      podeSalvar: ctrl.podeSalvar,
      salvandoServico: ctrl.salvandoServico,
      caiuSessao: ctrl.caiuSessao,
      isPublicado: isPublicado
    };

    return m('#conteudo', {
      config: function (element, isInitialized) {
        if (isInitialized) {

            $('.editor-markdown textarea').each(function () {
                if(!$(this).hasClass("markItUpEditor")){
                    $(this).markItUp(settingsMarkitup);
                }
            });

          return;
        }

        jQuery(window).bind('beforeunload', function () {
          if (modificado()) {
            return 'Suas últimas alterações ainda não foram salvas.';
          }
        });
      }
    }, [
      m('span.cabecalho-cor'),
      m('#wrapper', [
        m.component(require('cabecalho/cabecalho'), {
          metadados: true,
          logout: true,
          nomeDaPagina: ctrl.servico().nome
          () || 'Novo serviço',
          salvar: _.bind(ctrl.salvar, ctrl),
          disabled: !ctrl.podeSalvar,
          publicar: _.bind(ctrl.publicar, ctrl),
          cabecalho: ctrl.cabecalho,
          salvandoServico: ctrl.salvandoServico,
          caiuSessao: ctrl.caiuSessao,
          ajudaTutorial: ctrl.ajudaTutorial,
           tipo: 'super-servico'
        }),
        m.component(require('super-servico/menu-lateral'), {
            despublicarConfig: {
                tipo: 'super-servico',
                despublicar: _.bind(ctrl.despublicar, ctrl),
                metadados: ctrl.cabecalho.metadados(),
                salvandoServico: ctrl.salvandoServico,
                caiuSessao: ctrl.caiuSessao,
            }

        }),

        m('#servico', m('.scroll', [
            m('div', [
                m('h3', 'Tipo de Página: Super Serviço')
            ]),
          m.component(require('super-servico/dados-basicos/dados-basicos'), binding),
          m.component(require('super-servico/servicos-relacionados/super-servico-servicos-relacionados'), binding)
        ]))
      ])
    ]);
  }
};
