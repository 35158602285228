'use strict';

var safeGet = require('utils/code-checks').safeGet;
var erro = require('utils/erro-ajax');
var api = require('api');

module.exports = {

  view: function (ctrl, args) {
    var prop = safeGet(args, 'prop');
    var servicoId = args.servicoId;
    var nome = args.validaNome;
    var width = args.width || '93%';

    var initSelectionFun;

    if(prop()) {
      initSelectionFun = function (element, callback) {
        api.obterNomeServico(prop())
          .then(function (nome) {
            callback({
              id: prop(),
              text: nome
            });
          }, erro);
      };
    }

    return m('div#select-orgao.input-container', {
      class: nome && nome.erro && nome.erro()
    }, m.component(require('componentes/select2'), {
      ajax: {
        url: '/editar/api/servico/publicado/nome',
        dataType: 'json',
        delay: 250,
        data: function (params) {
          return {
            q: params.term
          };
        },
        processResults: function (data, page) {
          var index = null;
          var result = data.map(function (s, i) {
            if(s.id == servicoId) {
              index = i;
            }
            return {
              id: s.id,
              text: s.nomeServico
            };
          });

          if(index != null) {
            result.splice(index, 1);
            index = null;
          }

          return {
            results: result
          };
        },
        cache: true
      },
      prop: prop,
      onchange: args.onchange,
      bloquear: args.bloquear,
      width: width,
      minimumResultsForSearch: 1,
      minimumInputLength: 3,
      initSelection: initSelectionFun
    }));
  }
};
