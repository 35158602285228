'use strict';

var mensagem = 'Deseja realmente alterar a disponibilidade do serviço?';

module.exports = function (despublicarFn) {
  return function () {
    alertify.labels.cancel = 'Cancelar';
    alertify.labels.ok = 'Sim';
    alertify.confirm(mensagem, function (ok) {
      if (ok) {
        despublicarFn();
      }
    });
  };
};
