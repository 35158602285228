'use strict';

var referencia = require('referencia');
var slugify = require('slugify');

module.exports = {

  controller: function (args) {
    this.canaisDePrestacao = args;
    this.slugify = slugify;
    this.tipos = referencia.tiposDeCanalDePrestacaoVisualizar;
    this.converter = new window.showdown.Converter();

    this.temCanalDePrestacao = function () {
      return !_.isEmpty(this.canaisDePrestacao.casoPadrao().campos()) || !_.isEmpty(this.canaisDePrestacao.outrosCasos());
    };

    this.pegarIconeCampo = function (tipo) {
      switch (tipo){
        case 'Aplicativo móvel': return 'fa fa-mobile fa-2x';
        case 'E-mail': return 'fa fa-at fa-lg';
        case 'Fax': return 'fa fa-fax fa-lg';
        case 'Postal': return 'fa fa-envelope fa-lg';
        case 'Presencial': return 'fa fa-male fa-lg';
        case 'SMS': return 'fa fa-commenting fa-lg';
        case 'Telefone': return 'fa fa-phone fa-lg';
        default: return 'fa fa-desktop fa-lg';
      }
    }
  },

  view: function (ctrl, slugify) {

    var descricaoView = function (campo) {
      if (ctrl.tipos[ctrl.slugify(campo.tipo())].destacado) {
        return m('a', {
          href: campo.descricao()
        }, ctrl.tipos[ctrl.slugify(campo.tipo())].descricaoLink);
      }
      return m.trust(ctrl.converter.makeHtml(campo.descricao()));
    };

    var tempoEsperaView = function (campo) {

        if(campo.tipo() == "Presencial" || campo.tipo() == "Telefone"){

            return  m('div', [
                m('h4.subtitulo-servico', 'Tempo Estimado de Espera'),
                m('p', [
                    m('span', 'Até '),
                    m('span', campo.tempoEstimadoEspera()),
                    m('span', ' '),
                    m('span', campo.tempoEstimadoPeriodo())
                ])
            ]);

        }else{
            return '';
        }
    };

    var procedimentoSistemaIndisponivelView = function (campo) {

        if(((campo.tipo().indexOf("Web") >=0 || campo.tipo().indexOf("Aplicativo")) && campo.procedimentoSistemaIndisponivel())){

            return  m('div', [
                m('h4.subtitulo-servico', 'Procedimentos de atendimento quando o sistema informatizado se encontrar indisponível'),
                m('p.markdown', m.trust(ctrl.converter.makeHtml(campo.procedimentoSistemaIndisponivel())))
            ]);

        }else{
            return '';
        }

    };

    var camposView = function (campos) {

      return campos.map(function (campo) {

          return m('li', [
                    m('i.icone-etapa.' + ctrl.pegarIconeCampo(campo.tipo())),
                    m('span', ctrl.tipos[ctrl.slugify(campo.tipo())].text + ': '),
                    descricaoView(campo),
                    tempoEsperaView(campo),
                    procedimentoSistemaIndisponivelView(campo)
                ]);
      });
    };

    var canalPadraoView = function (cp) {
      return m('ul', camposView(cp.campos()));
    };

    var outrosCanaisView = function (casos) {
      return casos.map(function (caso) {
        return m('ul', [
                    m('.info-etapa', caso.descricao()),
                    camposView(caso.campos())
                ]);
      });
    };

    if (ctrl.temCanalDePrestacao()) {
      return m('.subtitulo-etapa', [
                    m('p.titulo-documento', 'Canais de prestação'),
                    m('p.info-etapa', ''),
                    canalPadraoView(ctrl.canaisDePrestacao.casoPadrao()),
                    outrosCanaisView(ctrl.canaisDePrestacao.outrosCasos())
                ]);
    }
    return m.component(require('servico/visualizar/view-vazia'));

  }
};
