/**
 * @class PublicarCategoriasDestaqueController
 */
var PublicarCategoriasDestaqueController = (function (m, require) {

    return function () {
        var self = this;

        var CabecalhoModel = require('cabecalho/cabecalho-model');
        var avisos = require('utils/avisos');
        var api = require('api');

        this.resetServico = function () {
            self.servico(-1);
            var el = $('#select-servico').children().children();
            el.select2('val', '');
        };

        this.cabecalho = new CabecalhoModel();
        this.caiuSessao = m.prop(false);
        this.servico = m.prop(-1);
        this.paraOrdenar = m.prop();
        this.alterado = m.prop(false);
        this.salvando = m.prop(false);

        this.categorias = [];

        this.categoriasDestaque = [];

        this.buscarTodos = function () {
            api.get('lista-formulario/lista-areas/completo').then(function (response) {
                response.forEach(function(c) {
                    self.categorias.push({
                        id: c.id,
                        text: c.nome,
                        tipo: c.tipo
                    });
                });
            });
        };

        this.buscarTodosDestaque = function () {
            api.buscarCategoriasEmDestaque()
                .then(function (data) {
                    self.categoriasDestaque = JSON.parse(data);
                });
        };

        this.buscarTodos();
        this.buscarTodosDestaque();

        this.adicionarDestaque = function () {
            var el = $('#select-servico').children().children();
            var select2Data = el.select2('data')[0];
            var categoria = {
                id: select2Data.id,
                nome: select2Data.text,
                tipo: select2Data.tipo
            };
            if(this.verificaJaEmDestaque(categoria)){
                avisos.erro('Esta Categoria já se encontra na lista de destaque.');
                return;
            }

            this.categoriasDestaque.push(categoria);
            this.servico(-1);
            this.alterado(true);
            el.select2('val', '');
        };

        this.verificaJaEmDestaque = function(categoria) {
            return _.findIndex(this.categoriasDestaque, {id: categoria.id}) > -1
        };

        this.setParaOrdenar = function (e) {
            this.paraOrdenar($(e.target).val()[0]);
        };

        this.incrementarPosicaoDestaque = function () {
            var paraOrdenarIndex = this.acharIndexDestaque(this.paraOrdenar());
            if(paraOrdenarIndex <= 0){
                return;
            }
            var temp = this.categoriasDestaque[paraOrdenarIndex - 1];
            var paraOrdenar = this.categoriasDestaque[paraOrdenarIndex];
            this.categoriasDestaque[paraOrdenarIndex - 1] = paraOrdenar;
            this.categoriasDestaque[paraOrdenarIndex] = temp;
            this.setSelectDestaqueValor(paraOrdenar.id);
            this.alterado(true);
        };

        this.decrementarPosicaoDestaque = function () {
            var paraOrdenarIndex = this.acharIndexDestaque(this.paraOrdenar());
            if(paraOrdenarIndex >=  this.categoriasDestaque.length - 1){
                return;
            }
            var temp = this.categoriasDestaque[paraOrdenarIndex + 1];
            var paraOrdenar = this.categoriasDestaque[paraOrdenarIndex];
            this.categoriasDestaque[paraOrdenarIndex + 1] = paraOrdenar;
            this.categoriasDestaque[paraOrdenarIndex] = temp;
            this.setSelectDestaqueValor(paraOrdenar.id);
            this.alterado(true);
        };

        this.setSelectDestaqueValor = function (val) {
            setTimeout(function(){
                $('#selectDestaque').val(val);
            }, 10);
        };

        this.acharIndexDestaque = function (id) {
            return _.findIndex(this.categoriasDestaque, { id: id })
        };
        
        this.publicar = function () {
            this.alterado(false);
            return api.publicarCategoriasEmDestaque(this.categoriasDestaque);
        };

        this.despublicar = function () {
            this.buscarTodosDestaque();
            this.alterado(false);
        };

        this.removerEmDestaque = function () {
            var paraOrdenarIndex = this.acharIndexDestaque(this.paraOrdenar());
            if(paraOrdenarIndex <= 0){
                return;
            }
            this.categoriasDestaque.splice(paraOrdenarIndex, 1);
            this.alterado(true);
        };

        this.stringToEllipses = function(s, max){
            if(s.length > max){
                return s.substring(0, max - 3).trim().concat('...');
            }
            return s;
        };
    };
})(m, require);

module.exports = PublicarCategoriasDestaqueController;