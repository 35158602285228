/**
 * Created by ygorazevedo on 2/10/17.
 */
'use strict';

var promiseUtil = require('utils/promise');
var avisos = require('utils/avisos');
var api = require('api');
var validacoes = require('utils/validacoes');
var unidadeToJson = require('administracao/unidade/util/exportar-unidade-json').unidadeToJson;

var validaUnidadeUnica = function (ua) {
    var flag = false;
    var erro;
    return api.buscarUnidade(ua).then(function (response) {
        if(!response){
            return capturaErro(erro, ua);
        }
        var data = JSON.parse(response);
        data.id != ua.id() ? erro = 'Já existe uma unidade com os mesmo nome, CNPJ e Órgão.' : '';
        return capturaErro(erro, ua);
    });
};

var validarDadosBasico = function (ua) {
    var erro;

    !ua.orgao().dbId() ? erro = 'Erro na validação do Órgão.' : '';
    !ua.nome() ? erro = 'Erro na validação do Nome.' : '';
    !ua.cnpj() ? erro = 'Erro na validação do CNPJ.' : '';
    return capturaErro(erro, ua);
};

var validarEndereco = function (ua) {
    var erro;
    !ua.endereco().logradouro() || maiorQue(ua.endereco().logradouro().size, 70) ?
        erro = 'Erro na validação do endereço.' : '';
    !ua.endereco().numero() || maiorQue(ua.endereco().numero().size, 10) ?
        erro = 'Erro na validação do número.' : '';
    !ua.endereco().pais().id() ? erro = 'Erro na validação do país.' : '';
    ua.endereco().estado().id() == 0 && !ua.endereco().estado().nome() ?
        erro = 'Erro na validação do estado.' : '';
    ua.endereco().cidade().id() == 0 && !ua.endereco().cidadeExterior() ?
        erro = 'Erro na validação do cidade.' : '';
    !ua.endereco().bairro() ? erro = 'Erro na validação do bairro.' : '';
    !ua.endereco().cep() ? erro = 'Erro na validação do CEP.' : '';
    return capturaErro(erro, ua);
};

var validarAtendimento = function (ua) {
    var erro;
    !ua.telefone() ? erro = 'Erro na validação do telefone.' : '';
    for(var i = 0; i < ua.horarioDeAtendimento().length; i++){
        if(erro){
            break;
        }
        !ua.horarioDeAtendimento()[i].horaInicio() || !ua.horarioDeAtendimento()[i].horaFim()
            ? erro = 'Erro na validação da hora do horário de atendimento.' : '';
        !ua.horarioDeAtendimento()[i].diaInicio()
            ? erro = 'Erro na validação do dia do horário de atendimento.' : '';
        if(maisTarde(ua.horarioDeAtendimento()[i].horaInicio(), ua.horarioDeAtendimento()[i].horaFim())){
            erro = 'Erro na validação da hora do horário de atendimento.';
        }
    }
    return capturaErro(erro, ua);
};

var validarLocalizacao = function (ua) {
    var erro;
    !ua.longitude() || !ua.latitude() ? erro = 'Erro na validação nas coordenadas.' : '';
    return capturaErro(erro, ua);
};

var salvarUnidade = function (ua) {
    return promiseUtil.resolved(ua)
        .then(function (ua) {
            var erro;
            if (!validacoes.valida(ua)) {
                erro = 'Preencha os campos obrigatórios.';
            }
            return capturaErro(erro, ua);
        })
        // .then(validarDadosBasico)
        // .then(validarEndereco)
        // .then(validarAtendimento)
        // .then(validarLocalizacao)
        .then(validaUnidadeUnica)
        .then(unidadeToJson)
        .then(function (ua) {
            return api.salvarUnidade(ua);
        });
};

function maiorQue(a, b) {
    return a > b;
}

function maisTarde(a, b) {
    return Date.parse('01/01/2001 ' + a) >= Date.parse('01/01/2001 ' + b);
}

function capturaErro(e, o) {
    if(e){
        return $.Deferred().reject(e);
    }
    return o;
}

module.exports = {
    salvarUnidade: salvarUnidade
};
