'use strict';

var modelos = require('super-servico/modelos');

var str = function () {
  return '';
};

var peloMenos = _.curry(function (n, lista, fn) {
  if (lista && lista.length >= n) {
    return lista;
  }
  return lista.concat(_.times(n - lista.length, fn));
});

var um = peloMenos(1);

var item = function (i, n) {
    return n.textContent;
}

var servicoRelacionado = function (i, e) {
    var t = jQuery(e);
    return new modelos.servicoRelacionado({
        id: t.find('>id').text(),
        tag: t.find('> tag').text()
    });
};

var superServico = function (x, servicoEdtitado) {

  var seletor = servicoEdtitado ? 'servico-editado' : 'super-servico';

  return new modelos.superServico({

    dbId: x.find('> dbId').text(),
    nome: x.find('> nome').text(),
    descricao: x.find('> descricao').text(),
    servicosRelacionados: um(x.find('servicos-relacionados > item', x).map(servicoRelacionado).get(), servicoRelacionado),
    servicoEditado : null,

  });
};

module.exports = {
  domParaSuperServico: function (dom) {

    var servicoEditado = dom.getElementsByTagName("servico-editado")[0];
    var servicoEditavelDomConverted;

    if(servicoEditado){
        servicoEditavelDomConverted = superServico(jQuery('super-servico servico-editado', dom), true);
        dom.documentElement.removeChild(servicoEditado);
        var servicoAtual = superServico(jQuery('super-servico', dom));
        servicoAtual.servicoEditado = servicoEditavelDomConverted;
    }else{
        var servicoAtual = superServico(jQuery('super-servico', dom));
    }

    return servicoAtual;
  }
};
