'use strict';

window.cabecalhoModel = new (require('cabecalho/cabecalho-model'))();
var slugify = require('slugify');
var salvarServico = require('xml/salvar').salvarServico;
var publicarServico = require('xml/publicar').publicarServico;
var promise = require('utils/promise');
var validarParaPublicar = require('xml/publicar').validar;
var validarServicoDigital = require('xml/publicar').validarServicoDigital;
var descartarServico = require('xml/descartar').descartarServico;
var despublicarServico = require('api').despublicar;
var alterarDisponibilidadeServico = require('api').alterarDisponibilidade;
var avaliarServico = require('api').avaliar;
var servicoEmEdicao = require('servico/servico-em-edicao');
var redirecionarNovoServico = require('redirecionador');
var routeUtils = require('utils/route-utils');
var permissoes = require('utils/permissoes');
var modificado = m.prop(false);
var avisos = require('utils/avisos');

module.exports = {
  controller: function () {
    var self = this;
    this.podeSalvar = true;

    this.verificaPodeSalvar = function (servico) {
        var usuarioSiorgOuId = window.loggedUser.siorg;
        if(!routeUtils.ehNovo() && !permissoes.ehAdmin() && servico.orgao().nome().indexOf(usuarioSiorgOuId) == -1 &&
          servico.orgao().dbId().indexOf(usuarioSiorgOuId) == -1) {
            this.podeSalvar = false;
        }
    };

    this.cabecalho =  cabecalhoModel;
    this.caiuSessao = m.prop(false);
    this.servico = servicoEmEdicao.recuperar(this.cabecalho, _.bind(this.verificaPodeSalvar, this));

    this.salvandoServico = m.prop(false);
    this.redirect = m.prop(false);
    this.docId = m.route.param('id');
    this.dbId = m.route.param('dbId');
    this.ajudaTutorial = true;

    this._servicoSalvo = _.bind(function (servico) {
      this.servico(servico);
      this.cabecalho.limparErro();
      modificado(false);
      redirecionarNovoServico('servico', servico.nome(), servico.dbId(), this.redirect);
      this.redirect(false);
      return servico;
    }, this);

    this.salvar = function (isPublicacao, isSolicitacaoPublicacao) {

      var selectData = $('#select-orgao.input-container').children().children().select2('data')[0];
      if(selectData && selectData.siorg){
        this.servico().orgao().nome(selectData.siorg);
      }

      return salvarServico(this.servico(), this.servico().nome(), this.cabecalho.metadados, this.dbId, isPublicacao, isSolicitacaoPublicacao)
        .then(function (xml) {

          if(isSolicitacaoPublicacao){
              alertify.success('Solicitação de publicação enviada com sucesso!');
          }else if(isPublicacao) {
            alertify.success('Serviço publicado com sucesso! As informações aparecerão no Portal em até 15 minutos.');
          }
          return xml;
        })
        .then(this._servicoSalvo);
    };

    this.publicar = function (solicitarPublicacao) {

      return promise.resolved(this.servico())
        .then(validarServicoDigital)
        .then(validarParaPublicar)
        .then(_.bind(function (s) {
            if(solicitarPublicacao) {
                return self.salvar(false,true);
            }else{
                return self.salvar(true);
            }
        }, this))
        .then(_.bind(function (s) {
          if(!solicitarPublicacao){
              return publicarServico(s, this.cabecalho.metadados, this.dbId);
          }
        }, this));
    };

    this.solicitarPublicacao = function () {
      return this.publicar(true);
    };

    this.descartar = _.bind(function () {
      this.redirect(true);
      return descartarServico(this.servico(), this.cabecalho.metadados, this.dbId)
        .then(this._servicoSalvo);
    }, this);

    this.despublicar = function () {
      return despublicarServico('servico', this.servico().nome(), this.cabecalho.metadados, this.dbId);
    };

    this.alterarDisponibilidade = function () {
        return alterarDisponibilidadeServico('servico', this.servico().nome(), this.cabecalho.metadados, this.dbId);
    };

    this.avaliar = function() {
      return avaliarServico(this.servico(), this.servico().nome(), this.cabecalho.metadados, this.dbId);
    };

    this.visualizar = function () {
      var id = slugify(this.servico().nome());
      servicoEmEdicao.manter(this.servico, this.cabecalho.metadados);
      m.route('/editar/visualizar/servico/' + id+"/"+this.dbId);

      return true;
    };

  },

  view: function (ctrl) {

    if (routeUtils.ehNovo() && !permissoes.podeCriarPagina('servico')) {
      return m.component(require('acesso-negado'));
    }

    if (!routeUtils.ehNovo() && !permissoes.podeSalvarPagina(window.loggedUser.siorg, ctrl.servico().orgao().nome(), ctrl.servico().orgao().dbId())) {
      return m.component(require('acesso-negado'));
    }

    var isPublicado = false;
    if(ctrl.cabecalho.metadados.publicado) {
      isPublicado = ctrl.cabecalho.metadados.publicado.horario != null;
    }

    var binding = {
      servico: ctrl.servico,
      novo: routeUtils.ehNovo(),
      podeSalvar: ctrl.podeSalvar,
      salvandoServico: ctrl.salvandoServico,
      caiuSessao: ctrl.caiuSessao,
      isPublicado: isPublicado
    };

    return m('#conteudo', {
      config: function (element, isInitialized) {
        if (isInitialized) {

            $('.editor-markdown textarea').each(function () {
                if(!$(this).hasClass("markItUpEditor")){
                    $(this).markItUp(settingsMarkitup);
                }
            });

          return;
        }

        jQuery(element).on('change', function () {
          modificado(true);
        });

        jQuery(window).bind('beforeunload', function () {
          if (modificado()) {
            return 'Suas últimas alterações ainda não foram salvas.';
          }
        });
      }
    }, [
      m('span.cabecalho-cor'),
      m('#wrapper', [
        m.component(require('cabecalho/cabecalho'), {
          metadados: true,
          logout: true,
          nomeDaPagina: ctrl.servico().nome
          () || 'Novo serviço',
          salvar: _.bind(ctrl.salvar, ctrl),
          disabled: !ctrl.podeSalvar,
          publicar: _.bind(ctrl.publicar, ctrl),
          solicitarPublicacao: _.bind(ctrl.solicitarPublicacao, ctrl),
          visualizar: _.bind(ctrl.visualizar, ctrl),
          descartar: _.bind(ctrl.descartar, ctrl),
          cabecalho: ctrl.cabecalho,
          salvandoServico: ctrl.salvandoServico,
          caiuSessao: ctrl.caiuSessao,
          orgaoId: ctrl.servico().orgao().nome(),
          orgaoDbId: ctrl.servico().orgao().dbId(),
          ajudaTutorial: ctrl.ajudaTutorial,
           tipo: 'servico'
        }),
        m.component(require('componentes/menu/menu-lateral'), {
          menuConfig: binding,
          despublicarConfig: {
            tipo: 'servico',
            despublicar: _.bind(ctrl.despublicar, ctrl),
            metadados: ctrl.cabecalho.metadados(),
            salvandoServico: ctrl.salvandoServico,
            caiuSessao: ctrl.caiuSessao,
            orgaoId: ctrl.servico().orgao().nome()
          },
          disponibilidadeConfig: {
              tipo: 'servico',
              alterarDisponibilidade: _.bind(ctrl.alterarDisponibilidade, ctrl),
              metadados: ctrl.cabecalho.metadados(),
              salvandoServico: ctrl.salvandoServico,
              caiuSessao: ctrl.caiuSessao,
              orgaoId: ctrl.servico().orgao().nome()
          }
        }),
        m('#servico', m('.scroll', [
          m.component(require('servico/dados-basicos/dados-basicos'), binding),
          m.component(require('servico/solicitantes/solicitantes'), binding),
          m.component(require('servico/etapas/etapas'), binding),
          m.component(require('servico/outras-informacoes/outras-informacoes'), binding),
        ]))
      ])
    ]);
  }
};
