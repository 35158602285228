'use strict';

var modelos = require('servico/modelos');
var Solicitante = require('servico/solicitantes/solicitante-model');

var isBlank = _.flow(_.trim, _.isEmpty);

var allBlank = function () {
  return _.every(arguments, isBlank);
};

var allEmpty = function () {
  return _.every(arguments, _.isEmpty);
};

var limparSolicitante = function (solicitante) {
  if (allBlank(solicitante.tipo(), solicitante.requisitos())) {
    return;
  }
  return new Solicitante({
    id: _.trim(solicitante.dbId()),
    tipo: _.trim(solicitante.tipo()),
    requisitos: _.trim(solicitante.requisitos())
  });
};

var limparCaso = function (caso, fnLimpar) {
  var dbId = caso.dbId();
  var padrao = caso.padrao;
  var descricao = _.trim(caso.descricao());
  var campos = _.compact(caso.campos().map(fnLimpar));

  if (allEmpty(descricao, campos)) {
    return;
  }

  return new modelos.Caso(null, {
    dbId: dbId,
    padrao: padrao,
    descricao: descricao,
    campos: campos
  });
};

var limparCasos = function (obj, fnLimpar) {
  var casoPadrao = limparCaso(obj.casoPadrao(), fnLimpar);
  var outrosCasos = _.compact(obj.outrosCasos().map(function (caso) {
    return limparCaso(caso, fnLimpar);
  }));

  if (!casoPadrao && _.isEmpty(outrosCasos)) {
    return;
  }

  return {
    casoPadrao: casoPadrao,
    outrosCasos: outrosCasos
  };
};

var limparDocumento = function (documento) {
  if (!documento || isBlank(documento.descricao())) {
    return;
  }

  return new modelos.Documento({
    dbId: documento.dbId(),
    descricao: _.trim(documento.descricao()),
    codigo: _.trim(documento.codigo()),
    ondeObter: _.trim(documento.ondeObter()),
    observacoes: _.trim(documento.observacoes())
  });
};

var limparDocumentos = function (documentos) {
  var config = limparCasos(documentos, limparDocumento);

  if (!config) {
    return;
  }

  return new modelos.Documentos(config);
};

var limparCusto = function (custo) {
  var dbId = custo.dbId();
  var descricao = _.trim(custo.descricao());
  var moeda = _.trim(custo.moeda());
  var valor = _.trim(custo.valor());
  var valorVariavel =  _.trim(custo.valorVariavel());
  var statusCustoVariavel =  _.trim(custo.statusCustoVariavel());

  if(statusCustoVariavel == 1){
      moeda = null;
      valor = null;
  }else{
      valorVariavel = null;
  }

  if (allBlank(descricao, valor, valorVariavel) && (isBlank(moeda) || moeda === 'R$')) {
    return;
  }

  return new modelos.Custo({
    dbId: dbId,
    descricao: descricao,
    moeda: moeda,
    valor: valor,
    valorVariavel: valorVariavel,
    statusCustoVariavel : statusCustoVariavel
  });
};

var limparCustos = function (custos) {
  var config = limparCasos(custos, limparCusto);
  if (!config) {
    return;
  }
  return new modelos.Custos(config);
};

var limparCanalDePrestacao = function (canal) {
  var dbId = canal.dbId();
  var tipo = _.trim(canal.tipo());
  var descricao = _.trim(canal.descricao());
  var procedimentoSistemaIndisponivel =  _.trim(canal.procedimentoSistemaIndisponivel());
  var tempoEstimadoEspera = canal.tempoEstimadoEspera();
  var tempoEstimadoPeriodo = canal.tempoEstimadoPeriodo();

  if (allBlank(tipo, descricao)) {
    return;
  }

  return new modelos.CanalDePrestacao({
    dbId: dbId,
    tipo: tipo,
    descricao: descricao,
    procedimentoSistemaIndisponivel : procedimentoSistemaIndisponivel,
    tempoEstimadoPeriodo: tempoEstimadoPeriodo,
    tempoEstimadoEspera :tempoEstimadoEspera
  });
};

var limparCanaisDePrestacao = function (canaisDePrestacao) {
  var config = limparCasos(canaisDePrestacao, limparCanalDePrestacao);

  if (!config) {
    return;
  }

  return new modelos.CanaisDePrestacao(config);
};

var limparEtapa = function (etapa) {
  var dbId = _.trim(etapa.dbId());
  var titulo = _.trim(etapa.titulo());
  var descricao = _.trim(etapa.descricao());
  var docs = limparDocumentos(etapa.documentos());
  var custos = limparCustos(etapa.custos());
  var canaisDePrestacao = limparCanaisDePrestacao(etapa.canaisDePrestacao());

    var tteEtapa = etapa.tempoTotalEstimado();

  if (allBlank(titulo, descricao, docs, custos, canaisDePrestacao)) {
    return;
  }

  return new modelos.Etapa({
    dbId: dbId,
    titulo: titulo,
    descricao: descricao,
    documentos: docs,
    custos: custos,
    canaisDePrestacao: canaisDePrestacao,

    tempoTotalEstimado: new modelos.TempoTotalEstimado({
        tipo: _.trim(tteEtapa.tipo()),
        entreMinimo: _.trim(tteEtapa.entreMinimo()),
        ateMaximo: _.trim(tteEtapa.ateMaximo()),
        ateTipoMaximo: _.trim(tteEtapa.ateTipoMaximo()),
        entreMaximo: _.trim(tteEtapa.entreMaximo()),
        entreTipoMaximo: _.trim(tteEtapa.entreTipoMaximo()),
        descricao: _.trim(tteEtapa.descricao()),
    })

  });
};

var limparItemComId = function(item){
  if(item == "") return null;

  var id = _.trim(item.id ? item.id() : undefined);
  var descricao = _.trim(item.item());

  return new modelos.Item({
    id: id,
    item: descricao
  });
};

module.exports = function (servico) {
  var tte = servico.tempoTotalEstimado();
  var tdv = servico.validadeDocumento();

  return new modelos.Servico({
    nome: _.trim(servico.nome()),
    sigla: _.trim(servico.sigla()),
    nomesPopulares: _.compact(servico.nomesPopulares().map(limparItemComId)),
    descricao: _.trim(servico.descricao()),
    gratuidade: servico.gratuidade(),
    porcentagemDigital: servico.porcentagemDigital(),
    porcentagemManual: servico.porcentagemManual(),
    solicitantes: _.compact(servico.solicitantes().map(limparSolicitante)),
    tempoTotalEstimado: new modelos.TempoTotalEstimado({
      tipo: _.trim(tte.tipo()),
      entreMinimo: _.trim(tte.entreMinimo()),
      ateMaximo: _.trim(tte.ateMaximo()),
      ateTipoMaximo: _.trim(tte.ateTipoMaximo()),
      entreMaximo: _.trim(tte.entreMaximo()),
      entreTipoMaximo: _.trim(tte.entreTipoMaximo()),
      descricao: _.trim(tte.descricao()),
    }),
    validadeDocumento: new modelos.ValidadeDocumento({
      tipo: _.trim(tdv.tipo()),
      validade: _.trim(tdv.validade()),
      validadeTipo: _.trim(tdv.validadeTipo()),
      descricao: _.trim(tdv.descricao()),
    }),
    etapas: _.compact(servico.etapas().map(limparEtapa)),
    orgao: new modelos.Orgao({
      nome: _.trim(servico.orgao().nome()),
      dbId: _.trim(servico.orgao().dbId()),
      contato: _.trim(servico.orgao().contato())
    }),
    segmentosDaSociedade: servico.segmentosDaSociedade(),
    areasDeInteresse: servico.areasDeInteresse(),
    palavrasChave: _.compact(servico.palavrasChave().map(limparItemComId)),
    legislacoes: _.compact(servico.legislacoes().map(limparItemComId)),
    servicosRelacionados: servico.servicosRelacionados(),
    servicoDigital: servico.servicoDigital(),
    linkServicoDigital: servico.linkServicoDigital(),
    tratamentoPrioritario: _.trim(servico.tratamentoPrioritario()),
    tratamentoDispensadoAtendimento: _.trim(servico.tratamentoDispensadoAtendimento()),
    condicoesAcessibilidade: _.trim(servico.condicoesAcessibilidade()),
  });
};
