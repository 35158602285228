'use strict';

var promiseUtil = require('utils/promise');
var avisos = require('utils/avisos');
var api = require('api');
var validacoes = require('utils/validacoes');
var portalEexternoToJson = require('administracao/portais-externos/util/exportar-portal-externo-json').portalEexternoToJson;

var salvarPortalExterno = function (pe) {
    return promiseUtil.resolved(pe)
        .then(function (pe) {
            var erro;
            if (!validacoes.valida(pe)) {
                erro = 'Preencha os campos obrigatórios.';
            }
            return capturaErro(erro, pe);
        })
        .then(portalEexternoToJson)
        .then(function (pe) {
            return api.salvarPortalExterno(pe);
        });
};


function capturaErro(e, o) {
    if(e){
        return $.Deferred().reject(e);
    }
    return o;
}

module.exports = {
    salvarPortalExterno: salvarPortalExterno
};
