'use strict';

var slugify = require('slugify');

module.exports = function (tipo, nome, dbId, redirect, servico) {
  var oldId = m.route.param('dbId');
  var newId = dbId || oldId;
  if (!_.isEqual(oldId, newId)) {
    m.route('/editar/' + tipo + '/' + slugify(nome) + '/' + dbId);
  }
  if (redirect && redirect()) {
    window.location.reload();
  }
};
