'use strict';

var modelos = require('super-servico/modelos');


module.exports = function (servico) {

  return new modelos.superServico({
    nome: _.trim(servico.nome()),
    descricao: _.trim(servico.descricao()),
    servicosRelacionados: servico.servicosRelacionados()
  });
};
