/**
 * @class PublicarServicosDestaqueController
 */
var PublicarServicosDestaqueController = (function (m, require) {

    return function (args) {
        var self = this;

        var CabecalhoModel = require('cabecalho/cabecalho-model');
        var avisos = require('utils/avisos');
        var api = require('api');

        this.resetServico = function () {
            self.servico(-1);
            var el = $('#select-servico').children().children();
            el.select2('val', '');
        };

        this.cabecalho = new CabecalhoModel();
        this.caiuSessao = m.prop(false);
        this.servico = m.prop(-1);
        this.paraOrdenar = m.prop(-1);
        this.alterado = m.prop(false);

        this.servicosPaginas = [];

        this.servicosDestaque = [];

        this.buscarServicosPaginas = function () {
            api.buscarServicosPaginasPublicadas()
                .then(function (data) {
                    var resposta = JSON.parse(data).resposta;
                    self.servicosPaginas = [];
                    resposta.forEach(function (o) {
                        self.servicosPaginas.push({
                            id: o.id + '*' + o.tipo /*workaround para evitar problema de conflitos com páginas temáticas e serviços com o mesmo id*/,
                            text: o.nome + (o.tipo == 'servico' ? ' - [Serviço]' : ' - [Página Temática]'),
                            tipo: o.tipo,
                            novo: o.novo
                        })
                    });
                });
        };

        this.buscarServicosEmDestaque = function () {
            api.buscarServicosEmDestaque()
                .then(function (data) {
                    var resposta = JSON.parse(data);
                    self.servicosDestaque = [];
                    resposta.forEach(function(o){
                        self.servicosDestaque.push({
                            id: o.id + '*' + o.tipo,
                            nome: o.nome,
                            tipo: o.tipo,
                            novo: o.novo
                        });
                    });
                });
        };

        this.buscarServicosPaginas();
        this.buscarServicosEmDestaque();

        this.adicionarDestaque = function () {
            var el = $('#select-servico').children().children();
            var select2Data = el.select2('data')[0];
            var servicoPagina = {
                id: select2Data.id,
                nome: select2Data.text.split('- [')[0],
                tipo: select2Data.tipo
            };
            if(this.verificaJaEmDestaque(servicoPagina)){
                avisos.erro('Este Serviço/Página Temática já se encontra na lista de destaque.');
                return;
            }

            this.servicosDestaque.push(servicoPagina);
            this.servico(-1);
            this.alterado(true);
            el.select2('val', '');
        };

        this.verificaJaEmDestaque = function(servicoPagina) {
            return _.findIndex(this.servicosDestaque, {id: servicoPagina.id,
                    tipo: servicoPagina.tipo}) > -1
        };

        this.setParaOrdenar = function (e) {
            this.paraOrdenar($(e.target).val()[0]);
        };

        this.incrementarPosicaoDestaque = function () {
            var servicoParaOrdenarIndex = this.paraOrdenar();
            if(servicoParaOrdenarIndex <= 0){
                return;
            }
            var servicoTemp = this.servicosDestaque[servicoParaOrdenarIndex - 1];
            var servicoParaOrdenar = this.servicosDestaque[servicoParaOrdenarIndex];
            this.servicosDestaque[servicoParaOrdenarIndex - 1] = servicoParaOrdenar;
            this.servicosDestaque[servicoParaOrdenarIndex] = servicoTemp;
            this.paraOrdenar(this.paraOrdenar() - 1);
            this.selecionarTableRow();
            this.alterado(true);
        };

        this.decrementarPosicaoDestaque = function () {
            var servicoParaOrdenarIndex = this.paraOrdenar();
            if(servicoParaOrdenarIndex >= this.servicosDestaque.length - 1){
                return;
            }
            var servicoTemp = this.servicosDestaque[servicoParaOrdenarIndex + 1];
            var servicoParaOrdenar = this.servicosDestaque[servicoParaOrdenarIndex];
            this.servicosDestaque[servicoParaOrdenarIndex + 1] = servicoParaOrdenar;
            this.servicosDestaque[servicoParaOrdenarIndex] = servicoTemp;
            this.paraOrdenar(this.paraOrdenar() + 1);
            this.selecionarTableRow();
            this.alterado(true);
        };

        this.selecionarTableRow = function (e) {
            $('.selected-row').removeClass('selected-row');
            if(e){
                self.paraOrdenar(e.target.parentElement.rowIndex);
                $(e.target).parent('tr').addClass('selected-row');
            } else {
                $($('table#tableDestaqueServico tr')[this.paraOrdenar()]).addClass('selected-row');
            }
        };

        this.publicarServicosDestaque = function () {
            this.alterado(false);
            return api.publicarServicosEmDestaque(this.clearDestaques(this.servicosDestaque));
        };

        this.descartarServicosDestaque = function () {
            this.buscarServicosEmDestaque();
            this.paraOrdenar(-1);
            this.selecionarTableRow();
            this.alterado(false);
        };

        this.removerServicoDestaque = function () {
            this.servicosDestaque.splice(this.paraOrdenar(), 1);
            this.paraOrdenar(-1);
            this.alterado(true);
        };

        this.clearDestaques = function(arr){
            return _.each(arr, function (o) {
                o.id = o.id.split('*')[0];
                o.nome = o.nome.split(' - ')[0];
            });
        };
    };
})(m, require);

module.exports = PublicarServicosDestaqueController;