'use strict';

var Template = require('administracao/menu-rodape/modelos').Template;
var salvarMenuCabecalhoRodape = require('administracao/menu-rodape/util/salvar').salvarMenuCabecalhoRodape;
var Api = require('api');
var avisos = require('utils/avisos');

module.exports = {
    controller: function (args) {

        var self = this;
        this.menuCabecalho = null;
        this.menuRodape = null;
        this.salvandoTemplate = m.prop(false);

        this.alertMsg = 'Menu e Rodapé atualizados com sucesso.';

        this.salvar = function () {
            return salvarMenuCabecalhoRodape(this.menuCabecalho,this.menuRodape)
                .then(function (data) {
                   var response = JSON.parse(data);
                   return response;
                });
        };

        Api.buscarMenuCabecalhoRodape().then(function (response) {
            response = JSON.parse(response);

            for(var i in response.resposta){
                if(response.resposta[i].id == "MENU_CABECALHO"){
                    self.menuCabecalho = new Template(response.resposta[i]);
                }else if(response.resposta[i].id == "MENU_RODAPE"){
                    self.menuRodape = new Template(response.resposta[i]);
                }
            }


        }, function(error){

        });

    },

    view: function (ctrl) {

        var MENU_RODAPE_CADASTRO_PATH = 'administracao/menu-rodape/cadastro';

        return m('#conteudo', [
            m('span.cabecalho-cor'),
            m('#wrapper', [
                m.component(require('cabecalho/cabecalho'), {
                    metadados: true,
                    nomeDaPagina: 'Gerenciar Menu e Rodapé',
                    logout: true,
                    salvar: _.bind(ctrl.salvar, ctrl),
                    salvandoServico: ctrl.salvandoTemplate,
                    caiuSessao: m.prop(false),
                    alertMsg: ctrl.alertMsg,
                    naoRecarregarPagina: true
                }),
                m('div#admin', [
                    m('div.scroll', [
                       m.component(require(MENU_RODAPE_CADASTRO_PATH + '/template'), {
                           menuRodape:ctrl.menuRodape,
                           menuCabecalho:ctrl.menuCabecalho
                        })
                    ])
                ])
            ])
        ]);


    }
};
