'use strict';

var id = require('utils/id');
var routeUtils = require('utils/route-utils');
var v = require('utils/validacoes');

var servicoRelacionado = function (config) {
    var data = (config || {});
    this.id = v.prop(data.id || "");;
    this.tag = v.prop(data.tag || "");
}


var superServico = function (config) {
  var data = (config || {});
  this.id = id('super-servico');

  var validaIdJaExistente = _.trim(data.nome) ? _.noop : v.idUnicoSuperServico;
  this.nome = v.prop(data.nome || '', v.obrigatorio, v.textoCurto, validaIdJaExistente);
  this.descricao = v.prop(data.descricao || '', v.obrigatorio, v.textoLongo3000);
  this.servicosRelacionados = v.prop(data.servicosRelacionados || []);
  this.dbId = v.prop(data.dbId || '', v.obrigatorio, v.maximo(100000));
  this.servicoEditado = data.servicoEditado;
};

module.exports = {
  id: id,
  superServico: superServico,
   servicoRelacionado : servicoRelacionado
};
