/**
 * Created by ygorazevedo on 9/28/17.
 */
this.ordenacaoAlfabeticaCrescente = function (a, b) {
    return a.text.localeCompare(b.text);
};

this.ordenacaoAlfabeticaDecrescente = function (a, b) {
    return b.text.localeCompare(a.text);
};

module.exports = {
    ordenacaoAlfabeticaCrescente: ordenacaoAlfabeticaCrescente,
    ordenacaoAlfabeticaDecrescente: ordenacaoAlfabeticaDecrescente
};