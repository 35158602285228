'use strict';

var modal = require('componentes/modal-bootstrap');

module.exports = function (ctrl, args) {

    var self = this;
    self.servico = {};

    var prompt = {
        title: function(attrs) {
            return attrs.title ? attrs.title : "Title";
        },
        controller: function(attrs) {
            this.field = m.prop("");
            this.submit = function() {
                attrs.callback(this.field());
            }.bind(this);
        },
        view: function(ctrl, attrs) {
            return m("form", [
                m("p",
                    m("input[type='text']", {onchange: m.withAttr("value", ctrl.field), value: ctrl.field()})
                ),
                m("p",
                    m("button[type='button']", {onclick: ctrl.submit}, "Submit")
                )
            ]);
        }
    };

    var action = function(name) {
        this.feedback("Thanks " + name + ", you're welcome");
    }.bind(this);

    var show = function(servico){
        self.servico = servico;
        modal.show.bind(modal, prompt, {callback: action, title: "Editar porcentagem digital"});
    }

}