/**
 * Created by ygorazevedo on 2/8/17.
 */
'use strict';

var tooltips = require('tooltips');

module.exports = {
    controller: function (args) {
        var self = this;

        this.latitude = args.latitude;
        this.longitude = args.longitude;
        this.map;
        this.isMap = 0;

        this.click = function (e) {
            var lonlat = ol.proj.transform(e.coordinate, 'EPSG:3857', 'EPSG:4326');
            self.setPinOnMap(lonlat[0], lonlat[1], e);
            m.redraw();
        };

        this.setPinOnMap = function (longitude, latitude, e) {
            self.longitude(longitude);
            self.latitude(latitude);

            var iconFeatures = [];

            var iconFeature = new ol.Feature({
                geometry: new ol.geom.Point(ol.proj.transform([longitude, latitude], 'EPSG:4326', 'EPSG:3857'))
            });

            iconFeatures.push(iconFeature);

            var vectorSource = new ol.source.Vector({
                features: iconFeatures
            });

            var iconStyle = new ol.style.Style({
                image: new ol.style.Icon(/** @type {olx.style.IconOptions} */ ({
                    anchor: [0.5, 27],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    opacity: 1,
                    src: 'http://i.imgur.com/QZAE02n.png'
                }))
            });

            var layerToRemove = self.map.getLayers().a[1];
            if(layerToRemove){
                self.map.removeLayer(layerToRemove);
            }

            if(!e){
                self.flyTo(ol.proj.fromLonLat([longitude, latitude]), function () {});
            }

            var vectorLayer = new ol.layer.Vector({
                source: vectorSource,
                style: iconStyle
            });

            self.map.addLayer(vectorLayer);
        };

        this.initMap = function (eId) {
            if(self.isMap > 1){
                return;
            }
            self.isMap++;
            self.map = new ol.Map({
                target: eId,
                layers: [
                    new ol.layer.Tile({
                        source: new ol.source.OSM()
                    })
                ],
                view: new ol.View({
                    center: ol.proj.fromLonLat([-47.88281816303313, -15.79413706965552]),
                    zoom: 16
                })
            });
            self.map.on('singleclick', self.click, this);
            if(self.longitude() && self.latitude()){
                self.setPinOnMap(parseFloat(self.longitude()), parseFloat(self.latitude()));
            } else {
                self.trackMe(self.map.getView());
            }
        };

        this.adicionarPin = function () {
            if(self.longitude() && self.latitude()){
                self.setPinOnMap(parseFloat(self.longitude()), parseFloat(self.latitude()));
            }
        };

        this.flyTo = function (location, done) {
            var duration = 2000;
            var zoom = self.map.getView().getZoom();
            var parts = 2;
            var called = false;
            function callback(complete) {
                --parts;
                if (called) {
                    return;
                }
                if (parts === 0 || !complete) {
                    called = true;
                    done(complete);
                }
            }
            self.map.getView().animate({
                center: location,
                duration: duration
            }, callback);
            self.map.getView().animate({
                zoom: zoom - 1,
                duration: duration / 2
            }, {
                zoom: 16,
                duration: duration / 2
            }, callback);
        };

        this.trackMe = function (view) {
            var geolocation = new ol.Geolocation({
                tracking: true
            });
            geolocation.once('change', function (evt) {
                var coordinate = geolocation.getPosition();
                self.setPinOnMap(coordinate[0], coordinate[1]);
                view.setCenter(ol.proj.fromLonLat(coordinate));
                view.setZoom(16);
                m.redraw();
            });
            geolocation.on('change', function (evt) {
                var coordinate = geolocation.getPosition();
                self.setPinOnMap(coordinate[0], coordinate[1], {});
            });
        }
    },

    view: function (ctrl) {
        ctrl.initMap('mapa');

        return m('fieldset#localizacao', [
            m('h3', [
                'Informe a Localização Geográfica',
                m.component(tooltips.localizacaoUnidadeAtendimento)
            ]),
            m('div.mapa#mapa'),

            m('div.right.width-half', m('label.titulo', 'Latitude'),
                m('.input-container', {
                    class: ctrl.latitude.erro()
                } ,[
                    m('input[type=text]', {
                        value: ctrl.latitude(),
                        onchange: m.withAttr('value', ctrl.latitude)
                    })
                ])
            ),
            m('div.left.width-half', m('label.titulo', 'Longitude'),
                m('.input-container', {
                    class: ctrl.latitude.erro()
                } ,[
                    m('input[type=text]', {
                        value: ctrl.longitude(),
                        onchange: m.withAttr('value', ctrl.longitude)
                    })
                ])
            ),
            m('a.button.botao-primario.right', {
                onclick: ctrl.adicionarPin,
                style: {
                    margin: '2em 0em'
                }
            }, 'Adicionar Marcador')
        ]);
    }
};