
'use strict';

var v = require('utils/validacoes');

var Template = function (config) {
    var data = (config || {});
    this.id = m.prop(data.id);
    this.template = v.prop(data.template || '', v.obrigatorio);
};


module.exports = {
    Template: Template
};

