'use strict';

var tooltips = require('tooltips');
var permissoes = require('utils/permissoes');
var Api = require('api');

module.exports = {
    controller: function (args) {
        var self = this;
        this.menuCabecalho = args.menuCabecalho;
        this.menuRodape = args.menuRodape;
        this.previsualizar = false;
        this.converter = new window.showdown.Converter();

        this.togglePrevisualizar = function () {
            self.previsualizar = !self.previsualizar;
        }
    },


    view: function (ctrl) {

        return m('fieldset', [

            m('label.input-container',
                m('input[type=checkbox]', {
                    value: "true",
                    checked: ctrl.previsualizar,
                    onchange: ctrl.togglePrevisualizar
                }),
                'Previsualizar'
            ),
            m('h3', [
                'Menu'
            ]),

            m("textarea",{
                rows: 15,
                value: ctrl.menuCabecalho.template(),
                onchange: m.withAttr('value', ctrl.menuCabecalho.template),
                style:{
                    display: !ctrl.previsualizar ? 'block' : 'none'
                }

            }),

            (ctrl.previsualizar ?
            m('.menu-previsualizacao menu-cabecalho-previsualizacao', m.trust(ctrl.menuCabecalho.template()))
                : m('')),

            m('h3', [
                'Rodapé'
            ]),

            m("textarea",{
                rows: 15,
                value: ctrl.menuRodape.template(),
                onchange: m.withAttr('value', ctrl.menuRodape.template),
                style:{
                    display: !ctrl.previsualizar ? 'block' : 'none'
                }
            }),

            (ctrl.previsualizar ?
                m('.menu-previsualizacao .menu-rodape-previsualizacao', m.trust(ctrl.menuRodape.template()))
                : m('')),
        ]);
    }
};
