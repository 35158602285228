var BrasilCidadaoController = (function (m, require) {

    var avisos = require('utils/avisos');
    var buscarFlagBrasilCidadao = require('api').buscarFlagBrasilCidadao;
    var salvarFlagBrasilCidadao = require('api').salvarFlagBrasilCidadao;

    return function (args) {
        alertify.set({delay: 10000});

        var self = this;
        this.flagBrasilCidadao = m.prop();

        buscarFlagBrasilCidadao().then(function (data) {
            self.flagBrasilCidadao(data == 1);
            self.flagBrasilCidadaoCache = m.prop(data == 1);
        });

        this.toggleBrasilCidadao = function (value) {
            self.flagBrasilCidadao(value);
        };

        this.salvarFlagBrasilCidadao = function () {
            var flag = (self.flagBrasilCidadao() ? '1': '0');
            if(self.flagBrasilCidadao() == self.flagBrasilCidadaoCache()) {
                avisos.erro('Erro! O Brasil Cidadão já está ' + (flag != '1' ? 'desabilitado.' : 'habilitado.'));
                return;
            }
            salvarFlagBrasilCidadao(flag).then(function (resp) {
                if(!resp){
                    avisos.sucesso('Brasil Cidadão ' + (flag == '1' ? 'habilitado' : 'desabilitado') + ' com sucesso.');
                    setTimeout(function () {
                        window.location.replace('/editar');
                    }, 2500);
                    self.flagBrasilCidadaoCache(flag == 1);
                } else {
                    avisos.erro('Algo deu errado. Tente novamente. O Brasil Cidadão continua ' + flag == '1' ? 'desabilitado.' : 'habilitado.');
                }
            });
        }

    };
})(m, require);

module.exports = BrasilCidadaoController;