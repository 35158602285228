'use strict';

window.cabecalhoModel = new (require('cabecalho/cabecalho-model'))();
var Tooltips = require('tooltips');
var EditorBase = require('componentes/editor-base');

var paginaEmEdicao = require('servico/servico-em-edicao');
var carregarPagina = require('xml/carregar').carregarPaginaTematica;
var salvarPagina = require('xml/salvar').salvarPaginaTematica;
var publicarPagina = require('xml/publicar').publicarPaginaTematica;
var descartarPagina = require('xml/descartar').descartarPaginaTematica;
var despublicar = require('api').despublicar;
var redirecionarNovaPagina = require('redirecionador');
var permissoes = require('utils/permissoes');
var routeUtils = require('utils/route-utils');
var idUnico = require('utils/id-unico');
var slugify = require('slugify');
var api = require('api');
var promiseUtil = require('utils/promise');
var v = require('utils/validacoes');
var verificarPublicacao = require('xml/publicar').verificarPublicacao;

module.exports = {
  controller: function (args) {
    this.tipo = m.prop('pagina-tematica');
    this.docId = m.route.param('id');
    this.dbId = m.route.param('dbId');

    this.podeSalvar = false;

    this.verificaPodeSalvar = function (pt) {
        var usuarioSiorgOuId = window.loggedUser.siorg;
        var orgaos = pt.orgaos();
        if(!routeUtils.ehNovo() && !permissoes.ehAdmin()) {
            for(var i = 0; i < orgaos.length; i++) {
                if(orgaos[i].nome().indexOf(usuarioSiorgOuId) > -1 ||
                    orgaos[i].dbId().indexOf(usuarioSiorgOuId) > -1){
                    this.podeSalvar = true;
                }
            }
        } else {
            this.podeSalvar = true;
        }
    };

    this.modificado = m.prop(false);
    this.cabecalho = cabecalhoModel;
    this.pagina = carregarPagina(m.route.param('id'), this.cabecalho, m.route.param('dbId'), _.bind(this.verificaPodeSalvar, this));
    this.salvandoServico = m.prop(false);
    this.caiuSessao = m.prop(false);
    this.redirect = m.prop(false);
    this.dbId = m.route.param('dbId');

    this._onOp = _.bind(function (pagina) {
      this.pagina(pagina);
      this.modificado(false);
      redirecionarNovaPagina(this.tipo(), pagina.nome(), pagina.dbId(), this.redirect);
      this.redirect(false);
      return pagina;
    }, this);

    this.salvar = _.bind(function (isPublicacao, isSolicitacaoPublicacao) {

      return salvarPagina(this.pagina(), this.pagina().nome(), this.cabecalho.metadados, this.dbId, isPublicacao, isSolicitacaoPublicacao)
        .then(this.pagina)
        .then(function (xml) {
          if(isSolicitacaoPublicacao){
              alertify.success('Solicitação de publicação enviada com sucesso!');
          }else if(isPublicacao) {
            alertify.success('Página Temática publicado com sucesso! As informações aparecerão no Portal em até 15 minutos.');
          }
          return xml;
        })
        .then(this._onOp);
    }, this);

    this.publicar = function (isSolicitacaoPublicacao) {
        return verificarPublicacao(null, 'pagina-tematica', this.docId, this.dbId)
            .then(_.bind(function () {
                if(isSolicitacaoPublicacao) {
                    return this.salvar(false,true);
                }else{
                    return this.salvar(true);
                }

            }, this))
            .then(_.bind(function (s) {
                return publicarPagina(s, s.nome(), this.cabecalho.metadados, this.dbId);
            }, this))
            .then(this._onOp,  function(e){
                alertify.error(e);
            });
    };

    this.solicitarPublicacao = function () {
       return this.publicar(true);
    };

    this.descartar = _.bind(function () {
      this.redirect(true);
      return descartarPagina(this.pagina(), this.cabecalho.metadados, this.dbId)
        .then(this._onOp);
    }, this);

    this.despublicar = function () {
      return despublicar('pagina-tematica', this.pagina().nome(), this.cabecalho.metadados, this.dbId);
    };

    this.validar = function (nome) {
      var idAtual = slugify(this.pagina().nome());
      if (!nome) {
        return 'Este campo é obrigatório.';
      }
      if (idAtual !== slugify(nome) && !idUnico(nome)) {
        return 'O nome preenchido já está sendo utilizado em outra página.';
      }
    };

    this.renomearPagina = function (novoNome) {
      var pagina = this.pagina();
      var dbId = pagina.dbId();
      api.renomear('pagina-tematica', dbId, novoNome).then(_.bind(function () {
        pagina.nome(novoNome);
        m.route('/editar/pagina-tematica/' + slugify(novoNome) + '/' + dbId);
        alertify.success('Página renomeada com sucesso!', 0);
      }, this));
    };

    this.visualizar = function () {
      var id = slugify(this.pagina().nome());
      paginaEmEdicao.manter(this.pagina, this.cabecalho.metadados);
      m.route('/editar/visualizar/pagina/tematica/' + id);
      return true
    };
  },

  view: function (ctrl, args) {
    var pagina = ctrl.pagina;

    $('.editor-markdown textarea').each(function () {
        if(!$(this).hasClass("markItUpEditor")){
            $(this).markItUp(settingsMarkitup);
        }
    });

    function renomearView() {
      alertify.labels.cancel = 'Cancelar';
      alertify.labels.ok = 'Renomear';
      alertify.prompt('Novo nome:',
        function (e, novoNome) {
          if (e && pagina().nome() !== novoNome) {
            var validacao = ctrl.validar(novoNome);
            if (!validacao) {
              ctrl.renomearPagina(novoNome);
            } else {
              alertify.labels.ok = 'Ok';
              alertify.alert(validacao, function () {
                renomear();
              });
            }

          }
        },
        pagina().nome()
      );
    }

    var renomear = function () {
      if(ctrl.cabecalho.metadados.publicado.horario) {
        alertify.confirm('Ao alterar o nome da Pagina Temática todas as alterações salvas serão publicadas. Deseja continuar?', function (result) {
          if(result) {
            renomearView();
          }
        });
      } else {
        renomearView();
      }
    };

    if (routeUtils.ehNovo() &&
      !permissoes.podeCriarPagina('pagina-tematica')) {
      return m.component(require('acesso-negado'));
    }

    if (!routeUtils.ehNovo() &&
      !permissoes.possuiPermissaoPaginaTematica('EDITAR_SALVAR PAGINA-TEMATICA')) {
      return m.component(require('acesso-negado'));
    }

    if (!pagina()) {
      return m('');
    }

    var tamanhoConteudo = 10000;
    var tooltips = {
      tipo: Tooltips.tipoPagina,
      nome: Tooltips.nomePaginaTematica,
      conteudo: Tooltips.conteudoPaginaTematica
    };

    var binding = {
      pagina: pagina,
      nome: pagina().nome,
      novo: routeUtils.ehNovo()
    };

    return m.component(EditorBase, {
      conteudoConfig: function (element, isInitialized) {
        if (isInitialized) {
          return;
        }
        jQuery(element).on('change', function () {
          ctrl.modificado(true);
        });
        jQuery(window).bind('beforeunload', function () {
          if (ctrl.modificado()) {
            return 'Suas últimas alterações ainda não foram salvas.';
          }
        });
      },

      cabecalhoConfig: {
        metadados: true,
        logout: true,
        nomeDaPagina: pagina().nome() || 'Nova página temática',
        salvar: _.bind(ctrl.salvar, ctrl),
        disabled: !ctrl.podeSalvar,
        publicar: _.bind(ctrl.publicar, ctrl),
        solicitarPublicacao: _.bind(ctrl.solicitarPublicacao, ctrl),
        descartar: _.bind(ctrl.descartar, ctrl),
        cabecalho: ctrl.cabecalho,
        salvandoServico: ctrl.salvandoServico,
        caiuSessao: ctrl.caiuSessao,
        visualizar: _.bind(ctrl.visualizar, ctrl)
      },

      menuLateralConfig: {
        despublicarConfig: {
          tipo: 'pagina-tematica',
          despublicar: _.bind(ctrl.despublicar, ctrl),
          metadados: ctrl.cabecalho.metadados(),
          salvandoServico: ctrl.salvandoServico,
          caiuSessao: ctrl.caiuSessao
        }
      },

      componentes: [
        m.component(require('pagina/componentes/tipo-de-pagina'), {
          tipo: ctrl.tipo,
          tooltipTipo: tooltips.tipo
        }),

        !routeUtils.ehNovo() ? m.component(require('pagina/componentes/nome'), _.assign(binding, {
          titulo: 'Nome da página temática',
          componente: require('componentes/input'),
          tooltipNome: tooltips.nome,
          nomeFn: _.identity,
          editNome: true,
          desabilitaRenomear:!permissoes.ehAdmin(),
          promptRenomear: renomear
        })) : m.component(require('pagina/componentes/nome'), _.assign(binding, {
          titulo: 'Nome da página temática',
          componente: require('componentes/input'),
          tooltipNome: tooltips.nome,
          nomeFn: _.identity
        })),

        m.component(require('pagina/componentes/orgaos'), {
          prop: pagina().orgaos(),
        }),

        m.component(require('pagina/componentes/conteudo'), {
          prop: pagina().conteudo,
          maximo: tamanhoConteudo,
          tooltipConteudo: tooltips.conteudo
        })
      ]
    });
  }
};
