'use strict';

var referencia = require('referencia');

module.exports = {

  controller: function (args) {
      this.etapa = args;
      this.unidades = referencia.unidadesDeTempoVisualizar;
      this.converter = new window.showdown.Converter();

      this.temTempoEntre = function () {
          return this.etapa.tempoTotalEstimado().tipo() === 'entre';
      };

      this.temTempoAte = function () {
          return this.etapa.tempoTotalEstimado().tipo() === 'ate';
      };

      this.temTempo = function () {
          return this.etapa.tempoTotalEstimado() && (this.temTempoAte() || this.temTempoEntre());
      };

      this.temTempoImediato = function () {
          return this.etapa.tempoTotalEstimado().tipo() == 'atendimento-imediato';
      };


  },

  view: function (ctrl) {

      var tempoEntreView = function () {
          return m('p', [
              'Entre ',
              m('span', ctrl.etapa.tempoTotalEstimado().entreMinimo()),
              ' e ',
              m('span', ctrl.etapa.tempoTotalEstimado().entreMaximo()),
              ' ',
              m('span', ctrl.unidades[ctrl.etapa.tempoTotalEstimado().entreTipoMaximo()]),
              ' é o tempo estimado para a prestação deste serviço.'
          ]);
      };

      var tempoAteView = function () {
          return m('p', [
              m('span', 'Até '),
              m('span', ctrl.etapa.tempoTotalEstimado().ateMaximo()),
              m('span', ' '),
              m('span', ctrl.unidades[ctrl.etapa.tempoTotalEstimado().ateTipoMaximo()])
          ]);
      };

      var tempoImediatoView = function () {
          return m('p', [
              m('span', 'Atendimento Imediato')
          ]);
      };

      var tempoNaoEstimadoView = function () {
          return m('p', [
              m('span', 'Não estimado ainda')
          ]);
      };

      var tempoView = function () {
          if (ctrl.temTempoEntre()) {
              return tempoEntreView();
          } else if (ctrl.temTempoAte()) {
              return tempoAteView();
          } else if(ctrl.temTempoImediato()) {
              return tempoImediatoView();
          } else {
              return tempoNaoEstimadoView();
          }
      };

  return m('.subtitulo-etapa', [
      m('p.titulo-documento', 'TEMPO DE DURAÇÃO DA ETAPA'),
      m('p.info-etapa', tempoView())

  ]);
    //return m.component(require('servico/visualizar/view-vazia'));

  }
};
