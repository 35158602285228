    'use strict';

    var api = require('api');
    var checkBoxes = require('componentes/checkboxes-ajax');

    var criaCheckboxes = function(listaSegmentos) {
        module.exports = checkBoxes.create({
            chave: 'segmentosDaSociedade',
            id: 'segmentos-da-sociedade',
            titulo: 'Segmentos da sociedade',
            itens: listaSegmentos
        });
    };

    api.get('lista-formulario/lista-segmentos').then(criaCheckboxes);