/**
 * Created by ygorazevedo on 1/24/17.
 */

var v = require('utils/validacoes');
var salvarAjudaHtml = require('api').salvarAjudaHtml;
var avisos = require('utils/avisos');

'use strict';

module.exports = {
    controller: function () {

        alertify.set({delay: 10000});

        this.files = null;

        var self = this;

        this.importaArquivo = function (e) {
            e.preventDefault();
            var formData = new FormData();
            formData.append('ajuda', self.files[0]);
            salvarAjudaHtml(formData).then(function (data) {
                if(!data){
                    avisos.sucesso('Arquivo importado com sucesso!');
                    $('#arquivoNome').val('');
                    $('#importaArquivo').prop('disabled', true);
                } else {
                    avisos.erro('Falha ao importar o arquivo, tente novamente.');
                }
            });
            $('#arquivoNome').val('');
            self.removerArquivo();
        },

        this.validaArquivo = function () {
            // self.removerArquivo();
            self.files = v.validaArquivos($('input[type=file]')[0].files, 1, '.html', 'text/html', 5);
            var div = $('div.input-container');
            if(typeof self.files !== 'object'){
                div.addClass('arquivo-fora-padrao');
                $('#arquivoNome').val('');
                $('#importaArquivo').prop('disabled', true);
                $('#listaArquivo').html('');
            } else {
                div.removeClass('arquivo-fora-padrao');
                $('#arquivoNome').val(self.files[0].name);
                $('#importaArquivo').prop('disabled', false);
                self.adicionarArqNaLista(self.files[0]);
            }
        },

        this.adicionarArqNaLista = function (file) {
            $('#listaArquivo').html("<li class='listaArquivo'><button class='remove itemArquivo' id='removerArquivo' aria-hidden='true'><span></span></button><span>"+file.name+"</span></li>");
            $('#removerArquivo').click(self.removerArquivo);
        },

        this.removerArquivo = function () {
            self.files = [];
            $('#arquivoNome').val('');
            $('#importaArquivo').prop('disabled', true);
            $('#listaArquivo').html('');
            $('#arquivoAjuda').wrap('<form>').closest('form').get(0).reset();
            $('#arquivoAjuda').unwrap();
        }

    },

    view: function (ctrl) {

        return m('#conteudo', [
            m('span.cabecalho-cor'),
            m('#wrapper', [
                m.component(require('cabecalho/cabecalho'), {
                    metadados: false,
                    logout: true
                }),
                m('div#admin', [
                    m('div.scroll', [
                        m('fieldset#tipoDePagina', [
                            m('div.novo', [
                                m('h3', [
                                    'Adicione o arquivo HTML',
                                    m.component(require('tooltips').importarAjuda)
                                ]),
                                m('form', {
                                    enctype: 'multipart/form-data',
                                    style: {
                                        marginTop: '2em'
                                    }
                                }, [
                                    m('label.button.botao-primario',
                                        {for: 'arquivoAjuda'}, [
                                            m('i.fa.fa-upload'),
                                            m.trust(' Adicionar')
                                        ]),
                                    m('input[type=file]#arquivoAjuda', {
                                        accept: '.html',
                                        onchange: ctrl.validaArquivo,
                                        style: {
                                            display: 'none'
                                        }
                                    }),
                                    m('button.button.botao-primario#importaArquivo', {
                                        onclick: ctrl.importaArquivo,
                                        disabled: 'disabled'
                                    }, [
                                        m('i.fa.fa-save'),
                                        m.trust(' Importar')
                                    ]),
                                    m('div.input-container.ajuda-importar', m('input[type=text]#arquivoNome', {
                                        readonly: true,
                                        style: {
                                            width: '300px',
                                        }
                                    })),
                                    m('ul#listaArquivo')
                                ])
                            ])
                        ])
                    ])
                ])
            ])
        ]);
    }
};