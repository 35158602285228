'use strict';

var safeGet = require('utils/code-checks').safeGet;
var avisos = require('utils/avisos');
var promise = require('utils/promise');
var permissoes = require('utils/permissoes');
var modal = require('componentes/modal-bootstrap');
var api = require('api');
var validacoes = require('utils/validacoes');

function botaoQueEspera(flagProp, opts) {
    return m('button#' + opts.id, {
        onclick: opts.onclick,
        disabled: (opts.disabled ? 'disabled' : '')
    }, m('i.fa.fa-' + opts.icon));
}

module.exports = {
    controller: function (args) {

        this.publicar = safeGet(args, 'publicar');
        this.solicitarPublicacao = safeGet(args, 'solicitarPublicacao');
        this.reprovarPublicacao = safeGet(args, 'reprovarPublicacao');
        this.descartar = safeGet(args, 'descartar');
        this.avaliar = safeGet(args, 'avaliar');
        this.salvandoServico = args.salvandoServico;
        this.caiuSessao = args.caiuSessao;
        this.orgaoId = args.orgaoId;
        this.orgaoDbId = args.orgaoDbId;
        this.publicando = m.prop(false);
        this.avaliando = m.prop(false);
        this.descartando = m.prop(false);
        this.dbId = m.route.param('dbId');
        this.isValidacao = args.isValidacao;
        this.tipo = args.tipo;

        this.justificativa = validacoes.prop('', validacoes.obrigatorio);

        this.opera = function (prop, operacao) {
            prop(true);
            this.salvandoServico(true);
            promise.onSuccOrError(
                operacao,
                _.bind(function () {
                    prop(false);
                    this.salvandoServico(false);
                    m.redraw.strategy('all');
                    m.redraw(true);
                }, this));
        };

        this.publicarClick = function () {
            this.opera(
                this.publicando,
                this.publicar()
                .then(null, null));
        };

        this.solicitarPublicacaoClick = function () {
            this.opera(
                this.publicando,
                this.solicitarPublicacao()
                    .then(null, null));
        };

        this.reprovarPublicacaoClick = function () {
            this.opera(
                this.descartando,
                this.reprovarPublicacao({justificativa:this.justificativa(),idServico: this.dbId})
                    .then(null, null));
        };


        this.descartarClick = function () {
            this.opera(
                this.descartando,
                this.descartar()
                    .then(
                        avisos.alertFn('Alterações recusadas. Recarregando serviço.'),
                        avisos.erroFn('Não foi possível descartar as alterações')));
        };

        this.avaliarClick = function() {
            this.opera(
                this.avaliar,
                this.avaliar()
                    .then(
                        avisos.alertFn('Avaliação concluída!'),
                        avisos.erroFn('Não foi possível concluir a avaliação.')
                    )
            );
        };

    },

    view: function (ctrl, args) {
        var meta = args.metadados;

        var self = ctrl;

        //if (_.isEmpty(meta)) {
        //    api.metadados(ctrl.dbId).then(function(metadados) {
        //        meta = metadados;
        //    });
        //}
        var desabilitaBotoes = ctrl.publicando() || ctrl.descartando();
        var adm = (function () {
            for (var i in loggedUser.permissoes) {
                if (loggedUser.permissoes[i].authority === 'ADMIN'){
                    return true;
                }
            }
            return false;
        })();
        var usrPublica = (function(){
            for (var i in loggedUser.permissoes) {
                if (loggedUser.permissoes[i].authority.match(/PUBLICAR/)){
                    return true;
                }
            }
            return false;
        })();

        function temEdicao() {
            return _.get(meta, 'editado.revisao');
        }

        function temPublicacao() {
            return _.get(meta, 'publicado.revisao');
        }

        function revisaoEditadoEPublicadoDiferente() {
            return (_.get(meta, 'editado.revisao') !== _.get(meta, 'publicado.revisao'));
        }

        function podeDescartar() {
            return temEdicao() && temPublicacao() && revisaoEditadoEPublicadoDiferente();
        }

        function podePublicar() {
            var pode = temEdicao() && revisaoEditadoEPublicadoDiferente();
            if (adm) {
                return pode;
            }
            return pode;// && _.get(meta, 'status') === 'AVALIADO';
        }

        function podeAvaliar() {
            return adm && _.get(meta, 'status') !== 'AVALIADO' && temPublicacao();
        }

        /*var avaliarView = (function(podeAvaliar){
            if (podeAvaliar) {
                return m('span#publicar-view', [
                    m('span.label-botao', 'Avaliar alterações?'),
                    m.trust('&nbsp&nbsp'),
                    botaoQueEspera(ctrl.avaliando, {
                        id: 'avaliar',
                        onclick: _.bind(ctrl.avaliarClick, ctrl),
                        icon: 'check',
                        disabled: desabilitaBotoes || !podeAvaliar || ctrl.salvandoServico() || ctrl.caiuSessao()
                    })
                ])
            }
            return m('');
        })(podeAvaliar());*/

        var promptValidacao = {
            title: function(attrs) {
                return attrs.title ? attrs.title : "Justificativa";
            },
            controller: function() {

                this.submit = function() {
                    if(this.formIsValid()){
                        modal.hide();
                        ctrl.reprovarPublicacaoClick();
                    }else{
                        avisos.erro("Informe o motivo da não aprovação da publicação")
                    }
                }.bind(this);

                this.formIsValid = function () {
                    return self.justificativa().trim() && self.justificativa().trim() != "";
                };
            },
            view: function(ctrl) {
                return m("form", [
                    m('label.titulo', 'Informar o motivo da não aprovação da publicação'),
                    m('textarea', {
                        onchange: m.withAttr("value", self.justificativa), value: self.justificativa()
                    }),
                    m("p.btn-salvar",
                        m("button[type='button']", {
                            onclick: ctrl.submit,
                        }, "Confirmar")
                    )
                ]);
            }
        };

        var publicarDescartarView = (function(podePublicarDescartar){

            var publicar = desabilitaBotoes || !podePublicar() || ctrl.salvandoServico() || ctrl.caiuSessao() || !usrPublica;
            var solicitar = desabilitaBotoes || !podePublicar() || ctrl.salvandoServico() || ctrl.caiuSessao();

            if ((podePublicarDescartar || ctrl.isValidacao) && (adm || ctrl.tipo == 'super-servico')) {
                var descartar = desabilitaBotoes || !podeDescartar() || ctrl.salvandoServico() || ctrl.caiuSessao() || !usrPublica;

                return m('span#publicar-view', [
                    m('span.label-botao', 'Publicar alterações?'),
                    m.trust('&nbsp&nbsp'),

                    ctrl.isValidacao ?  botaoQueEspera(ctrl.descartando, {
                        id: 'descartar',
                        onclick: modal.show.bind(modal, promptValidacao),
                        icon: 'times',
                    }) : botaoQueEspera(ctrl.descartando, {
                        id: 'descartar',
                        onclick: _.bind(ctrl.descartarClick, ctrl),
                        icon: 'times',
                        disabled: descartar
                    }),

                    botaoQueEspera(ctrl.publicando, {
                        id: 'publicar',
                        onclick: _.bind(ctrl.publicarClick, ctrl),
                        icon: 'check',
                        disabled: publicar
                    })
                ]);
            }else if(ctrl.tipo == 'servico'){

                return m('span#publicar-view', [
                    m('span.label-botao', 'Solicitar Publicação?'),
                    m.trust('&nbsp&nbsp'),

                    botaoQueEspera(ctrl.publicando, {
                        id: 'solicitarPublicacao',
                        onclick: _.bind(ctrl.solicitarPublicacaoClick, ctrl),
                        icon: 'check',
                        disabled: solicitar || meta().statusPublicacao == 1|| meta().statusPublicacao == 2
                    })
                ]);
            }
            return m('');
        })(permissoes.podePublicarDascartarPagina(window.loggedUser.siorg, ctrl.orgaoId, ctrl.orgaoDbId));

        return m('span', [publicarDescartarView, m.component(modal)]);
    }
};
