'use strict';

var api = require('api');
var promise = require('utils/promise');
var slugify = require('slugify');
var validacoes = require('utils/validacoes');

function publicar(tipo, modelo, idUnsafe, metadados, dbId) {
  var id = slugify(idUnsafe);
  return api.publicar(tipo, id, metadados, dbId).then(function () {
    return modelo;
  });
}

function reprovarPublicacao(justificativa) {
    return api.reprovarPublicacao(justificativa).then(function () {
        return justificativa;
    });
}

function validar(modelo) {

    if(modelo.servicoEditado){
        modelo.servicoEditado = null;
    }

  if (validacoes.valida(modelo)) {
    return modelo;
  } else {
      alertify.error("campos obrigatórios não preenchidos");
      throw 'Erros de validação';
  }
}

function validarServicoDigital(modelo) {
    if(!modelo.servicoDigital()) {
        modelo.linkServicoDigital.erro('');
      return modelo;
    } else {
      if(modelo.linkServicoDigital()) {
          modelo.linkServicoDigital.erro('');
        return modelo;
      }
    }
    modelo.linkServicoDigital.erro('erro-campo-obrigatorio');
    throw 'Erros de validação';
}

function verificarPublicacao(modelo, tipo, docId, dbId) {
  return api.get('/pagina/publicacao/verificar/' + tipo + '/' + slugify(docId) + '/' + dbId).then(function (response) {
    if(!response){

      var pagina = (tipo == "pagina-tematica") ? "página temática" : "página de Órgão";

      throw 'É necessário que pelo menos um serviço publicado seja vinculado à '+pagina+' para publicá-la';
    } else {
      return modelo;
    }
  });
}

function fluxoPublicar(tipo, modelo, id, metadados, dbId) {
  return promise.resolved(modelo)
    .then(validar)
    .then(function(s){
      return verificarPublicacao(s, tipo, id, dbId);
    })
    .then(function (s) {
      return publicar(tipo, s, id, metadados, dbId);
    });
}

module.exports = {
  publicarServico: function (servico, metadados, dbId) {
    return fluxoPublicar('servico', servico, servico.nome(), metadados, dbId);
  },
   publicarSuperServico: function (servico, metadados, dbId) {
        return fluxoPublicar('super-servico', servico, servico.nome(), metadados, dbId);
   },
  reprovarPublicacaoServico: function (justificativa) {
      return reprovarPublicacao(justificativa);
  },
  publicarPaginaTematica: function (pagina, docId, metadados, dbId) {
    return fluxoPublicar('pagina-tematica', pagina, docId, metadados, dbId);
  },
  publicarOrgao: function (pagina, docId, metadados, dbId) {
    return fluxoPublicar('orgao', pagina, docId, metadados, dbId);
  },
  validar: validar,
  validarServicoDigital: validarServicoDigital,
  verificarPublicacao: verificarPublicacao
};
