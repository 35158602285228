'use strict';

module.exports = {

  controller: function (args) {
    this.servico = args.servico;
    this.podeSalvar = args.podeSalvar;
  },

  view: function (ctrl) {
    var binding = {
      servico: ctrl.servico,
      podeSalvar: ctrl.podeSalvar
    };

    return m('#outras-informacoes', [
      m.component(require('servico/outras-informacoes/orgao-responsavel'), binding),
      m.component(require('servico/outras-informacoes/tempo-total-estimado'), binding),
      m.component(require('servico/outras-informacoes/informacoes-acessibilidade'), binding),
      m.component(require('servico/outras-informacoes/informacoes-tratamento-prioritario'), binding),
      m.component(require('servico/outras-informacoes/segmentos-da-sociedade'), binding),
      m.component(require('servico/outras-informacoes/areas-de-interesse'), binding),
      m.component(require('servico/outras-informacoes/palavras-chave'), binding),
      m.component(require('servico/outras-informacoes/legislacoes'), binding),
      m.component(require('servico/outras-informacoes/servicos-relacionados'), binding)
    ]);
  }
};
