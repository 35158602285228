var GerenciarCategoriaView = (function (m, require) {
    /**
     * @param {ListaOrgaoView} ctrl
     * @param args
     */
    return function (ctrl, args) {

        var modal = require('componentes/modal-bootstrap');

        function alterar(ctrl, unidade) {
            var prompt = {
                title: function(attrs) {
                    return attrs.title ? attrs.title : "Alterar " + unidade.nome;
                },
                controller: function(attrs) {
                    this.antigoNome = unidade.nome;
                    this.novoNome = m.prop(unidade.nome);

                    this.submit = function() {
                        if(this.formIsValid()){
                            unidade.nome = this.novoNome();
                            modal.hide();
                            attrs.callback(unidade, this.antigoNome);
                        }
                    }.bind(this);

                    this.formIsValid = function () {
                        var length = this.novoNome().trim('').length;
                        return length > 0 && length < 251;
                    };

                },
                view: function(ctrl) {
                    return m("form", [
                        m('label.titulo', 'Informe o novo nome da Categoria'),
                        m('input[type=text]', {
                            value: ctrl.novoNome(),
                            onchange: m.withAttr('value', ctrl.novoNome)
                        }),
                        m("p.btn-salvar",
                            m("button[type='button']", {
                                onclick: ctrl.submit,
                            }, "Salvar")
                        ),
                        m("p.btn-salvar",
                            m("button[type='button']", {
                                onclick: function(){modal.hide();}
                            }, "Cancelar")
                        )
                    ]);
                }
            };

            return  m('a', {
                href: '#',
                onclick: modal.show.bind(modal, prompt, {callback: _.bind(ctrl.alterar, ctrl)})
            }, unidade.nome);
        }

        var PLACEHOLDER_PESQUISA = "Nome da nova Categoria";

        var conteudoTable = function(unidades) {
            var retorno = [
                m('tr', [
                    m('th[colspan=4]', 'Nome'),
                    m('th.text-center[colspan=1]', 'Destaque'),
                    m('th[colspan=2]', 'Número de Serviços Associados'),
                    m('th.text-center.no-padding-left[colspan=1]','Ações')
                ])
            ];
            _.each(unidades, function (unidade) {
                retorno.push(
                    m('tr', [
                        m('td[colspan=4]', [
                            alterar(ctrl, unidade)
                        ]),
                        m('td.text-center[colspan=1]', [
                            (ctrl.isEmDestaque(unidade.nome) !== -1) ? 'Sim' : 'Não'
                        ]),
                        m('td.text-center[colspan=2]', [
                            unidade.servicosAssociados || 0
                        ]),
                        m('td.text-center[colspan=1]', [
                            excluir(unidade)
                        ])
                    ])
                );
            });
            return retorno;
        };

        function excluir(unidade) {
            return m('button.remover', {
                onclick: _.bind(ctrl.excluir, ctrl, unidade)
            }, [m('i.fa.fa-trash-o'), m('span.tooltip-content', 'Excluir Categoria')]);
        }

        return m('#conteudo',[
            m('span.cabecalho-cor'),
            m('#wrapper', [
                m.component(require('cabecalho/cabecalho'), {
                    metadados: false,
                    nomeDaPagina: 'Lista de páginas',
                    logout: true
                }),

                m('#bem-vindo.lista-unidades', [
                    m('div.busca-unidades', [
                        m('input[type=text][placeholder="' + PLACEHOLDER_PESQUISA + '"]', {
                            style: {
                                width: '61em',
                                display: 'inline-block'
                            },
                            value: ctrl.novaCategoria(),
                            onchange: m.withAttr('value', ctrl.novaCategoria)
                        }),
                        m('button.botao-primario.fa.fa-plus', {
                            style: {float: 'right', marginTop: '0'},
                            onclick: _.bind(ctrl.salvar, ctrl)
                        })
                    ]),

                    m('div.lista-unidades', [
                        m('table', [
                            m('thead', m('tr', [
                                m(
                                    'td[colspan=8]',
                                    (ctrl.categorias.length === 1)
                                        ? 'Um item foi encontrado'
                                        : ((ctrl.categorias.length > 1)
                                            ? ctrl.categorias.length + " itens foram encontrados"
                                            : "Nenhum item foi encontrado")
                                )
                            ])),
                            conteudoTable(ctrl.categorias)
                        ])
                    ])
                ])
            ])
        , m.component(modal)]);

    };
})(m, require);

module.exports = GerenciarCategoriaView;