'use strict';

var domParaServico = require('xml/servico-factory').domParaServico;
var domParaSuperServico = require('xml/super-servico-factory').domParaSuperServico;
var domParaPaginaTematica = require('xml/pagina-tematica-factory').domParaPaginaTematica;
var domParaOrgao = require('xml/orgao-factory').domParaOrgao;
var api = require('api');

function carregarServico(id, cabecalho, dbId) {
  return api.carregar('servico', id, cabecalho.metadados, dbId)
    .then(function (xml) {
      cabecalho.limparErro();
      return domParaServico(xml);
    });
}

function carregarSuperServico(id, cabecalho, dbId) {
    return api.carregar('super-servico', id, cabecalho.metadados, dbId)
        .then(function (xml) {
            cabecalho.limparErro();
            return domParaSuperServico(xml);
        });
}

function carregarPaginaTematica(id, cabecalho, dbId, callback) {
  return api.carregar('pagina-tematica', id, cabecalho.metadados, dbId)
    .then(function (xml) {
      cabecalho.limparErro();
      var pagina = domParaPaginaTematica(xml);
      if(typeof callback === 'function') {
          callback(pagina);
      }
      return pagina;
    });
}

function carregarOrgao(id, cabecalho, dbId) {
  return api.carregar('orgao', id, cabecalho.metadados, dbId)
    .then(function (xml) {
      cabecalho.limparErro();
      return domParaOrgao(xml);
    });
}

module.exports = {
  carregarServico: carregarServico,
  carregarPaginaTematica: carregarPaginaTematica,
  carregarOrgao: carregarOrgao,
  carregarSuperServico: carregarSuperServico
};
