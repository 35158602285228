'use strict';

module.exports = {

  controller: function (args) {
    this.solicitantes = args;
    this.converter = new window.showdown.Converter();
  },

  view: function (ctrl) {
    return m('', ctrl.solicitantes.map(function (solicitante) {
      return m('.solicitantes markdown margem-solicitantes coluna-descricao-servico', [
                m('h4', m('p', m.trust(ctrl.converter.makeHtml(solicitante.tipo())))),
                m.trust(ctrl.converter.makeHtml(solicitante.requisitos()))
            ]);
    }));
  }
};
