'use strict';

var idUnico = require('utils/id-unico');
var idServicoUnico = require('utils/id-unico-super-servico')

var primeiroErroPara = _.curry(function (validacoes, valor) {
  return _.reduce(validacoes, function (erro, validador) {
    return erro || validador(valor);
  }, undefined);
});

var validador = function (property, validacoes) {
  var erro = m.prop();

  var valida = function () {
    var valor;
    if (_.isEmpty(arguments)) {
      valor = property();
    } else {
      valor = _.head(arguments);
    }

    return erro(primeiroErroPara(validacoes, valor || property()));
  };

  var wrapper = function () {
    var novoValor = property.apply(property, arguments);
    if (!_.isEmpty(arguments)) {
      valida(novoValor);
    }
    return novoValor;
  };

  wrapper.erro = erro;
  wrapper.valida = valida;
  return wrapper;
};

var prop = function () {
  var valorInicial = _.head(arguments);
  var validacoes = _.tail(arguments);

  return validador(m.prop(valorInicial), validacoes);
};

var validaTamanhoMaximoCampos = function(obj){
  switch (typeof obj) {
    case 'object':
      return _.every(_.map(obj, validaTamanhoMaximoCampos));

    case 'function':
      if(obj() instanceof Object){
        return _.every(_.map(obj(), validaTamanhoMaximoCampos));
      } else if(_.isFunction(obj.valida)) {
        var erro = obj.valida();
        if(erro && erro.indexOf('max') === -1){
          obj.erro('');
        }
        return !erro || (erro.indexOf('max') === -1) && valida(obj());
      }
      return true;
  }

  return true;
};

var valida = function (obj) {
  switch (typeof obj) {
  case 'object':
    return _.every(_.map(obj, valida));

  case 'function':
    if (_.isFunction(obj.valida)) {
      var erro = obj.valida();
      return (_.isUndefined(erro) || _.compact(erro).length === 0) && valida(obj());
    }
    return valida(obj());
  }

  return true;
};

var maximo = _.curry(function (len, v) {
  if (v && v.length > len) {
    return 'erro-max-' + len;
  }
});

var minimo = _.curry(function (len, v) {
  if (v && v.length < len) {
    return 'erro-min-' + len;
  }
});

var numerico = function (v) {
  if (v && !v.match(/^\d+(\.\d{3})*(,\d+)?$/)) {
    return 'erro-campo-numerico';
  }
};

var obrigatorio = function (v) {
  if (!v) {
    return 'erro-campo-obrigatorio';
  }
};

var validaIdUnico = function (unsafeId) {
  return idUnico(unsafeId) ? undefined : 'erro-nome-servico-existente';
};

var validaIdUnicoTematica = function (unsafeId) {
  return idUnico(unsafeId) ? undefined : 'erro-nome-tematica-existente';
};

var validaidUnicoSuperServico = function (unsafeId) {
    return idServicoUnico(unsafeId) ? undefined : 'erro-nome-servico-existente';
}

var validaIdUnicoOrgao = function (unsafeId) {
  return idUnico(unsafeId) ? undefined : 'erro-nome-orgao-existente';
};

var cada = function () {
  var validacoes = arguments;

  return function (valores) {
    var erros = _.map(valores, primeiroErroPara(validacoes));
    return !_.isEmpty(_.compact(erros)) ? erros : undefined;
  };
};

var se = function () {
  var propTeste = arguments[0];
  var valorTeste = arguments[1];
  var validacoes = _.drop(arguments, 2);

  return function (valor) {
    if (propTeste.apply(propTeste) === valorTeste) {
      return primeiroErroPara(validacoes, valor);
    }
  };
};

var validaArquivos = function(files, length, extension, type, size){
  if(!files){
    files = 'no-file-error';
  } else if(files.length > length){
    files = 'too-many-files-error';
  } else if(!(files[0].name.indexOf(extension) > -1) && !(files[0].type === type)){
    files = 'wrong-extension-error';
  } else if((files[0].size / 1024 / 1024) > size){
    files = 'too-big-error';
  }

  return files;
};

var validaHora = function (v) {
  if(!/(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]/.test(v)){
    return 'erro-hora-invalida';
  }
};

var validarTelefone = function (v) {
  if(!/(\((([1,4,6,8,9][1-9])|(2[1,2,4,7,8])|(3[1-8])|(4[1-9])|(5[1-5])|(7[1,3,4,5,7,9]))\)).+/i.test(v)){
    return 'erro-telefone-invalido';
  }
}

module.exports = {
  valida: valida,
  prop: prop,
  cada: cada,
  se: se,
  obrigatorio: obrigatorio,
  maximo: maximo,
  minimo: minimo,
  numerico: numerico,
  textoCurto: maximo(150),
  textoMedio: maximo(250),
  textoLongo: maximo(500),
  textoLongo1000: maximo(1000),
  textoLongo3000: maximo(3000),
  idUnico: validaIdUnico,
  idUnicoSuperServico: validaidUnicoSuperServico,
  idUnicoTematica: validaIdUnicoTematica,
  idUnicoOrgao: validaIdUnicoOrgao,
  validaTamanhoMaximoCampos: validaTamanhoMaximoCampos,
  validaArquivos: validaArquivos,
  validaHora: validaHora,
  validarTelefone: validarTelefone
};
