/**
 * Created by ygorazevedo on 2/8/17.
 */
'use strict';

var tooltips = require('tooltips');
var permissoes = require('utils/permissoes');
var api = require('api');
var avisos = require('utils/avisos');

module.exports = {
    controller: function (args) {
        this.orgao = args.orgao;
        this.sigla = args.sigla;
        this.nome = args.nome;

        var self = this;

        this.salvarOrgao = function () {
            if(!self.validaNome()){
                return;
            }
            self.orgao.nome(self.nome() + ' (' + self.sigla() + ')');
            api.salvarOrgaoSemSiorg(self.orgao)
                .then(function (data) {
                    var resposta = JSON.parse(data).resposta;
                    self.orgao.dbId(resposta.id);
                    avisos.sucesso('Órgão salvo com sucesso.');
                    m.route('/editar/administracao/orgao/lista');
                });
        };

        this.validaNome = function () {
            if(self.nome()){
                self.nome.erro('');
                return true
            } else {
                self.nome.erro('erro-campo-obrigatorio');
                return false
            }
        };

        this.limparInfos = function () {
            self.nome('');
            self.sigla('');
        };

        this.voltarPagina = function () {
            history.go(-1);
        };
    },

    view: function (ctrl) {

        return m('fieldset#dados-basicos', [
            m('h2', [
                'Criar Órgão',
                m.component(tooltips.criarOrgao)
            ]),
            m('div.left', {
                style: {
                    width: '49%'
                }
            } ,[
                m('h3', 'Nome'),
                m('.input-container.cadastro-unidade', {
                        class: ctrl.nome.erro(),
                    },
                    m('input[type=text]#cnpj', {
                        value: ctrl.nome(),
                        onchange: m.withAttr('value', ctrl.nome)
                    }))
            ]),
            m('div.right',  {
                style: {
                    width: '49%',
                }
            } ,[
                m('h3.opcional', 'Sigla'),
                m('.input-container.cadastro-unidade',
                    m('input[type=text]#cnpj', {
                        value: ctrl.sigla(),
                        onchange: m.withAttr('value', ctrl.sigla)
                    }))
            ]),
            m('div.right', {
                style: {
                    marginTop: '2em'
                }
            } , [
                m('button.button.botao-primario', {
                    onclick: ctrl.voltarPagina
                } , 'Voltar'),
                m('button.button.botao-primario', {
                    onclick: _.bind(ctrl.limparInfos, ctrl),
                    disabled: !ctrl.nome() && !ctrl.sigla()
                } , 'Limpar'),
                m('button.button.botao-primario', {
                    onclick: _.bind(ctrl.salvarOrgao, ctrl)
                } , 'Gravar')
            ])
        ]);
    }
};
