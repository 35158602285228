var ListaPortaisExternosController = (function (m, require) {

    return function (ctrl, args) {
        var Api = require('api');
        var Paginacao = require('componentes/paginacao');
        var permissoes = require('utils/permissoes');


        var conteudoTablePortaisExternos = function(portaisExternos) {
            var retorno = [
                m('tr', [
                    m('th[colspan=3]', 'Estado'),
                    m('th.center[colspan=1]', 'Sigla'),
                    m('th.center[colspan=2]', 'Period.'),
                    m('th.center[colspan=3]', 'URL da API'),
                    m('th.text-center.no-padding-left[colspan=1]', 'Ações')
                ])
            ];
            _.each(portaisExternos, function (portalExterno) {
                retorno.push(
                    m('tr', [
                        m('td.center[colspan=3]', [
                            m('a[href=/editar/administracao/portais-externos/edicao/'+portalExterno.id+']', portalExterno.nomeEstado)
                        ]),
                        m('td.center[colspan=1]', portalExterno.siglaEstado),
                        m('td.center[colspan=2]', portalExterno.periodicidadeExecucao),
                        m('td.center[colspan=3]', portalExterno.urlApi),
                        m('td.text-center[colspan=1]', [
                            excluirPortalExterno(portalExterno)
                        ])
                    ])
                );
            });
            return m('table', [
                m('thead', m('tr', [
                    m(
                        'td[colspan=8]',
                        (ctrl.portaisExternos.length === 1)
                            ? 'Um item foi encontrado'
                            : ((ctrl.portaisExternos.length > 1)
                            ? ctrl.portaisExternos.length + " itens foram encontrados"
                            : "Nenhum item foi encontrado")
                    )
                ])),
                m('tbody', retorno)
            ]);
        };

        function excluirPortalExterno(portalExterno) {
            return m('button.remover', {
                title: 'Remover este conteúdo',
                onclick: _.bind(ctrl.excluirPortalExterno, ctrl, portalExterno)
            }, [m('i.fa.fa-trash-o'), m('span.tooltip-content', 'Excluir Portal Externo')]);
        }


        return m('#conteudo',[
            m('span.cabecalho-cor'),
            m('#wrapper', [
                m.component(require('cabecalho/cabecalho'), {
                    metadados: false,
                    nomeDaPagina: 'Lista de páginas',
                    logout: true
                }),

                m('#bem-vindo.lista-unidades', [
                    m('div.busca-unidades', [
                        m('h4', ['Estado']),
                        m.component(require('administracao/portais-externos/lista/select-estado'), {
                            estados: ctrl.estados,
                            prop: ctrl.estado,
                            onchange: ctrl.filtrarPorEstado
                        })
                    ]),


                    m('div.lista-unidades', [
                        m.component(Paginacao, {
                            data: ctrl.portaisExternos,
                            rowsperpage: 10,
                            pagerender: conteudoTablePortaisExternos,
                            wrapperclass: 'importar-unidade',
                        }),
                        m('div.loader.text-center', {style: {display: ctrl.pesquisaTimeOut ? 'inherit' : 'none'}} ,[
                            m('i.fa.fa-spinner.fa-pulse.fa-5x', { style: {marginTop: '30%'}}),
                            m('br'),
                            m('br'),
                            m.trust('Pesquisando...')
                        ])
                    ])
                ])
            ])
        ]);

    };
})(m, require);

module.exports = ListaPortaisExternosController;