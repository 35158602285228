    'use strict';

    var api = require('api');
    var referencia = require('referencia');
    var modelos = require('../modelos');

    var listaTempoPeriodo = [];
    var mapTempoPeriodo;

    var controller = function(args) {
        var self = this;
        api.get('lista-formulario/lista-canais')
            .then(function(lista) { self.tiposDeCanalDePrestacao = m.prop(lista); });

        api.get('lista-formulario/lista-canais-slugfy')
            .then(function(lista) { self.mapTiposDeCanalPrestacao = m.prop(lista); });


        api.get('/lista-formulario/lista-tempo-periodos-slugfy')
            .then(function(lista) {
                mapTempoPeriodo = m.prop(lista);
                api.get('/lista-formulario/lista-tempo-periodos')
                    .then(function(lista) {
                        listaTempoPeriodo = [];
                        return lista.map(function(t) {
                            listaTempoPeriodo.push({ id: t.nomeTempoPeriodo, text: t.nomeTempoPeriodo});
                        });
                    });
            });


        this.adicionar = function (prop) {
            var canaisDePrestacao = prop();
            canaisDePrestacao.push(new modelos.CanalDePrestacao());
            prop(canaisDePrestacao);
        };

        this.remover = function (prop, i) {
            var canaisDePrestacao = prop();
            canaisDePrestacao.splice(i, 1);
            prop(canaisDePrestacao);
        };
    };

    var view = function(ctrl, args) {



        var selectTipo = function (prop, tte) {
            var tipo = mapTempoPeriodo()[prop()];
            if(tipo){
                prop(tipo);
            }
            return m.component(require('componentes/select2'), {
                prop: prop,
                tte: tte,
                data: listaTempoPeriodo
            });
        };


        var tempoEstimado = function (canalDePrestacao) {
            return m('div', [
                m('label.titulo', 'TEMPO MÁXIMO DE ESPERA PARA ATENDIMENTO'),

                m('span.tipo-ate', {
                    style: {
                        display: 'inline'
                    }
                }, [
                    m('span', 'ATÉ'),
                    m('.input-container.inline.margin-right', {
                        class: canalDePrestacao.tempoEstimadoEspera.erro()
                    }, [
                        m('input.ate-maximo[type="number"]', {
                        min: 1,
                        value: canalDePrestacao.tempoEstimadoEspera(),
                        onchange: m.withAttr('value', canalDePrestacao.tempoEstimadoEspera)
                    })]),

                    selectTipo(canalDePrestacao.tempoEstimadoPeriodo)
                ]),
            ])
        };

        var selectAteEntre = function () {
            return m.component(require('componentes/select2'), {
                data: [{id:"ate",text:"Até"}]
            });
        };

        var procedimentoSistemaIndisponivel = function(canalDePrestacao){

            return m('div', [
                m('label.titulo.opcional', 'PROCEDIMENTOS DE ATENDIMENTO QUANDO O SISTEMA INFORMATIZADO SE ENCONTRAR INDISPONÍVEL'),
                m.component(require('componentes/editor-markdown'), {
                    rows: 3,
                    value: canalDePrestacao.procedimentoSistemaIndisponivel(),
                    onchange: m.withAttr('value', canalDePrestacao.procedimentoSistemaIndisponivel),
                    onblur: m.withAttr('value', canalDePrestacao.procedimentoSistemaIndisponivel),
                    maximo: 1000
                })
            ])
        };


        var canaisDePrestacao = args.campos;
        if (canaisDePrestacao().length === 0) {
            ctrl.adicionar(canaisDePrestacao);
        }

        return m('.canais-de-prestacao', [
            canaisDePrestacao().map(function (canalDePrestacao, i) {
                var tipo = ctrl.mapTiposDeCanalPrestacao()[canalDePrestacao.tipo()];

                var exibirProcedimentoSistemaIndisponivel = canalDePrestacao.tipo().includes("Web") || canalDePrestacao.tipo().includes("Aplicativo");
                var exibirTempoEspera = canalDePrestacao.tipo().includes("Presencial") || canalDePrestacao.tipo().includes("Telefone");

                if(tipo){
                    canalDePrestacao.tipo(tipo);
                }
                return m('.canal-de-prestacao', {
                    key: canalDePrestacao.id
                }, [

                    m.component(require('componentes/select2'), {
                        data: ctrl.tiposDeCanalDePrestacao(),
                        prop: canalDePrestacao.tipo
                    }),

                    canaisDePrestacao().length === 1 ? '' : m('button.remove', {
                        onclick: ctrl.remover.bind(ctrl, canaisDePrestacao, i)
                    }, [m('span')]),

                    exibirTempoEspera ? tempoEstimado(canalDePrestacao) : '',

                    m('label.titulo', referencia.descricoesDeCanaisDePrestacao[canalDePrestacao.tipo() || 'Descreva como o cidadão deve utilizar este canal']),
                    m.component(require('componentes/editor-markdown'), {
                        rows: 3,
                        value: canalDePrestacao.descricao(),
                        onchange: m.withAttr('value', canalDePrestacao.descricao),
                        onblur: m.withAttr('value', canalDePrestacao.descricao),
                        erro: canalDePrestacao.descricao.erro()
                    }),
                    exibirProcedimentoSistemaIndisponivel ? procedimentoSistemaIndisponivel(canalDePrestacao) : ''

                ]);
            }),
            m('button.adicionar.adicionar-canal-de-prestacao', {
                onclick: ctrl.adicionar.bind(ctrl, canaisDePrestacao)
            }, [
                m('i.fa.fa-plus'),
                ' Adicionar canal '
            ])
        ]);
    };

    module.exports = {controller:controller, view:view};
