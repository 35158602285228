'use strict';

var tratamentoAcessoNegado = require('utils/tratamento-acesso-negado');
var tratamentoConcorrenciaEdicao = require('utils/tratamento-concorrencia-edicao');

function fazMockSeRodarEmTestes(xhr) {
    if (!xhr.getResponseHeader) {
        xhr.getResponseHeader = _.noop;
    }
}

module.exports = function (metadados) {
    return function (xhr) {
        fazMockSeRodarEmTestes(xhr);


        metadados = _.extend(metadados,{
            publicado: {
                revisao: xhr.getResponseHeader('X-Git-Commit-Publicado'),
                autor: xhr.getResponseHeader('X-Git-Autor-Publicado'),
                horario: xhr.getResponseHeader('X-Git-Horario-Publicado')
            },
            editado: {
                revisao: xhr.getResponseHeader('X-Git-Commit-Editado'),
                autor: xhr.getResponseHeader('X-Git-Autor-Editado'),
                horario: xhr.getResponseHeader('X-Git-Horario-Editado')
            },
            status: xhr.getResponseHeader('X-Db-Status'),
            statusUpdatedAt: xhr.getResponseHeader('X-Db-UpdatedAt'),
            statusPublicacao: xhr.getResponseHeader('X-Db-StatusPublicacao'),
            servicoIndisponivel: xhr.getResponseHeader('X-Db-ServicoIndisponivel')

        });

        !(metadados instanceof Function) ?'': metadados(metadados);
        tratamentoAcessoNegado(xhr);
        tratamentoConcorrenciaEdicao(xhr);

        return (_.contains([406, 403], xhr.status)) ? 'acesso_negado' : xhr.responseText;
    };
};