'use strict';

var id = require('utils/id');
var routeUtils = require('utils/route-utils');
var v = require('utils/validacoes');

var tratamentoDispensadoAtendimentoPadrao="O usuário deverá receber, conforme os princípios expressos na lei nº 13.460/17, um atendimento pautado nas seguintes diretrizes:\n· Urbanidade;\n· Respeito;\n· Acessibilidade;\n· Cortesia;\n· Presunção da boa-fé do usuário;\n· Igualdade;\n· Eficiência;\n· Segurança; e\n· Ética";
var condicoesAcessibilidadePadrao = "O usuário do serviço público, conforme estabelecido pela lei nº13.460/17, tem direito a atendimento presencial, quando necessário, em instalações salubres, seguras, sinalizadas, acessíveis e adequadas ao serviço e ao atendimento.";
var tratamentoPrioritarioPadrao ="Tem direito a atendimento prioritário as pessoas com deficiência, os idosos com idade igual ou superior a 60 anos, as gestantes, as lactantes, as pessoas com crianças de colo e os obesos, conforme estabelecido pela lei 10.048, de 8 de novembro de 2000​";

var Caso = function (parentId, config) {
  var data = (config || {});
  this.id = id((parentId ? parentId + '-' : '') + 'caso');
  this.padrao = data.padrao;
  this.dbId = m.prop(data.dbId || '');
  this.descricao = v.prop(data.descricao || '', v.textoCurto);
  this.campos = m.prop(data.campos || []);
};

var Item = function(config) {
  var data = (config || {});
  this.id = m.prop(data.id || '');
  this.item = m.prop(data.item || '');
};

var ItemSegmento = function(config) {
  var data = (config || {});
  this.idSegmento = m.prop(data.idSegmento || '');
  this.idServicoSegmento = m.prop(data.idServicoSegmento || '');
  this.item = m.prop(data.item || '');
};

var CanaisDePrestacao = function (config) {
  var data = (config || {});
  this.id = id('canais-de-prestacao');
  this.casoPadrao = m.prop(data.casoPadrao || new Caso(this.id, {
    padrao: true,
    campos: []
  }));
  this.outrosCasos = m.prop(data.outrosCasos || []);
};

var CanalDePrestacao = function (config) {
  var data = (config || {});
  this.id = id('canal-de-prestacao');
  this.dbId = m.prop(data.dbId || '');
  this.tipo = v.prop(data.tipo || '', v.obrigatorio);
  this.descricao = v.prop(data.descricao || '', v.textoLongo, v.obrigatorio);
  this.tempoEstimadoEspera =  v.prop(data.tempoEstimadoEspera || '');
  this.tempoEstimadoPeriodo = v.prop(data.tempoEstimadoPeriodo || '');
  this.procedimentoSistemaIndisponivel =  v.prop(data.procedimentoSistemaIndisponivel || '');
};

var Documentos = function (config) {
  var data = (config || {});
  this.id = id('documentos');
  this.casoPadrao = m.prop(data.casoPadrao || new Caso(this.id, {
    padrao: true,
    campos: []
  }));
  this.outrosCasos = m.prop(data.outrosCasos || []);
};

var Documento = function (config) {
  var data = (config || {});
  this.id = id('documento');
  this.dbId = v.prop(data.dbId || '');
  this.descricao = v.prop(data.descricao || '', v.textoLongo);
  this.codigo = m.prop(data.codigo);
  this.ondeObter = m.prop(data.ondeObter || '', v.textoMedio);
  this.observacoes = m.prop(data.observacoes || '', v.textoMedio);
  this.bloquear = m.prop(data.bloquear != undefined ? data.bloquear : true);
};

var Custo = function (config) {
  var data = (config || {});
  this.id = id('custo');
  this.dbId = m.prop(data.dbId || ''),
  this.descricao = v.prop(data.descricao || '', v.textoCurto);
  this.moeda = m.prop(data.moeda || '');
  this.valor = v.prop(data.valor || '', v.numerico);
  this.statusCustoVariavel = v.prop(data.statusCustoVariavel || 0, v.numerico);
  this.valorVariavel = v.prop(data.valorVariavel || '');

};

var Custos = function (config) {
  var data = (config || {});
  this.id = id('custos');
  this.casoPadrao = m.prop(data.casoPadrao || new Caso(this.id, {
    padrao: true,
    campos: []
  }));
  this.outrosCasos = m.prop(data.outrosCasos || []);
};

var Etapa = function (config) {
  var data = (config || {});
  this.id = id('etapa');
  this.dbId = m.prop(data.dbId || '');
  this.titulo = v.prop(data.titulo || '', v.obrigatorio, v.textoCurto);
  this.descricao = v.prop(data.descricao || '', v.textoLongo);
  this.documentos = m.prop(data.documentos || new Documentos());
  this.custos = m.prop(data.custos || new Custos());
  this.canaisDePrestacao = m.prop(data.canaisDePrestacao || new CanaisDePrestacao());
  this.tempoTotalEstimado = m.prop(data.tempoTotalEstimado || new TempoTotalEstimado(), v.valida);
};

var TempoTotalEstimado = function (config) {
  var data = (config || {});
  this.id = id('tempo-total-estimado');
  this.tipo = v.prop(data.tipo || '', v.obrigatorio);
  this.descricao = v.prop(data.descricao || '', v.textoLongo);
  this.ateMaximo = v.prop(data.ateMaximo || '', v.se(this.tipo, 'ate', v.obrigatorio), v.maximo(6));
  this.ateTipoMaximo = v.prop(data.ateTipoMaximo || '', v.se(this.tipo, 'ate', v.obrigatorio));
  this.entreMinimo = v.prop(data.entreMinimo || '', v.se(this.tipo, 'entre', v.obrigatorio), v.maximo(6));
  this.entreMaximo = v.prop(data.entreMaximo || '', v.se(this.tipo, 'entre', v.obrigatorio), v.maximo(6));
  this.entreTipoMaximo = v.prop(data.entreTipoMaximo || '', v.se(this.tipo, 'entre', v.obrigatorio));
};

var ValidadeDocumento = function (config) {
  var data = (config || {});
  this.id = id('validade-documento');
  this.tipo = v.prop(data.tipo || '');
  this.descricao = v.prop(data.descricao || '', v.textoLongo);
  this.validade = v.prop(data.validade || '', v.maximo(6));
  this.validadeTipo = v.prop(data.validadeTipo || '');
};

var Orgao = function (config) {
  var data = (config || {});

  this.id = id('orgao');
  this.dbId = v.prop(data.dbId || '', v.obrigatorio);
  this.nome = v.prop(data.nome || '');
  this.contato = v.prop(data.contato || '', v.textoLongo);
};

var GRATUITO = 'gratuito';
var PAGO = 'pago';

var Servico = function (config) {
  var data = (config || {});
  this.id = id('servico');

  var validaIdJaExistente = _.trim(data.nome) ? _.noop : v.idUnico;

  this.nome = v.prop(data.nome || '', v.obrigatorio, v.textoCurto, validaIdJaExistente);
  this.porcentagemDigital = m.prop(data.porcentagemDigital);
  this.porcentagemManual = v.prop(data.porcentagemManual || false);
  this.sigla = v.prop(data.sigla || '', v.maximo(15));
  this.nomesPopulares = v.prop(data.nomesPopulares || [], v.cada(v.textoCurto));
  this.descricao = v.prop(data.descricao || '', v.obrigatorio, v.textoLongo3000);
  this.gratuidade = v.prop(data.gratuidade, v.obrigatorio);
  this.solicitantes = v.prop(data.solicitantes || [], v.minimo(1));
  this.tempoTotalEstimado = m.prop(data.tempoTotalEstimado || new TempoTotalEstimado(), v.valida);
  this.validadeDocumento = m.prop(data.validadeDocumento || new ValidadeDocumento(), v.valida);
  this.etapas = v.prop(data.etapas || [], v.minimo(1));
  this.orgao = v.prop(data.orgao || new Orgao(data), v.obrigatorio);
  this.tratamentoPrioritario = v.prop(data.tratamentoPrioritario || tratamentoPrioritarioPadrao, v.obrigatorio, v.textoLongo1000);
  this.tratamentoDispensadoAtendimento = v.prop(data.tratamentoDispensadoAtendimento || tratamentoDispensadoAtendimentoPadrao, v.obrigatorio, v.textoLongo1000);
  this.condicoesAcessibilidade = v.prop(data.condicoesAcessibilidade || condicoesAcessibilidadePadrao, v.obrigatorio, v.textoLongo1000);

  this.segmentosDaSociedade = v.prop(data.segmentosDaSociedade || [], v.minimo(1));
  this.areasDeInteresse = v.prop(data.areasDeInteresse || [], v.minimo(1));
  this.palavrasChave = v.prop(data.palavrasChave || [], v.minimo(3), v.cada(v.textoCurto), v.cada(v.obrigatorio));
  this.legislacoes = v.prop(data.legislacoes || [], v.cada(v.textoLongo));
  this.dbId = v.prop(data.dbId || '', v.obrigatorio, v.maximo(100000));
  this.servicoDigital = v.prop(data.servicoDigital || false);
  this.linkServicoDigital = v.prop(data.linkServicoDigital || '');
  this.servicosRelacionados = v.prop(data.servicosRelacionados || []);
  this.servicoEditado = data.servicoEditado;
};

module.exports = {
  id: id,
  Caso: Caso,
  Item: Item,
  ItemSegmento: ItemSegmento,
  CanaisDePrestacao: CanaisDePrestacao,
  CanalDePrestacao: CanalDePrestacao,
  Documentos: Documentos,
  Custo: Custo,
  Documento: Documento,
  Custos: Custos,
  Etapa: Etapa,
  Servico: Servico,
  Orgao: Orgao,
  TempoTotalEstimado: TempoTotalEstimado,
  ValidadeDocumento: ValidadeDocumento,
  Gratuidade: {
    GRATUITO: GRATUITO,
    PAGO: PAGO
  }
};
