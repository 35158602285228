'use strict';

var api = require('api');
var slugify = require('slugify');
var select = require('componentes/select2');

var listaValidadeUnidade = [];
var mapValidadeUnidade;
var listaValidadeTipo = [];
var mapValidadeTipo;

var selectValidadeUnidade = function (prop, tvd) {
    var tipo = mapValidadeUnidade()[prop()];
    if(tipo){
        prop(tipo);
    }
    return m.component(select, {
        prop: prop,
        tvd: tvd,
        data: listaValidadeUnidade
    });
};

var selectValidadeTipo = function (prop, tvd) {
    var tipo = mapValidadeTipo()[prop()];
    if(tipo){
        prop(tipo);
    }
    return m.component(select, {
        prop: prop,
        tvd: tvd,
        data: listaValidadeTipo
    });
};


var controller = function(args) {

    listaValidadeUnidade = [];
    mapValidadeUnidade = null;
    listaValidadeTipo = [{ id: '-1', text: 'Selecione...'}];
    mapValidadeTipo = null;

    api.get('lista-formulario/lista-validade-unidade-slugfy')
        .then(function(lista) {
            mapValidadeUnidade = m.prop(lista);
            api.get('lista-formulario/lista-validade-unidade')
                .then(function(lista) {
                    return lista.map(function(t) {
                        listaValidadeUnidade.push({ id: t.nome, text: t.nome});
                    });
                });
        });

    api.get('lista-formulario/lista-validade-tipo-slugfy')
        .then(function(lista) {
            mapValidadeTipo = m.prop(lista);
            api.get('lista-formulario/lista-validade-tipo')
                .then(function(lista) {
                    return lista.map(function(t) {
                        listaValidadeTipo.push({ id: t.nome, text: t.nome});
                    });
                });
        });

};

var view = function (ctrl, args) {
    var tvd = args.servico().validadeDocumento;

    return m('fieldset#tempo-validade-documento', [
        m('h3.opcional', [
            'Tempo de validade do documento',
            m.component(require('tooltips').validadeDocumento)
        ]),

        selectValidadeTipo(tvd().tipo, tvd()),

        m('span.tipo-valido-por', {
            style: {
                display: slugify(tvd().tipo()) === 'valido-por' ? 'inline' : 'none'
            }
        }, [
            m('.input-container.inline.margin-right', {
                class: tvd().validade.erro()
            }, [m('input.ate-maximo[type="number"]', {
                min: 1,
                value: tvd().validade(),
                onchange: m.withAttr('value', tvd().validade)
            })]),

            selectValidadeUnidade(tvd().validadeTipo, tvd())
        ]),

        m('label.titulo.opcional', 'Informações adicionais ao tempo de validade'),

        m.component(require('componentes/editor-markdown'), {
            rows: 3,
            onchange: m.withAttr('value', tvd().descricao),
            onblur: m.withAttr('value', tvd().descricao),
            value: tvd().descricao(),
            erro: tvd().descricao.erro()
        })
    ]);
};

module.exports = {controller:controller, view:view};