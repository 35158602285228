'use strict';

var safeGet = require('utils/code-checks').safeGet;
var erro = require('utils/erro-ajax');
var api = require('api');

module.exports = {

  view: function (ctrl, args) {
    var servico = safeGet(args, 'prop');
    var nome = args.validaNome;
    var codSiorg = args.codSiorg;
    var servicosPaginas = args.servicosPaginas;
    var width = args.width;
    var component = args.component;

    return m('div#select-servico.input-container', {
      class: nome && nome.erro && nome.erro(),
      onclick: args.onclick
    }, [
      m.component(require('componentes/select2'), {
        data: servicosPaginas,
        prop: servico,
        onchange: args.onchange,
        bloquear: args.bloquear,
        width: width ? width : '44em',
        minimumResultsForSearch: 1
      }),
        component ? component : m('')
    ]);
  }
};