'use strict';

window.cabecalhoModel = new (require('cabecalho/cabecalho-model'))();
var slugify = require('slugify');
var salvarServico = require('xml/salvar').salvarServico;
var publicarServico = require('xml/publicar').publicarServico;
var promise = require('utils/promise');
var validarParaPublicar = require('xml/publicar').validar;
var validarServicoDigital = require('xml/publicar').validarServicoDigital;
var descartarServico = require('xml/descartar').descartarServico;
var despublicarServico = require('api').despublicar;
var avaliarServico = require('api').avaliar;
var servicoEmEdicao = require('servico/servico-em-edicao');
var redirecionarNovoServico = require('redirecionador');
var routeUtils = require('utils/route-utils');
var permissoes = require('utils/permissoes');
var modificado = m.prop(false);
var avisos = require('utils/avisos');
var limparModelo = require('limpar-modelo');
var reprovarPublicacaoServico = require('xml/publicar').reprovarPublicacaoServico;

module.exports = {
    controller: function () {
        var self = this;
        this.podeSalvar = true;
        this.isValidacao = true;

        this.verificaPodeSalvar = function (servico) {
            var usuarioSiorgOuId = window.loggedUser.siorg;
            if(!routeUtils.ehNovo() && !permissoes.ehAdmin() && servico.orgao().nome().indexOf(usuarioSiorgOuId) == -1 &&
                servico.orgao().dbId().indexOf(usuarioSiorgOuId) == -1) {
                this.podeSalvar = false;
            }
        };


        this.cabecalho =  cabecalhoModel;
        this.caiuSessao = m.prop(false);
        this.servico = servicoEmEdicao.recuperar(this.cabecalho, _.bind(this.verificaPodeSalvar, this));
        this.salvandoServico = m.prop(false);
        this.redirect = m.prop(false);
        this.docId = m.route.param('id');
        this.dbId = m.route.param('dbId');
        this.ajudaTutorial = true;

        this._servicoSalvo = _.bind(function (servico) {
            this.servico(servico);
            this.cabecalho.limparErro();
            modificado(false);
            redirecionarNovoServico('servico', servico.nome(), servico.dbId(), this.redirect);
            this.redirect(false);
            return servico;
        }, this);

        this.salvar = function (isPublicacao, isSolicitacaoPublicacao) {
            return salvarServico(this.servico(), this.servico().nome(), this.cabecalho.metadados, this.dbId, isPublicacao, isSolicitacaoPublicacao)
                .then(function (xml) {

                    if(isSolicitacaoPublicacao){
                        alertify.success('Solicitação de publicação enviada com sucesso!');
                    }else if(isPublicacao) {
                        alertify.success('Serviço publicado com sucesso! As informações aparecerão no Portal em até 15 minutos.');
                    }
                    return xml;
                })
                .then(this._servicoSalvo);
        };

        this.publicar = function () {

            return promise.resolved(this.servico())
                .then(validarServicoDigital)
                .then(validarParaPublicar)
                .then(_.bind(function (s) {
                    return self.salvar(true);
                }, this))
                .then(_.bind(function (s) {
                    return publicarServico(s, this.cabecalho.metadados, this.dbId);
                }, this));
        };


        this.descartar = _.bind(function () {
            this.redirect(true);
            return descartarServico(this.servico(), this.cabecalho.metadados, this.dbId)
                .then(this._servicoSalvo);
        }, this);


        this.visualizar = function () {
            var id = slugify(this.servico().nome());
            servicoEmEdicao.manter(this.servico, this.cabecalho.metadados);
            m.route('/editar/visualizar/servico/' + id);
            return true;
        };

        this.reprovarPublicacao = function (justificativa) {
            return reprovarPublicacaoServico(justificativa).then(function () {
                alertify.success('Publicação recusada com sucesso');
                m.route('/editar');
            });
        };
    },

    view: function (ctrl) {

        var servicoEditado = ctrl.servico().servicoEditado ?  limparModelo(ctrl.servico().servicoEditado) : null;
        var servico = limparModelo(ctrl.servico());


        if(ctrl.cabecalho.metadados.statusPublicacao != 2){
            return m.component(require('acesso-negado'));
        }

        if (routeUtils.ehNovo() && !permissoes.podePublicarDascartarPagina('servico')) {
            return m.component(require('acesso-negado'));
        }

        if (!routeUtils.ehNovo() && !permissoes.ehAdmin()) {
            return m.component(require('acesso-negado'));
        }

        var isPublicado = false;
        if(ctrl.cabecalho.metadados.publicado) {
            isPublicado = ctrl.cabecalho.metadados.publicado.horario != null;
        }

        var binding = {
            servico: ctrl.servico,
            novo: routeUtils.ehNovo(),
            podeSalvar: ctrl.podeSalvar,
            salvandoServico: ctrl.salvandoServico,
            caiuSessao: ctrl.caiuSessao,
            isPublicado: isPublicado
        };

        return m('#conteudo', {
            config: function (element, isInitialized) {
                if (isInitialized) {


                    var novoServico = $(".validar-col2").html();
                    var servicoAntigo = $(".validar-col1").html();

                    if(novoServico && servicoAntigo){
                        var diferencas = htmldiff(servicoAntigo, novoServico);

                        $(".validar-col2").html(diferencas);
                    }

                    return;
                }

                jQuery(element).on('change', function () {
                    modificado(true);
                });

                jQuery(window).bind('beforeunload', function () {
                    if (modificado()) {
                        return 'Suas últimas alterações ainda não foram salvas.';
                    }
                });
            }
        }, [
            m('span.cabecalho-cor'),
            m('#wrapper', [
                m.component(require('cabecalho/cabecalho'), {
                    metadados: true,
                    logout: true,
                    isValidacao : true,
                    nomeDaPagina: ctrl.servico().nome() || 'Novo serviço',
                    salvar: _.bind(ctrl.salvar, ctrl),
                    disabled: !ctrl.podeSalvar,
                    publicar: _.bind(ctrl.publicar, ctrl),
                    reprovarPublicacao: _.bind(ctrl.reprovarPublicacao, ctrl),
                    visualizar: _.bind(ctrl.visualizar, ctrl),
                    descartar: _.bind(ctrl.descartar, ctrl),
                    cabecalho: ctrl.cabecalho,
                    salvandoServico: ctrl.salvandoServico,
                    caiuSessao: ctrl.caiuSessao,
                    orgaoId: ctrl.servico().orgao().nome(),
                    orgaoDbId: ctrl.servico().orgao().dbId(),
                    ajudaTutorial: ctrl.ajudaTutorial
                }),

                servicoEditado ? m('#visualizar.validar-col1', m('#main', m('section#conteudo', [m('.row',
                    m('h2', servicoEditado.nome() + (servicoEditado.sigla() ? ' (' + servicoEditado.sigla() + ')' : ''))),
                    m('.row', m.component(require('servico/visualizar/descricao'), servicoEditado)),
                    m('.row', m.component(require('servico/visualizar/solicitantes'), servicoEditado)),
                    m('.row', m.component(require('servico/visualizar/etapas'), servicoEditado)),
                    m('.row', m.component(require('servico/visualizar/tempo'), servicoEditado)),
                    m('.row', m.component(require('servico/visualizar/validade'), servicoEditado)),
                    m('.row', m.component(require('servico/visualizar/legislacao'), servicoEditado)),
                    m('.row', m.component(require('servico/visualizar/outras-info'), servicoEditado))
                ]))) : m('#visualizar.validar-col1', m('#main', m('section#conteudo', [m('.row',
                    m('')),

                ]))),
                m('#visualizar.validar-col2', m('#main', m('section#conteudo', [m('.row',
                    m('h2', servico.nome() + (servico.sigla() ? ' (' + servico.sigla() + ')' : ''))),
                    m('.row', m.component(require('servico/visualizar/descricao'), servico)),
                    m('.row', m.component(require('servico/visualizar/solicitantes'), servico)),
                    m('.row', m.component(require('servico/visualizar/etapas'), servico)),
                    m('.row', m.component(require('servico/visualizar/tempo'), servico)),
                    m('.row', m.component(require('servico/visualizar/validade'), servico)),
                    m('.row', m.component(require('servico/visualizar/legislacao'), servico)),
                    m('.row', m.component(require('servico/visualizar/outras-info'), servico))
                ])))
            ])
        ]);
    }
};
