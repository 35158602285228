'use strict';

var safeGet = require('utils/code-checks').safeGet;
var erro = require('utils/erro-ajax');
var api = require('api');

module.exports = {

  view: function (ctrl, args) {
    var estado = safeGet(args, 'prop');
    var nome = args.validaNome;
    var estados = args.estados;
    var idPais = args.idPais;

    return m('div#select-estado.input-container', {
      class: nome && nome.erro && nome.erro()
    }, m.component(require('componentes/select2'), {
      data: estados,
      prop: estado,
      bloquear: args.bloquear,
      width: '44em',
      minimumResultsForSearch: 1,
      // minimumInputLength: 3,
      onchange: args.onchange
    }));
  }
};
