var ListaOrgaoView = (function (m, require) {
    /**
     * @param {ListaOrgaoView} ctrl
     * @param args
     */
    return function (ctrl, args) {
        var PLACEHOLDER_PESQUISA = "Pesquise por nome ou sigla";

        var conteudoTableOrgaos = function(unidades) {
            var retorno = [
                m('tr', [
                    m('th[colspan=4]', 'Nome'),
                    m('th[colspan=3]', 'Sigla'),
                    m('th.center[colspan=1]', 'Ações')
                ])
            ];
            _.each(unidades, function (orgao) {
                retorno.push(
                    m('tr', [
                        m('td.center[colspan=4]', [
                            m('a[href=/editar/administracao/orgao/editor/'+orgao.id+']', orgao.nomeOrgao.split('(')[0] /*Pega só o nome do órgão*/)
                        ]),
                        m('td.center[colspan=3]', orgao.nomeOrgao.split('(')[1].slice(0, -1) /*Pega só a sigla do órgão*/),
                        m('td.center[colspan=1]', [
                            excluirOrgao(orgao)
                        ])
                    ])
                );
            });
            return retorno;
        };

        function excluirOrgao(orgao) {
            return m('button.remover', {
                title: 'Remover este conteúdo',
                onclick: _.bind(ctrl.excluirOrgao, ctrl, orgao)
            }, [m('i.fa.fa-trash-o'), m('span.tooltip-content', 'Excluir órgão')]);
        }

        return m('#conteudo',[
            m('span.cabecalho-cor'),
            m('#wrapper', [
                m.component(require('cabecalho/cabecalho'), {
                    metadados: false,
                    nomeDaPagina: 'Lista de páginas',
                    logout: true
                }),

                m('#bem-vindo.lista-unidades', [
                    m('div.busca-unidades', [
                        m('input[type=search][placeholder="' + PLACEHOLDER_PESQUISA + '"]', {
                            oninput: function (e) {
                                ctrl.pesquisar(e.target.value);
                            }
                        })
                    ]),

                    m('div.lista-unidades', [
                        m('table', [
                            m('thead', m('tr', [
                                m(
                                    'td[colspan=8]',
                                    (ctrl.orgaos.length === 1)
                                        ? 'Um item foi encontrado'
                                        : ((ctrl.orgaos.length > 1)
                                            ? ctrl.orgaos.length + " itens foram encontrados"
                                            : "Nenhum item foi encontrado")
                                )
                            ])),
                            conteudoTableOrgaos(ctrl.orgaos)
                        ])
                    ])
                ])
            ])
        ]);

    };
})(m, require);

module.exports = ListaOrgaoView;