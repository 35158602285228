/**
 * Created by ygorazevedo on 2/8/17.
 */
'use strict';

var tooltips = require('tooltips');
var permissoes = require('utils/permissoes');
var Api = require('api');

module.exports = {
    controller: function (args) {
        this.orgao = args.orgao;
        this.nome = args.nome;
        this.cnpj = args.cnpj;
        this.email = args.email;
        this.site = args.site;
        this.rip = args.rip;

        var self = this;

        if(!args.podeSalvar) {
            self.bloquearOrgao = true;
        } else {
            self.bloquearOrgao = false;
        }

        if(!permissoes.ehAdmin() && args.podeSalvar){
            var nomeOrgao = window.loggedUser.siorg_name;
            Api.pesquisarOrgao(nomeOrgao).then(function (response) {
                var orgaos = JSON.parse(response);
                self.orgao().dbId(orgaos[0].id);
                self.orgao().codigoSiorg(orgaos[0].codigoSiorg);
            });
            self.bloquearOrgao = true;
        } else {
            self.orgao().codigoSiorg('');
        }
    },

    view: function (ctrl) {
        $('#cnpj').mask('99.999.999/9999-99');

        return m('fieldset#dados-basicos', [
            m('h3', [
                'Órgão responsável',
                m.component(tooltips.orgaoResponsavel)
            ]),
            m.component(require('pagina/orgao/select-orgao'), {
                prop: ctrl.orgao().dbId,
                bloquear: ctrl.bloquearOrgao,
                validaNome: ctrl.orgao().dbId
            }),
            m('h3', [
                'Nome da Unidade de Atendimento',
                m.component(tooltips.nomeUnidadeAtendimento)
            ]),
            m('.input-container.cadastro-unidade', {
                class: ctrl.nome.erro(),
            },
                m('input[type=text]', {
                    value: ctrl.nome(),
                    onchange: m.withAttr('value', ctrl.nome)
                })),
            m('h3.opcional', [
                'CNPJ da Unidade de Atendimento',
                m.component(tooltips.cnpjUnidadeAtendimento)
            ]),
            m('.input-container.cadastro-unidade', {
                    class: ctrl.cnpj.erro(),
            },
                m('input[type=text]#cnpj', {
                    value: ctrl.cnpj(),
                    onchange: m.withAttr('value', ctrl.cnpj)
                })),
            m('h3.opcional', [
                'Site',
                m.component(tooltips.site)
            ]),
            m('.input-container.site',
                m('input[type=text]#site', {
                    value: ctrl.site(),
                    onchange: m.withAttr('value', ctrl.site)
                })),
            m('h3.opcional', [
                'E-mail',
                m.component(tooltips.email)
            ]),
            m('.input-container.email',
                m('input[type=text]#email', {
                    value: ctrl.email(),
                    onchange: m.withAttr('value', ctrl.email)
                })),
            m('h3.opcional', [
                'RIP da Unidade de Atendimento',
                m.component(tooltips.rip)
            ]),
            m('.input-container.rip',
                m('input[type=text]#rip', {
                    value: ctrl.rip(),
                    onchange: m.withAttr('value', ctrl.rip)
                }))
        ]);
    }
};
