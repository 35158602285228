'use strict';

var PortalExterno = require('administracao/portais-externos/modelos').PortalExterno;
var salvarPortalExterno = require('administracao/portais-externos/util/salvar').salvarPortalExterno;
var Api = require('api');

module.exports = {
    controller: function (args) {

        var self = this;
        this.portalExterno = null;
        var idPortalExterno = m.route.param('id');

        if(idPortalExterno){
            this.alertMsg = 'Portal Externo alterado com sucesso.';

            Api.buscarPortalExternoPorId(idPortalExterno).then(function (response) {
                response = JSON.parse(response);
                self.portalExterno = new PortalExterno(response.resposta);
            }, function(error){

            });
        } else {
            this.alertMsg = 'Portal Externo incluído com sucesso.';
            this.portalExterno = new PortalExterno();
        }

        this.salvar = function () {
            return salvarPortalExterno(this.portalExterno)
                .then(function (data) {
                    var pe = JSON.parse(data);
                    self.portalExterno = new PortalExterno(pe);
                    m.route('/editar/administracao/portais-externos/lista');
                });
        };



        this.salvandoPortalExterno = m.prop(false);

    },

    view: function (ctrl) {

        const PORTAL_EXTERNO_CADASTRO_PATH = 'administracao/portais-externos/cadastro';

        return m('#conteudo', [
            m('span.cabecalho-cor'),
            m('#wrapper', [
                m.component(require('cabecalho/cabecalho'), {
                    metadados: true,
                    nomeDaPagina: ctrl.portalExterno.nomeEstado() || 'Novo Portal Externo',
                    logout: true,
                    salvar: _.bind(ctrl.salvar, ctrl),
                    salvandoServico: ctrl.salvandoPortalExterno,
                    caiuSessao: m.prop(false),
                    alertMsg: ctrl.alertMsg
                }),
                m('div#admin', [
                    m('div.scroll', [
                        m.component(require(PORTAL_EXTERNO_CADASTRO_PATH + '/dados-basicos'), {
                            nomePortalExterno: ctrl.portalExterno.nomePortalExterno,
                            nomeEstado: ctrl.portalExterno.nomeEstado,
                            siglaEstado: ctrl.portalExterno.siglaEstado,
                            urlApi: ctrl.portalExterno.urlApi,
                            periodicidadeExecucaoRotina: ctrl.portalExterno.periodicidadeExecucaoRotina,
                        })
                    ])
                ])
            ])
        ]);


    }
};
