'use strict';

var domParaServico = require('xml/servico-factory').domParaServico;
var domParaPaginaTematica = require('xml/pagina-tematica-factory').domParaPaginaTematica;
var domParaOrgao = require('xml/orgao-factory').domParaOrgao;
var domParaSuperServico =  require('xml/super-servico-factory').domParaSuperServico;
var exportarXmlServico = require('xml/exportar-servico-xml');
var exportarXmlSuperServico = require('xml/exportar-super-servico-xml');
var exportarXmlPaginaTematica = require('xml/exportar-pagina-tematica-xml');
var exportarXmlOrgao = require('xml/exportar-orgao-xml');
var validacoes = require('utils/validacoes');
var promiseUtil = require('utils/promise');
var limparModelo = require('limpar-modelo');
var limparModeloSuperServico = require('super-servico/limpar-modelo-super-servico')
var api = require('api');
var permissoes = require('utils/permissoes');

var valida = _.curry(function (nome, doc) {
  if (validacoes.valida(_.get(doc, nome))) {
    return doc;
  }
  throw 'Erro na validação do nome';
});

var validaConteudoVazio = _.curry(function (conteudo, doc) {
    if (validacoes.valida(_.get(doc, conteudo))) {
        return doc;
    }
    throw 'Erro na validação do conteúdo da página';
});

var verificacaoExistePaginaOrgao = _.curry(function (nome, doc) {
    var orgaoDbId = doc.orgaoDbId();
    var dbId = null;
    if (doc.dbId && doc.dbId()) {
        dbId = doc.dbId();
    }
    return api.verificacaoExistePaginaOrgao(orgaoDbId, dbId).then(function(existePagina){
        if(existePagina == 'false'){
            return doc;
        } else {
            throw 'Já existe uma página cadastrada para esse Órgão.';
        }
    });

});

var validaTamanho = _.curry(function (nome, doc) {
  if (validacoes.maximo(10000, doc[nome]())) {
    throw 'O limite de 10.000 caracteres foi ultrapassado.';
  }

  return doc;
});

var validaTamanhoMaximoCampos = function (servico) {
  if (validacoes.validaTamanhoMaximoCampos(servico)) {
    return servico;
  } else {
    throw 'Erros de validação';
  }
};

    var validaListaOrgao = function (pagina) {
    var orgaos = [];
    if(pagina.orgaos().length == 1
        && pagina.orgaos()[0].dbId() == ""){
        throw 'É necessário cadastrar pelo menos um órgão';
    };
    pagina.orgaos().map(function(o, i){
        if(o.dbId() != ""){
            orgaos.push(o);
        }
    });

    pagina.orgaos(orgaos);
    return pagina;
};

var validaNome = valida('nome');
var verificacaoExistePaginaOrgao = verificacaoExistePaginaOrgao('url');
var validaTamanhoMaximoConteudo = validaTamanho('conteudo');
var validaConteudoVazio = validaConteudoVazio('conteudo');
var validaTamanhoMaximoContato = validaTamanho('contato');
var validaUrl = valida('url');

function salvarServico(servico, docId, metadados, dbId, isPublicacao, isSolicitacaoPublicacao) {

    return promiseUtil.resolved(servico)
    .then(validaNome)
    .then(validaTamanhoMaximoCampos)
    .then(limparModelo)
    .then(exportarXmlServico)
    .then(function (xml) {
      return api.salvar('servico', docId, xml, metadados, dbId, isPublicacao, isSolicitacaoPublicacao);
    })
    .then(domParaServico);
}

function salvarSuperServico(servico, docId, metadados, dbId, isPublicacao) {

    return promiseUtil.resolved(servico)
        .then(validaNome)
        .then(validaTamanhoMaximoCampos)
        .then(limparModeloSuperServico)
        .then(exportarXmlSuperServico)
        .then(function (xml) {
            return api.salvar('super-servico', docId, xml, metadados, dbId, isPublicacao);
        })
        .then(domParaSuperServico);
}

function salvarPaginaTematica(pagina, docId, metadados, dbId, isPublicacao, isSolicitacaoPublicacao) {
    if (permissoes.ehAdmin()) {
        return promiseUtil.resolved(pagina)
            .then(validaNome)
            .then(validaTamanhoMaximoConteudo)
            .then(function (doc) {
                if(isPublicacao || isSolicitacaoPublicacao){
                    return validaConteudoVazio(doc);
                }
                return doc;
            })
            .then(exportarXmlPaginaTematica)
            .then(function (xml) {
                return api.salvar('pagina-tematica', docId, xml, metadados, dbId, isPublicacao, isSolicitacaoPublicacao);
            })
            .then(domParaPaginaTematica);
    } else {
        return promiseUtil.resolved(pagina)
            .then(validaNome)
            .then(validaListaOrgao)
            .then(validaTamanhoMaximoConteudo)
            .then(function (doc) {
                if(isPublicacao || isSolicitacaoPublicacao){
                    return validaConteudoVazio(doc);
                }
                return doc;
            })
            .then(exportarXmlPaginaTematica)
            .then(function (xml) {
                return api.salvar('pagina-tematica', docId, xml, metadados, dbId, isPublicacao, isSolicitacaoPublicacao);
            })
            .then(domParaPaginaTematica);
    }
}

function salvarOrgao(orgao, docId, metadados, dbId, isPublicacao, isSolicitacaoPublicacao) {
  return promiseUtil.resolved(orgao)
    // .then(validaNome)
    .then(validaTamanhoMaximoContato)
    .then(validaTamanhoMaximoConteudo)
     .then(function (orgao) {
          if(isPublicacao || isSolicitacaoPublicacao){
              return validaConteudoVazio(orgao);
          }
          return orgao;
      })
    .then(validaUrl)
    .then(verificacaoExistePaginaOrgao)
    .then(exportarXmlOrgao)
    .then(function (xml) {
      return api.salvar('orgao', docId, xml, metadados, dbId, isPublicacao, isSolicitacaoPublicacao);
    })
    .then(domParaOrgao);
}

module.exports = {
  salvarServico: salvarServico,
  salvarPaginaTematica: salvarPaginaTematica,
  salvarOrgao: salvarOrgao,
  salvarSuperServico: salvarSuperServico
};
