'use strict';

module.exports = {

  controller: function (args) {
    this.servico = args;
    this.converter = new window.showdown.Converter();
  },

  view: function (ctrl) {

    var nomesPopularesView = function () {
      if (!_.isEmpty(ctrl.servico.nomesPopulares())) {
        var emptyCount = 0;
        ctrl.servico.nomesPopulares().forEach(function (np) {
            if(!np.item()) {
                emptyCount++;
            }
        });
        if(emptyCount == ctrl.servico.nomesPopulares().length) {
            return m.component(require('servico/visualizar/view-vazia'));
        }
        return m('p', [
                    'Você também pode conhecer este serviço como: ',
                    _.map(ctrl.servico.nomesPopulares(), 'item').map(function(x){ return x(); }).join(', '),
                    '.'
                ]);
      }
      return m.component(require('servico/visualizar/view-vazia'));
    };

    var descricaoView = function () {
        if(ctrl.servico.descricao()) {
            return [
                m('h3.subtitulo-servico', 'O que é?'),
                m('.markdown .coluna-descricao-servico', m.trust(ctrl.converter.makeHtml(ctrl.servico.descricao())))
            ]
        }
        return m.component(require('servico/visualizar/view-vazia'));
    };


    return m('#servico-descricao', [
        descricaoView()
    ]);
  }
};
