'use strict';

var safeGet = require('utils/code-checks').safeGet;

module.exports = {
  view: function (ctrl, args) {
    var prop = safeGet(args, 'prop');
    var id = safeGet(args, 'elementId');
    var target = args.target;
    var clazz = args.clazz || '';
    var width = args.width || '';
    var onchange = args.onchange || function (dateText, inst) {
            m.startComputation();
            prop(dateText);
            m.endComputation();
            if(target) {
                var el = $('#' + target);
                var date = dateText.split('/');
                el.datepicker( "option", "minDate", new Date(date[2], date[1] - 1, date[0]));
            }
        };

    return m('div.input-container',
        m('input[type=text].datepicker' + clazz + '#' + id, {
            style: {width: width},
            onchange: m.withAttr('value', prop),
            config: function (element, isInitialized) {
                if(!isInitialized) {
                    $(element).datepicker({
                        onSelect: onchange
                    });
                }
            }
        })
    )
  }
};
