'use strict';

var XML = require('utils/xml');

var orgaos = function(orgaos){
  if (!orgaos || orgaos.length == 0 || (orgaos.length == 1 && !orgaos[0].dbId())){
    return '';
  }
  return m('orgaos', orgaos.map(function (orgao) {
    return m('orgao', {
      dbId: orgao.dbId()
    }, []);
  }));
};

module.exports = function (paginaTematica) {
  var doc = XML.createDocument('http://servicos.gov.br/v3/schema');

  m.render(doc, m('pagina-tematica', {
    'xmlns': 'http://servicos.gov.br/v3/schema',
    'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
  }, [
    m('nome', paginaTematica.nome()),
    m('conteudo', paginaTematica.conteudo()),
    orgaos(paginaTematica.orgaos())
  ]));
  XML.cdata(doc, 'conteudo');

  return doc;
};
