'use strict';


var portalEexternoToJson = function (pe) {
    return {
        id : pe.id(),
        nomePortalExterno : pe.nomePortalExterno(),
        nomeEstado : pe.nomeEstado(),
        siglaEstado : pe.siglaEstado(),
        urlApi : pe.urlApi(),
        periodicidadeExecucao: pe.periodicidadeExecucaoRotina(),
    };
};

module.exports = {
    portalEexternoToJson: portalEexternoToJson,
}