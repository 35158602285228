    'use strict';

    var api = require('api');
    var checkBoxes = require('componentes/checkboxes-ajax');

    var criaCheckboxes = function(listaAreas) {
        module.exports = checkBoxes.create({
            chave: 'areasDeInteresse',
            id: 'areas-de-interesse',
            titulo: 'Áreas de interesse',
            itens: listaAreas
        });
    };

    api.get('lista-formulario/lista-areas').then(criaCheckboxes);

