'use strict';

var XML = require('utils/xml');

var tempoTotalEstimado = function (tempoEstimado) {
  var limite;
  if (tempoEstimado.tipo() === 'entre') {
    limite = m('entre', {
      min: tempoEstimado.entreMinimo(),
      max: tempoEstimado.entreMaximo(),
      unidade: tempoEstimado.entreTipoMaximo()
    });

  } else if (tempoEstimado.tipo() === 'ate') {
    limite = m('ate', {
      max: tempoEstimado.ateMaximo(),
      unidade: tempoEstimado.ateTipoMaximo()
    });
  } else if (tempoEstimado.tipo() === 'em-media') {
    limite = m('em-media', {
      max: tempoEstimado.ateMaximo(),
      unidade: tempoEstimado.ateTipoMaximo()
    });
  } else if (tempoEstimado.tipo() === 'atendimento-imediato') {
    limite = m('atendimento-imediato', {});
  } else if (tempoEstimado.tipo() === 'nao-estimado-ainda') {
    limite = m('nao-estimado-ainda', {});
  }

  return m('tempo-total-estimado', [
    limite,
    m('descricao', tempoEstimado.descricao())
  ]);
};

var validadeDocumento = function (validadeDocumento) {
  return m('validade-documento', [
    m('tipo', validadeDocumento.tipo()),
    m('quantidade', validadeDocumento.validade()),
    m('unidade', validadeDocumento.validadeTipo()),
    m('descricao', validadeDocumento.descricao())
  ]);
};

var item = function (i) {
  return m('item', i);
};

var itemNaoVazio = function (i) {
  if (!i){
    return;
  }
  return m('item', i);
};

var itemComId = function (item) {
    if(item.item()) {
        if(item.id()){
            return m('item', {id: item.id()}, item.item());
        } else {
            return m('item', item.item());
        }
    }
};

var itemSegmento = function (item) {
  var idSegmento = {};
  var descricao = "";
  if(item.idSegmento && item.idSegmento()){
    idSegmento = {
      idSegmento: item.idSegmento(),
      idServicoSegmento: item.idServicoSegmento()
     };
    descricao = item.item();
  } else if(typeof item.idSegmento == 'function'){
    descricao = item.item();
  } else {
    descricao = item;
  }

  return m('item', idSegmento, descricao);
};

var casos = function (e, nome, itemDoCaso) {
  return m(nome, [
    m('default', e.casoPadrao().campos().map(itemDoCaso)),
    e.outrosCasos().map(function (caso) {

      var casoElement = {
        descricao: caso.descricao()
      };

      if(caso.dbId()){
        casoElement.id = caso.dbId();
      }

      return m('caso', casoElement, caso.campos().map(itemDoCaso));
    })
  ]);
};

var documento = function (doc) {
  if(doc.dbId()){
    return m('item', {id: doc.dbId()}, doc.descricao());
  } else {
    return m('item', doc.descricao());
  }
};

// MODELO NOVO COM DOCUMENTOS PRÉ CADASTRADOS
// var documento = function (doc) {
//   var id = {};
//   if(doc.dbId() != ""){
//     id = {
//       id: doc.dbId()
//     }
//   }
//
//   id.codigo = doc.codigo();
//
//   return m('documento', id, [
//       m("descricao", doc.descricao()),
//       m("ondeObter", doc.ondeObter()),
//       m("observacoes", doc.observacoes())
//   ]);
// };

var documentos = function (e) {
  return e ? casos(e, 'documentos', documento) : '';
};

var custo = function (e) {
  var id = {};
  if(e.dbId()){
    id = {
      id: e.dbId()
    }
  }
  return m('custo', id, [
      m('descricao', e.descricao()),
      m('moeda', e.moeda()),
      m('valor', e.valor()),
      m('valorVariavel', e.valorVariavel()),
      m('statusCustoVariavel', e.statusCustoVariavel())
    ]);
};

var custos = function (e) {
  return e ? casos(e, 'custos', custo) : '';
};

var canalDePrestacao = function (e) {

  var atributosCanal = {
    tipo: e.tipo()
  };

  if(e.dbId()){
    atributosCanal.id = e.dbId();
  }

  if(e.procedimentoSistemaIndisponivel()){
      atributosCanal['procedimento-sistema-indisponivel'] = e.procedimentoSistemaIndisponivel();
  }

  if(e.tempoEstimadoEspera()){
      atributosCanal['tempo-estimado-espera'] = e.tempoEstimadoEspera();
  }

    if(e.tempoEstimadoPeriodo()){
        atributosCanal['tempo-estimado-periodo'] = e.tempoEstimadoPeriodo();
    }

    return m('canal-de-prestacao', atributosCanal, [
   m('descricao', e.descricao()),
 ]);
};

var canaisDePrestacao = function (e) {
  return e ? casos(e, 'canais-de-prestacao', canalDePrestacao) : '';
};

var etapa = function (e) {
  var id = {};

  if(e.dbId()){
    id = {
      id: e.dbId()
    }
  }

  return m('etapa', id, [
    m('titulo', e.titulo()),
    m('descricao', e.descricao()),
    documentos(e.documentos()),
    custos(e.custos()),
    tempoTotalEstimado(e.tempoTotalEstimado()),
    canaisDePrestacao(e.canaisDePrestacao())
  ]);
};

var solicitantes = function (sol) {
  return m('solicitantes', sol.map(function (s) {
    var id = {};
    if(s.dbId()){
      id = {
        id: s.dbId()
      }
    }

    return m('solicitante', id, [
        m('tipo', s.tipo()),
        m('requisitos', s.requisitos())
      ]);
  }));
};

// var sevicoDigital = function (s) {
//     return m('servico-digital', s.servicoDigital()),
//         m('link-servico-digital', s.linkServicoDigital());
// };

var Gratuidade = require('servico/modelos').Gratuidade;
var gratuidade = function (ehGratuito) {
  switch (ehGratuito) {
  case Gratuidade.GRATUITO:
    return true;
  case Gratuidade.PAGO:
    return false;
  }
  return undefined;
};

module.exports = function (servico) {
  var doc = XML.createDocument('http://servicos.gov.br/v3/schema');

  m.render(doc, m('servico', {
    'xmlns': 'http://servicos.gov.br/v3/schema',
    'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
    'xsi:schemaLocation': 'http://servicos.gov.br/v3/schema ../servico.xsd'
  }, [
    m('nome', servico.nome()),
    m('sigla', servico.sigla()),
    m('porcentagem', servico.porcentagemDigital()),
    m('porcentagem-manual', servico.porcentagemManual()),
    m('nomes-populares', servico.nomesPopulares().map(itemComId)),
    m('descricao', servico.descricao()),
    m('servico-digital', servico.servicoDigital()),
    m('link-servico-digital', servico.linkServicoDigital()),
    // sevicoDigital(servico),
    m('contato', servico.orgao().contato()),
    m('gratuito', gratuidade(servico.gratuidade())),
    solicitantes(servico.solicitantes()),
    tempoTotalEstimado(servico.tempoTotalEstimado()),
    validadeDocumento(servico.validadeDocumento()),
    m('etapas', servico.etapas().map(etapa)),
    servico.orgao().dbId() ? m('orgao', {
      id: servico.orgao().nome(),
      dbId: servico.orgao().dbId()
    }) : m(''),
    m('segmentos-da-sociedade', servico.segmentosDaSociedade().map(itemSegmento)),
    m('areas-de-interesse', servico.areasDeInteresse().map(item)),
    m('palavras-chave', servico.palavrasChave().map(itemComId)),
    m('legislacoes', servico.legislacoes().map(itemComId)),
    m('servicos-relacionados', servico.servicosRelacionados().map(itemNaoVazio)),
    m('tratamento-dispensado-atendimento', servico.tratamentoDispensadoAtendimento()),
    m('tratamento-prioritario', servico.tratamentoPrioritario()),
    m('condicoes-acessibilidade', servico.condicoesAcessibilidade()),
  ]));

  XML.cdata(doc, 'nome');
  XML.cdata(doc, 'descricao');
  XML.cdata(doc, 'requisitos');
  XML.cdata(doc, 'contato');
  XML.cdata(doc, 'legislacoes item');


  return doc;
};
