var jsonfy = {
    toJson : function (obj) {
        var json = {};
        for(attr in obj){
            if(typeof obj[attr]() == 'object'){
                json[attr] = toJson(obj[attr]())
            } else {
                json[attr] = obj[attr]();
            }
        }

        return json;
    }
};

module.exports = jsonfy;