'use strict';

var tooltips = require('tooltips');
var api = require('api');

module.exports = {
    controller: function(args) {
        this.informacoes = args.informacoes;
    },

    view: function(ctrl) {
        return m('fieldset#informacoes-complementares', [
            m('h3.opcional', [
                'Informações Complementares',
                m.component(tooltips.informacoes)
            ]),
            m.component(require('componentes/editor-markdown'), {
                rows: 3,
                value: ctrl.informacoes(),
                maximo: 500,
                onchange: m.withAttr('value', ctrl.informacoes),
                onblur: m.withAttr('value', ctrl.informacoes)
            }),
        ]);
    }
};