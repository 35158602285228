/**
 * Created by ygorazevedo on 2/8/17.
 */
'use strict';

var tooltips = require('tooltips');
var select = require('componentes/select2');
var HorarioDeAtendimento = require('administracao/unidade/modelos').HorarioDeAtendimento;

module.exports = {
    controller: function (args) {
        this.horarioDeAtendimento = args.horarioDeAtendimento;
        this.telefone = args.telefone;
        this.dias = [
            'Segunda', 'Terça', 'Quarta', 'Quinta',
            'Sexta', 'Sábado', 'Domingo'
        ];

        if(this.horarioDeAtendimento().length == 0) {
            this.horarioDeAtendimento([new HorarioDeAtendimento()]);
        }

        this.adicionar = function (haProp) {
            var ha = new HorarioDeAtendimento();

            var horarioDeAtendimento = haProp();
            horarioDeAtendimento.push(ha);
            haProp(horarioDeAtendimento);
            $('[data-target="horaInicio"], [data-target="horaFim"]').mask('99:99');
            m.redraw();
        };

        this.remover = function (haProp, i) {
            var horarioDeAtendimento = haProp();
            horarioDeAtendimento.splice(i, 1);
            haProp(horarioDeAtendimento);
        };
    },

    view: function (ctrl) {
        $('#telefone').mask('(99) 9999-9999');
        $('[data-target="horaInicio"], [data-target="horaFim"]').mask('99:99');

        return m('fieldset#atendimento', [
            m('h3.opcional', [
                'Telefone',
                m.component(tooltips.telefoneUnidadeAtendimento)
            ]),
            m('.input-container.cadastro-unidade', {
                class: ctrl.telefone.erro()
            },
                m('input[type=text]#telefone', {
                value: ctrl.telefone(),
                onchange: m.withAttr('value', ctrl.telefone)
            })),
            m('h3.opcional', [
                'Horário de Atendimento',
                m.component(tooltips.funcionamentoUnidadeAtendimento)
            ]),
            m('div',
                ctrl.horarioDeAtendimento().map(function (ha, i) {
                    return [
                        m('div.left', [
                            m('.input-container.left[data-target="erro-campo-obrigatorio-hora"]', {
                                    class: ha.horaInicio.erro(),
                                    style: {width: '10%'}
                            },
                                m('input[type=text][data-target="horaInicio"]', {
                                    value: ha.horaInicio(),
                                    onchange: m.withAttr('value', ha.horaInicio),
                                })),
                            m('span.margin-art.left' ,'às'),
                            m('.input-container.left[data-target="erro-campo-obrigatorio-hora"]', {
                                    class: ha.horaFim.erro(),
                                    style: {width: '10%'}
                            },
                                m('input[type=text][data-target="horaFim"]', {
                                    value: ha.horaFim(),
                                    onchange: m.withAttr('value', ha.horaFim),
                                })),
                            m('span.margin-prep.left' ,'de'),
                            m('.input-container.left[data-target="erro-campo-obrigatorio-dia"]', {
                                    class: ha.diaInicio.erro()
                                }, m.component(select, {
                                    prop: ha.diaInicio,
                                    data: ctrl.dias
                                })
                            ),
                            m('span.margin-art.left' ,'a'),
                            m('.input-container.left[data-target="erro-campo-obrigatorio-dia"]', {
                                    class: ha.diaFim.erro()
                                }, m.component(select, {
                                    prop: ha.diaFim,
                                    data: ctrl.dias
                                })
                            ),
                            i > 0 ? m('button.remove.absolute.left.remove-hora-atendimento', {
                                onclick: ctrl.remover.bind(ctrl, ctrl.horarioDeAtendimento, i)
                            }) : ''
                        ])
                    ]
                }),
                m('a.adicionar-horario', {
                    onclick: ctrl.adicionar.bind(ctrl, ctrl.horarioDeAtendimento)
                }, [
                    '+ Adicionar Horário de Atendimento'
                ])
            )

        ])
    }
};
