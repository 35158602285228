'use strict';

var modelos = require('servico/modelos');
var Solicitante = require('servico/solicitantes/solicitante-model');

var str = function () {
  return '';
};

var peloMenos = _.curry(function (n, lista, fn) {
  if (lista && lista.length >= n) {
    return lista;
  }
  return lista.concat(_.times(n - lista.length, fn));
});

var um = peloMenos(1);
var tres = peloMenos(3);

var item = function (i, n) {
  return n.textContent;
};

var itemSegmento = function (i, n) {
  var t = jQuery(n);
  var idSegmento = t.attr('idSegmento');
  var idServicoSegmento = t.attr('idServicoSegmento');
  return new modelos.ItemSegmento({
      item : n.textContent,
      idSegmento : idSegmento,
      idServicoSegmento: idServicoSegmento
  });
};

var itemNome = function (i, n) {
  var t = jQuery(n);
  var id = t.attr('id');
  return new modelos.Item({item : n.textContent, id : id});
};

var itemComId = function (i, n) {
  var t = jQuery(n);
  var id = t.attr('id');
  return new modelos.Item({item : n.textContent, id : id});
};

var itemDocumento = function (i, n) {
  var t = jQuery(n);
  var id = t.attr('id');
  return new modelos.Documento({descricao : n.textContent, dbId : id});
};

var itemDocumentoCompleto = function (i, n) {
  var t = jQuery(n);
  var codigo = t.attr('codigo');
  return new modelos.Documento({
    dbId: t.find('id').text(),
    descricao : t.find('descricao').text(),
    ondeObter : t.find('ondeObter').text(),
    observacoes : t.find('observacoes').text(),
    codigo : codigo
  });
};

var solicitantes = function (i, n) {
  var t = jQuery(n);
  return new Solicitante({
    id: t.attr('id'),
    tipo: t.find('tipo').text(),
    requisitos: t.find('requisitos').text(),
  });
};

var tempoTotalEstimado = function (i, n) {
  var tag, t = jQuery(n);

  if (t.find('entre').length !== 0) {
    tag = t.find('entre');
    return new modelos.TempoTotalEstimado({
      tipo: 'entre',
      entreMinimo: tag.attr('min'),
      entreMaximo: tag.attr('max'),
      entreTipoMaximo: tag.attr('unidade'),
      descricao: t.find('descricao').text().trim()
    });
  }

  if (t.find('ate').length !== 0) {
    tag = t.find('ate');
    return new modelos.TempoTotalEstimado({
      tipo: 'ate',
      ateMaximo: tag.attr('max'),
      ateTipoMaximo: tag.attr('unidade'),
      descricao: t.find('descricao').text().trim()
    });
  }

  if (t.find('em-media').length !== 0) {
    tag = t.find('em-media');
    return new modelos.TempoTotalEstimado({
      tipo: 'em-media',
      ateMaximo: tag.attr('max'),
      ateTipoMaximo: tag.attr('unidade'),
      descricao: t.find('descricao').text().trim()
    });
  }

  if (t.find('atendimento-imediato').length !== 0) {
    return new modelos.TempoTotalEstimado ({
      tipo: 'atendimento-imediato',
      descricao: t.find('descricao').text().trim()
    });
  }

  if (t.find('nao-estimado-ainda').length !== 0) {
    return new modelos.TempoTotalEstimado ({
      tipo: 'nao-estimado-ainda',
      descricao: t.find('descricao').text().trim()
    });
  }

  return new modelos.TempoTotalEstimado({
      descricao: t.find('descricao').text().trim()
  });
};

var validadeDocumento = function (n) {
  var t = jQuery(n);

  return new modelos.ValidadeDocumento({
    tipo: t.find('tipo').text(),
    validade: t.find('quantidade').text(),
    validadeTipo: t.find('unidade').text(),
    descricao: t.find('descricao').text()
  });
};

var caso = function (tipo) {
  return function (i, c) {
    var t = jQuery(c);
    return new modelos.Caso(null, {
      dbId: t.attr('id'),
      descricao: t.attr('descricao'),
      campos: t.find('> *').map(tipo).get()
    });
  };
};

var documentos = function (d) {
  var t = jQuery(d);

  var campos = [];
  if(t.find('> default item').length > 0){
    campos = um(t.find('> default item').map(itemDocumento).get(), function () {
      return new modelos.Documento();
    });
  } else {
    campos = um(t.find('> default documento').map(itemDocumentoCompleto).get(), function () {
      return new modelos.Documento();
    });
  }

  return new modelos.Documentos({
    casoPadrao: new modelos.Caso(null, {
      campos: campos,
    }),
    outrosCasos: t.find('caso').map(caso(itemDocumentoCompleto)).get()
  });
};

var custo = function (i, c) {
  var t = jQuery(c);
  return new modelos.Custo({
    dbId: t.attr('id'),
    descricao: t.find('descricao').text(),
    moeda: t.find('moeda').text(),
    valor: t.find('valor').text(),
    valorVariavel: t.find('valorVariavel').text(),
    statusCustoVariavel: t.find('statusCustoVariavel').text()
  });
};

var custos = function (c) {
  var t = jQuery(c);
  return new modelos.Custos({
    casoPadrao: new modelos.Caso(null, {
      campos: um(t.find('> default custo').map(custo).get(), custo),
    }),
    outrosCasos: t.find('caso').map(caso(custo)).get()
  });
};

var canalDePrestacao = function (i, c) {
  var t = jQuery(c);
  return new modelos.CanalDePrestacao({
    dbId: t.attr('id'),
    tipo: t.attr('tipo'),
    procedimentoSistemaIndisponivel: t.attr('procedimento-sistema-indisponivel'),
    descricao: t.find('descricao').text(),
    tempoEstimadoEspera: t.attr('tempo-estimado-espera'),
    tempoEstimadoPeriodo: t.attr('tempo-estimado-periodo'),
  });
};

var canaisDePrestacao = function (c) {
  var t = jQuery(c);
  return new modelos.CanaisDePrestacao({
    casoPadrao: new modelos.Caso(null, {
      campos: um(t.find('> default canal-de-prestacao').map(canalDePrestacao).get(), canalDePrestacao),
    }),
    outrosCasos: t.find('caso').map(caso(canalDePrestacao)).get()
  });
};

var etapas = function (i, e) {
  var t = jQuery(e);
  return new modelos.Etapa({
    dbId: t.attr("id"),
    titulo: t.find('> titulo').text(),
    descricao: t.find('> descricao').text(),
    documentos: documentos(t.find('> documentos').get(0)),
    custos: custos(t.find('> custos').get(0)),
    canaisDePrestacao: canaisDePrestacao(t.find('> canais-de-prestacao').get(0)),
    tempoTotalEstimado:  t.find('> tempo-total-estimado').map(tempoTotalEstimado).get(0)
  });
};

var orgao = function (x, seletor) {
  var orgaoXML = x.find(seletor+' > orgao');
  var contatoXML = x.find('> contato');
  return new modelos.Orgao({
    nome: orgaoXML.attr('id'),
    dbId:orgaoXML.attr('dbId'),
    contato: contatoXML.text()
  });
};

var Gratuidade = require('servico/modelos').Gratuidade;
var gratuidade = function (x) {
  var value = x.text();
  switch (value) {
  case 'true':
    return Gratuidade.GRATUITO;
  case 'false':
    return Gratuidade.PAGO;
  }
  return undefined;
};

var servico = function (x, servicoEdtitado) {

  var seletor = servicoEdtitado ? 'servico-editado' : 'servico';

  return new modelos.Servico({
    dbId: x.find('> dbId').text(),
    nome: x.find('> nome').text(),
    sigla: x.find('> sigla').text(),
    porcentagemDigital: x.find('> porcentagem').text(),
    porcentagemManual: x.find('> porcentagem-manual').text(),
    nomesPopulares: um(x.find('> nomes-populares > item').map(itemNome).get(), str),
    servicoDigital: x.find('> servico-digital').text() == 'true',
    linkServicoDigital: x.find('> link-servico-digital').text(),
    descricao: x.find('> descricao').text(),
    gratuidade: gratuidade(x.find('> gratuito')),
    tempoTotalEstimado: x.find('> tempo-total-estimado').map(tempoTotalEstimado).get(0),
    validadeDocumento: validadeDocumento(x.find('> validade-documento')),
    solicitantes: um(x.find('> solicitantes > solicitante').map(solicitantes).get(), solicitantes),
    etapas: um(x.find('etapas > etapa', x).map(etapas).get(), etapas),
    orgao: orgao(x, seletor),
    segmentosDaSociedade: x.find(seletor+' > segmentos-da-sociedade > item').map(itemSegmento).get(),
    areasDeInteresse: x.find(seletor+' > areas-de-interesse > item').map(item).get(),
    palavrasChave: tres(x.find(seletor+' > palavras-chave > item').map(itemComId).get(), str),
    legislacoes: um(x.find(seletor+' > legislacoes > item').map(itemComId).get(), str),
    servicosRelacionados: um(x.find(seletor+' > servicos-relacionados > item').map(item).get(), str),
    servicoEditado : null,
    tratamentoPrioritario: x.find('> tratamento-prioritario').text(),
    tratamentoDispensadoAtendimento: x.find('> tratamento-dispensado-atendimento').text(),
    condicoesAcessibilidade: x.find('> condicoes-acessibilidade').text(),

  });
};

module.exports = {
  domParaServico: function (dom) {

    var servicoEditado = dom.getElementsByTagName("servico-editado")[0];
    var servicoEditavelDomConverted;

    if(servicoEditado){
        servicoEditavelDomConverted = servico(jQuery('servico servico-editado', dom), true);
        dom.documentElement.removeChild(servicoEditado);
        var servicoAtual = servico(jQuery('servico', dom));
        servicoAtual.servicoEditado = servicoEditavelDomConverted;
    }else{
        var servicoAtual = servico(jQuery('servico', dom));
    }

    return servicoAtual;
  }
};
