'use strict';

var v = require('utils/validacoes');

module.exports = {
  view: function (ctrl, args) {
    var servico = args.servico;
    var text = 'Habilitar botão de solicitação de serviço 100% digital';

      return m('fieldset#servicoDigital', [
          m('h3', [
              'Solicitação de Serviço',
              m.component(require('tooltips').servicoDigital)
          ]),
          m('label', [
              m('input[type=checkbox]', {
                  value: text,
                  checked: servico().servicoDigital(),
                  onchange: function (e) {
                      servico().linkServicoDigital = e.target.checked ?
                          v.prop(servico().linkServicoDigital(), v.obrigatorio) :
                          v.prop(servico().linkServicoDigital());
                      servico().servicoDigital(e.target.checked);
                  }
              }),
              text
          ]),
          m('label.titulo' + (servico().servicoDigital() ? '' : '.opcional'), {
            style: {
              marginTop: '2em'
            }
          }, 'Link para solicitação do Serviço 100% digital'),
          m('div.input-container', {
              class: servico().linkServicoDigital.erro()
          }, [
              m('input[type=text]', {
                  value: servico().linkServicoDigital(),
                  onkeyup: m.withAttr('value', servico().linkServicoDigital)
              })
          ])
      ]);
  }
};
