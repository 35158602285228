var BrasilCidadaoView = (function (m, require) {
    return function (ctrl, args) {

        return m('#conteudo', [
            m('span.cabecalho-cor'),
            m('#wrapper', [
                m.component(require('cabecalho/cabecalho'), {
                    metadados: false,
                    logout: true
                }),
                m('div#admin', [
                    m('div.scroll', [
                        m('fieldset', [
                            m('div.novo', [
                                m('h3', 'Gerar Relatório das Avaliações'),
                                m('div', {style: {marginTop: '2em'}}, [
                                    m('h4', 'Órgão'),
                                    m.component(require('componentes/select2'), {
                                        data: ctrl.orgaos,
                                        prop: ctrl.orgao.dbId,
                                        onchange: ctrl.buscarServicos,
                                        width: '40.6em',
                                        minimumResultsForSearch: ctrl.orgaos.length > 5 ? 1 : -1
                                    }),
                                    m('div', {style: {marginTop: '2em'}} ,[
                                        m('div.left', [
                                            m('h4', 'Serviço'),
                                            m.component(require('componentes/select2'), {
                                                data: ctrl.servicos,
                                                prop: ctrl.servico.dbId,
                                                width: '20em',
                                                minimumResultsForSearch: ctrl.servicos.length > 5 ? 1 : -1,
                                            })
                                        ]),
                                        m('div.left', [
                                            m('h4.one-line-select', 'Tipo de avaliação'),
                                            m.component(require('componentes/select2'), {
                                                data: ctrl.tiposAvaliacao,
                                                prop: ctrl.tipoAvaliacao,
                                                width: '20em',
                                                clazz: '.one-line-select'
                                            })
                                        ])
                                    ]),
                                    m('div', {style: {marginTop: '8em'}} ,[
                                        m('div.left', [
                                            m('h4', 'Início'),
                                            m.component(require('componentes/datepicker-component'), {
                                                prop: ctrl.inicio,
                                                elementId: 'dpInicio',
                                                width: '21em',
                                                target: 'dpFim'
                                            })
                                        ]),
                                        m('div.left', [
                                            m('h4.one-line-select', 'Fim'),
                                            m.component(require('componentes/datepicker-component'), {
                                                prop: ctrl.fim,
                                                elementId: 'dpFim',
                                                clazz: '.one-line-select',
                                                width: '21em'
                                            })
                                        ])
                                    ]),
                                    m('a.button.botao-primario.confirmarSeloDigital', {href: '/editar/api/servico/gerar-relatorio?'
                                        + 'orgaoId=' + ctrl.orgao.dbId()
                                        + '&servicoId=' + ctrl.servico.dbId()
                                        + '&tipoAvaliacao=' + ctrl.tipoAvaliacao()
                                        + '&dataInicio=' + (ctrl.inicio() || '')
                                        + '&dataFim=' + (ctrl.fim() || '')} , m.trust('Gerar Relatório'))
                                ])
                            ])
                        ])
                    ])
                ])
            ])
        ]);

    };
})(m, require);

module.exports = BrasilCidadaoView;