'use strict';

var XML = require('utils/xml');



var itemServicoRelacionado = function (item) {
    var id = "";
    var tag = "";

    if(typeof item.id == 'function'){
      id = item.id();
    }else{
      id = item.id;
    }

    if(typeof item.tag == 'function'){
        tag = item.tag();
    }else{
        tag = item.tag;
    }

   return  m('item', [
       m('id',id),
       m('tag',tag)
   ]);
};

module.exports = function (servico) {
  var doc = XML.createDocument('http://servicos.gov.br/v3/schema');

  m.render(doc, m('super-servico', {
    'xmlns': 'http://servicos.gov.br/v3/schema',
    'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
    'xsi:schemaLocation': 'http://servicos.gov.br/v3/schema ../servico.xsd'
  }, [
    m('nome', servico.nome()),
    m('descricao', servico.descricao()),
    m('servicos-relacionados', servico.servicosRelacionados().map(itemServicoRelacionado)),
  ]));

  XML.cdata(doc, 'nome');
  XML.cdata(doc, 'descricao');


  return doc;
};
