// -- /home/lista-paginas/controller.js
'use strict';

var erro = require('utils/erro-ajax');
var buscador = require('utils/busca-string');
var referencia = require('referencia');
var api = require('api');
var modal = require('componentes/modal-bootstrap');
var validacoes = require('utils/validacoes');
var avisos = require('utils/avisos');


module.exports = function (args) {
    'use strict';
    this.publicarConteudo = _.noop;
    var paginasProp = m.prop([]);

    var fazFiltragemPor = function(filtro, paginas, callback) {
        return (!filtro) ? paginas : _.filter(paginas, function(pg){
            return callback(pg);});
    };

    var filtraAvaliados = function(paginas, avaliados, naoAvaliados, naoPublicados) {
        var bin = [avaliados?'1':'0', naoAvaliados?'1':'0', naoPublicados?'1':'0'].join('');
        var aFiltrar = {
            '111':{'nao-avaliado': [true, false], 'nao-publicado':[true, false]},
            '110':{'nao-avaliado': [true, false], 'nao-publicado':[false]},
            '101':{'nao-avaliado': [true, false], 'nao-publicado':[true, false]},
            '100':{'nao-avaliado': [true],        'nao-publicado': [false]},
            '011':{'nao-avaliado': [false],       'nao-publicado': [true, false]},
            '010':{'nao-avaliado': [false],       'nao-publicado': [false]},
            '001':{'nao-avaliado': [false],       'nao-publicado': [true]}
        };

        if (bin === '000') {
            return paginas;
        }

        // encadeia vários filtros
        var filtros = [
            _.flow(function(pg) {return _.contains(aFiltrar[bin]['nao-avaliado'],  _.get(pg, 'avaliado'));}),
            _.flow(function(pg) {return _.contains(aFiltrar[bin]['nao-publicado'], _.get(pg, 'temAlteracoesNaoPublicadas'));})
        ];

        _.forEach(filtros, function(f) {
            paginas = _.filter(paginas, f);
        });
        return paginas;
    };

    var filtraStatus = function(paginas, status) {

       var filterByStatus =  function (obj) {
            return   status.indexOf(obj.statusPublicacao) >=0 || (obj.publicado && status == 1);
        }

       return paginas.filter(filterByStatus);
    };



    this.paginasFiltradas = function (filtro) {


        var paginas = paginasProp();
        var filtroFn = _.identity;

       if (filtro.filtroPublicados) {
            filtroFn = _.flow(_.property('temAlteracoesNaoPublicadas'));
        }

        var filtroTipos = _.filter([
            filtro.filtroServicos ? 'servico' : null,
            filtro.filtroOrgaos ? 'orgao' : null,
            filtro.filtroPaginasTematicas ? 'pagina-tematica' : null,
            filtro.filtroSuperServicos ? 'super-servico' : null
        ], function(i) { return i != null; });

        if (filtroTipos.length > 0) {
            filtroFn = _.flow(function (pg) {
                return _.contains(filtroTipos, _.get(pg, 'conteudo.tipo'));
            });
        }

        if(filtro.filtroAlteracoesNaoPublicadas){
            filtroFn = _.flow(function (pg) {
                return _.contains([true], _.get(pg, 'temAlteracoesNaoPublicadas'));
            });
        }

        var status = [];


        if(filtro.filtroPublicados){
            status.push(1);
        }

        if(filtro.filtroPendentesPublicacao){
            status.push(2);
        }


        if(filtro.filtroAlteracoesRecusadas){
            status.push(4);
        }

        if(filtro.filtroEmConstrucao){
            status.push(0);
        }

        if(status.length > 0){
            paginas = filtraStatus(paginas,status);
        }


        paginas = filtraAvaliados(
            paginas,
            filtro.filtroAvaliados,
            filtro.filtroNaoAvaliados,
            filtro.filtroNaoPublicados
        );

        paginas = _.filter(paginas, filtroFn);
        var buscas = [
            {busca:filtro.buscaOrgao, key:'conteudo.nomeOrgao'},
            {busca:filtro.busca, key:'conteudo.nome'}
        ];
        _.forEach(buscas, function(b){
            paginas = buscador.fazBuscaPor(b.busca, paginas, b.key);
        });

        paginas = _.sortBy(paginas, 'id');
        return paginas;
    };

    this.listarConteudos = _.debounce(function () {
        m.request({
            'method': 'GET',
            'url': '/editar/api/conteudos'
        })
        .then(paginasProp, erro);
    }.bind(this), 500);

    this.excluirConteudo = function (id, tipo, pagina) {
        alertify.labels.cancel = 'Cancelar';
        alertify.labels.ok = 'Remover';
        alertify.confirm('Você tem certeza que deseja remover o(a) ' + referencia.tipoDePagina(tipo) + '?', function (result) {
            if (result) {
                pagina.excluindo = m.prop(true);
                m.redraw();
                api.excluir(tipo, id, null, pagina.conteudo.dbId)
                    .then(this.listarConteudos)
                    .then(function () {
                        alertify.success(referencia.tipoDePagina(tipo) + ' excluído(a) com sucesso!', 0);
                        pagina.excluindo(false);
                    });
            }
        }.bind(this));
    };

    this.alterarPorcentagemDigital = function (servico, novaPorcentagem, porcentagemManual) {
        novaPorcentagem = parseInt(novaPorcentagem);
        api.alterarSeloDigital(servico.conteudo.dbId, novaPorcentagem, porcentagemManual).then(function (response) {
            avisos.sucesso("Porcentagem do selo digital alterado com sucesso. Para que as mudanças sejam aplicadas no Portal é necessário que elas sejam publicadas.");
        });

        return true;
    };

    this.modalAvaliacao = function(pg) {
        var conteudoModal = 'Deseja considerar este serviço como <strong>avaliado</strong>?' +
            '<p>Importante: Certifique-se que o serviço avaliado seja o serviço publicado ' +
            'no Portal e não o que está no Editor.</p>';
        if (pg.avaliado) {
            conteudoModal = conteudoModal.replace('avaliado', 'não avaliado');
        }
        alertify.labels.cancel = 'Cancelar';
        alertify.labels.ok = 'Confirmar';
        alertify.confirm(
            conteudoModal,
            function(rs) {
                if (!rs) return;
                pg.avaliando = m.prop(true);
                m.redraw();
                api.avaliar(pg.conteudo.tipo, pg.conteudo.nome, pg, pg.conteudo.dbId)
                    .then(this.listarConteudos)
                    .then(function(){
                        pg.avaliando(false);
                    });
            }.bind(this)
        );
    };

    this.listarConteudos();
};
