'use strict';

var referencia = require('referencia');
var permissoes = require('utils/permissoes');
var api = require('api');
var modal = require('componentes/modal-bootstrap');
var avisos = require('utils/avisos');

function isServico(p) {
  return p.conteudo.tipo === 'servico';
}

function isOrgao(p) {
  return p.conteudo.tipo === 'orgao';
}

function alterarPorcentagemDigital(ctrl, pagina) {
    if (isOrgao(pagina)) {
        return '';
    }

    var prompt = {
        title: function(attrs) {
            return attrs.title ? attrs.title : "Deseja modificar a porcentagem do Selo Digital deste Serviço: " + pagina.conteudo.nome + "?";
        },
        controller: function(attrs) {
            this.porcentagem = m.prop();
            this.novaPorcentagem = m.prop(0);
            this.porcentagemManual = m.prop(false);

            this.submit = function() {
                if(this.formIsValid()){
                    pagina.conteudo.porcentagemDigital = this.novaPorcentagem();
                    attrs.callback(pagina, this.novaPorcentagem(), this.porcentagemManual());
                }
            }.bind(this);

            this.formIsValid = function () {
                var porcentagem = parseInt(this.novaPorcentagem());
                if(isNaN(porcentagem)){
                    avisos.erro("O valor da porcentagem é obrigatório");
                    return false;
                }

                if(porcentagem < 100 && porcentagem > -1){
                    this.novaPorcentagem(porcentagem);
                } else {
                    this.novaPorcentagem(0);
                    avisos.erro("O valor deve estar entre 0 e 100.");
                    return false;
                }

                return true;
            };

            api.buscarSeloDigital(pagina.conteudo.dbId).then( function(response) {
                var resposta = JSON.parse(response);
                this.porcentagem(resposta.automatico);
                this.novaPorcentagem(resposta.manual);
                this.porcentagemManual(resposta.porcentagemManual)
            }.bind(this));

        },
        view: function(ctrl) {
            return m("form", [
                m('label.titulo', 'Informar Porcentagem Manual?'),
                m('label.switch', [
                    m('input[type=checkbox]#habilitarSelo', {
                        checked: ctrl.porcentagemManual(),
                        onchange: m.withAttr('checked', ctrl.porcentagemManual)
                    }),
                    m('div.slider.round')
                ]),
                m('span', {style: {padding: '2em'}} , [ctrl.porcentagemManual() ? 'Sim' : 'Não']),
                m('label.titulo', 'Porcentagem Automática'),
                m('span.percent', ctrl.porcentagem() + ' %'),
                m('label.titulo', 'Porcentagem Manual'),
                m("div.input-container", [
                    m("input[type='number'][max='100'][min='0'].left", {
                        onchange: m.withAttr("value", ctrl.novaPorcentagem),
                        value: ctrl.novaPorcentagem(),
                        style: {
                            width: '15%'
                        }
                    }),
                    m('span.percent', '%')
                ]),
                m("p.btn-salvar",
                    m("button[type='button']", {
                        onclick: ctrl.submit,
                    }, "Confirmar")
                )
            ]);
        }
    };

    return  permissoes.ehAdmin() ? m('button.remover', {
        onclick: modal.show.bind(modal, prompt, {callback: _.bind(ctrl.alterarPorcentagemDigital)})
    }, [m('i.fa.fa-percent', "%"), m('span.tooltip-content', 'Editar porcentagem digital')]) : '';
}

function excluirView(ctrl, pagina) {
  if (isOrgao(pagina)) {
    return '';
  }
  if (pagina.excluindo && pagina.excluindo()) {
    return m('i.fa.fa-spin.fa-spinner');
  }
  return m('button.remover', {
    onclick: _.bind(ctrl.excluirConteudo, ctrl, pagina.conteudo.nome, pagina.conteudo.tipo, pagina)
  }, [m('i.fa.fa-trash-o'), m('span.tooltip-content', 'Excluir página')]);
}

function validarPublicacaoView(ctrl, pagina, dbId) {

    return  permissoes.ehAdmin() && pagina.statusPublicacao == 2 ? m('a.visualizar', {
        href: '/editar/validar/' + pagina.conteudo.tipo + '/' + pagina.id + dbId
    }, [m('i.fa.fa-check'), m('span.tooltip-content', 'Validar Publicação')]) : '';

}


function visualizarView(ctrl, pagina, dbId) {
  if (!isServico(pagina)) {
    return '';
  }
  return m('a.visualizar', {
    href: '/editar/visualizar/' + pagina.conteudo.tipo + '/' + pagina.id + dbId
  }, [m('i.fa.fa-eye'), m('span.tooltip-content', 'Ver página')]);
}

module.exports = function (ctrl, args) {
    var filtro       = args.filtro;
    var paginas      = ctrl.paginasFiltradas(filtro);
    var iconesDeTipo = {servico: 'fa-file-text-o', orgao: 'fa-building-o'};

    var contador = function(mapped) {
        var qtd = (_.filter(mapped, function(m){ return m.children.length > 0;}).length -1 );
        var msgs = {
            zeroItems:"Nenhum ítem foi encontrado.",
            umItem:"1 foi encontrado.",
            maisItems:qtd + " foram encontrados."
        };

        return m('thead', m('tr', [
            m(
                'td[colspan=7]',[
                    permissoes.ehAdmin()
                        ? m('div.left', [
                            m('i.fa.fa-square.legenda-orgao-extinto'), "Necessita de alteração de Órgão"
                        ])
                        : m('')
                    , (qtd === 1) ? msgs.umItem : ((qtd > 1) ? msgs.maisItems : msgs.zeroItems)
                ]
            )
        ]));
    }

    var tdAvaliado = function(pg) {
        if (_.contains(['pagina-tematica', 'orgao'], pg.conteudo.tipo)) {
            return ['—'];
        }

        var data = moment(parseInt(pg.ultimaAvaliacao)).fromNow(),
            msg  =  "Não Avaliado";

        if (pg.publicado && pg.avaliado)
            msg = data + ", por " + pg.avaliadoPor;

        return [msg];
    }

    var tdStatus= function(pg) {

        var descricao = "";

        switch(pg.statusPublicacao) {
            case 1:
                descricao = "Publicado";
                break;
            case 2:
                descricao = "Pendente publicação";
                break;
            case 3:
                descricao = "Alterações não publicadas";
                break;
            case 4:
                descricao = "Alterações recusadas";
                break;
            default:
                descricao = "Em construção";
        }
        return descricao;
    }



    var btAvaliar = function(ctrl, pg) {
        if (!permissoes.ehAdmin() || _.contains(['pagina-tematica', 'orgao'], pg.conteudo.tipo)) {
            return m('');
        }
        return m(
            'a.avaliar', {
                onclick:_.bind(ctrl.modalAvaliacao, ctrl, pg)
            },
            [m('i.fa.fa-pencil'), m('span.tooltip-content', 'Marcar/desmarcar este conteúdo como avaliado')]
        );
    }

    var mapPaginas = function(paginas) {
        var retorno = [
            m('tr', [
                m('th[width="35%"]', 'Nome'),
                m('th.center[width="20%"]', 'Órgão'),
                m('th.center[width="8%"]', 'Tipo'),
                m('th.center', 'Publicação'),
                m('th.center', 'Status'),
                m('th.center', 'Edição'),
                m('th.center', '')
            ])
        ];
        _.each(paginas, function (s) {
            var dbId = s.conteudo.dbId || null;
                dbId = dbId === null ?'': '/' + dbId;
            retorno.push(
                permissoes.podeMostrarPaginaLista(s.conteudo.tipo, window.loggedUser.siorg, s.conteudo.orgaoSiorg, s.conteudo.orgaoId) ?
                m('tr', {
                    style: s.conteudo.extinto && permissoes.ehAdmin() ? {background: '#FFCCCC'} : {}
                }, [
                    m('td',
                        m('a.nome-link.force-wrap', { href: '/editar/' + s.conteudo.tipo + '/' + s.conteudo.uri + dbId }, [
                            m('span.fa', { class: iconesDeTipo[s.conteudo.tipo] || 'fa-file-o' }),
                            m.trust(' &nbsp; '),
                            s.conteudo.nome
                        ]),
                        s.temAlteracoesNaoPublicadas ? m('span.mudanca', 'Alterações não publicadas') : ''
                    ),
                    m('td.center', s.conteudo.tipo !== 'pagina-tematica' ? s.conteudo.nomeOrgao : ' — — '),
                    m('td.center', referencia.tipoDePagina(s.conteudo.tipo)),
                    m('td.center.publicado', s.publicado && s.publicado.autor ? [
                        moment(s.publicado.horario).fromNow(),
                        ', por ',
                        s.publicado.autor.split('@')[0]
                        ] : '—'
                    ),
                    m('td.center.avaliado', tdStatus(s)),
                    m('td.center.editado', s.editado && s.editado.autor ? [
                        moment(s.editado.horario).fromNow(),
                        ', por ',
                        s.editado.autor.split('@')[0]
                        ] : '—'
                    ),
                    m('td.center', [
                        validarPublicacaoView(ctrl,s,dbId),
                        s.conteudo.tipo == 'servico' ? alterarPorcentagemDigital(ctrl, s) : '',
                        visualizarView(ctrl, s, dbId),
                        permissoes.podeExcluirPagina(s.conteudo.tipo, window.loggedUser.siorg, s.conteudo.orgaoSiorg, s.conteudo.orgaoId || '') ? excluirView(ctrl, s) : ''
                    ])
                ]) :
                m('tr')
            );
        });
        return retorno;
    };

    var mapped = mapPaginas(paginas);
    return m('div', [m('table', [contador(mapped), mapped]), m.component(modal)]);
};