'use strict';

var permissoes = require('utils/permissoes');

module.exports = {
  view: function (ctrl, args) {

    var nomeProp = args.nome;
    var tooltipNome = args.tooltipNome;

    var componenteNome = args.novo ? m.component(args.componente, _.assign(args, {
      prop: nomeProp
    })) : nomeProp();

    var editButton = args.editNome && !args.desabilitaRenomear ? m('button.renomear', {
      style: {
        float: 'right'
      },
      onclick: function () {
        args.promptRenomear();
      }
    }, [m('i.fa.fa-pencil'), ' Alterar nome']) : m('');

    return m('fieldset#nome', [
      m('h3', [
          args.titulo,
          args.novo
          ? m.component(tooltipNome)
          : ''
      ]),
      m('.input-container', componenteNome),
      editButton
    ]);
  }
};
