'use strict';

var carregarSuperServico = require('xml/carregar').carregarSuperServico;

function carregar(cabecalho) {
  return carregarSuperServico(m.route.param('id'), cabecalho, m.route.param('dbId'));
}

var servicoMantido = m.prop(null);
var metadadosMantido = m.prop(null);

module.exports = {
  recuperar: function (cabecalho, callback) {
      carregar(cabecalho)
          .then(function (ser) {
              servicoMantido(ser);
              metadadosMantido = cabecalho.metadados;
              if(typeof callback === "function"){
                  callback(ser);
              }
          });
    cabecalho.metadados(metadadosMantido());
    return servicoMantido;
  },

  manter: function (servico, metadados) {
    servicoMantido = servico;
    metadadosMantido = metadados;
  }
};
