/**
 * Created by ygorazevedo on 4/27/17.
 */
'use strict';

var api = require('api');

module.exports = {

    controller: function (args) {
        this.pagina = args.pagina;

        var _this = this;
        this.pagina().orgaos().map(function (orgao, i) {
            api.obterNomeOrgao(orgao.dbId()).then(function (nomeOrgao) {
                _this.pagina().orgaos()[i].nome(nomeOrgao);
            });
        });

        this.converter = new window.showdown.Converter();
    },

    view: function (ctrl) {
        if (!ctrl.pagina().orgaos() || ctrl.pagina().orgaos().length == 0) {
            return m.component(require('servico/visualizar/view-vazia'));
        }

        return m('#pagina-conteudo', [
            m('h3.subtitulo-servico', 'Órgãos Relacionados'),
            m('div',
                ctrl.pagina().orgaos().map(function (orgao) {
                    return m('p.lista-orgaos', [
                        m('a', {
                            href: '#'
                        }, orgao.nome())
                    ]);
                })
            )
        ]);

    }
};
