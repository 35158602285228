'use strict';

var focus = require('focus');
var Api = require('api');
var avisos = require('utils/avisos');

module.exports = {
  controller: function (args) {
    this.servicoPublicado = [];
    var self = this;

    this.servico = args.servico;

    Api.get('servico/publicado/por-orgao/-1').then(function (response) {
      if (response){
        self.servicoPublicado = response.map(function (s) {
          return {
            id: s.id,
            text: s.nomeServico
          }
        });
      }
    });

    this.adicionar = function () {
      var servicosRelacionados = this.servico().servicosRelacionados();
      servicosRelacionados.push(m.prop());
      this.servico().servicosRelacionados(servicosRelacionados);
    };

    this.remover = function (i) {
      var servicosRelacionados = this.servico().servicosRelacionados();
      servicosRelacionados.splice(i, 1);
      this.servico().servicosRelacionados(servicosRelacionados);
    };
  },

  view: function (ctrl) {
    var erro = ctrl.servico().servicosRelacionados.erro();
    var erroFieldSet = _.isString(erro) ? erro : '';

    return m('fieldset#servicos-relacionados.relative', [
      m('h3.opcional', {
        class: erroFieldSet
      }, [
        'Servicos Relacionados',
        m.component(require('tooltips').servicoRelacionado)
      ]),

      ctrl.servico().servicosRelacionados().map(function (servicos, i) {
        var prop;
        if(typeof ctrl.servico().servicosRelacionados()[i] == 'string') {
          prop = m.prop(ctrl.servico().servicosRelacionados()[i]);
        } else {
          prop = ctrl.servico().servicosRelacionados()[i];
        }
        return [
          ctrl.servico().servicosRelacionados().length === 1 ? '' : m('button.remove.absolute', {
            style: {marginTop: i == 0 ? '5px' : '19px'},
            onclick: ctrl.remover.bind(ctrl, i)
          }),

          m('.input-container.full', {style: {marginTop: '1em'}} , [
            m.component(require('componentes/select-servico-publicado'), {
              prop: prop,
              servicoId: ctrl.servico().dbId(),
              onchange: _.bind(function (value) {
                if (_.indexOf(this.servico().servicosRelacionados(), value) != -1){
                  avisos.erro('Este serviço já foi selecionado!');
                  prop('');
                  return;
                }
                this.servico().servicosRelacionados()[i] = value;
              }, ctrl)
            })
          ])
        ]
      }),
      m('button.adicionar.adicionar-servicos-relacionados', {
        style: {marginTop: '3em'},
        onclick: ctrl.adicionar.bind(ctrl)
      }, [
        m('i.fa.fa-plus'),
        ' Adicionar demais serviços relacionados '
      ])
    ]);
  }
};
