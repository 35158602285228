/**
 * @class GerenciarCategoriaController
 */
var GerenciarCategoriaController = (function (m, require) {

    var slugify = require('slugify');

    return function (args) {
        var CabecalhoModel = require('cabecalho/cabecalho-model');
        var api = require('api');
        var avisos = require('utils/avisos');

        var self = this;
        this.cabecalho = new CabecalhoModel();
        this.caiuSessao = m.prop(false);

        this.categoriasEmDestaque = [];

        this.novaCategoria = m.prop('');
        this.categorias = [];

        this.buscarCategoriasEmDestaque = function () {
            api.buscarCategoriasEmDestaque().then(function (response) {
                self.categoriasEmDestaque = JSON.parse(response);
            });
        };

        this.isEmDestaque = function (nome) {
            return _.findIndex(this.categoriasEmDestaque, {nome: nome});
        };

        this.listarCategoriasPublicadas = function(){
            api.listarCategoriasPublicadas().then(function (response) {
                self.categorias = JSON.parse(response).resposta
            });
        };

        this.excluir = function (unidade) {
            alertify.labels.cancel = 'Cancelar';
            alertify.labels.ok = 'Remover';
            alertify.confirm('Você tem certeza que deseja remover o(a) Categoria?', function (result) {
                if (result) {
                    api.removerCategoria(unidade.id)
                        .then(self.listarCategoriasPublicadas)
                        .then(function () {
                            alertify.success('Categoria excluída(o) com sucesso!', 5000);
                        });
                }
            }.bind(this));
        };

        this.salvar = function () {
            if(this.jaCadastrado(this.novaCategoria())){
                avisos.erro('Erro! ' + this.novaCategoria() + ' já é uma Categoria cadastrada.');
            } else if(this.novaCategoria().length > 250) {
                avisos.erro('Erro! O nome da Categoria não pode ter mais de 250 caracteres.');
            } else {
                api.criarCategoria(this.novaCategoria()).then(function (response) {
                    var novaCategoria = JSON.parse(response).resposta;
                    if(typeof novaCategoria == 'object'){
                        self.categorias.push(novaCategoria);
                        self.organizar();
                        avisos.sucesso('Categoria adicionada com sucesso!');
                        self.novaCategoria('');
                    }
                })
            }
        };

        this.voltarPagina = function () {
            history.go(-1);
        };

        this.jaCadastrado = function (nome) {
            return this.categorias.some(function (c) {
                if(slugify(nome) == slugify(c.nome)){
                    return true;
                }
            });
        };

        this.organizar = function () {
            this.categorias.sort(function (a, b) {
                return a.nome.localeCompare(b.nome);
            });
        };

        this.alterar = function (unidade, antigoNome) {
            if(this.isEmDestaque(antigoNome) > -1){
                alertify.error('Não é possível editar uma categoria que está em destaque ou associada a um serviço.', 5000);
                unidade.nome = antigoNome;
                return;
            }
            api.alterarCategoria(unidade)
                .then(this.listarCategoriasPublicadas)
                .then(function () {
                    alertify.success('Categoria alterada(o) com sucesso!', 5000);
                });
        };

        this.isPublicadoComAreaInteresse = function (antigoNome) {
            return api.isPublicadoComAreaInteresse(antigoNome);
        };

        this.listarCategoriasPublicadas();
        this.buscarCategoriasEmDestaque();

    };
})(m, require);

module.exports = GerenciarCategoriaController;