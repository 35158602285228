'use strict';

var permissoes = require('utils/permissoes');
var Api = require('api');

module.exports = {

    controller: function (args) {
        this.servico = args.servico;
        this.bloquearSelect = false;

        var self = this;

        if(!args.podeSalvar) {
            self.bloquearSelect = true;
        }

        if(!permissoes.ehAdmin() && args.podeSalvar){
            var nomeOrgao = window.loggedUser.siorg_name;
            Api.pesquisarOrgao(nomeOrgao).then(function (response) {
                var orgaos = JSON.parse(response);
                self.servico().orgao().dbId(orgaos[0].id);
            });
            self.bloquearSelect = true;
        } else if(!self.servico().orgao().dbId() && self.servico().orgao().nome()) {
            var siorg = self.servico().orgao().nome();
            if(siorg.indexOf('/')) {
                var splitedSiorg = siorg.split('/');
                siorg = splitedSiorg[splitedSiorg.length - 1];
            } else if(siorg.indexOf('-')) {
                var splitedSiorg = siorg.split('-');
                siorg = splitedSiorg[splitedSiorg.length - 1];
            }
            Api.buscarIdPorSiorg(siorg).then(function (response) {
                var data = JSON.parse(response);
                self.servico().orgao().dbId(data.resposta);
            });
        }
    },

    view: function (ctrl) {

        return m('', [
            m('fieldset#orgao-responsavel', [
                m('h2', 'outras informações'),
                m('h3', [
                    'Órgão responsável',
                    m.component(require('tooltips').orgaoResponsavel)
                ]),

                m('.input-container.full', [
                    m.component(require('pagina/orgao/select-orgao'), {
                        prop: ctrl.servico().orgao().dbId,
                        bloquear: ctrl.bloquearSelect
                    })
                ])
            ]),

            m('fieldset#tratamento-dispensado-atendimento', [
                m('h3', [
                    'Tratamento a ser dispensado ao usuário no atendimento',
                    m.component(require('tooltips').tratamentoDispensadoAtendimento)
                ]),

                m('div.input-container', {
                        class: ctrl.servico().tratamentoDispensadoAtendimento.erro(),
                    },
                    m.component(require('componentes/editor-markdown'), {
                        rows: 3,
                        maximo: 1000,
                        value: ctrl.servico().tratamentoDispensadoAtendimento(),
                        onchange: m.withAttr('value', ctrl.servico().tratamentoDispensadoAtendimento),
                        onblur: m.withAttr('value', ctrl.servico().tratamentoDispensadoAtendimento),
                    })
                )
            ]),

            m('fieldset#contato-orgao', [
                m('h3.opcional', [
                    'Contato do serviço',
                    m.component(require('tooltips').contatoOrgao)
                ]),

                m('div.input-container', {
                        class: ctrl.servico().orgao().contato.erro(),
                    },
                    m.component(require('componentes/editor-markdown'), {
                        rows: 3,
                        value: ctrl.servico().orgao().contato(),
                        onchange: m.withAttr('value', ctrl.servico().orgao().contato),
                        onblur: m.withAttr('value', ctrl.servico().orgao().contato),
                    })
                )
            ])
        ]);
    }
};
