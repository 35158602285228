'use strict';

var referencia = require('referencia');

module.exports = {

  controller: function (args) {
    this.servico = args;
    this.unidades = referencia.unidadesDeTempoVisualizar;
    this.converter = new window.showdown.Converter();

    this.temTempoEntre = function () {
      return this.servico.tempoTotalEstimado().tipo() === 'entre';
    };

    this.temTempoAte = function () {
      return this.servico.tempoTotalEstimado().tipo() === 'ate';
    };

    this.temTempo = function () {
      return this.servico.tempoTotalEstimado() && (this.temTempoAte() || this.temTempoEntre());
    };

    this.temTempoImediato = function () {
      return this.servico.tempoTotalEstimado().tipo() == 'atendimento-imediato';
    };
  },

  view: function (ctrl) {

    var tempoEntreView = function () {
      return m('p', [
        'Entre ',
        m('span', ctrl.servico.tempoTotalEstimado().entreMinimo()),
        ' e ',
        m('span', ctrl.servico.tempoTotalEstimado().entreMaximo()),
        ' ',
        m('span', ctrl.unidades[ctrl.servico.tempoTotalEstimado().entreTipoMaximo()]),
        ' é o tempo estimado para a prestação deste serviço.'
      ]);
    };

    var tempoAteView = function () {
      return m('p', [
        m('span', 'Até '),
        m('span', ctrl.servico.tempoTotalEstimado().ateMaximo()),
        m('span', ' '),
        m('span', ctrl.unidades[ctrl.servico.tempoTotalEstimado().ateTipoMaximo()])
      ]);
    };

    var tempoImediatoView = function () {
      return m('p', [
        m('span', 'Atendimento Imediato')
      ]);
    };

    var tempoNaoEstimadoView = function () {
      return m('p', [
        m('span', 'Não estimado ainda')
      ]);
    };

    var tempoView = function () {
      if (ctrl.temTempoEntre()) {
        return tempoEntreView();
      } else if (ctrl.temTempoAte()) {
        return tempoAteView();
      } else if(ctrl.temTempoImediato()) {
        return tempoImediatoView();
      } else {
        return tempoNaoEstimadoView();
      }
    };

    return m('#servico-tempo', [
      m('h3', 'Quanto tempo leva?'),
      tempoView(),
      ctrl.servico.tempoTotalEstimado().descricao().length > 0 ? m('br') : '',
      ctrl.servico.tempoTotalEstimado().descricao().length > 0 ? m('h4', 'Informações adicionais ao tempo estimado') : '',
      m.trust(ctrl.converter.makeHtml(ctrl.servico.tempoTotalEstimado().descricao()))
    ]);
  }
};
