'use strict';

function retornaPermissoes(comTodas) {
    if (window.loggedUser.permissoes !== undefined) {

        if (comTodas) {
            var arrayOriginal = window.loggedUser.permissoes
        } else {
            var arrayOriginal = window.loggedUser.permissoes.slice(1);
        }

        return arrayOriginal.map(function (permissao) {
            return permissao.authority;
        });
    } else {
        return [];
    }
}

var arrayPermissoes = [];

function compareSiorg(a, b) {
    return (a ? a.replace(/-/g, '/').split('/').pop() : null) === (b ? b.replace(/-/g, '/').split('/').pop() : null);
}

function compareId(a, b) {
    return a === b;
}

function possuiPermissao(permissao, comTodas) {
    arrayPermissoes = retornaPermissoes(comTodas);
    return _.contains(arrayPermissoes, permissao);
}

function possuiPermissaoOrgaoEspecifico(permissao, orgaoIdUsuario, orgaoSiorgPagina, orgaoIdPagina) {
    arrayPermissoes = retornaPermissoes();
    return _.contains(arrayPermissoes, permissao) &&
        (orgaoIdUsuario && orgaoIdUsuario.indexOf('http') > -1 ?
            compareSiorg(orgaoIdUsuario, orgaoSiorgPagina) :
            compareId(orgaoIdUsuario, orgaoIdPagina));
}

function estaNaPaginaServico() {
    return m.route().indexOf('editar/servico') > -1;
}

function estaNaPaginaSuperServico() {
    return m.route().indexOf('editar/super-servico') > -1;
}

function estaNaPaginaOrgao() {
    return m.route().indexOf('editar/orgao') > -1;
}

function estaNaPaginaTematica() {
    return m.route().indexOf('editar/pagina-tematica') > -1;
}

function possuiPermissaoPaginaServico(permissao) {
    return possuiPermissao(permissao) && estaNaPaginaServico();
}

function possuiPermissaoPaginaSuperServico(permissao) {
    return possuiPermissao(permissao) && estaNaPaginaSuperServico();
}


function possuiPermissaoPaginaServicoOrgaoEspecifico(permissao, orgaoIdUsuario, orgaoSiorgPagina, orgaoIdPagina) {
    return possuiPermissao(permissao) && estaNaPaginaServico() &&
        (orgaoIdUsuario && orgaoIdUsuario.indexOf('http') > -1 ?
            compareSiorg(orgaoIdUsuario, orgaoSiorgPagina) :
            compareId(orgaoIdUsuario, orgaoIdPagina));
}

function possuiPermissaoPaginaTematica(permissao) {
    return possuiPermissao(permissao) && estaNaPaginaTematica();
}

function possuiPermissaoPaginaOrgao(permissao) {
    return possuiPermissao(permissao) && estaNaPaginaOrgao();
}

function possuiPermissaoPaginaOrgaoOrgaoEspecifico(permissao, orgaoIdUsuario, orgaoSiorgPagina, orgaoIdPagina) {
    return possuiPermissao(permissao) && estaNaPaginaOrgao() &&
        (orgaoIdUsuario && orgaoIdUsuario.indexOf('http') > -1 ?
            compareSiorg(orgaoIdUsuario, orgaoSiorgPagina) :
            compareId(orgaoIdUsuario, orgaoIdPagina));
}

function podeSalvarPagina(orgaoIdUsuario, orgaoSiorgPagina, orgaoIdPagina) {
    return !orgaoSiorgPagina ||
        possuiPermissaoPaginaServico('EDITAR_SALVAR SERVICO') ||
        possuiPermissaoPaginaServicoOrgaoEspecifico('EDITAR_SALVAR SERVICO (ORGAO ESPECIFICO)', orgaoIdUsuario, orgaoSiorgPagina, orgaoIdPagina) ||
        possuiPermissaoPaginaTematica('EDITAR_SALVAR PAGINA-TEMATICA') ||
        possuiPermissaoPaginaOrgao('EDITAR_SALVAR ORGAO') ||
        possuiPermissaoPaginaOrgaoOrgaoEspecifico('EDITAR_SALVAR ORGAO (ORGAO ESPECIFICO)', orgaoIdUsuario, orgaoSiorgPagina, orgaoIdPagina);
}

function podeMostrarPaginaLista(tipo, orgaoIdUsuario, orgaoSiorgPagina, orgaoIdPagina) {
    var returnValue = false;

    if (tipo === 'servico') {
        returnValue = possuiPermissao('EDITAR_SALVAR SERVICO') ||
            possuiPermissaoOrgaoEspecifico('EDITAR_SALVAR SERVICO (ORGAO ESPECIFICO)', orgaoIdUsuario, orgaoSiorgPagina, orgaoIdPagina);
    }

    if (tipo === 'super-servico') {
        returnValue = possuiPermissao('EDITAR_SALVAR SERVICO') ||
            possuiPermissaoOrgaoEspecifico('EDITAR_SALVAR SERVICO (ORGAO ESPECIFICO)', orgaoIdUsuario, orgaoSiorgPagina, orgaoIdPagina);
    }

    if (tipo === 'pagina-tematica') {
        returnValue = possuiPermissaoOrgaoEspecifico('EDITAR_SALVAR PAGINA-TEMATICA');
    }

    if (tipo === 'orgao') {
        returnValue = possuiPermissaoOrgaoEspecifico('EDITAR_SALVAR ORGAO (ORGAO ESPECIFICO)', orgaoIdUsuario, orgaoSiorgPagina, orgaoIdPagina) ||
            possuiPermissao('EDITAR_SALVAR ORGAO');
    }

    return returnValue;
}

function podeCriarPagina(tipoPagina) {
    if (tipoPagina === 'servico') {
        return possuiPermissao('CRIAR SERVICO') ||
            possuiPermissao('CRIAR SERVICO (ORGAO ESPECIFICO)');
    }

    if (tipoPagina === 'orgao') {
        return possuiPermissao('CRIAR ORGAO (ORGAO ESPECIFICO)') ||
            possuiPermissao('CRIAR ORGAO');
    }

    if (tipoPagina === 'pagina-tematica') {
        return possuiPermissao('CRIAR PAGINA-TEMATICA');
    }

    if (tipoPagina === 'importar-xml') {
        return possuiPermissao('CRIAR SERVICO') ||
            possuiPermissao('CRIAR SERVICO (ORGAO ESPECIFICO)');
    }

    return possuiPermissao('CRIAR SERVICO') ||
        possuiPermissao('CRIAR SERVICO (ORGAO ESPECIFICO)') ||
        possuiPermissao('CRIAR ORGAO') ||
        possuiPermissao('CRIAR ORGAO (ORGAO ESPECIFICO)') ||
        possuiPermissao('CRIAR PAGINA-TEMATICA');
}

function podePublicarPagina(orgaoIdUsuario, orgaoSiorgPagina, orgaoIdPagina) {
    return possuiPermissaoPaginaServicoOrgaoEspecifico('PUBLICAR SERVICO (ORGAO ESPECIFICO)', orgaoIdUsuario, orgaoSiorgPagina, orgaoIdPagina) ||
        possuiPermissaoPaginaServico('PUBLICAR SERVICO') ||
        possuiPermissaoPaginaSuperServico('PUBLICAR SUPER-SERVICO') ||
        possuiPermissaoPaginaTematica('PUBLICAR PAGINA-TEMATICA') ||
        possuiPermissaoPaginaOrgaoOrgaoEspecifico('PUBLICAR ORGAO (ORGAO ESPECIFICO)', orgaoIdUsuario, orgaoSiorgPagina, orgaoIdPagina) ||
        possuiPermissaoPaginaOrgao('PUBLICAR ORGAO');
}

function podeDescartarPagina(orgaoIdUsuario, orgaoSiorgPagina, orgaoIdPagina) {
    return possuiPermissaoPaginaServicoOrgaoEspecifico('DESCARTAR SERVICO (ORGAO ESPECIFICO)', orgaoIdUsuario, orgaoSiorgPagina, orgaoIdPagina) ||
        possuiPermissaoPaginaServico('DESCARTAR SERVICO') ||
        possuiPermissaoPaginaSuperServico('DESCARTAR SUPER-SERVICO') ||
        possuiPermissaoPaginaTematica('DESCARTAR PAGINA-TEMATICA') ||
        possuiPermissaoPaginaOrgaoOrgaoEspecifico('DESCARTAR ORGAO (ORGAO ESPECIFICO)', orgaoIdUsuario, orgaoSiorgPagina, orgaoIdPagina) ||
        possuiPermissaoPaginaOrgao('DESCARTAR ORGAO');
}

function podeDespublicarPagina(orgaoIdUsuario, orgaoIdPagina) {
    return possuiPermissaoPaginaServicoOrgaoEspecifico('DESPUBLICAR SERVICO (ORGAO ESPECIFICO)', orgaoIdUsuario, orgaoIdPagina) ||
        possuiPermissaoPaginaServico('DESPUBLICAR SERVICO') ||
        possuiPermissaoPaginaSuperServico('DESPUBLICAR SUPER-SERVICO') ||
        possuiPermissaoPaginaTematica('DESPUBLICAR PAGINA-TEMATICA') ||
        possuiPermissaoPaginaOrgaoOrgaoEspecifico('DESPUBLICAR ORGAO (ORGAO ESPECIFICO)', orgaoIdUsuario, orgaoIdPagina) ||
        possuiPermissaoPaginaOrgao('DESPUBLICAR ORGAO');
}

function podeAlterarDisponibilidadePagina() {
    return ehAdmin() || ehEditor();
}


function podeExcluirPagina(tipoPagina, orgaoIdUsuario, orgaoSiorgPagina, orgaoIdPagina) {
    if (tipoPagina === 'servico') {
        return possuiPermissaoOrgaoEspecifico('EXCLUIR SERVICO (ORGAO ESPECIFICO)', orgaoIdUsuario, orgaoSiorgPagina, orgaoIdPagina) ||
            possuiPermissao('EXCLUIR SERVICO');
    }

    if (tipoPagina === 'orgao') {
        return possuiPermissaoOrgaoEspecifico('EXCLUIR ORGAO (ORGAO ESPECIFICO)', orgaoIdUsuario, orgaoSiorgPagina, orgaoIdPagina) ||
            possuiPermissao('EXCLUIR ORGAO');
    }

    if (tipoPagina === 'pagina-tematica') {
        return possuiPermissao('EXCLUIR PAGINA-TEMATICA');
    }

    return false;
}

function podePublicarDascartarPagina(orgaoIdUsuario, orgaoSiorgPagina, orgaoIdPagina) {
    return podePublicarPagina(orgaoIdUsuario, orgaoSiorgPagina, orgaoIdPagina) &&
        podeDescartarPagina(orgaoIdUsuario, orgaoSiorgPagina, orgaoIdPagina);
}

function podeCriarUsuario() {
    return possuiPermissao('CADASTRAR ADMIN') ||
        possuiPermissao('CADASTRAR PONTO_FOCAL') ||
        possuiPermissao('CADASTRAR PUBLICADOR') ||
        possuiPermissao('CADASTRAR EDITOR');
}

function podeRenomearServico() {
    return ehAdmin();
}

function ehAdmin() {
    return possuiPermissao('CADASTRAR ADMIN') || possuiPermissao('ADMIN');
}

function ehEditor() {
    return possuiPermissao('EDITOR', true);
}

module.exports = {
    podeCriarUsuario: podeCriarUsuario,
    podeSalvarPagina: podeSalvarPagina,
    podeCriarPagina: podeCriarPagina,
    podePublicarDascartarPagina: podePublicarDascartarPagina,
    podeDespublicarPagina: podeDespublicarPagina,
    podeExcluirPagina: podeExcluirPagina,
    podeMostrarPaginaLista: podeMostrarPaginaLista,
    podeRenomearServico: podeRenomearServico,
    possuiPermissaoPaginaTematica: possuiPermissaoPaginaTematica,
    possuiPermissaoPaginaServicoOrgaoEspecifico: possuiPermissaoPaginaServicoOrgaoEspecifico,
    podeAlterarDisponibilidadePagina: podeAlterarDisponibilidadePagina,
    ehAdmin: ehAdmin,
    ehEditor: ehEditor
};
