'use strict';

var promiseUtil = require('utils/promise');
var avisos = require('utils/avisos');
var api = require('api');
var validacoes = require('utils/validacoes');
var menuRodapeToJson = require('administracao/menu-rodape/util/exportar-menu-rodape-json').menuRodapeToJson;

var salvarMenuCabecalhoRodape = function (menu,rodape) {

    var menuRodape = [];
    menuRodape.push(menu);
    menuRodape.push(rodape);

    return promiseUtil.resolved(menuRodape)
        .then(function (menuRodape) {
            var erro;
            if (!validacoes.valida(menuRodape)) {
                erro = 'Preencha os campos obrigatórios.';
            }
            return capturaErro(erro, menuRodape);
        })
        .then(menuRodapeToJson)
        .then(function (menuRodape) {
            return api.salvarMenuCabecalhoRodape(menuRodape);
        });
};


function capturaErro(e, o) {
    if(e){
        return $.Deferred().reject(e);
    }
    return o;
}

module.exports = {
    salvarMenuCabecalhoRodape: salvarMenuCabecalhoRodape
};
