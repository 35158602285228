'use strict';

var buscarAjudaHtml = require('api').buscarAjudaHtml;
var avisos = require('utils/avisos');

module.exports = {

    controller: function (args) {

        this.config = _.merge({
            salvar: _.noop,
            publicar: _.noop,
            solicitarPublicacao: _.noop,
            reprovarPublicacao: _.noop,
            descartar: _.noop,
            avaliar: _.noop,
            visualizar: _.noop,
            editar: _.noop,
            atribuir: _.noop,
            naoRecarregarPagina: _.noop
        }, args);

        this.isValidacao = args.isValidacao;
        this.tipo = args.tipo;

        this.buscarAjudaHtml = function () {
            buscarAjudaHtml().then(function (data) {
                if(data){
                    var wnd = window.open('ajuda');
                    wnd.document.write(data);
                } else {
                    avisos.erro('Não existe nenhum arquivo de ajuda importado. Importe um arquivo acessando "Administração" -> "Importação de arquivo de ajuda".');
                }
            });
        };

    },

    view: function (ctrl) {

        var salvarView = '';
        if (ctrl.config.salvar !== _.noop) {
            salvarView = m.component(require('cabecalho/salvar-button'), {
                salvar: ctrl.config.salvar,
                disabled: ctrl.config.disabled,
                salvandoServico: ctrl.config.salvandoServico,
                caiuSessao: ctrl.config.caiuSessao,
                orgaoId: ctrl.config.orgaoId,
                alertMsg: ctrl.config.alertMsg,
                naoRecarregarPagina : ctrl.config.naoRecarregarPagina
            });
        }

        var visualizarView = '';
        if (ctrl.config.visualizar !== _.noop && !ctrl.isValidacao) {
            visualizarView = m.component(require('cabecalho/visualizar-button'), {
                visualizar: ctrl.config.visualizar,
                salvandoServico: ctrl.config.salvandoServico,
                caiuSessao: ctrl.config.caiuSessao
            });
        }

        var publicarView = '';
        if (ctrl.config.publicar !== _.noop) {
            var metadados = ctrl.config.cabecalho.metadados;

            publicarView = m.component(require('cabecalho/publicar-view'), {
                publicar: ctrl.config.publicar,
                solicitarPublicacao: ctrl.config.solicitarPublicacao,
                reprovarPublicacao: ctrl.config.reprovarPublicacao,
                descartar: ctrl.config.descartar,
                avaliar: ctrl.config.avaliar,
                metadados: _.isEmpty(metadados()) ? metadados : metadados(),
                salvandoServico: ctrl.config.salvandoServico,
                caiuSessao: ctrl.config.caiuSessao,
                orgaoId: ctrl.config.orgaoId,
                orgaoDbId: ctrl.config.orgaoDbId,
                isValidacao : ctrl.isValidacao,
                tipo : ctrl.tipo
            });
        }

        var editarView = '';
        if (ctrl.config.editar !== _.noop) {
            editarView = m.component(require('cabecalho/editar-button'), {editar: ctrl.config.editar});
        }

        var ajudaTutorialView = '';
        if(ctrl.config.ajudaTutorial){
            ajudaTutorialView = m('span.novo', {onclick: ctrl.buscarAjudaHtml}, [
                m.component(require('tooltips').abrirAjuda)
            ]);
        }

        var atribuirView = '';
        if (ctrl.config.atribuir !== _.noop) {
            atribuirView = m.component(require('administracao/componentes/publicar-atribuicao'), {
                publicar: ctrl.config.atribuir.publicar,
                descartar: ctrl.config.atribuir.descartar,
                publicarButton: ctrl.config.atribuir.publicarButton,
                publicarButtonDisable: ctrl.config.atribuir.publicarButtonDisable,
                descartarButton: ctrl.config.atribuir.descartarButton,
                descartarButtonDisable: ctrl.config.atribuir.descartarButtonDisable,
                publicarSucessoMsg: ctrl.config.atribuir.publicarSucessoMsg,
                publicarErroMsg: ctrl.config.atribuir.publicarErroMsg,
                descartarSucessoMsg: ctrl.config.atribuir.descartarSucessoMsg,
                descartarErroMsg: ctrl.config.atribuir.descartarErroMsg,
                salvando: ctrl.config.atribuir.salvando,
                thenn: ctrl.config.atribuir.thenn,
                publicando: ctrl.config.atribuir.publicando
            });
        }

        return m('#metadados', [
            m.component(require('componentes/status-conexao'), {
                salvandoServico: ctrl.config.salvandoServico,
                caiuSessao: ctrl.config.caiuSessao
            }),
            ajudaTutorialView,
            salvarView,
            visualizarView,
            publicarView,
            editarView,
            atribuirView
        ]);
    }

};
