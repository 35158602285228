/**
 * @class ImportarUnidadeController
 */
var ImportarUnidadeController = (function (m, require) {

    return function (args) {
        var CabecalhoModel = require('cabecalho/cabecalho-model');
        var Api = require('api');
        var avisos = require('utils/avisos');
        var Validacoes = require('utils/validacoes');
        var permissoes = require('utils/permissoes');

        var self = this;

        this.orgao = m.prop("", Validacoes.obrigatorio);
        this.tiposValidos = ["JSON", "XML", "CSV"];
        this.cabecalho = new CabecalhoModel();
        this.caiuSessao = m.prop(false);
        this.tipoExemplo = m.prop("");

        this.arquivoCarregado = m.prop(false);
        this.arquivoValido = m.prop(false);
        this.importadoComSucesso = m.prop(false);
        this.importando = m.prop(false);
        this.loaderMensagem = m.prop('');

        this.file = m.prop({name: ""});

        this.data = [];
        this.rowsperpage = 10;
        this.bloquearOrgao = false;

        if(!permissoes.ehAdmin()){
            var nomeOrgao = window.loggedUser.siorg_name;
            Api.pesquisarOrgao(nomeOrgao).then(function (response) {
                var orgaos = JSON.parse(response);
                self.orgao(orgaos[0].id);
            });
            self.bloquearOrgao = true;
        }

        this.interval;

        this.limparArquivoCarregado = function (e) {
            self.arquivoValido(false);
            self.arquivoCarregado(false);
            self.data = [];
            self.file({name: ""});
            document.getElementById("importarArquivo").value = "";
        };

        this.validarArquivo = function (e) {
            var tipoValido = false;
            var nomeArquivo = this.files[0].name;
            self.tiposValidos.map(function (tipo, i) {
                if (nomeArquivo.indexOf(tipo.toLowerCase()) > -1) {
                    tipoValido = true;
                }
            });

            if (!tipoValido) {
                avisos.erro("Arquivo inválido");
                this.files = [];
                return;
            }

            if (this.files[0]) {
                self.file(this.files[0]);
                self.arquivoValido(true);
            } else {
                self.arquivoValido(false);
            }
        };

        this.carregarArquivo = function (e) {
            e.preventDefault();
            var formData = new FormData();
            formData.append('file', self.file());
            this.importando(true);
            this.loaderMensagem('Carregando Arquivo...');
            m.redraw();
            Api.importarUnidades(formData).then(function (response) {
                response = JSON.parse(response);
                if(response.resposta && response.resposta.length > 0){
                    self.data = response.resposta;
                    self.importadoComSucesso(true);
                    self.arquivoCarregado(true);
                }
                if (response.mensagem && response.mensagem.length > 0) {
                    avisos.erro(response.mensagem);
                    self.importadoComSucesso(false);
                    self.arquivoCarregado(true);
                    self.data = response.resposta;
                }
                self.importando(false);
            });
        };

        this.salvarImportacao = function (e) {
            if (self.orgao() == "") {
                avisos.erro("Selecione um órgão");
            } else {
                Api.salvarImportacao(self.data, self.orgao()).then(function (response) {
                  self.importando(true);
                  self.loaderMensagem('Importando Unidades.');
                  m.redraw();
                  self.verificaImportacao();
                });
            }
        };

        this.verificaImportacao = function () {
          Api.verificarImportacaoUnidadeAtendimento().then(function (response) {
            var resposta = response.resposta;
            if(resposta.value == 0){
                if(self.interval){
                  self.importando(false);
                  avisos.sucesso("Unidades importadas com sucesso");
                  m.route('/editar/administracao/unidade/lista');
                  clearInterval(self.interval);
                }
            } else {
              self.importando(true);
              self.loaderMensagem('Importando Unidades.');
              m.redraw();
              if(!self.interval){
                self.interval = setInterval(function () {
                  self.verificaImportacao();
                }, 3000);
              }
            }
          });
        };

        this.verificaImportacao();

    };
})(m, require);

module.exports = ImportarUnidadeController;