    'use strict';

    var api = require('api');
    var Gratuidade = require('servico/modelos').Gratuidade;
    var listaOpcoes = [];

    var controller = function (args) {
        this.servico = args.servico;
        this.listaOpcoes = [];
        var self = this;
        var populaOpcoes = function(listaGratuidades) {
            if (typeof listaGratuidades === "string") {
                listaGratuidades = _.collect(eval(listaGratuidades), function(x){ return x.toLowerCase();});
            }

            self.listaOpcoes = [];
            _.each(listaGratuidades, function(g){
                self.listaOpcoes.push({
                    onchange: function() { self.servico().gratuidade(g.toLowerCase()); },
                    checked: self.servico().gratuidade() === g.toLowerCase()
                });
            })
        };

        api.get('lista-formulario/lista-gratuidades').then(populaOpcoes);
    };

    var view = function (ctrl) {
        var listaLabels = [];
        var listaMensagens = [
            'Este serviço é gratuito para o solicitante',
            'Este serviço tem custos para o solicitante'
        ];
        for (var i in ctrl.listaOpcoes) {
            var opt = ctrl.listaOpcoes[i];
            var label = (i === 0) ? 'label.input-container' : 'label';
            listaLabels.push(m(
                label,
                m('input[type=radio][name=gratuidade]', opt),
                listaMensagens[i]
            ));
        }
        return m('fieldset#gratuidade', [
            m('h3.input-container', {class: ctrl.servico().gratuidade.erro()}, [
                'Gratuidade deste serviço',
                m.component(require('tooltips').gratuidade)
            ]),
            listaLabels
        ]);
    };

    module.exports = {'controller':controller, 'view':view};