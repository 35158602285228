'use strict';

var safeGet = require('utils/code-checks').safeGet;
var promise = require('utils/promise');
var confirmacao = require('componentes/menu/despublicar-confirmacao');
var permissoes = require('utils/permissoes');
var avisos = require('utils/avisos');

function botaoQueEspera(opts) {
  return m('button.botao-primario#' + opts.id, {
    onclick: opts.onclick,
    disabled: (opts.disabled ? 'disabled' : '')
  }, opts.espera ? [m('i.fa.fa-spin.fa-spinner'), m.trust('&nbsp;Despublicando...')] : [m('i.fa.fa-' + opts.icon), 'Despublicar']);
}

function urlInNewContext(tipo, contexto) {
  var parser = document.createElement('a');
  var portalUrl = window.portalUrl || window.location.href;
  parser.href = portalUrl;
  parser.pathname = '/' + tipo + '/' + contexto;
  parser.search = '';
  parser.hash = '';
  return parser.href;
}

module.exports = {
    controller: function (args) {
    this.despublicar = safeGet(args, 'despublicar');
    this.despublicando = m.prop(false);
    this.salvandoServico = args.salvandoServico;
    this.caiuSessao = args.caiuSessao;
    this.orgaoId = args.orgaoId;

        this.opera = function (prop, operacao) {
            prop(true);
            this.salvandoServico(true);
            m.redraw();
            promise.onSuccOrError(
                operacao,
                _.bind(function () {
                    prop(false);
                    this.salvandoServico(false);
                    m.redraw();
                }, this));
        };

        this.onClick = function() {
            this.opera(
                this.despublicando,
                this.despublicar()
                    .then(
                        avisos.sucessoFn('Serviço despublicado com sucesso! As informações desaparecerão no Portal em até 15 minutos.'),
                        avisos.erroFn('Ocorreu u erro ao despublicar.')));
        };

        this.retornaDescricaoStatus = function(status){
            

            var descricao = "";

            switch(status) {
                case "1":
                    descricao = "publicado";
                    break;
                case "2":
                    descricao = "pendente publicação";
                    break;
                case "3":
                    descricao = "alterações não publicadas";
                    break;
                case "4":
                    descricao = "alterações recusadas";
                    break;
                default:
                    descricao = "despublicado";
            }

            return descricao;
        }

    },

  view: function (ctrl, args) {

    var tipo = _.get(args, 'tipo');
    var publicado = _.get(args, 'metadados.publicado.revisao');
    var publicadoAvaliado = _.get(args, 'metadados.status');
    var statusPublicacao = _.get(args, 'metadados.statusPublicacao');


    var mensagemAvaliado = '';

    /*if (publicadoAvaliado === 'AVALIADO' && publicado) {
      mensagemAvaliado = ' avaliado';
    }
    if (publicadoAvaliado === 'AVALIADO' && !publicado) {
      mensagemAvaliado = ' não avaliado';
    }*/

    return m('#secao-despublicar', [
      m('hr'),
      m('label', [
        m('', [
          'Status: ',
            m( statusPublicacao == 1 ? 'span.publicado' : 'span.npublicado', ctrl.retornaDescricaoStatus(statusPublicacao))
        ]),
        publicado ? m('a', {
          href: urlInNewContext(tipo, m.route.param('id')),
          target: '_blank'
        }, 'Acesse a versão no Portal') : ''
     ]),
      permissoes.podeDespublicarPagina(window.loggedUser.siorg, ctrl.orgaoId) ? botaoQueEspera({
        id: 'despublicar',
        onclick: confirmacao(_.bind(ctrl.onClick, ctrl)),
        icon: '',
        disabled: !publicado || ctrl.despublicando() || ctrl.salvandoServico() || ctrl.caiuSessao(),
        espera: ctrl.despublicando()
      }) : m('')
    ]);
  }
};
