var VerificarLinkView = (function (m, require) {

  var tooltips = require('tooltips');
  var Paginacao = require('componentes/paginacao');

  return function (ctrl, args) {

    var verificarImportacao = function(){
      return m('fieldset#carregando.importacao', [
        m("div", [m('div.meter', [m('span', {"style": {"width":"100%"}})]),
          m('div.msg-importacao', [m('label', 'Verificação em Andamento')])
        ])
      ])
    };

    var gerarRelatorio = function (dado) {
      return m('a',
        {href: '/editar/api/verificao-links/gerar-arquivo/' + dado.id},
        m('i.fa.fa-paperclip.fa-2x')
      );
    };

    var tabelaPreview = function (data) {

      if(data.length > 0){
        return m('.ui.sixteen.wide.column#bem-vindo', {
          style: {
            'float':'none'
          }
        }, [
          m('table.ui.table', [
            m('thead',
              m('tr',
                m('th[colspan=2]', {style: {width: '30%'}} ,"Data da notificação"),
                m('th[colspan=4]', {style: {width: '60%'}} ,"Realizado Por"),
                m('th.text-center.no-padding-left[colspan=2]', '')
              )
            ),
            m('tbody', data.map(function (dado) {
              return m('tr', {
                key: dado.id
              }, [
                m('td[colspan=2]', moment(dado.dataCriacao).format('DD/MM/YYYY [às] HH:mm')),
                m('td[colspan=4]', dado.usuario),
                m('td.text-center[colspan=2]', gerarRelatorio(dado))
              ]);
            }))
          ])
        ]);
      }
    };

    return m('#conteudo',[
      m('span.cabecalho-cor'),
      m('#wrapper', [
        m.component(require('cabecalho/cabecalho'), {
          metadados: false,
          nomeDaPagina: 'Verificação de Links Quebrados',
          logout: true
        }),

        m('div.importacao.importar-unidade', [
          m('fieldset#nome', [
            m('label.input-container',
              m('input[type=checkbox]', {
                checked: ctrl.config.automatica,
                onchange: m.withAttr('checked', function (value) {
                  ctrl.config.automatica = value;
                })
              }),
              'Ativar verificação automática'
            ),

            m('div.integracao-siorg',
              m('div.input-container', {
                  class: ''
                }, 'Realizar verificação a cada: ',
                m('input[type=number].dias',{
                  value: ctrl.config.dias,
                  onchange: m.withAttr('value', function (value) {
                    ctrl.config.dias = value;
                  }),
                  disabled: !ctrl.config.automatica
                }),
                " dias"
              )
            ),
            m('br'),
            m('br'),
            m('div.btns-acao',
              m('button.botao-primario', {
                onclick: ctrl.salvarConfigVerificacaoLinks
              }, 'Salvar')
            )
          ])
        ]),
        ctrl.verificando ?
          verificarImportacao() :
          m('fieldset#nome', [
          m('h4', 'Órgão'),
          m.component(require('componentes/select2'), {
            data: ctrl.orgaos,
            prop: ctrl.orgaoId,
            width: '60em',
            minimumResultsForSearch: ctrl.orgaos.length > 5 ? 1 : -1
          }),
          m('div.right', {style: {margin: '2em 0 0 1em'}} , [
            m('button.botao-primario', {
              onclick: ctrl.verificar,
              disabled: ctrl.verificando
            }, 'Gerar Arquivo'),
            m('button.botao-primario', {
              onclick: ctrl.verificarNotificar,
              disabled: ctrl.verificando
            }, 'Notificar Órgãos')
          ]),
          m.component(Paginacao, {
            data: ctrl.historico,
            rowsperpage: ctrl.rowsperpage,
            pagerender: tabelaPreview,
            wrapperclass: 'importar-unidade'
          })
        ])
      ])
    ]);


  };
})(m, require);

module.exports = VerificarLinkView;