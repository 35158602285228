var BrasilCidadaoView = (function (m, require) {
    return function (ctrl, args) {

        return m('#conteudo', [
            m('span.cabecalho-cor'),
            m('#wrapper', [
                m.component(require('cabecalho/cabecalho'), {
                    metadados: false,
                    logout: true
                }),
                m('div#admin', [
                    m('div.scroll', [
                        m('fieldset#tipoDePagina', [
                            m('div.novo', [
                                m('h3', [
                                    'Situação do Brasil Cidadão',
                                    m.component(require('tooltips').habilitarBrasilCidadao)
                                ]),
                                m('div', {style: {marginTop: '2em'}}, [
                                    m('label.switch', [
                                        m('input[type=checkbox]#habilitarBrasilCidadao', {
                                            checked: ctrl.flagBrasilCidadao(),
                                            onchange: m.withAttr('checked', ctrl.toggleBrasilCidadao)
                                        }),
                                        m('div.slider.round'),
                                    ]),
                                    m('span', {style: {paddingLeft: '2em'}} , [ctrl.flagBrasilCidadao() ? 'Habilitado' : 'Desabilitado']),
                                    m('a.button.botao-primario.confirmarSeloDigital', {onclick: ctrl.salvarFlagBrasilCidadao} , m.trust('Confirmar')),
                                    m('a.button.botao-primario.confirmarSeloDigital', {href: '/editar'} , m.trust('Voltar'))
                                ])
                            ])
                        ])
                    ])
                ])
            ])
        ]);

    };
})(m, require);

module.exports = BrasilCidadaoView;