'use strict';

var ListaPaginas = require('home/lista-paginas/componente');
var Tooltips = require('tooltips');
var Api = require('api');
var Permissoes = require('utils/permissoes');

var HomeModel = {
    PLACEHOLDER_PALAVRAS_CHAVE:'Filtre os resultados por serviços, órgãos e palavras chave',
    PLACEHOLDER_ORGAOS:'Filtre os resultados por órgãos',

    isSelected:false,

    siorgName:function(){return loggedUser.siorg_name || null},
    isAdm:function(){return Permissoes.ehAdmin();}
};

var HomeController = function(args) {
    /**
     * Força um click quando um elemento estiver à vista
     * @param Object selector
     * @return Object
     */
    this.fnElementClickOnReady = function(selector, value) {
        var onDone = function(){
                if (HomeModel.isSelected) return;

                var elm = $(selector);
                if (elm[0] && !elm[0].checked) {
                    HomeModel.isSelected = elm[0].checked = true;
                    return elm.click();
                }
                setTimeout(onDone, 100);
            };

        onDone();
    };

    // Parâmetros da controller usados na vies como 'ctrl.*'
    this.filtro = m.prop('');
    this.filtrarPublicados = m.prop();
    this.orgao = m.prop('');
    this.filtroOrgao = m.prop('');
    this.filtroOrgaos = m.prop('');
    this.filtroPaginasTematicas = m.prop('');
    this.filtroServicos = m.prop('');
    this.filtroSuperServicos = m.prop('');
    this.filtroPublicados = m.prop('');
    this.filtroPendentesPublicacao = m.prop('');
    this.filtroAlteracoesNaoPublicadas = m.prop('');
    this.filtroAlteracoesRecusadas = m.prop('');
    this.filtroEmConstrucao = m.prop('');
};

var HomeView = function(ctrl) {
    moment.locale('pt-br');
    var filtroOrgaos = null;
    var siorgName = HomeModel.siorgName();
    var adm = HomeModel.isAdm();

    // input filtro orgaos
    var filtroOrgaos = (function(adm){
        if (adm) {
            return m('input[type=search][placeholder="' + HomeModel.PLACEHOLDER_ORGAOS + '"]', {
                oninput: m.withAttr('value', ctrl.filtroOrgao)
            });
        }
        return m('');
    })(adm);

    var filtroUsuarioOrgao = (function(adm, siorgName){
        if (!siorgName) return '';
        var notHidden = (!adm) ? {} : { style:'display:none' } ;
        var onlySiorg = (!adm) ? '#radio-um-orgao' : '#radio-todos-orgaos';
        var span = m('span',
            notHidden,
            (
                m('label', [
                    m('input[id=radio-um-orgao][type=radio][name=orgao][value=' + siorgName + ']', {
                        onclick: m.withAttr('value', ctrl.filtroOrgao)
                    }),
                    siorgName,
                    m.component(Tooltips.filtroRadioUmOrgao)
                ])
            ),
            (
                m('label', [
                   m('input[id=radio-todos-orgaos][type=radio][name=orgao][value= ]', {
                        onclick: m.withAttr('value', ctrl.filtroOrgao)
                    }),
                    'Todos os Órgãos',
                    m.component(Tooltips.filtroRadioTodosOrgaos)
                ])
            )
        );
        ctrl.fnElementClickOnReady(onlySiorg, siorgName);

        return span;
    })(adm, siorgName);

    var filtroAvaliacoes = (function(adm){
        if (!adm) {
            return m('');
        }
        return m('div.filtro-orgaos',[
            m('fieldset', {title:'Páginas de serviços'}, [
                m('legend', ['Páginas de serviços']),
                m('label', [
                    m('input[type=checkbox]', {onclick: m.withAttr('checked', ctrl.filtroPublicados)}),
                    'Publicados',
                ]),

                m('label', [
                    m('input[type=checkbox]', {onclick: m.withAttr('checked', ctrl.filtroPendentesPublicacao)}),
                    'Pendentes publicação',
                ]),

                m('label', [
                    m('input[type=checkbox]', {onclick: m.withAttr('checked', ctrl.filtroAlteracoesNaoPublicadas)}),
                    'Alterações não Publicadas',
                ]),
                m('label', [
                    m('input[type=checkbox]', {onclick: m.withAttr('checked', ctrl.filtroAlteracoesRecusadas)}),
                    'Alterações recusadas',
                ]),
                m('label', [
                    m('input[type=checkbox]', {onclick: m.withAttr('checked', ctrl.filtroEmConstrucao)}),
                    'Em Construção',
                ])
            ])
        ]);
    })(adm);

    return m('#conteudo', [
        m('span.cabecalho-cor'),
        m('#wrapper', [
            m.component(require('cabecalho/cabecalho'), {
                metadados: false,
                nomeDaPagina: 'Lista de páginas',
                logout: true
            }),

            m('#bem-vindo', [
                m('div.busca', [
                    filtroOrgaos,
                    m('input[type=search][placeholder="' + HomeModel.PLACEHOLDER_PALAVRAS_CHAVE + '"]', {
                        oninput: m.withAttr('value', ctrl.filtro)
                    }),
                    m('div.filtro-orgaos', filtroUsuarioOrgao),
                    m('div.filtro-orgaos', [
                        m('label', [
                            m('input[type=checkbox]', {onclick: m.withAttr('checked', ctrl.filtroOrgaos)}),
                            'Páginas de órgãos', m.component(Tooltips.paginasOrgaos)
                        ]),

                        m('label', [
                            m('input[type=checkbox]', {onclick: m.withAttr('checked', ctrl.filtroPaginasTematicas)}),
                            'Páginas temáticas', m.component(Tooltips.paginasTematicas)
                        ]),

                        m('label', [
                            m('input[type=checkbox]', {onclick: m.withAttr('checked', ctrl.filtroServicos)}),
                            'Páginas de serviços', m.component(Tooltips.paginasServicos)
                        ]),

                        m('label', [
                            m('input[type=checkbox]', {onclick: m.withAttr('checked', ctrl.filtroSuperServicos)}),
                            'Páginas de super serviços', m.component(Tooltips.paginasSuperServicos)
                        ])
                    ]),
                    filtroAvaliacoes
                ]),
                m.component(ListaPaginas, {
                    filtro: {
                        orgao: ctrl.orgao(),
                        busca: ctrl.filtro(),
                        buscaOrgao: ctrl.filtroOrgao(),
                        publicados: ctrl.filtrarPublicados(),

                        filtroOrgaos: ctrl.filtroOrgaos(),
                        filtroPaginasTematicas: ctrl.filtroPaginasTematicas(),
                        filtroServicos: ctrl.filtroServicos(),
                        filtroSuperServicos: ctrl.filtroSuperServicos(),

                        filtroPublicados: ctrl.filtroPublicados(),
                        filtroPendentesPublicacao: ctrl.filtroPendentesPublicacao(),
                        filtroAlteracoesNaoPublicadas: ctrl.filtroAlteracoesNaoPublicadas(),
                        filtroAlteracoesRecusadas : ctrl.filtroAlteracoesRecusadas(),
                        filtroEmConstrucao : ctrl.filtroEmConstrucao()
                    }
                })
            ])
        ])
    ]);
};

module.exports = {controller:HomeController, view:HomeView};