/**
 * Created by ygorazevedo on 2/8/17.
 */
'use strict';

var tooltips = require('tooltips');
var Cidade = require('administracao/unidade/modelos').Cidade;
var api = require('api');

module.exports = {
    controller: function (args) {
        this.endereco = args.endereco;
        this.lastEstadoId = this.endereco().estado().id();
        this.estados = [];

        var self = this;

        api.listarEstados().then(function (data) {
            JSON.parse(data).map(function (estado) {
                self.estados.push({
                    id: estado.id,
                    text: estado.nome + ' - ' + estado.sigla
                });
            });
        });

        this.resetCidade = function (prop) {
            if(this.lastEstadoId == prop){
                this.lastEstadoId = this.endereco().estado().id();
                return;
            }
            this.endereco().cidade().id('0');
            var el = $('#select-cidade').children().children();
            el.length > 0 ? el.select2('val', '') : '';
        }.bind(this);
    },

    view: function (ctrl) {
        $('#cep').mask('99.999-999');

        return m('fieldset#endereco', [
            m('h3', 'Endereço da Unidade de Atendimento'),
            m('label.titulo.opcional', 'Endereço'),
            m('div.input-container', {
                class: ctrl.endereco().logradouro.erro()
            },
                m('input[type=text]', {
                    value: ctrl.endereco().logradouro(),
                    onchange: m.withAttr('value', ctrl.endereco().logradouro)
                })),
            m('div.left', {style: {width: '20%'}}, m('label.titulo.opcional', 'Número'),
                m('.input-container[data-target="erro-campo-obrigatorio-numero"]', {
                    class: ctrl.endereco().numero.erro()
                } ,[
                    m('input[type=text]', {
                        value: ctrl.endereco().numero(),
                        onchange: m.withAttr('value', ctrl.endereco().numero)
                    })
                ])
            ),
            m('div.right', {style: {width: '76%'}} , m('label.titulo.opcional', 'Complemento'),
                m('.input-container', {
                    class: ctrl.endereco().complemento.erro()
                } ,[
                    m('input[type=text]', {
                        value: ctrl.endereco().complemento(),
                        onkeyup: m.withAttr('value', ctrl.endereco().complemento)
                    })
                ]),
                m('.editor-markdown', [
                    m('.footer', {
                        style: {
                            marginBottom: '0em'
                        }
                    } , [
                        m('span.counter', [
                            'caracteres restantes: ',
                            m('span', {
                                class: ctrl.endereco().complemento().length < 250 ? 'ok' : 'nok'
                            }, 250 - ctrl.endereco().complemento().length)
                        ])
                    ])
                ])
            ),
            m('label.titulo.opcional', 'País'),
            m('.margin-bottom-1', [
                m.component(require('administracao/unidade/cadastro/select-pais'), {
                    prop: ctrl.endereco().pais().id,
                    validaNome: ctrl.endereco().pais
                })
            ]),
            ctrl.endereco().pais().id() == 33 ?
                m('', [
                    m('label.titulo.opcional', 'Estado'),
                    m('.margin-bottom-1', [
                        m.component(require('administracao/unidade/cadastro/select-estado'), {
                            prop: ctrl.endereco().estado().id,
                            estados: ctrl.estados,
                            idPais: ctrl.endereco().pais().id(),
                            idCidade: ctrl.endereco().cidade().id,
                            validaNome: ctrl.endereco().estado,
                            onchange: ctrl.resetCidade
                        })
                    ])
                ]) : '',
            m('label.titulo.opcional', 'Cidade'),
            m('.margin-bottom-1', [
                ctrl.endereco().estado().id() != 0 && ctrl.endereco().pais().id() == 33 ?
                m.component(require('administracao/unidade/cadastro/select-cidade'), {
                    prop: ctrl.endereco().cidade().id,
                    idEstado: ctrl.endereco().estado().id,
                    validaNome: ctrl.endereco().cidade
                }) : m('input[type=text]', {
                    disabled: ctrl.endereco().pais().id() == 33,
                    value: ctrl.endereco().cidadeExterior(),
                    onchange: m.withAttr('value', ctrl.endereco().cidadeExterior)
                })
            ]),
            m('div.left.width-half', m('label.titulo.opcional', 'Bairro'),
                m('div.input-container', {
                    class: ctrl.endereco().bairro.erro()
                } ,[
                    m('input[type=text]', {
                        value: ctrl.endereco().bairro(),
                        onchange: m.withAttr('value', ctrl.endereco().bairro)
                    })
                ])
            ),
            m('div.right.width-half', m('label.titulo.opcional', 'CEP'),
                m('div.input-container', {
                    class: ctrl.endereco().cep.erro()
                } ,[
                    m('input[type=text]#cep', {
                        value: ctrl.endereco().cep(),
                        onchange: m.withAttr('value', ctrl.endereco().cep)
                    })
                ])
            )
        ]);
    }
};
