/**
 * @class AtribuirServicoController
 */
var AtribuirServicoController = (function (m, require) {

    return function (args) {
        var self = this;

        var CabecalhoModel = require('cabecalho/cabecalho-model');
        var avisos = require('utils/avisos');
        var permissoes = require('utils/permissoes');
        var Orgao = require('administracao/unidade/modelos').Orgao;
        var api = require('api');

        this.resetServico = function () {
            self.servico(-1);
            var el = $('#select-servico').children().children();
            el.select2('val', '');
        };

        this.cabecalho = new CabecalhoModel();
        this.caiuSessao = m.prop(false);
        this.orgao = new Orgao();
        this.servico = m.prop(-1);
        this.isPublicado = m.prop(false);
        this.publicando = m.prop(false);
        this.despublicando = m.prop(false);
        this.isAtribuido = m.prop(false);
        this.flagKey = m.prop('');

        this.todasUnidades = [];
        this.todasUnidadesFinal = [];

        this.unidadesAtribuidas = [];

        this.idsParaAtribuir = [];
        this.idsParaDesatribuir = [];

        this.servicosPaginas = [];

        this.atribuindo = false;

        this.buscarFlagTemporariaInterval;

        this.sucessoMsg;

        if(!permissoes.ehAdmin()){
            var nomeOrgao = window.loggedUser.siorg_name;
            api.pesquisarOrgao(nomeOrgao).then(function (response) {
                var orgaos = JSON.parse(response);
                self.orgao.codigoSiorg(orgaos[0].id);
                self.buscarServicosPaginasPorOrgao();
                self.listarUnidade();
            });
            self.bloquearOrgao = true;
        }
        
        this.buscarServicosPaginasPorOrgao = function () {
            api.buscarServicosPaginasPorOrgao(self.orgao.codigoSiorg())
                .then(function (data) {
                    var resposta = JSON.parse(data).resposta;
                    self.servicosPaginas = [];
                    resposta.map(function (o) {
                        self.servicosPaginas.push({
                            id: o.id,
                            text: o.nome,
                            hasUnidadeParaDespublicar: o.hasUnidadeParaDespublicar,
                            hasUnidadeParaPublicar: o.hasUnidadeParaPublicar,
                            tipo: o.tipo
                        });
                    })
                });
        };

        this.listarUnidade = function (resetServico) {
            self.buscarServicosPaginasPorOrgao();
            if(resetServico){
                self.resetServico();
            }
            self.todasUnidades = [];
            self.unidadesAtribuidas = [];
            self.idsParaAtribuir = [];
            self.idsParaDesatribuir = [];
            api.buscarUnidadePorOrgao(self.orgao.codigoSiorg()).then(function (data) {
                var resposta = JSON.parse(data).resposta;
                resposta.map(function (u) {
                    self.todasUnidades.push(u)
                });
            });
            self.todasUnidadesFinal = self.todasUnidades;
        };

        this.popularArrayParaAtribuirUnidade = function(e) {
            self.idsParaAtribuir = $(e.target).val();
        };

        this.atribuirUnidade = function() {
            self.idsParaAtribuir.map(function(v) {
                var i;
                self.todasUnidades.map(function (o, index) {
                    if(o.id == v){
                        i = index;
                    }
                });
                self.unidadesAtribuidas.push(self.todasUnidades.splice(i, 1)[0]);
            });
            $('#selectParaAtribuir').val(-1);
        };

        this.atribuirTodasUnidade = function () {
            self.todasUnidades.splice(0, self.todasUnidades.length).map(function (u) {
                self.unidadesAtribuidas.push(u);
            });
        };

        this.popularArrayParaDesatribuirUnidade = function(e) {
            self.idsParaDesatribuir = $(e.target).val();
        };

        this.desatribuirUnidade = function(e) {
            self.idsParaDesatribuir.map(function(v) {
                var i;
                self.unidadesAtribuidas.map(function (o, index) {
                    if(o.id == v){
                        i = index;
                    }
                });
                self.todasUnidades.push(self.unidadesAtribuidas.splice(i, 1)[0]);
            });
            $('#selectAtribuida').val(-1);
        };

        this.desatribuirTodasUnidade = function () {
            self.unidadesAtribuidas.splice(0, self.unidadesAtribuidas.length).map(function (u) {
                self.todasUnidades.push(u);
            });
        };

        this.buscarUnidadesAtribuidas = function () {
            self.todasUnidades = self.todasUnidadesFinal;
            self.unidadesAtribuidas = [];
            self.idsParaAtribuir = [];
            self.idsParaDesatribuir = [];
            var el = $('#select-servico').children().children();
            self.servico.tipo = m.prop(el.select2('data')[0].tipo);
            self.isPublicado(el.select2('data')[0].hasUnidadeParaDespublicar);
            self.isAtribuido(el.select2('data')[0].hasUnidadeParaPublicar);
            if(self.servico.tipo().indexOf('servico') > -1){
                api.buscarUnidadesAtribuidasPorServico(self.servico()).then(function (data) {
                    var resposta = JSON.parse(data).resposta;
                    self.setUnidadesAtribuidas(resposta);
                });
            } else {
                api.buscarUnidadesAtribuidasPorPaginaTematica(self.servico()).then(function (data) {
                    var resposta = JSON.parse(data).resposta;
                    self.setUnidadesAtribuidas(resposta);
                });
            }
        };

        this.setUnidadesAtribuidas = function (ua) {
            ua.map(function (o) {
                for(var j = 0; j < self.todasUnidades.length; j++){
                    if(self.todasUnidades[j].id === o.id && o.statusRegistro == "ATIVO"){
                        self.unidadesAtribuidas.push(o);
                        self.todasUnidades.splice(j, 1);
                        break;
                    } else {
                      self.isAtribuido(true);
                    }
                }
            });
        };

        this.atribuir = function () {
            self.atribuindo = true;
            m.redraw();
            if(self.servico.tipo().indexOf('servico') > -1){
                api.atribuirUnidadesParaServico(self.servico(), self.unidadesAtribuidas).then(function (data) {
                    var resposta = JSON.parse(data).resposta;
                    if(resposta){
                        self.sucessoMsg = 'Unidade(s) de Atendimento atualizadas com sucesso!';
                        self.criaIntervalFlag(resposta);
                    } else {
                        avisos.erro('Erro! Unidades não foram atribuídas.');
                    }
                });
            } else {
                api.atribuirUnidadesParaPaginaTematica(self.servico(), self.unidadesAtribuidas).then(function (data) {
                    var resposta = JSON.parse(data).resposta;
                    if(resposta){
                        self.criaIntervalFlag(resposta);
                    } else {
                        avisos.erro('Erro! Unidades não foram atribuídas.');
                    }
                });
            }
        };

        this.buscarFlagTemporaria = function () {
            api.buscarFlagTemporaria(self.flagKey()).then(function (resposta) {
                if(!resposta){
                    self.isAtribuido(true);
                    avisos.sucesso(self.sucessoMsg);
                    self.atribuindo = false;
                    clearInterval(self.buscarFlagTemporariaInterval);
                    self.publicando(false);
                    self.despublicando(false);
                    m.redraw();
                }
            })
        };

        this.criaIntervalFlag = function (resposta) {
            self.flagKey(resposta);
            self.buscarFlagTemporariaInterval = setInterval(function () {
                self.buscarFlagTemporaria();
            }, 3000);
        };

        this.publicar = function () {
            self.sucessoMsg = 'Unidades publicadas com sucesso!';
            if(self.servico.tipo().indexOf('servico') > -1){
                self.isPublicado(true);
                self.isAtribuido(false);
                return api.publicarUnidadesAtribuidasParaServico(self.servico());
            } else {
                self.isPublicado(true);
                self.isAtribuido(false);
                return api.publicarUnidadesAtribuidasParaPaginaTematica(self.servico());
            }
        };

        this.thenn = function (data) {
            var resposta = JSON.parse(data).resposta;
            if(resposta){
                self.criaIntervalFlag(resposta);
            } else {
                avisos.erro("Erro! Unidades não foram atribuídas.");
            }
        };

        this.despublicar = function () {
            self.sucessoMsg = 'Unidades despublicadas com sucesso!';
            self.despublicando(true);
            if(self.servico.tipo().indexOf('servico') > -1){
                api.despublicarUnidadesAtribuidasParaServico(self.servico()).then(function (data) {
                    var resposta = JSON.parse(data).resposta;
                    if(resposta){
                        self.criaIntervalFlag(resposta);
                    } else {
                        avisos.erro("Erro. Unidades não foram despublicada com sucesso.");
                    }
                });
            } else {
                api.despublicarUnidadesAtribuidasParaPaginaTematica(self.servico()).then(function (data) {
                    var resposta = JSON.parse(data).resposta;
                    if(resposta){
                        self.criaIntervalFlag(resposta);
                    } else {
                        avisos.erro("Erro. Unidades não foram despublicada com sucesso.");
                    }
                });
            }
        };

        this.stringToEllipses = function(s, max){
            if(s.length > max){
                return s.substring(0, max - 3).trim().concat('...');
            }
            return s;
        };
    };
})(m, require);

module.exports = AtribuirServicoController;