/**
 * Created by ygorazevedo on 2/2/17.
 */

var avisos = require('utils/avisos');
var buscarFlagSeloDigital = require('api').buscarFlagSeloDigital;
var salvarFlagSeloDigital = require('api').salvarFlagSeloDigital;

'use strict';

module.exports = {
    controller: function () {

        alertify.set({delay: 10000});

        var self = this;
        this.flagSeloDigitalCache;

        buscarFlagSeloDigital().then(function (data) {
            self.flagSeloDigital = (data == 1 ? m.prop(true) : m.prop(false));
            self.flagSeloDigitalCache = self.flagSeloDigital;
        });

        this.toggleSeloDigital = function (value) {
            self.flagSeloDigital = m.prop(value);
        };

        this.salvarFlagSeloDigital = function () {
            var flag = (self.flagSeloDigital() ? '1': '0');
            if(self.flagSeloDigital === self.flagSeloDigitalCache) {
                avisos.erro('Erro! O Selo Digital já está ' + (flag != '1' ? 'desabilitado.' : 'habilitado.'));
                return;
            }
            salvarFlagSeloDigital(flag).then(function (resp) {
                if(!resp){
                    avisos.sucesso('Selo Digital ' + (flag == '1' ? 'habilitado' : 'desabilitado') + ' com sucesso.');
                    setTimeout(function () {
                        window.location.replace('/editar/administracao');
                    }, 2500);
                    self.flagSeloDigitalCache = flag;
                } else {
                    avisos.erro('Algo deu errado. Tente novamente. O Selo Digital continua ' + flag == '1' ? 'desabilitado.' : 'habilitado.');
                }
            });
        }
    },

    view: function (ctrl) {

        return m('#conteudo', [
            m('span.cabecalho-cor'),
            m('#wrapper', [
                m.component(require('cabecalho/cabecalho'), {
                    metadados: false,
                    logout: true
                }),
                m('div#admin', [
                    m('div.scroll', [
                        m('fieldset#tipoDePagina', [
                            m('div.novo', [
                                m('h3', [
                                    'Situação do selo digital',
                                    m.component(require('tooltips').habilitarSeloDigital)
                                ]),
                                m('div', {style: {marginTop: '2em'}}, [
                                    m('label.switch', [
                                        m('input[type=checkbox]#habilitarSelo', {
                                            checked: ctrl.flagSeloDigital(),
                                            onchange: m.withAttr('checked', ctrl.toggleSeloDigital)
                                        }),
                                        m('div.slider.round'),
                                    ]),
                                    m('span', {style: {paddingLeft: '2em'}} , [ctrl.flagSeloDigital() ? 'Habilitado' : 'Desabilitado']),
                                    m('a.button.botao-primario.confirmarSeloDigital', {onclick: ctrl.salvarFlagSeloDigital} , m.trust('Confirmar')),
                                    m('a.button.botao-primario.confirmarSeloDigital', {href: '/editar/administracao'} , m.trust('Voltar'))
                                ])
                            ])
                        ])
                    ])
                ])
            ])
        ]);
    }
};