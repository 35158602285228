
'use strict';

var v = require('utils/validacoes');

var PortalExterno = function (config) {
    var data = (config || {});
    this.id = m.prop(data.id);
    this.nomePortalExterno = v.prop(data.nomePortalExterno || '', v.obrigatorio);
    this.nomeEstado = v.prop(data.nomeEstado || '', v.obrigatorio);
    this.siglaEstado = v.prop(data.siglaEstado || '', v.obrigatorio, v.maximo(2));
    this.urlApi = v.prop(data.urlApi || '', v.obrigatorio);
    this.periodicidadeExecucaoRotina = v.prop(data.periodicidadeExecucao || '', v.obrigatorio);
};


module.exports = {
    PortalExterno: PortalExterno
};

