var PublicarServicosDestaqueView = (function (m, require) {
    /**
     * @param {AtribuirServicoView} ctrl
     * @param args
     */
    return function (ctrl) {

        var tooltips = require('tooltips');

        var atribuir = {
            publicar: _.bind(ctrl.publicarServicosDestaque, ctrl),
            publicarButton: true,
            publicarButtonDisable: ctrl.alterado,
            publicarSucessoMsg: 'Serviços/Páginas Temáticas em destaque publicadas com sucesso. As informações apareceram no Portal em até 15 minutos!',
            publicarErroMsg: 'Erro ao publicar os Serviços/Páginas Temáticas em destaque. Tente novamente em alguns minutos',
            descartar: _.bind(ctrl.descartarServicosDestaque, ctrl),
            descartarButton: true,
            descartarButtonDisable: ctrl.alterado,
            descartarSucessoMsg: 'As alteraçõs dos Serviços/Páginas Temáticas em destaque foram descartadas.',
            descartarErroMsg: 'Erro ao descartar as alterações dos Serviços/Páginas Temáticas em destaque. Tente novamente em alguns minutos'
        };

        var tabelaServicosDestaque = function (servicosDestaque) {
                return m('table#tableDestaqueServico', [
                    m('thead', [
                        m('th', {style: {width: '28em'}}, 'Serviço/Página Temática'),
                        m('th', {style: {width: '9em'}}, 'Tipo'),
                        m('th', 'Novo'),
                    ]),
                    m('tbody', [
                        servicosDestaque.map(function (s) {
                            return m('tr', {
                                onclick: ctrl.selecionarTableRow
                            } ,[
                                m('td', s.nome),
                                m('td', s.tipo == 'servico' ? 'Serviço' : 'Página Temática'),
                                m('td', m('input[type=checkbox]', {
                                    checked: s.novo,
                                    onchange: m.withAttr('checked', function (val) {
                                        s.novo = val;
                                        ctrl.alterado(true);
                                    })
                                }))
                            ])
                        }),
                        m('td', [
                            m('button.botao-primario.left', {
                                onclick: _.bind(ctrl.removerServicoDestaque, ctrl),
                                disabled: ctrl.paraOrdenar() == -1
                            }, 'Remover'),
                        ]),
                        m('td'),
                        m('td', m('span', ctrl.servicosDestaque.length + '/8'))
                    ])
                ]);
        };

        return m('#conteudo',[
            m('span.cabecalho-cor'),
            m('#wrapper', [
                m.component(require('cabecalho/cabecalho'), {
                    metadados: true,
                    atribuir: atribuir,
                    nomeDaPagina: 'Serviços/Página temáticas em Destaque',
                    logout: true,
                    caiuSessao: m.prop(false)
                }),
                m('div#admin', [
                    m('div.scroll', [
                        m('fieldset#destaque', [
                            m('h3', { style: {paddingBottom: '1em'}}, 'Gerenciar Serviços em Destaque'),
                            m('h1', 'Adicione o Serviço/Página temática para ser exibido em destaque no Portal'),
                            m.component(require('administracao/unidade/atribuir-servico/select-servico-pagina-tematica'),
                                {
                                    prop: ctrl.servico,
                                    servicosPaginas: ctrl.servicosPaginas,
                                    width: '40em',
                                    component: m('button.botao-primario.fa.fa-plus', {
                                        style: {float: 'right'},
                                        onclick: _.bind(ctrl.adicionarDestaque, ctrl)
                                    })
                                }),
                            m('h1', { style: {paddingTop: '1em', fontSize: '0.968em'}}, 'Ordene as 8 primeiros Serviços/Páginas Temáticas que serão exibidas em destaque no Portal'),
                            m('div', [
                                m('table', [
                                    m('thead', [
                                        m('th', {style: {width: '40em', borderBottom: 'none'}}),
                                        m('th', {style: {borderBottom: 'none'}})
                                    ]),
                                    m('tbody', [
                                        m('tr', 'Serviços/Páginas Temáticas em Destaque'),
                                        m('td', tabelaServicosDestaque(ctrl.servicosDestaque)),
                                        m('td', [
                                            m('tr', m('button.botao-primario.fa.fa-caret-up', {
                                                style: {marginLeft: '15px'},
                                                onclick: _.bind(ctrl.incrementarPosicaoDestaque, ctrl)
                                            })),
                                            m('tr', m('button.botao-primario.fa.fa-caret-down', {
                                                style: {marginLeft: '15px'},
                                                onclick: _.bind(ctrl.decrementarPosicaoDestaque, ctrl)
                                            }))
                                        ])
                                    ])
                                ])
                            ])
                        ])
                    ])
                ])
            ])
        ]);

    };
})(m, require);

module.exports = PublicarServicosDestaqueView;