'use strict';

var safeGet = require('utils/code-checks').safeGet;
var erro = require('utils/erro-ajax');
var api = require('api');

module.exports = {

  view: function (ctrl, args) {
    var cidade = safeGet(args, 'prop');
    var nome = args.validaNome;
    var idEstado = args.idEstado;

    return m('div#select-cidade.input-container', {
      class: nome && nome.erro && nome.erro()
    }, m.component(require('componentes/select2'), {
      ajax: {
        url: '/editar/api/unidade-atendimento/cidades',
        dataType: 'json',
        delay: 250,
        data: function (params) {
          return {
            nome: params.term,
            'idEstado': idEstado
          };
        },
        processResults: function (data, page) {
          var result = _.map(data, function (o) {
            return {
              id: o.id,
              text: o.nome
            };
          });

          return {
            results: result
          };
        },
        cache: true
      },
      prop: cidade,
      bloquear: args.bloquear,
      width: '44em',
      minimumResultsForSearch: 1,
      minimumInputLength: 3,
      initSelection: function (element, callback) {
        api.buscarCidadePorId(cidade())
            .then(function (nome) {
              callback({
                id: nome ? cidade() : '',
                text: nome
              });
            }, erro);
      }
    }));
  }
};