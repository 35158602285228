/**
 * Created by ygorazevedo on 2/8/17.
 */
'use strict';

var v = require('utils/validacoes');

var UnidadeAtendimento = function (config) {
    var data = (config || {});
    this.id = m.prop(data.id);
    this.orgao = m.prop(data.orgao  ? new Orgao(data.orgao) : new Orgao(data), v.obrigatorio);
    this.nome = v.prop(data.nome || '', v.obrigatorio);
    this.cnpj = v.prop(data.cnpj || '');
    this.email = v.prop(data.email || '');
    this.site = v.prop(data.site || '');
    this.rip = v.prop(data.rip || '');
    this.endereco = v.prop(new Endereco(data.endereco));
    this.telefone = v.prop(data.telefone || '');
    this.horarioDeAtendimento = v.prop(data.horarios
        ? HorarioDeAtendimentoList(data.horarios)
        : [], v.obrigatorio);
    this.longitude = v.prop(data.longitude || '', v.obrigatorio);
    this.latitude = v.prop(data.latitude || '', v.obrigatorio);
    this.informacoes = v.prop(data.informacoes || '');
};

var Pais = function (config) {
    var data = (config || {});
    this.id = v.prop(data.id || '');
    this.nome = v.prop(data.nome || '');
    this.nomeIngles = v.prop(data.nomeIngles || '');
};

var Estado = function (config) {
    var data = (config || {});
    this.id = v.prop(data.id || '');
    this.nome = v.prop(data.nome || '');
    this.sigla = v.prop(data.sigla || '');
    this.pais = v.prop(new Pais(data.pais));
};

var Cidade = function (config) {
    var data = (config || {});
    this.id = v.prop(data.id || '');
    this.nome = v.prop(data.nome || '');
    this.codigoIbge = v.prop(data.codigoIbge || '');
    this.estado = v.prop(new Estado(data.pais));
};

var HorarioDeAtendimento = function (config) {
    var data = (config || {});
    this.id = m.prop(data.id);
    this.horaInicio = v.prop(data.horaInicio || '');
    this.horaFim = v.prop(data.horaFim || '');
    this.diaInicio = v.prop(data.diaInicio || '');
    this.diaFim = v.prop(data.diaFim || '');
};

var HorarioDeAtendimentoList = function (horarios) {
    var resultado = [];
    horarios.map(function (horario) {
        resultado.push(new HorarioDeAtendimento(horario));
    });

    return resultado;
};

var Endereco = function (config) {
    var data = (config || {});
    this.id = v.prop(data.id);
    this.logradouro = v.prop(data.logradouro || '', v.maximo(70));
    this.numero = v.prop(data.numero || '', v.maximo(10));
    this.complemento = v.prop(data.complemento  || '', v.maximo(250));
    this.pais = v.prop(new Pais(data.pais));
    this.estado = v.prop(new Estado(data.estado));
    this.estadoExterior = v.prop(data.estadoExterior || '');
    this.cidade = v.prop(new Cidade(data.cidade));
    this.cidadeExterior = v.prop(data.cidadeExterior || '');
    this.bairro = v.prop(data.bairro || '', v.maximo(100));
    this.cep = v.prop(data.cep || '');
};

var Orgao = function (config) {
    var data = (config || {});
    this.codigoSiorg = v.prop(data.codigoSiorg || '');
    this.dbId = v.prop(data.id || '', v.obrigatorio);
};

var ConfiguracaoSiorg = function (config) {
    var data = (config || {});
    this.atualizacaoAutomatica = v.prop(data.atualizacaoAutomatica || '');
    //Verificar com "!= undefined" pois o minuto ou hora pode ser igual a zero !
    if(data.hora != undefined && data.minuto != undefined){
        this.horario = v.prop(padding(data.hora, 2) + ":" + padding(data.minuto, 2), v.validaHora, v.obrigatorio);
    } else {
        this.horario = v.prop("", v.validaHora, v.obrigatorio);
    }
    this.dia = v.prop(data.dia || '', v.obrigatorio);
    this.hora = v.prop(data.hora || '');
    this.minuto = v.prop(data.minuto || '');
    this.importandoDadosSiorg = v.prop(data.importandoDadosSiorg || false);
    this.atualizandoBaseSiorg = v.prop(data.atualizandoBaseSiorg || false);
};

var HistoricoImportacao = function (config) {
    this.quantidadeOrgaosAtualizados = m.prop(config.quantidadeOrgaosAtualizados || 0);
    this.quantidadeOrgaosNovos = m.prop(config.quantidadeOrgaosNovos || 0);
    this.quantidadeOrgaosDesativados = m.prop(config.quantidadeOrgaosDesativados || 0);
    this.dataImportacao = m.prop(config.dataImportacao ? new Date(config.dataImportacao).toLocaleString() : null);
}

/**
 * Faz o padding a esquerda com zeros
 */
var padding = function(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

module.exports = {
    UnidadeAtendimento: UnidadeAtendimento,
    Orgao: Orgao,
    Endereco: Endereco,
    Pais: Pais,
    Estado: Estado,
    Cidade: Cidade,
    HorarioDeAtendimento: HorarioDeAtendimento,
    ConfiguracaoSiorg: ConfiguracaoSiorg,
    HistoricoImportacao: HistoricoImportacao
};
