var ImportarUnidadeView = (function (m, require) {
    /**
     * @param {ImportarUnidadeController} ctrl
     * @param args
     */
    return function (ctrl, args) {
        var EditorBase = require('componentes/editor-base');
        var Paginacao = require('componentes/paginacao');

        var itemValido = function (obj, item) {
            if(item){
                return obj && obj[item] && obj[item] != "";
            } else {
                return obj && obj != "";
            }
        };

        var buscarInformacao = function (obj, item) {
            if(item){
                return itemValido(obj, item) ? obj[item] : "Não informado";
            } else {
                return itemValido(obj, item) ? obj : "Não informado";
            }
        };

        var converterHorarios = function (horarios) {
            horariosEmString = "";

            if(horarios == "" || !horarios){
                return "Não informado"
            }

            horarios.map(function (horario) {
                if(horariosEmString != ""){
                    horariosEmString += " / ";
                }
                if(horario.diaFim && horario.diaFim != ""){
                    horariosEmString += horario.diaInicio + " a " + horario.diaFim;
                } else {
                    horariosEmString += horario.diaInicio;
                }

                horariosEmString += " de " +  horario.horaInicio + " às " + horario.horaFim;

            });
            return horariosEmString ;
        };

        var tabelaPreview = function (data) {
            return m('.ui.sixteen.wide.column', [
                m('table.ui.table', [
                    m('thead',
                        m('tr',
                            m('td',  {style: {width: '250px'}}, "Nome"),
                            m('td',  {style: {width: '150px'}}, "CNPJ"),
                            m('td',  {style: {width: '150px'}}, "Telefone"),
                            m('td',  {style: {width: '150px'}}, "CEP"),
                            m('td',  {style: {width: '150px'}}, "Número"),
                            m('td',  {style: {width: '150px'}}, "Cidade"),
                            m('td',  {style: {width: '150px'}}, "Bairro"),
                            m('td',  {style: {width: '150px'}}, "Estado"),
                            m('td',  {style: {width: '150px'}}, "Endereco"),
                            m('td',  {style: {width: '150px'}}, "Pais"),
                            m('td',  {style: {width: '150px'}}, "Latidude"),
                            m('td',  {style: {width: '150px'}}, "Longitude"),
                            m('td',  {style: {width: '150px'}}, "Horários")
                        )
                    ),
                    m('tbody', data.map(function (item) {
                        return m('tr', {
                            key: item.id
                        }, [
                            m('td.nomeUnidade', {class: itemValido(item.nome) ? "valido" : "invalido", title: buscarInformacao(item.nome)}, buscarInformacao(item.nome)),
                            m('td', {class: itemValido(item.cnpj) ? "valido" : "invalido"}, buscarInformacao(item.cnpj)),
                            m('td', {class: itemValido(item.telefone) ? "valido" : "invalido"}, buscarInformacao(item.telefone)),
                            m('td', {class: itemValido(item.endereco, "cep") ? "valido" : "invalido"}, buscarInformacao(item.endereco, "cep")),
                            m('td', {class: itemValido(item.endereco, "numero") ? "valido" : "invalido"}, buscarInformacao(item.endereco, "numero")),
                            m('td', {class: itemValido(item.endereco, "cidade") ? "valido" : "invalido"}, buscarInformacao(item.endereco, "cidade")),
                            m('td', {class: itemValido(item.endereco, "bairro") ? "valido" : "invalido"}, buscarInformacao(item.endereco, "bairro")),
                            m('td', {class: itemValido(item.endereco, "estado") ? "valido" : "invalido"}, buscarInformacao(item.endereco, "estado")),
                            m('td', {class: itemValido(item.endereco, "logradouro") ? "valido" : "invalido"}, buscarInformacao(item.endereco, "logradouro")),
                            m('td', {class: itemValido(item.endereco, "pais") ? "valido" : "invalido"}, buscarInformacao(item.endereco, "pais")),
                            m('td', {class: itemValido(item.latitude) ? "valido" : "invalido"}, buscarInformacao(item.latitude)),
                            m('td', {class: itemValido(item.longitude) ? "valido" : "invalido"}, buscarInformacao(item.longitude)),
                            m('td', {class: itemValido(item.horarios) ? "valido" : "invalido"}, converterHorarios(item.horarios))
                        ]);
                    }))
                ])
            ]);
        };

        return m.component(EditorBase, {
            cabecalhoConfig: {
                metadados: true,
                logout: true,
                cabecalho: ctrl.cabecalho,
                nomeDaPagina: 'Importar Unidades de Atendimento',
                caiuSessao: ctrl.caiuSessao
            },

            componentes: [

                m("div.importar-unidade",
                    m('fieldset#nome', [
                        m('h3', ["Órgão responsável", m.component(require('tooltips').orgaoResponsavel)]),
                        m('.input-container',
                            m.component(require('pagina/orgao/select-orgao'), {
                                prop: ctrl.orgao,
                                bloquear: ctrl.bloquearOrgao
                            })
                        )
                    ]),

                    m('fieldset#modelo', [
                        m('label.info', "Obs.: Os arquivos poderão ser importados nor formatos json, " +
                            "xml ou csv conforme o modelo predefinido. O encode do arquivo deve ser UTF-8. Selecione o modelo abaixo para baixar."),
                        m('div.modelo',
                            m.component(require('componentes/select2'), {
                                data: ctrl.tiposValidos,
                                prop: ctrl.tipoExemplo
                            }),
                            m('a.button.botao-primario', {
                                onclick: function (e) {
                                    if(ctrl.tipoExemplo() == ""){
                                        return false;
                                    }
                                },
                                href: "/editar/api/unidade-atendimento/download/exemplo-arquivo-importacao/" + ctrl.tipoExemplo().toLowerCase()
                            }, [
                                m.trust('Baixar Modelo')
                            ])
                        )
                    ]),

                    m('fieldset#importar', [
                        m('form', {
                            enctype: 'multipart/form-data',
                        }, [
                            m('h3', ["Selecione o arquivo", m.component(require('tooltips').importarUnidade)]),
                            m('div.arquivo',
                                m('input[type=text].nome-arquivo', {
                                    disabled: true,
                                    value: ctrl.file().name
                                }),
                                m('label.button.botao-primario',
                                    {for: 'importarArquivo'}, [
                                        m.trust('Selecione o arquivo')
                                    ]),
                                m('input[type=file]#importarArquivo', {
                                    onchange: ctrl.validarArquivo,
                                    accept: '.csv,.json,.xml',
                                    style: {
                                        display: 'none'
                                    }
                                }),
                                m('button.botao-primario', {
                                    onclick: _.bind(ctrl.carregarArquivo, ctrl),
                                    disabled: !ctrl.arquivoValido()
                                }, [
                                    m.trust('Carregar Arquivo')
                                ])
                            )
                        ]),

                    ]),

                    ctrl.importando() ? m('fieldset#carregando.importacao', [
                        m("div", [m('div.meter', [m('span', {"style": {"width":"100%"}})]),
                          m('div.msg-importacao', [m('label', ctrl.loaderMensagem())])
                        ])
                    ]) : m(''),

                    ctrl.data.length > 0 && !ctrl.importando() ? m('fieldset', {
                        style: {
                            display: ctrl.arquivoCarregado() ? 'block' : 'none'
                        }
                    }, m('.resultado-importacao', [
                        m('h3', ["Resultado do Carregamento do Arquivo"]),
                        m.component(Paginacao, {
                            data: ctrl.data,
                            rowsperpage: ctrl.rowsperpage,
                            pagerender: tabelaPreview,
                            wrapperclass: 'column'
                        })
                    ])) : m(''),

                    m('fieldset#salvar', [
                        m('div.btns-acao',
                            m('button.botao-primario.cancelar', {
                                onclick: ctrl.limparArquivoCarregado
                            }, [
                                'Cancelar'
                            ]),
                            m('button.botao-primario', {
                                disabled: !ctrl.importadoComSucesso(),
                                onclick: _.bind(ctrl.salvarImportacao, ctrl)
                            },[
                                m.trust('Importar Arquivo')
                            ])
                        )
                    ])
                )

            ]
        });
    };

})(m, require);

module.exports = ImportarUnidadeView;