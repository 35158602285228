'use strict';

var safeGet = require('utils/code-checks').safeGet;
var promise = require('utils/promise');
var confirmacao = require('componentes/menu/disponibilidade-confirmacao');
var permissoes = require('utils/permissoes');
var avisos = require('utils/avisos');

function botaoQueEspera(opts) {
  return m('button.botao-primario#' + opts.id, {
    onclick: opts.onclick,
    disabled: (opts.disabled ? 'disabled' : '')
  }, opts.espera ? [m('i.fa.fa-spin.fa-spinner'), m.trust('&nbsp;Alterando...')] : [m('i.fa.fa-' + opts.icon),
      (opts.servicoIndisponivel == "0" ? "Serviço Indisponível" : "Serviço Disponível")]);
}


module.exports = {
    controller: function (args) {
    this.alterarDisponibilidade = safeGet(args, 'alterarDisponibilidade');
    this.alterandoDisponibilidade = m.prop(false);
    this.salvandoServico = args.salvandoServico;
    this.caiuSessao = args.caiuSessao;
    this.orgaoId = args.orgaoId;

        this.opera = function (prop, operacao) {
            prop(true);
            this.salvandoServico(true);
            m.redraw();
            promise.onSuccOrError(
                operacao,
                _.bind(function () {
                    prop(false);
                    this.salvandoServico(false);
                    m.redraw();
                }, this));
        };

        this.onClick = function() {
            this.opera(
                this.alterandoDisponibilidade,
                this.alterarDisponibilidade()
                    .then(
                        avisos.sucessoFn('Disponibilidade do serviço alterada com sucesso'),
                        avisos.erroFn('Ocorreu ao alterar disponibilidade do serviço')));
        };


    },

  view: function (ctrl, args) {

    var tipo = _.get(args, 'tipo');
    var servicoIndisponivel = _.get(args, 'metadados.servicoIndisponivel');

    var mensagemAvaliado = '';

    return m('#secao-disponibilidade', [
      permissoes.podeAlterarDisponibilidadePagina() ? botaoQueEspera({
        id: 'disponibilidade',
        onclick: confirmacao(_.bind(ctrl.onClick, ctrl)),
        icon: '',
        servicoIndisponivel : servicoIndisponivel,
        disabled: ctrl.alterandoDisponibilidade() || ctrl.salvandoServico() || ctrl.caiuSessao(),
        espera: ctrl.alterandoDisponibilidade()
      }) : m('')
    ]);
  }
};
