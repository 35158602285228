/**
 * Created by ygorazevedo on 1/23/17.
 */
'use strict';

var tooltips = require('tooltips');
var Orgao = require('servico/modelos').Orgao;
var v = require('utils/validacoes');
var api = require('api');

module.exports = {
    controller: function (args) {
        this.orgao = new Orgao();
        this.sigla = m.prop('');
        this.nome = v.prop('', v.obrigatorio);

        var idOrgao = m.route.param('id');

        var self = this;

        if(idOrgao){
            api.buscarUnicoSemSiorg(idOrgao)
                .then(function (response) {
                    var resposta = JSON.parse(response).resposta;
                    self.orgao.nome(resposta.nomeOrgao);
                    self.orgao.dbId(resposta.id);
                    self.nome(resposta.nomeOrgao.split('(')[0].trim());
                    self.sigla(resposta.nomeOrgao.split('(')[1].slice(0, -1));
                });
        }
    },

    view: function (ctrl) {

        return m('#conteudo', [
            m('span.cabecalho-cor'),
            m('#wrapper', [
                m.component(require('cabecalho/cabecalho'), {
                    metadados: false,
                    nomeDaPagina: ctrl.nome() || 'Novo Órgão',
                }),
                m('div#admin', [
                    m('div.scroll', [
                        m.component(require('administracao/orgao/cadastro/dados-basicos'), {
                            orgao: ctrl.orgao,
                            nome: ctrl.nome,
                            sigla: ctrl.sigla
                        })
                    ])
                ])
            ])
        ]);
    }
};
