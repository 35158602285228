'use strict';

var permissoes = require('utils/permissoes');

module.exports = {

  view: function (ctrl, args) {
    return m('#dados-basicos', [
      m.component(require('./nome'), args),
      m.component(require('./sigla'), args),
      m.component(require('./nomes-populares'), args),
      m.component(require('./descricao'), args),
      permissoes.ehAdmin() ? m.component(require('./servico-digital'), args) : '',
      m.component(require('./tempo-validade-documento'), args),
      m.component(require('./gratuidade'), args),
    ]);

  }

};
