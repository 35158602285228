/**
 * @class VerificarLinkController
 */
var VerificarLinkController = (function (m, require) {

  var ConfiguracaoSiorg = require('administracao/unidade/modelos').ConfiguracaoSiorg;
  var HistoricoImportacao = require('administracao/unidade/modelos').HistoricoImportacao;
  var validacoes = require('utils/validacoes');
  var jsonfy = require('utils/jsonfy');
  var avisos = require('utils/avisos');
  var api = require('api');

  var ROOT_PATH_URL = 'verificao-links/';

  return function (args) {
    var self = this;

    this.rowsperpage = 10;
    this.historico = [];
    this.orgaoId = m.prop(-1);
    this.orgaos = [];
    this.orgaos.push({id: -1, text: 'Todos os Órgãos'});
    this.config;
    this.ordenacaoAlfabeticaCrescente = require('utils/ordenador').ordenacaoAlfabeticaCrescente;

    this.verificando = false;
    this.notificar = false;
    this.verificandoInterval = null;

    this.onInit = function () {
      self.buscarOrgaos();
      self.listarHistorico();
      self.buscarConfig();
      self.isVerificando();
    };

    this.listarHistorico = function () {
      api.get(ROOT_PATH_URL + 'historico').then(function (resposta) {
        self.historico = resposta.resposta;
      });
    };

    this.buscarConfig = function () {
      api.get(ROOT_PATH_URL + 'configuracao').then(function (resposta) {
        self.config = resposta.resposta;
      });
    };

    this.buscarOrgaos = function () {
      api.get('orgao/com-servicos-publicados-simplificado').then(function (data) {
        data.forEach(function (o) {
          self.orgaos.push({id: o.id, text: o.nomeOrgao});
        });
        self.orgaos.sort(self.ordenacaoAlfabeticaCrescente);
      });
    };

    this.verificarNotificar = function () {
      self.verificarLinks(true);
      self.notificar = true;
    };

    this.verificar = function () {
      self.verificarLinks(false);
    };

    this.verificarLinks = function (notificar) {
      api.verificarLinks(self.orgaoId(), notificar).then(function (response) {
        if(response.resposta) {
          avisos.sucesso('Verificação de links iniciada com sucesso.');
          self.verificando = true;
          self.verificandoInterval = setInterval(self.isVerificando, 3000);
        } else {
          avisos.erro('Já existe uma verificação sendo executada.');
        }
      });
    };

    this.isVerificando = function () {
      api.buscarFlagSistema('verificacando-links').then(function (response) {
        if(response.resposta.value == 0) {
          self.verificando = false;
          if(self.verificandoInterval) {
            avisos.sucesso('Verificação concluída');
            self.listarHistorico();
            clearInterval(self.verificandoInterval);
            self.verificandoInterval = null;
            if(!self.notificar) {
              window.location.href = window.location.origin + '/editar/api/verificao-links/gerar-arquivo/-1';
            } else {
              self.notificar = false;
            }
          }
        } else {
          if(!self.verificandoInterval) {
            self.verificando = true;
            self.verificandoInterval = setInterval(self.isVerificando, 3000);
          }
        }
      });
    };

    this.salvarConfigVerificacaoLinks = function () {
      if(self.config.dias > 0) {
        api.salvarConfigVerificacaoLinks(self.config).then(function (response) {
          if(response.resposta) {
            avisos.sucesso("Configuração salva com sucesso.");
          } else {
            avisos.erro("Erro ao tentar salvar configuração.");
          }
        });
      } else {
        avisos.erro('O número de dias deve ser, no mínimo, 1.');
      }
    };

    this.onInit();

  };
})(m, require);

module.exports = VerificarLinkController;