var ChatBotController = (function (m, require) {

    var avisos = require('utils/avisos');
    var buscarFlagChatBot = require('api').buscarFlagChatBot;
    var salvarFlagChatBot = require('api').salvarFlagChatBot;

    return function (args) {
        alertify.set({delay: 10000});

        var self = this;
        this.flagChatBot = m.prop();

        buscarFlagChatBot().then(function (data) {
            self.flagChatBot(data == 1);
            self.flagChatBotCache = m.prop(data == 1);
        });

        this.toggleChatBot = function (value) {
            self.flagChatBot(value);
        };

        this.salvarFlagChatBot = function () {
            var flag = (self.flagChatBot() ? '1': '0');
            if(self.flagChatBot() == self.flagChatBotCache()) {
                avisos.erro('Erro! O ChatBot já está ' + (flag != '1' ? 'desabilitado' : 'habilitado'));
                return;
            }
            salvarFlagChatBot(flag).then(function (resp) {
                console.log(resp);
                console.log(!resp);
                if(resp){
                    avisos.sucesso('ChatBot ' + (flag == '1' ? 'habilitado' : 'desabilitado') + ' com sucesso.');
                    setTimeout(function () {
                        window.location.replace('/editar');
                    }, 2000);
                    self.flagChatBotCache(flag == 1);
                } else {
                    avisos.erro('Algo deu errado. Tente novamente. O ChatBot continua ' + (flag != '1' ? 'desabilitado' : 'habilitado'));
                }
            });
        }

    };
})(m, require);

module.exports = ChatBotController;