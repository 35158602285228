/**
 * @class ImportacaoSiorgController
 */
var ImportacaoSiorgController = (function (m, require) {

    var ConfiguracaoSiorg = require('administracao/unidade/modelos').ConfiguracaoSiorg;
    var HistoricoImportacao = require('administracao/unidade/modelos').HistoricoImportacao;
    var validacoes = require('utils/validacoes');
    var jsonfy = require('utils/jsonfy');
    var avisos = require('utils/avisos');

    return function (args) {
        var api = require('api');
        var self = this;

        this.rowsperpage = 10;
        this.data = [];

        this.executandoExportacao = m.prop(false);
        this.configuracaoSiorg = null;
        this.historico = null;
        this.listaDadosSiorg = [];
        this.atualizacaoAutomatica = null;
        this.verificadorAndamentoImportacao = null;
        this.hasAlteracaoImportacao = 0;

        this.siorgImportacaoManual = m.prop('');
        this.orgaoImportacaoManual = 0;

        this.importando = false;
        this.exportando = false;

        this.init = function () {
            self.buscarConfiguracaoSiorg();
            self.listarDadosSiorg();
            self.buscarHistoricoImportacao(false);
        };

        this.salvarConfiguracao = function () {
            if(self.configuracaoSiorg.atualizacaoAutomatica() == "NAO"){
                self.desabilitarConfiguracaoAltomatica();
                return;
            }

            if(validacoes.valida(self.configuracaoSiorg)){
                var configJson = jsonfy.toJson(self.configuracaoSiorg);
                configJson.hora = self.configuracaoSiorg.horario().split(":")[0];
                configJson.minuto = self.configuracaoSiorg.horario().split(":")[1];

                api.salvarConfiguracaoSiorg(configJson).then(function (response) {
                    self.atualizacaoAutomatica = m.prop(self.configuracaoSiorg.atualizacaoAutomatica() == "SIM");
                    avisos.sucesso("Configuração de atualização registrada.");
                })
            } else {
                avisos.erro("Preencha os campos obrigatórios");
            }
        };

        this.desabilitarConfiguracaoAltomatica = function () {
            api.buscarConfiguracaoSiorg().then(function (response) {
                var resposta = JSON.parse(response).resposta;
                self.configuracaoSiorg = new ConfiguracaoSiorg(resposta);
                self.configuracaoSiorg.atualizacaoAutomatica("NAO");
                api.salvarConfiguracaoSiorg(jsonfy.toJson(self.configuracaoSiorg)).then(function (response) {
                    self.atualizacaoAutomatica = m.prop(self.configuracaoSiorg.atualizacaoAutomatica() == "SIM");
                    avisos.sucesso("Configuração de atualização registrada.");
                })
            });
        };

        this.habilitarAtualizacao = function (e) {
            var attAutomatica = e.target.checked ? "SIM" : "NAO";
            self.configuracaoSiorg.atualizacaoAutomatica(attAutomatica);
        };

        this.buscarConfiguracaoSiorg = function () {
            api.buscarConfiguracaoSiorg().then(function (response) {
                var resposta = JSON.parse(response).resposta;
                self.configuracaoSiorg = new ConfiguracaoSiorg(resposta);
                self.atualizacaoAutomatica = m.prop(self.configuracaoSiorg.atualizacaoAutomatica() == "SIM");

                if(self.configuracaoSiorg.importandoDadosSiorg() || self.configuracaoSiorg.atualizandoBaseSiorg()){
                    self.verificarAndamentoImportacao();
                }
            });
        };

        this.verificarStatusImprtacao = function () {
            api.buscarConfiguracaoSiorg().then(function (response) {
                var resposta = JSON.parse(response).resposta;
                self.configuracaoSiorg = new ConfiguracaoSiorg(resposta);
                self.atualizacaoAutomatica = m.prop(self.configuracaoSiorg.atualizacaoAutomatica() == "SIM");

                if(!self.configuracaoSiorg.importandoDadosSiorg() && !self.configuracaoSiorg.atualizandoBaseSiorg()){
                    clearInterval(self.verificadorAndamentoImportacao);
                    if(self.importando){
                      avisos.sucesso("Busca de órgãos concluída.");
                      self.importando = false;
                      self.buscarHistoricoImportacao(false);
                    } else {
                      avisos.sucesso("Importação concluída.");
                      self.exportando = false;
                      self.buscarHistoricoImportacao(true);
                    }
                    self.listarDadosSiorg();
                    // self.buscarHistoricoImportacao();
                }
            });
        };

        this.listarDadosSiorg = function(){
            api.listarDadosSiorg().then(function (response) {
                self.listaDadosSiorg = JSON.parse(response).resposta;
                self.data = self.listaDadosSiorg;
            });
        };

        this.importarTodos = function () {
            self.importando = true;
            api.importarTodos().then(function (response) {
                avisos.sucesso(JSON.parse(response).mensagem);
                self.verificarAndamentoImportacao();
            });
        };

        this.importarManual = function () {
            api.importarManual(self.siorgImportacaoManual()).then(function (response) {
                var resposta = JSON.parse(response).resposta;
                if(resposta){
                    self.orgaoImportacaoManual = resposta;
                } else {
                    self.orgaoImportacaoManual = 1;
                }
            });
        };

        this.exportarTodos = function () {

            alertify.labels.cancel = 'Não';
            alertify.labels.ok = 'Sim';
            alertify.confirm('Deseja realmente importar todos os Órgãos ?', function (result) {
                if (result) {
                  self.exportando = true;
                    if(self.executandoExportacao()){
                        avisos.alerta("Executando importação...");
                        return;
                    }
                    self.executandoExportacao(true);
                    api.exportarTodos().then(function (response) {
                        avisos.sucesso("Importando órgãos para a base de dados.");
                        self.executandoExportacao(false);
                        self.verificarAndamentoImportacao();
                    });
                }
            }.bind(this));
        };

        this.exportarManual = function () {
            var confirmMsg = self.orgaoImportacaoManual.statusRegistro == 'NOVO'
                ? 'Deseja realmente importar este Órgão ?'
                : 'Deseja atualizar o Órgão ?';
            alertify.labels.cancel = 'Não';
            alertify.labels.ok = 'Sim';
            alertify.confirm(confirmMsg, function (result) {
                if (result) {
                    api.exportarManual(self.orgaoImportacaoManual).then(function (response) {
                        var resposta = JSON.parse(response).resposta;
                        if(self.orgaoImportacaoManual.statusRegistro == 'ATUALIZADO'){
                            avisos.sucesso("Órgão atualizado com sucesso!");
                        } else {
                            avisos.sucesso("Órgão importado com sucesso!");
                        }
                        self.orgaoImportacaoManual = 0;
                        self.siorgImportacaoManual('');
                    });
                }
            }.bind(this));
        };

        this.limparForm = function () {
            self.configuracaoSiorg = new ConfiguracaoSiorg({});
        };

        this.buscarHistoricoImportacao = function(flag){
            api.buscarHistoricoImportacaoSiorg(flag).then(function (response) {
                var resposta = JSON.parse(response).resposta;
                if(resposta){
                    resposta.historico ? self.historico = new HistoricoImportacao(resposta.historico) : '';
                    self.hasAlteracaoImportacao = resposta.importacaoSiorgAlteracao;

                }
            });
        };

        this.verificarAndamentoImportacao = function () {
            var self = this;
            self.data = [];
            self.historico = null;

            self.verificarStatusImprtacao();
            self.verificadorAndamentoImportacao = setInterval(function(){
                if(self.configuracaoSiorg.importandoDadosSiorg() || self.configuracaoSiorg.atualizandoBaseSiorg()){
                    self.verificarStatusImprtacao();
                }
            }, 3000);
        };

        this.init();
    };
})(m, require);

module.exports = ImportacaoSiorgController;