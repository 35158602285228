var RelatorioAvaliacaoController = (function (m, require) {

    var avisos = require('utils/avisos');
    var Orgao = require('administracao/unidade/modelos').Orgao;
    var Servico = require('servico/modelos').Servico;
    var api = require('api');

    return function (args) {
        var self = this;
        this.orgao = new Orgao();
        this.orgao.dbId(-1);
        this.servico = new Servico();
        this.servico.dbId(-1);
        this.tipoAvaliacao = m.prop(0);
        this.inicio = m.prop();
        this.fim = m.prop();

        this.orgaos = [];
        this.orgaos.push({id: -1, text: 'Todos os Órgãos'});
        this.servicos = [];
        this.servicos.push({id: -1, text: 'Todos os Serviços'});

        this.tiposAvaliacao = [
            {id: 0, text: 'Todas'},
            {id: 1, text: 'Positivas'},
            {id: -1, text: 'Negativas'}
        ];

        this.buscarOrgaos = function () {
           api.get('orgao/com-servicos-publicados-simplificado').then(function (data) {
                data.forEach(function (o) {
                    self.orgaos.push({id: o.id, text: o.nomeOrgao});
                });
                self.orgaos.sort(self.ordenacaoAlfabeticaCrescente);
            });
        };

        this.buscarServicos = function () {
            self.servico.dbId(-1);
            api.get('servico/publicado/por-orgao/' + self.orgao.dbId()).then(function (data) {
                self.servicos = [];
                self.servicos.push({id: -1, text: 'Todos os Serviços'});
                data.map(function (s) {
                    self.servicos.push({id: s.id, text: s.nomeServico});
                });
                self.servicos.sort(self.ordenacaoAlfabeticaCrescente);
            });
        };

        this.ordenacaoAlfabeticaCrescente = require('utils/ordenador').ordenacaoAlfabeticaCrescente;

        this.buscarOrgaos();
    };
})(m, require);

module.exports = RelatorioAvaliacaoController;