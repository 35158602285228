var ListaUnidadeController = (function (m, require) {
    /**
     * @param {ListaUnidadeController} ctrl
     * @param args
     */
    return function (ctrl, args) {
        var Api = require('api');
        var PLACEHOLDER_PESQUISA = "Pesquise por nome";
        var Paginacao = require('componentes/paginacao');
        var permissoes = require('utils/permissoes');

        function podeExcluir(nomeOrgao) {
            return window.loggedUser.siorg_name === nomeOrgao;
        }

        var conteudoTableUnidades = function(unidades) {
            var retorno = [
                m('tr', [
                    m('th[colspan=3]', 'Nome'),
                    m('th.center[colspan=2]', 'Órgão'),
                    m('th.center[colspan=1]', 'Estado'),
                    m('th.center[colspan=1]', 'Cidade'),
                    m('th.text-center.no-padding-left[colspan=1]', 'Serviços Vinculados'),
                    m('th.text-center.no-padding-left[colspan=1]', 'Ações')
                ])
            ];
            _.each(unidades, function (unidade) {
                retorno.push(
                    m('tr', [
                      m('td.center[colspan=3]', [
                          m('a[href=/editar/administracao/unidade/editor/'+unidade.id+']', unidade.nome)
                      ]),
                      m('td.center[colspan=2]', unidade.orgao.nomeOrgao),
                      m('td.center[colspan=1]', (unidade.endereco ? (unidade.endereco.estado ? unidade.endereco.estado.nome : (unidade.endereco.cidadeExterior ? 'Exterior' : 'Não Informado')) : 'Não Informado')),
                      m('td.center[colspan=1]', (unidade.endereco ? (unidade.endereco.cidade ? unidade.endereco.cidade.nome : (unidade.endereco.cidadeExterior ? unidade.endereco.cidadeExterior : 'Não Informado')) : 'Não Informado')),
                      m('td.text-center[colspan=1]', unidade.servicos ? unidade.servicos : 0),
                      m('td.text-center[colspan=1]', [
                          permissoes.ehAdmin() ? excluirUnidade(unidade) :
                              !permissoes.ehEditor() && podeExcluir(unidade.orgao.nomeOrgao) ? excluirUnidade(unidade) : ''
                      ])
                    ])
                );
            });
            return m('table', [
              m('thead', m('tr', [
                m(
                  'td[colspan=8]',
                  (ctrl.unidades.length === 1)
                    ? 'Um item foi encontrado'
                    : ((ctrl.unidades.length > 1)
                    ? ctrl.unidades.length + " itens foram encontrados"
                    : "Nenhum item foi encontrado")
                )
              ])),
              m('tbody', retorno)
            ]);
        };

        function excluirUnidade(unidade) {
            return m('button.remover', {
                title: 'Remover este conteúdo',
                onclick: _.bind(ctrl.excluirUniadade, ctrl, unidade)
            }, [m('i.fa.fa-trash-o'), m('span.tooltip-content', 'Excluir unidade')]);
        }

        if(!permissoes.ehAdmin()){
            ctrl.bloquearOrgao = true;
        }

        return m('#conteudo',[
            m('span.cabecalho-cor'),
            m('#wrapper', [
                m.component(require('cabecalho/cabecalho'), {
                    metadados: false,
                    nomeDaPagina: 'Lista de páginas',
                    logout: true
                }),

                m('#bem-vindo.lista-unidades', [
                    m('div.busca-unidades', [
                        m('h4', ['Órgãos']),
                        m.component(require('pagina/orgao/select-orgao'), {
                            prop: ctrl.orgao,
                            onchange: ctrl.filtrarPorOrgao,
                            bloquear: ctrl.bloquearOrgao,
                            width: '95%'
                        })
                    ]),

                    m('div.busca-unidades', {style: {marginTop: '-1em', paddingBottom: '6em'}} ,[
                        m('h4', ['Nome']),
                        m('input[type=search][placeholder="' + PLACEHOLDER_PESQUISA + '"]', {
                            oninput: function (e) {
                                ctrl.pesquisarPorNome(e.target.value);
                            }
                        })
                    ]),

                    m('div.lista-unidades', [
                      m.component(Paginacao, {
                        data: ctrl.unidades,
                        rowsperpage: 10,
                        pagerender: conteudoTableUnidades,
                        wrapperclass: 'importar-unidade',
                        onClick: _.bind(ctrl.buscarServicosPaginasVinculados, ctrl)
                      }),
                      m('div.loader.text-center', {style: {display: ctrl.pesquisaTimeOut ? 'inherit' : 'none'}} ,[
                          m('i.fa.fa-spinner.fa-pulse.fa-5x', { style: {marginTop: '30%'}}),
                          m('br'),
                          m('br'),
                          m.trust('Pesquisando...')
                      ])
                    ])
                ])
            ])
        ]);

    };
})(m, require);

module.exports = ListaUnidadeController;