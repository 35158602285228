'use strict';

var CabecalhoModel = require('cabecalho/cabecalho-model');
var limparModelo = require('limpar-modelo');
var slugify = require('slugify');
var servicoEmEdicao = require('servico/servico-em-edicao');

module.exports = {
  controller: function (args) {
    this.cabecalho = new CabecalhoModel();
    this.servico = servicoEmEdicao.recuperar(this.cabecalho);
    this.caiuSessao = m.prop(false);

    this.editar = function () {
      var id = slugify(this.servico().nome());
      var dbId = this.servico().dbId();
      location.href = location.origin + '/editar/servico/' + id + '/' + dbId;
    };
  },

  view: function (ctrl, args) {
    var servico = limparModelo(ctrl.servico());

      var nomesPopularesView = function () {
          if (!_.isEmpty(servico.nomesPopulares())) {
              var emptyCount = 0;
              servico.nomesPopulares().forEach(function (np) {
                  if(!np.item()) {
                      emptyCount++;
                  }
              });
              if(emptyCount == servico.nomesPopulares().length) {
                  return m.component(require('servico/visualizar/view-vazia'));
              }
              return m('p', [
                  'Você também pode conhecer este serviço como: ',
                  _.map(servico.nomesPopulares(), 'item').map(function(x){ return x(); }).join(', '),
                  '.'
              ]);
          }
          return m.component(require('servico/visualizar/view-vazia'));
      };

    return m('#conteudo', [
                m('span.cabecalho-cor'),
                m('#wrapper', [
                m.component(require('cabecalho/cabecalho'), {
          metadados: true,
          nomeDaPagina: ctrl.servico().nome(),
          logout: true,
          editar: _.bind(ctrl.editar, ctrl),
          cabecalho: ctrl.cabecalho,
          caiuSessao: ctrl.caiuSessao
        }),
                    m('#visualizar', m('#main', m('section#conteudo', [m('.row',
            m('.aviso-visualizar', 'Esta é uma pré-visualização de como ficará o serviço quando publicado no Portal de Serviços.'),
                        m('.titulo-servico', m('h2', servico.nome() + (servico.sigla() ? ' (' + servico.sigla() + ')' : '')),
                            m('h4', nomesPopularesView()))),
                        m('.row', m.component(require('servico/visualizar/descricao'), servico)),
                        m('.row', m.component(require('servico/visualizar/solicitantes'), servico)),
                        m('.row', m.component(require('servico/visualizar/etapas'), servico)),
                       /* m('.row', m.component(require('servico/visualizar/legislacao'), servico)),*/
                        m('.row', m.component(require('servico/visualizar/outras-info'), servico))
                    ])))
                ])
        ]);
  }
};
