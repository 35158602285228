/**
 * @class AtribuirServicoView
 */
var ListaUnidadeController = (function (m, require) {

    return function (args) {
        var CabecalhoModel = require('cabecalho/cabecalho-model');
        var Api = require('api');
        var avisos = require('utils/avisos');

        var self = this;
        this.bloquearOrgao = false;
        this.orgao = m.prop('');
        this.cabecalho = new CabecalhoModel();
        this.caiuSessao = m.prop(false);

        this.filtro = m.prop('');
        this.unidades = [];

        this.pesquisaTimeOut;

        this.listarUnidade = function(){
            var nomeOrgao = window.loggedUser.siorg_name;
            Api.pesquisarOrgao(nomeOrgao).then(function (response) {
                var orgaos = JSON.parse(response);
                self.orgao(orgaos[0] ? orgaos[0].id : '');
                self.filtrarPorOrgao(self.orgao());
            });
        };

        this.excluirUniadade = function (unidade) {
            alertify.labels.cancel = 'Cancelar';
            alertify.labels.ok = 'Remover';
            alertify.confirm('Você tem certeza que deseja remover a unidade: ' + unidade.nome + '?', function (result) {
                if (result) {
                    Api.removerUnidade(unidade.id)
                        .then(this.listarUnidade)
                        .then(function () {
                            alertify.success(unidade.nome + ' excluído(a) com sucesso!', 0);
                        });
                }
            }.bind(this));
        };

        this.pesquisarPorNome = function (termo) {
            if(self.pesquisaTimeOut) {
                clearTimeout(self.pesquisaTimeOut);
            }
            self.pesquisaTimeOut = setTimeout(function () {
                Api.pesquisarUnidadePorNome(termo).then(function (response) {
                    self.unidades = response.resposta;
                    self.buscarServicosPaginasVinculados(1);
                    self.pesquisaTimeOut = _.noop();
                });
            }, 2000)
        };

        this.filtrarPorOrgao = function (orgaoId) {
            Api.buscarUnidadePorOrgao(orgaoId).then(function (response){
                self.unidades = JSON.parse(response).resposta;
                self.buscarServicosPaginasVinculados(1);
            });
        };

        this.listarUnidade();

        this.buscarServicosPaginasVinculados = function (pagina) {
            var head = (pagina * 10),
              tail = head - 10;
            var ids = [];
            for (var i = tail; i < head; i++){
                if(i >= self.unidades.length){
                    break;
                }
                ids.push(self.unidades[i].id);
            }
            Api.servicosPaginasVinculados(ids).then(function (response) {
                var resposta = response.resposta;
                for (var i = tail; i < head; i++){
                    if(i >= self.unidades.length){
                        break;
                    }
                    self.unidades[i].servicos = resposta[self.unidades[i].id];
                }
            });
            m.redraw();
        };

    };
})(m, require);

module.exports = ListaUnidadeController;