/**
 * Created by ygorazevedo on 1/23/17.
 */
'use strict';

var referencia = require('referencia');

module.exports = {

    controller: function (args) {
        this.servico = args;
        this.converter = new window.showdown.Converter();

        this.isValidoPor = function () {
            return this.servico.validadeDocumento().tipo() === 'Válido por';
        };

        this.isValidade = function () {
            return this.servico.validadeDocumento().tipo() || this.servico.validadeDocumento().descricao();
        }

    },

    view: function (ctrl) {

        var validade = function () {
            if(!ctrl.servico.validadeDocumento().tipo()){
                return '';
            }
            if (ctrl.isValidoPor()) {
                return m('p', [
                    m('span', 'Válido por '),
                    m('span', ctrl.servico.validadeDocumento().validade()),
                    m('span', ' '),
                    m('span', ctrl.servico.validadeDocumento().validadeTipo())
                ]);
            } else {
                return m('p', [
                    m('span', 'Sem validade')
                ]);
            }
        };

        if (ctrl.isValidade()) {
            return m('#servico-tempo', [
                m('h3', 'Validade do Documento'),
                validade(),
                ctrl.servico.validadeDocumento().descricao().length > 0 ? m('br') : '',
                ctrl.servico.validadeDocumento().descricao().length > 0 ? m('h4', 'Informações adicionais ao tempo estimado') : '',
                m.trust(ctrl.converter.makeHtml(ctrl.servico.validadeDocumento().descricao()))
            ]);
        }
        return m.component(require('servico/visualizar/view-vazia'));
    }
};
