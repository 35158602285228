'use strict';

var referencia = require('referencia');
var Gratuidade = require('servico/modelos').Gratuidade;

module.exports = {

    controller: function (args) {
        this.servico = args;
        this.nomeOrgao = m.prop('');
        this.converter = new window.showdown.Converter();

        this.obterOrgao = function (orgao) {
            m.request({
                method: 'GET',
                url: '/editar/api/orgao/nome-por-siorg',
                data: {
                    urlOrgao: orgao
                },
                deserialize: function (data) {
                    return data;
                }

            }).then(_.bind(function (nome) {
                this.nomeOrgao(nome);
            }, this));
        };

        this.ehFeminino = function () {
            return _.includes(referencia.orgaosFemininos, _.chain(this.nomeOrgao().toLowerCase()).words().first().value());
        };

        this.obterOrgao(this.servico.orgao().nome());
    },

    view: function (ctrl) {

        var retornaTexto = function (titulo, texto) {
            return m('#servico-tratamento-dispensado-usuario', [
                m('h3', titulo),
                m('ul', m('li', m('span.markdown', m.trust(ctrl.converter.makeHtml(texto)))))
            ]);
        }


        var gratuidadeView = function (ehGratuito) {
            if (ehGratuito) {
                return m('', m('p', 'Este serviço é gratuito para o cidadão.'));
            }
            return '';
        };

        return m('', [
            m('h3#servico-outras-info.subtitulo-servico', 'Outras informações'),
            m('.coluna-descricao-servico', [
                m('.row', m.component(require('servico/visualizar/tempo'), ctrl.servico)),
                m('.info-extra', gratuidadeView(ctrl.servico.gratuidade() === Gratuidade.GRATUITO)),

                !_.isEmpty(ctrl.servico.orgao().contato()) ?
                m('.row',

                    m('p.orgao-contato.markdown', [
                        'Para mais informações ou dúvidas sobre este serviço, entre em contato: ',
                        m.trust(ctrl.converter.makeHtml(ctrl.servico.orgao().contato()))
                    ])) : '',

                m('.row',
                    m('p.separacao-orgao', [
                        'Este é um serviço ',
                        ctrl.ehFeminino() ? 'da ' : 'do ',
                        m('a', ctrl.nomeOrgao()),
                        '. Em caso de dúvidas, reclamações ou sugestões favor contactá-',
                        ctrl.ehFeminino() ? 'la.' : 'lo.'
                    ])),

                m('hr', {
                    style: {
                        'margin-top': '20px',
                        border: 'none'
                    }
                }),

                m('.row', m.component(require('servico/visualizar/validade'), ctrl.servico)),
                m('.row', m.component(require('servico/visualizar/legislacao'), ctrl.servico)),

                ctrl.servico.tratamentoDispensadoAtendimento() ? m('.row',
                    retornaTexto('Tratamento a ser dispensado ao usuário no atendimento',
                        ctrl.servico.tratamentoDispensadoAtendimento())) : '',

                ctrl.servico.condicoesAcessibilidade() ? m('.row',
                    retornaTexto('Informações sobre as condições de acessibilidade, sinalização, limpeza e conforto dos locais de atendimento',
                        ctrl.servico.condicoesAcessibilidade())) : '',

                ctrl.servico.tratamentoPrioritario() ? m('.row',
                    retornaTexto('Informação sobre quem tem direito a tratamento prioritário',
                        ctrl.servico.tratamentoPrioritario())) : ''

            ])
        ]);
    }
};
