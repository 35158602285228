'use strict';

module.exports = function (m) {
    m.route.mode = 'pathname';

    m.route(document.body, '/editar', {
        '/editar'                                           : require('home/home'),
        '/editar/erro'                                      : require('erro'),
        '/editar/administracao/importar-ajuda'              : require('administracao/importar-ajuda'),
        '/editar/administracao/habilitar-selo-digital'      : require('administracao/habilitar-selo-digital'),
        '/editar/administracao/habilitar-brasil-cidadao'    : require('administracao/brasil-cidadao/module-brasil-cidadao'),
        '/editar/administracao/habilitar-chatbot'           : require('administracao/chatbot/module-chatbot'),
        '/editar/administracao/unidade/importar'            : require('administracao/unidade/importacao/module-importar-unidade'),
        '/editar/administracao/unidade/lista'               : require('administracao/unidade/lista/module-lista-unidade'),
        '/editar/administracao/unidade/atribuir'            : require('administracao/unidade/atribuir-servico/module-atribuir-servico'),
        '/editar/administracao/unidade/editor'              : require('administracao/unidade/editor'),
        '/editar/administracao/unidade/editor/:id'          : require('administracao/unidade/editor'),
        '/editar/administracao/orgao/editor'                : require('administracao/orgao/editor'),
        '/editar/administracao/orgao/lista'                 : require('administracao/orgao/lista/module-lista-orgao'),
        '/editar/administracao/orgao/integracao'            : require('administracao/orgao/importacao/module-importacao-siorg'),
        '/editar/administracao/orgao/editor/:id'            : require('administracao/orgao/editor'),
        '/editar/administracao/pagina-destaque/servico'     : require('administracao/pagina-destaque/servico/module-servico-destaque'),
        '/editar/administracao/pagina-destaque/categoria'   : require('administracao/pagina-destaque/categoria/module-categoria-destaque'),
        '/editar/administracao/categorias'                  : require('administracao/categorias/module-categorias'),
        '/editar/administracao/portais-externos/lista'      : require('administracao/portais-externos/lista/module-lista-portais-externos'),
        '/editar/administracao/portais-externos/cadastro'   : require('administracao/portais-externos/editor'),
        '/editar/administracao/portais-externos/edicao/:id'   : require('administracao/portais-externos/editor'),
        '/editar/administracao/relatorio'                   : require('administracao/relatorio-avaliacao/module-relatorio-avaliacao'),
        '/editar/administracao/verificar-links'             : require('administracao/verificar-links/module-verificar-links'),
        '/editar/administracao/menu-rodape'                 : require('administracao/menu-rodape/editor'),
        '/editar/servico/:id/:dbId'                         : require('servico/editor'),
        '/editar/servico/:id'                               : require('servico/editor'),
        '/editar/super-servico/:id/:dbId'                   : require('super-servico/editor'),
        '/editar/super-servico/:id'                         : require('super-servico/editor'),
        '/editar/orgao/:id/:dbId'                           : require('pagina/orgao/editor'),
        '/editar/orgao/:id'                                 : require('pagina/orgao/editor'),
        '/editar/importar-xml/:any'                         : require('importar-xml/editor'),
        '/editar/pagina-tematica/:id/:dbId'                 : require('pagina/tematica/editor'),
        '/editar/pagina-tematica/:id'                       : require('pagina/tematica/editor'),
        '/editar/pagina/nova'                               : require('pagina/nova'),
        '/editar/visualizar/servico/:id/:dbId'              : require('servico/visualizar'),
        '/editar/visualizar/servico/:id'                    : require('servico/visualizar'),
        '/editar/visualizar/pagina/orgao/:id'               : require('pagina/orgao/visualizar'),
        '/editar/visualizar/pagina/tematica/:id'            : require('pagina/tematica/visualizar'),
        '/editar/validar/servico/:id/:dbId'                 : require('servico/validar'),
        '/editar/validar/pagina-tematica/:id'               : require('pagina/tematica/validar')
    });
};
