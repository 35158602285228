'use strict';

var Api = require('api');

module.exports = {

    controller: function (args) {
        this.servico = args.servico;
    },

    view: function (ctrl) {

        return m('', [

            m('fieldset#informacoes-tratamento-prioritario', [
                m('h3', [
                    'Informações sobre quem tem direito a tratamento prioritário',
                    m.component(require('tooltips').informacoesTratamentoPrioritario)
                ]),

                m('div.input-container', {
                        class: ctrl.servico().tratamentoPrioritario.erro(),
                    },
                    m.component(require('componentes/editor-markdown'), {
                        rows: 3,
                        maximo: 1000,
                        value: ctrl.servico().tratamentoPrioritario(),
                        onchange: m.withAttr('value', ctrl.servico().tratamentoPrioritario),
                        onblur: m.withAttr('value', ctrl.servico().tratamentoPrioritario),
                    })
                )
            ])
        ]);
    }
};
