'use strict';

var permissoes = require('utils/permissoes');
var Api = require('api');

module.exports = {

    controller: function (args) {
        this.servico = args.servico;
    },

    view: function (ctrl) {

        return m('', [
            m('fieldset#informacoes-acessibilidade', [
                m('h3', [
                    'Informações sobre as condições de acessibilidade, sinalização, limpeza e conforto dos locais de atendimento',
                    m.component(require('tooltips').informacoesAcessibilidade)
                ]),

                m('div.input-container', {
                        class: ctrl.servico().condicoesAcessibilidade.erro(),
                    },
                    m.component(require('componentes/editor-markdown'), {
                        rows: 3,
                        maximo: 1000,
                        value: ctrl.servico().condicoesAcessibilidade(),
                        onchange: m.withAttr('value', ctrl.servico().condicoesAcessibilidade),
                        onblur: m.withAttr('value', ctrl.servico().condicoesAcessibilidade),
                    })
                )
            ])
        ]);
    }
};
